import React, { useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import Payments from './Payments'
import NearlyDays from './NearlyDays'
import PayFrequency from './PayFrequency'
import PayUserNumber from './PayUserNumber'
import FirstPaySituation from './FirstPaySituation'
import AccumulateDistribution from './AccumulateDistribution'
import Ltv from './Ltv'
import { useAppDispatch } from '@/hook/reduxHook'
import { setterUserInfoStoreState } from '@/store/userInfo'

const Wrapper = styled.div`
  width: 100%;
  min-width: 1390px;
  // margin-bottom: 50px;
  & > div {
    width: 100%;
    margin-bottom: 25px;
    height: 100%;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`

const TwoComWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  & > div:nth-child(1) {
    margin-right: 20px;
  }
  & > div {
    width: calc((100% - 20px) / 2);
    height: 380px;
  }
`
export default function CoreBoard() {
  const dispatch = useAppDispatch()
  useLayoutEffect(() => {
    if (document.getElementById('dataBoardLayoutId')) {
      document.getElementById('dataBoardLayoutId')!.scrollTop = 0
    }
  }, [])
  useEffect(() => {
    dispatch(
      setterUserInfoStoreState({
        type: 'sideBareType',
        value: 1,
      })
    )
  }, [])

  return (
    <Wrapper>
      <Payments />
      <NearlyDays />
      <TwoComWrapper>
        <PayFrequency />
        <PayUserNumber />
      </TwoComWrapper>
      <Ltv />
      <AccumulateDistribution />
      <FirstPaySituation />
    </Wrapper>
  )
}
