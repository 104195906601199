import React, { useState } from 'react'
import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import RenderEdit from './RenderEdit'
import { EditIcon } from '../styles'

import { EditContentWrapper, EditContent } from './RenderEdit/styles'

interface RenderContentProps {
  handleCondition: (newList: GroupItem) => void
  item: GroupItem
}
function RenderContent(props: RenderContentProps) {
  const { item, handleCondition } = props
  const [open, setOpen] = useState(false)

  const onChange = (e: RadioChangeEvent) => {
    handleCondition({
      ...item,
      valueType: e.target.value,
    })
  }

  const TimeEdit = (
    <EditContentWrapper>
      <div className="title">分组方式</div>
      <EditContent>
        <Radio.Group onChange={onChange} value={item.valueType}>
          <Radio value={1}>汇总</Radio>
          <Radio value={2}>不汇总</Radio>
        </Radio.Group>
      </EditContent>
    </EditContentWrapper>
  )

  const Content = (
    <>
      {item.dataType === '时间' && TimeEdit}
      {item.dataType === '数值' && (
        <RenderEdit
          handleCondition={newArr => handleCondition(newArr)}
          itemEdit={item}
        />
      )}
    </>
  )

  return (
    <PopoverTips
      placement="bottomRight"
      trigger="click"
      content={Content}
      overlayInnerStyle={{
        width: '322px',
        position: 'relative',
      }}
      open={open}
      setOpen={val => setOpen(val)}
    >
      <EditIcon className="edit">
        <SvgIcon icon="#icon-bianji" className="icon" />
      </EditIcon>
    </PopoverTips>
  )
}

export default RenderContent
