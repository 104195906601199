import React from 'react'

import { GroupsWrapper, Extra } from './styles'
import SvgIcon from '@/components/SvgIcon'
import DragGroup from './DragGroup'

interface Props {
  groupDataList: GroupItem[]
  iconPath: string
  groupFieldCollectionList: ExtendConditionDatum
  groupConditionDatumList: ExtendConditionDatum[]
  needChildren?: boolean
  icon?: string
  handleGroupDataList: (val: GroupItem[]) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
  maxLength?: number
}

function GroupsCom(props: Props) {
  const {
    icon, // 是否存在icon
    needChildren = true, // 是否有需要Children
    groupDataList, // 分组项数据
    iconPath, // 图标地址
    groupFieldCollectionList, // 收藏列表
    groupConditionDatumList, // 数据列表
    maxLength = 20, // 最大添加数
    handleFieldCollection, // 收藏
    handleGroupDataList, // 数据操作
  } = props

  /* 新增 */
  const handleAddGroupFunc = () => {
    const nameArr = groupDataList.map(item => item.label)
    const arrConditionDatumList = groupConditionDatumList
      .map(item => item.list)
      .flat()
      .filter(item => !nameArr.includes(item.label))
    const restOfConditionDatumList: ExtendConditionDatumList[] =
      arrConditionDatumList
    const firstItem = restOfConditionDatumList[0]
    // 取第一个出来用
    const useItem = {
      ...firstItem,
      id: groupDataList.length,
      index: groupDataList.length,
      valueType: 1, // 默认选择1 // 只有
      value: [], // 值
      hasOption: firstItem.dataType === '数值' || firstItem.dataType === '时间',
    }
    handleGroupDataList([...groupDataList, useItem])
  }

  /* 删除 */
  const handleDelFunc = (index: number) => {
    const newList = JSON.parse(JSON.stringify(groupDataList))
    newList.splice(index, 1)
    handleGroupDataList(newList)
  }

  return (
    <GroupsWrapper>
      <DragGroup
        groupDataList={groupDataList}
        iconPath={iconPath}
        icon={icon}
        groupFieldCollectionList={groupFieldCollectionList}
        groupConditionDatumList={groupConditionDatumList}
        handleDel={handleDelFunc}
        handleGroupDataList={handleGroupDataList}
        handleFieldCollection={handleFieldCollection}
      />
      {needChildren && groupDataList.length < maxLength && (
        <>
          <Extra onClick={handleAddGroupFunc}>
            <div className="add">
              <SvgIcon icon="#icon-leimucuquanbu" className="icon" />
              添加分组项
            </div>
          </Extra>
          {/* <Extra onClick={  () => handleGroupDataList([])}>
            <div className="add">
              <SvgIcon icon="#icon-leimucuquanbu" className="icon" />
              测试清空
            </div>
          </Extra> */}
        </>
      )}
    </GroupsWrapper>
  )
}

export default GroupsCom
