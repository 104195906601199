/* eslint-disable indent */
import React, { CSSProperties, useEffect, useState } from 'react'
import { Popover } from 'antd'
import {
  PopoverText,
  // DefaultSvgBox,
  MaskDiv,
  PopoverTipsStyle,
  PopoverTextStyle,
  overlayInnerDefaultStyle,
} from './styles'
import './index.css'

interface IPopoverTipsProps {
  content: string | React.ReactElement
  children?: React.ReactElement | string | number
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
  isBlackStyle?: boolean // 黑 or 白
  trigger?: 'hover' | 'click'
  overlayInnerStyle?: CSSProperties
  overlayInnerBlackStyle?: CSSProperties
  open?: boolean
  needMask?: boolean
  setOpen?: (val: boolean) => void
  title?: string
  icon?: any
}
const PopoverTips = (props: IPopoverTipsProps) => {
  const {
    content,
    children,
    placement = 'topLeft',
    isBlackStyle = false,
    open,
    needMask = false,
    trigger = 'hover',
    overlayInnerStyle = overlayInnerDefaultStyle,
    overlayInnerBlackStyle,
    setOpen,
    title = '',
    icon,
  } = props
  const [openPop, setOpenPop] = useState<boolean>(false)
  const getPopColorStyleName = () => {
    switch (placement) {
      case 'topLeft':
      case 'top':
      case 'topRight':
        return 'popoverTopColorStyle'
      case 'left':
      case 'leftTop':
      case 'leftBottom':
        return 'popoverLeftColorStyle'
      case 'right':
      case 'rightTop':
      case 'rightBottom':
        return 'popoverRightColorStyle'
      case 'bottom':
      case 'bottomLeft':
      case 'bottomRight':
        return 'popoverBottomColorStyle'
      default:
        return 'popoverTopColorStyle'
    }
  }

  useEffect(() => {
    setOpenPop(!!open)
  }, [props])

  function RenderContent() {
    if (children) {
      if (needMask) {
        return (
          <>
            {children}
            <MaskDiv show={openPop} />
          </>
        )
      }
      // return <DefaultSvgBox>{children}</DefaultSvgBox>
      return children
    }
    return (
      <PopoverText style={isBlackStyle ? PopoverTextStyle : undefined}>
        {icon || (
          <svg className="icon tip-icon" aria-hidden="true">
            <use xlinkHref="#icon-008duihuakuang-1" />
          </svg>
        )}
      </PopoverText>
    )
  }

  return (
    <Popover
      key={JSON.stringify(title)}
      title={title}
      placement={placement}
      overlayClassName={
        isBlackStyle
          ? `${getPopColorStyleName()} popoverColorStyle`
          : `${getPopColorStyleName()}`
      }
      content={() => (
        <div style={isBlackStyle ? PopoverTipsStyle : undefined}>{content}</div>
      )}
      open={open}
      onOpenChange={(val: boolean) => {
        setOpen ? setOpen(val) : setOpenPop(val)
      }}
      arrowPointAtCenter
      zIndex={9999}
      trigger={trigger}
      overlayInnerStyle={
        isBlackStyle
          ? {
              maxWidth: '250px',
              padding: '6px 10px',
              backgroundColor: 'rgb(50, 65, 87)',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              ...overlayInnerBlackStyle,
            }
          : overlayInnerStyle
      }
      destroyTooltipOnHide={{ keepParent: false }}
    >
      {RenderContent()}
    </Popover>
  )
}

export default PopoverTips
