import moment from 'moment'
import {
  getPayPanelExplore,
  getCoreBoardExplore,
  getActiveBoardExplore,
} from '@/pages/BusinessAnalysis/components/TemplateBoard/ContentWrap/getBoardData'
import { parsePercentFloatNum } from '@/utiles/common'

export const getResultData = async (exploreType, exploreParams) => {
  if (exploreType === 'payBoard') {
    const result = await getPayPanelExplore(exploreParams)
    return result
  }
  if (exploreType === 'cordBoard') {
    const result = await getCoreBoardExplore(exploreParams)
    return result
  }
  if (exploreType === 'activeBoard') {
    const result = await getActiveBoardExplore(exploreParams)
    return result
  }
}
export const getRate = props => {
  const { date, byTime, yearOnYear, ringRatio, totalNumber, ringRatioDate } =
    props
  let unitTime = ''
  switch (byTime) {
    case 'byDay':
      unitTime = `(${moment(date).format('dd')})`
      break
    case 'byWeek':
      unitTime = '当周'
      break
    case 'byMonth':
      unitTime = '月'
      break
    default:
      ''
      break
  }
  let ringRatioText = '' // 环比
  let yearOnYearText = '' //  同比
  if (ringRatio) {
    if (ringRatio * 1 > 0) {
      ringRatioText = `对比${ringRatioDate}增长了${parsePercentFloatNum(
        Number(ringRatio) * 1
      )}%`
    } else {
      ringRatioText = `对比${ringRatioDate}下降了${parsePercentFloatNum(
        Number(ringRatio) * -1
      )}%`
    }
  }
  if (yearOnYear && yearOnYear !== '-') {
    const yearDate = moment(date).subtract(1, 'week').format('YYYY-MM-DD')
    if (yearOnYear * 1 > 0) {
      yearOnYearText = `对比${yearDate}增长了${parsePercentFloatNum(
        Number(yearOnYear) * 1
      )}%`
    } else {
      yearOnYearText = `对比${yearDate}下降了${parsePercentFloatNum(
        Number(yearOnYear) * -1
      )}%`
    }
  }
  const rate = {
    date: date + unitTime,
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
    totalNumber: Number(totalNumber),
  }
  return rate
}
export const getRingRatioDate = (byTime, date) => {
  if (byTime === 'byDay') {
    return moment(date).subtract(1, 'd').format('YYYY-MM-DD')
  }
  if (byTime === 'byWeek') {
    return moment(date).subtract(1, 'week').format('YYYY-MM-DD')
  }
  if (byTime === 'byMonth') {
    return moment(date).subtract(1, 'month').format('YYYY-MM')
  }
}
export const downLoadName = (boardTitle, byTime, date) => {
  let dateText = ''
  if (byTime === 'byDay') {
    const dayText = moment(date).format('YYYY-MM-DD')
    dateText = `${dayText}-${dayText}`
  }
  // if (byTime === 'byWeek') {
  //   const dayText = moment(date).subtract(1, 'd').format('YYYY-MM-DD')
  //   dateText =  moment(date).subtract(7, 'd').format('YYYY-MM-DD')
  // }
  if (byTime === 'byMonth') {
    const stateDate = moment(date.slice(0, -1))
      .startOf('month')
      .format('YYYY-MM-DD')
    const endDate = moment(date.slice(0, -1))
      .endOf('month')
      .format('YYYY-MM-DD')
    dateText = `${stateDate}-${endDate}`
  }
  return `${boardTitle}_${dateText}`
}
