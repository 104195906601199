import React, { useState, useMemo } from 'react'
import { Select } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import { RelativeTimeComWrapper, SelectWrapper, NumberWrapper } from './styles'

const { Option } = Select

interface Props {
  handleValue: (value: any) => void
  filterValue: any
}
function RelativeTime(props: Props) {
  const { handleValue, filterValue } = props

  const [selectValue, setSelectValue] = useState<string>('区间')
  const [selectBetweenValue, setSelectBetweenValue] = useState<string>('D')
  const [beforeFocused, setBeforeFocused] = useState<boolean>(false)
  const [afterFocused, setAfterFocused] = useState<boolean>(false)
  const handleChange = (value: string) => {
    setSelectValue(value)
    if (value === '区间') {
      /* 根据之前的值来 判断是 天 小时 分钟 */
      handleValue(`-1${selectBetweenValue}, 1${selectBetweenValue}`)
    }
    if (value !== '区间') {
      handleValue(value)
    }
  }

  function SelectCom() {
    return (
      <SelectWrapper
        size="small"
        // @ts-ignore
        onChange={handleChange}
        value={selectValue}
        suffixIcon={null}
        dropdownStyle={{
          color: '#67729d',
          fontWeight: 400,
        }}
      >
        <Option value="区间">区间</Option>
        <Option value="当天">当天</Option>
        <Option value="当周">当周</Option>
        <Option value="当月">当月</Option>
      </SelectWrapper>
    )
  }

  /*
    获取时间
      如果是天 则 需要 取绝对值 * 24
      其余 取 绝对值
  */
  const getTime = (value: number) => {
    let suffix = ''
    switch (selectBetweenValue) {
      case 'D':
        suffix = '小时'
        break
      case 'H':
        suffix = '小时'
        break
      case 'M':
        suffix = '分'
        break
      default:
        suffix = '小时'
        break
    }
    const val = Math.abs(value)
    const num = selectBetweenValue === 'D' ? val * 24 : val
    if (value <= 0) {
      return `前${num}${suffix}`
    }
    return `后${num}${suffix}`
  }

  function BetweenSelectCom() {
    const beforeValue = useMemo(
      () => filterValue?.split(',')[0].slice(0, -1),
      [filterValue]
    )
    const afterValue = useMemo(
      () => filterValue?.split(',')[1].slice(0, -1),
      [filterValue]
    )
    return (
      <SelectWrapper
        size="small"
        onChange={value => {
          // @ts-ignore
          setSelectBetweenValue(value)
          handleValue(`${beforeValue}${value},${afterValue}${value}`)
        }}
        value={selectBetweenValue}
        dropdownMatchSelectWidth={false}
        suffixIcon={null}
        dropdownStyle={{
          color: '#67729d',
          fontWeight: 400,
        }}
      >
        <Option value="D">天(相对)</Option>
        <Option value="H">小时</Option>
        <Option value="M">分钟</Option>
      </SelectWrapper>
    )
  }

  /* 区间 */
  function BetweenCom() {
    const beforeValue = useMemo(
      () => filterValue.split(',')[0].slice(0, -1),
      [filterValue]
    )
    const afterValue = useMemo(
      () => filterValue.split(',')[1].slice(0, -1),
      [filterValue]
    )
    const beforeTime = `${getTime(beforeValue)}`
    const afterTime = `${getTime(afterValue)}`
    const info = `【事件时间】发生在【register】${beforeTime}到 ${afterTime}之间`
    const suffix = filterValue.split(',')[0].slice(-1) // 天 小时 分
    return (
      <>
        <NumberWrapper
          size="small"
          autoFocus={beforeFocused}
          value={beforeValue}
          // @ts-ignore
          formatter={value => (Number(value) > 0 ? `+${value}` : value)}
          // @ts-ignore
          onChange={value => {
            /* 起始值 必须小于 结束值 */
            //  起始值 大于 结束值 则结束值 等于输入值
            if (!value > afterValue) {
              handleValue(`${value}${suffix},${value}${suffix}`)
            } else {
              handleValue(`${value}${suffix},${afterValue}${suffix}`)
            }
          }}
          onFocus={() => setBeforeFocused(true)}
          onBlur={() => setBeforeFocused(false)}
        />
        <span className="word">到</span>
        <NumberWrapper
          size="small"
          // @ts-ignore
          style={{ marginRight: 4 }}
          // @ts-ignore
          formatter={value => (Number(value) > 0 ? `+${value}` : value)}
          autoFocus={afterFocused}
          value={afterValue}
          // @ts-ignore
          onChange={value => {
            /* 结束值 不能 小于 起始值 */
            //  结束值 小于 起始值 则起始值 等于输入值
            if (!value < beforeValue) {
              handleValue(`${value}${suffix},${value}${suffix}`)
            } else {
              handleValue(`${beforeValue}${suffix},${value}${suffix}`)
            }
          }}
          onFocus={() => setAfterFocused(true)}
          onBlur={() => setAfterFocused(false)}
        />
        <BetweenSelectCom />
        <PopoverTips content={info} placement="top" isBlackStyle />
      </>
    )
  }

  /* 当天 当周 当月 */
  function DateCom() {
    const info = `【事件时间】发生在【register】${selectValue}`
    return <PopoverTips content={info} placement="top" isBlackStyle />
  }

  return (
    <RelativeTimeComWrapper>
      <span className="word">在</span>
      <SelectCom />
      {selectValue === '区间' && <BetweenCom />}
      {selectValue !== '区间' && <DateCom />}
    </RelativeTimeComWrapper>
  )
}

export default RelativeTime
