import React, { useState, useMemo } from 'react'
import { Button } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import SelectTagsCom from '../SelectTagsCom' //  下拉框输入选择 可以批量添加
import InputCom from '../InputCom' // 输入框
import {
  ElementPositionComWrapper,
  Word,
  NumberWrapper,
  OperatorSelect,
  MenuWrapper,
  MenuItemWrapper,
} from './styles'

interface Props {
  handleValue: (value: string) => void
  filterValue: string
  labelEn: string
  tableType: number
}
interface FilterProps {
  label: string
  value?: string
  info?: string
}

const filterList = [
  {
    label: '等于',
    info: '',
  },
  {
    label: '不等于',
    info: '指该项有值且不等于设定值。如果该项无值，则不会被筛选到',
  },
  {
    label: '包括',
    info: '',
  },
  {
    label: '不包括',
    info: '',
  },
  {
    label: '有值',
    info: '',
  },
  {
    label: '无值',
    info: '',
  },
]

const handleText = (oldFilter: FilterProps, newFilter: FilterProps) => {
  const equalArr = ['等于', '不等于']
  if (
    equalArr.includes(oldFilter.label) &&
    equalArr.includes(newFilter.label)
  ) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  const sameArr = ['包括', '不包括']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  return {
    ...newFilter,
    value: '',
  }
}

function ElementPosition(props: Props) {
  const { handleValue, filterValue, labelEn, tableType } = props
  const numberValue = useMemo(() => filterValue?.split(',')[0], [filterValue])
  const filterLabel = useMemo(() => filterValue?.split(',')[1], [filterValue])
  const filterLabelValue = useMemo(
    () => filterValue.split(',')[2],
    [filterValue]
  )
  const [beforeFocused, setBeforeFocused] = useState<boolean>(false)
  const menuItem = filterList.map((item, index) => ({
    key: index.toString(),
    label: (
      <MenuItemWrapper
        active={item.label === filterLabel}
        onClick={() => {
          /* 相同点击没用 不同点击切换 */
          if (item.label !== filterLabel) {
            const oldFilterItem = {
              label: filterLabel,
              value: filterLabelValue,
            }
            const newFilter = handleText(oldFilterItem, item)
            const newFilterValue = `${numberValue},${newFilter.label},${newFilter.value}`
            handleValue(newFilterValue)
          }
        }}
      >
        <div className="contentItem">
          <div>{item.label}</div>
          {!!item.info && (
            <PopoverTips content={item.info} placement="right" isBlackStyle />
          )}
        </div>
      </MenuItemWrapper>
    ),
  }))

  return (
    <ElementPositionComWrapper>
      <Word>第</Word>
      <NumberWrapper
        size="small"
        autoFocus={beforeFocused}
        value={numberValue}
        // @ts-ignore
        onChange={value => {
          const newFilterValue = `${value},${filterLabel},${filterLabelValue}`
          handleValue(newFilterValue)
        }}
        onFocus={() => setBeforeFocused(true)}
        onBlur={() => setBeforeFocused(false)}
      />
      <Word>项</Word>
      <OperatorSelect
        dropdownRender={() => <MenuWrapper items={menuItem} />}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Button>{filterLabel}</Button>
      </OperatorSelect>
      {(filterLabel === '等于' || filterLabel === '不等于') && (
        <SelectTagsCom
          labelEn={labelEn}
          tableType={tableType}
          handleValue={val => {
            const newFilterValue = `${numberValue},${filterLabel},${val}`
            handleValue(newFilterValue)
          }}
          filterValue={filterLabelValue}
        />
      )}
      {(filterLabel === '包括' || filterLabel === '不包括') && (
        <InputCom
          handleValue={val => {
            const newFilterValue = `${numberValue},${filterLabel},${val}`
            handleValue(newFilterValue)
          }}
          filterValue={filterLabelValue}
        />
      )}
    </ElementPositionComWrapper>
  )
}

export default ElementPosition
