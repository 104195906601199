/* eslint-disable indent */
import styled from 'styled-components'
import { Input } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'

export const ConditionLeftWrapper = styled(PopoverTips)`
  .conditionPop {
    padding: 12px 16px;
    background: #000;
  }
`

export const SearchWrapper = styled.div`
  min-width: 110px;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 0;
  color: rgb(66, 84, 109);
  font-size: 14px;
  line-height: 1.5715;
  list-style: none;
  width: 400px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
`

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

export const SearchCom = styled(Input)`
  width: 100%;
  .icon {
    color: rgb(103, 144, 157);
  }
  :hover {
    .icon {
      color: rgb(61, 144, 255) !important;
    }
  }
`
export const SearchTabs = styled.div`
  height: auto;
  color: #67729d;
  font-weight: 400;
  font-size: 12px;
  box-shadow: inset 0 -1px 0 #e6e6e6;
  > div:first-child {
    margin-left: 12px;
  }
`

interface TabProps {
  active: boolean
}
export const TabItem = styled.div<TabProps>`
  display: inline-block;
  height: 30px;
  margin-right: 6px;
  margin-left: 10px;
  line-height: 30px;
  cursor: pointer;
  color: ${props => (props.active ? '#324157' : 'rgb(103,114,157)')};
  border-bottom: ${props => (props.active ? '3px solid #3d90ff' : 'unset')};
`

export const ContainerWrapper = styled.div`
  position: relative;
`

export const ContainerTop = styled.div`
  width: calc(100% - 8px);
  margin-right: 10px;
  border-top: none;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  color: #202d3f;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  background-color: #fff;
  .icon {
    margin-right: 5px;
  }
`
interface ContainerListProps {
  needTop: boolean
}
export const ContainerList = styled.div<ContainerListProps>`
  position: relative;
  height: 350px;
  width: 400px;
  overflow: auto;
  will-change: transform;
  direction: ltr;
  > div {
    margin-top: ${props => (props.needTop ? 40 : 0)};
  }
`

export const OtherTabList = styled.div`
  /* margin-top: 40px; */
  margin-left: 8px;
  margin-bottom: 8px;
`
interface ListItemProps {
  isChoose: boolean
  isText?: boolean // 是否是纯文本
  isCollected?: boolean // 是否已收藏
  isDisabled: boolean // 是否禁止
  isHover?: boolean // 是否悬浮该项
}

// 为啥用户行为序列需要
export const ListWrapper = styled.div`
  /* margin-left: 8px; */
`

export const ListItem = styled.div<ListItemProps>`
  position: relative;
  width: calc(100% - 16px);
  margin: 0 8px;
  padding: 0 12px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  cursor: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.isDisabled ? 'not-allowed' : props.isText ? 'unset' : 'pointer'};
  background-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    (props.isDisabled && props.isChoose) || props.isChoose
      ? '#e4f2ff !important'
      : props.isHover
      ? '#f0f2f5 !important'
      : 'unset'};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  .descText {
    color: #8d9eb9;
    font-weight: 300;
    margin-left: -2px;
    > span {
      margin: 2px;
    }
  }
  .label {
    display: inline-block;
    width: 150px;
    color: ${props => (props.isChoose ? '#3d90ff ' : '#42546d')};
    font-weight: 400;
    font-size: 14px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .searchSpan {
    color: #3d90ff;
  }
  .typeWrapper {
    float: right;
    width: 70px;
    margin-right: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #7789a6;
    .typeIcon {
      margin-right: 3px;
    }
  }
  .info {
    float: right;
    margin-right: 12px;
    color: #7789a6;
    font-weight: 400;
    font-size: 12px;
    text-align: right;
  }
  .tooltipIcon {
    position: absolute;
    top: 0;
    right: 8px;
    width: 28px;
    height: 32px;

    .icon {
      top: 0;
    }
  }

  :hover {
    /* background-color: ${props => (props.isText ? 'unset' : '#f0f2f5')}; */
    .favoriteIcon {
      display: ${props => (props.isCollected ? 'inline-block' : 'none')};
    }
    .unfavoriteIcon {
      display: ${props => (props.isCollected ? 'none' : 'inline-block')};
      :hover {
        .favoriteIcon {
          display: 'inline-block';
        }
      }
    }
  }
`
export const CollectedIcon = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  height: 100%;
  .icon {
    position: absolute;
    right: 0;
    display: inline-block;
    margin-right: 8px;
    line-height: 32px;
    color: #ffdb74;
    height: 100%;
  }
`
interface FavoriteIconBoxProps {
  isCollected: boolean
}
export const FavoriteIconBox = styled.div<FavoriteIconBoxProps>`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  :hover {
    .favoriteIcon {
      display: inline-block !important;
    }
    .unfavoriteIcon {
      display: none !important;
    }
  }
`
export const FavoriteIcon = styled(SvgIcon)<FavoriteIconBoxProps>`
  position: absolute;
  right: 0;
  display: ${props => (props.isCollected ? 'inline-block' : 'none')};
  margin-right: 8px;
  line-height: 32px;
  color: #ffdb74;
  height: 100%;
`
export const UnFavoriteIcon = styled(SvgIcon)`
  position: absolute;
  right: 0;
  display: none;
  margin-right: 8px;
  line-height: 32px;
  color: rgb(66, 84, 109);
  height: 100%;
`

export const ListName = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  color: #202d3f;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  .icon {
    margin-right: 5px;
  }
  .collectIcon {
    color: #ffdb74;
  }
`

interface ConditionLeftProps {
  isOpen?: boolean // 是否打开
}

export const ConditionLeft = styled.div<ConditionLeftProps>`
  display: inline-block;
  align-self: center;
  min-width: 40px;
  height: 28px;
  padding: 0 6px;
  font-size: 14px;
  line-height: 26px;
  white-space: nowrap;
  vertical-align: top;
  border: ${props =>
    props.isOpen
      ? '1px solid rgb(61, 144, 255 ) !important'
      : '1px solid rgb(240, 242, 245)'};
  background-color: ${props =>
    props.isOpen ? '#fff !important' : 'rgb(240, 242, 245)'};
  color: rgb(103, 114, 157);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    border: 1px solid rgb(61, 144, 255) !important;
    background-color: #fff !important;
  }
`

export const CardWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  height: auto !important;
  left: 400px;
  top: 168px;
  bottom: unset;
`
export const CardHeader = styled.div`
  padding: 12px;
  background-color: #f6f8fa;
  border-radius: 4px 4px 0 0;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`

export const HeaderContainerIcon = styled.div`
  margin-right: 9px;
  color: #202d3f;
`
export const HeaderContainerTitle = styled.div`
  flex-grow: 1;
`
export const HeaderContainerMain = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  color: #202d3f;
`
export const HeaderContainerType = styled.div`
  flex-shrink: 0;
  width: 62px;
  margin-left: 6px;
  color: #607595;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
`

export const HeaderSub = styled.div`
  margin-left: 24px;
  color: #42546d;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  word-break: break-all;
`

export const CardDesc = styled.div`
  flex-grow: 1;
  min-height: 90px;
  max-height: 120px;
  padding: 12px;
  overflow-y: auto;
  color: #607595;
  font-weight: 400;
  font-size: 14px;
  word-break: break-all;
`
export const CardNoDesc = styled.div`
  flex-grow: 1;
  min-height: 90px;
  box-shadow: inset 0 -1px 0 #e6e6e6;
`
export const CardNoContainer = styled.div`
  margin: 12px auto 0;
  text-align: center;
  .icon {
    margin-bottom: 8px;
    color: #8d9eb9;
    font-size: 32px;
  }
`
export const NoDescTitle = styled.div`
  color: #8d9eb9;
  font-size: 14px;
`
export const CardPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  padding: 12px 0;
  box-shadow: inset 0 1px 0 #e6e6e6;
`

export const PanelItem = styled.div`
  flex: 1 1;
  margin: 0 12px;
  :last-child {
    padding-right: 30px;
  }
`

export const PanelTitle = styled.div`
  color: #7789a6;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
export const PanelValue = styled.div`
  margin-top: 4px;
  color: #607595;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  .icon {
    margin-right: 4px;
  }
`
export const PanelValueText = styled.div`
  max-width: 78px;
  vertical-align: bottom;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: break-word;
  color: #607595;
`
export const CardFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  height: 34px;
  padding-top: 6px;
  padding-right: 12px;
  padding-left: 12px;
  color: #7789a6;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  box-shadow: inset 0 1px 0 #e6e6e6;
`

export const CardFooterLeft = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #7789a6;
  .icon {
    margin-right: 3px;
  }
`
export const CardFooterRight = styled.div`
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  line-height: 22px;
  text-align: center;
  background-color: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    width: 16px;
    height: 16px;
    color: #67729d;
    font-size: 16px;
    vertical-align: sub;
    transition: color 0.3s;
  }
  :hover {
    background-color: #f0f2f5;
    :hover {
      background-color: #f0f2f5;
      .icon {
        color: #3d90ff;
      }
    }
  }
`
