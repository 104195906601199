import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  extendCondition,
  filterList,
  getUserAttribute,
  fieldCollectionList,
} from '@/http/businessAnalysis/exploreBoard/condition'

interface AnalysisAttributeAll {
  analysisMap: { [key: string]: { [key: string]: string } }
  props: any[]
}

export interface IAnalysisFlows {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  conditionData: ConditionList | null
  filterList: FilterDatum[]
  analysisAttributeAll: AnalysisAttributeAll
  groupDataList: GroupItem[]
  pieTitle: {
    show: boolean
    text: string
    reName?: string
  }
  populationData: PopulationItem[]
  isGroupView: boolean
  indicators: string[]
  frozenList: string[]
}

const initialState: IAnalysisFlows = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 用户属性列表
  fieldCollectionList: {
    // 且用户符合收藏
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  conditionData: null, // 且用户符合
  filterList: [], // 条件列表
  analysisAttributeAll: { analysisMap: {}, props: [] }, // 分析属性的结构
  pieTitle: {
    // 分析属性title展示与输入
    show: true,
    text: '',
    reName: '',
  },
  groupDataList: [], // 分组项数据
  populationData: [], // 人群对比数据
  isGroupView: true,
  indicators: [], // 右侧分组头部选中数据
  frozenList: [], // 右侧分组头部选项列表
}

/* 获取且用户符合属性列表 */
export const getExtendCondition = createAsyncThunk(
  'userAnalysisAttribute/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'userAnalysisAttribute/getFilterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 分析属性下拉菜单 */
export const getUserList = createAsyncThunk(
  'userAnalysisAttribute/getUserList',
  async (params: { gameId: string }) => {
    const result = await getUserAttribute(params)
    return result
  }
)

/* 获取全局属性 */
export const getAttributeCollection = createAsyncThunk(
  'userAnalysisAttribute/getAttributeCollection',
  async (params: any) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

export const analysisAttributeSlice = createSlice({
  name: 'userAnalysisAttribute',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisFlows; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
  },

  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.analysisAttributeAll = action.payload.data || {
        analysisMap: {},
        props: [],
      }
    })
    builder.addCase(getAttributeCollection.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
  },
})

export const analysisAttributeState = (state: RootState) =>
  state.userAnalysisAttribute

export default analysisAttributeSlice.reducer

export const { setterStoreState, initGameIdAndUser } =
  analysisAttributeSlice.actions
