import React, { useState, useEffect, useRef } from 'react'
import { Modal, Input, Form, Button, Progress } from 'antd'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useAppSelector } from '@/hook/reduxHook'
import LoadingAnimation from '@/components/LoadingAnimation'
import { getDefaultResultName } from '@/http/businessAnalysis/userAnalysis/attribute'
import RidingLantern from './RidingLantern'

interface SendData {
  displayName: string // 分群名
  remarks: string // 分群简介
}
interface CreateProps {
  showCreate: boolean // 是否展示Modal框
  handleCallback: (paras: SendData) => Promise<boolean> // 确定事件回调
  cancelCallback?: Function // 关闭事件回调
}

interface ButtonProps {
  margin?: string
  width?: string
}

const { TextArea } = Input

const ModalDom = styled(Modal)`
  top: 150px;
`

const FooterButton = styled(Button)`
  margin: ${(props: ButtonProps) => props.margin || '0 0 0 8px'};
  width: ${(props: ButtonProps) => props.width || 'auto'};
`
const ProgressDom = styled.div`
  text-align: center;
`

const ProgressDescription = styled.div`
  padding: 40px 0 20px;
`

const BuleText = styled.span`
  color: #1890ff;
  cursor: pointer;
`
const TitleNode = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #42546d;
`

const HiddenText = styled.span`
  margin-left: 16px;
  color: #3d90ff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`

const PercentProgress = styled(Progress)`
  padding-top: 80px;
`

const defaultCreateConfig = {
  show: false,
  displayName: '',
}

export default function CreateModal(props: CreateProps) {
  const { showCreate = false, handleCallback, cancelCallback } = props
  const [createConfig, setCreateConfig] = useState(defaultCreateConfig)
  const [showDrawer, setShowDrawer] = useState(false) // 展示底部菜单
  const [caculateLoading, setCaculateLoading] = useState<boolean>(true) // 等待进度条展示
  const [great, setGreat] = useState<boolean>(true) // 成功或失败

  const [form] = Form.useForm()
  const formRef = useRef(null)

  const navigate = useNavigate()

  const gameId = useAppSelector(data => data.userInfo.gameId)
  const userInfoData = useAppSelector(data => data.userInfo.userInfoData)

  // 设置默认分群名称
  const setDefaultName = async () => {
    const resData = await getDefaultResultName({ gameId })
    if (resData.code === 0) {
      form.setFieldsValue({
        displayName: resData?.name?.name || '',
      })
    }
  }

  useEffect(() => {
    if (formRef.current) {
      form.resetFields()
    }
    setCaculateLoading(true)

    if (showCreate) {
      setDefaultName()
    }
  }, [showCreate])

  const onCancel = () => {
    setCreateConfig(defaultCreateConfig)
    form.resetFields()
    if (cancelCallback) {
      cancelCallback()
    }
  }

  const onOk = async () => {
    const allData = await form.validateFields(['displayName', 'remarks'])
    if (handleCallback) {
      const isCreate = await handleCallback(allData)
      setGreat(isCreate)
      setCaculateLoading(false)
      setCreateConfig({
        show: true,
        displayName: allData.displayName,
      })
    }
  }

  const handleClick = () => {
    setShowDrawer(true)
  }

  const callBackHandle = () => {
    setShowDrawer(false)
  }

  const TitleDom = (
    <TitleNode>
      创建结果分群
      <HiddenText onClick={handleClick}>
        <QuestionCircleOutlined />
        了解结果分群
      </HiddenText>
    </TitleNode>
  )

  // 右侧等待dom props
  const loadingAnimationProps = {
    status: caculateLoading,
    error: false,
    height: '300px',
  }

  return (
    <>
      <ModalDom
        open={showCreate}
        onCancel={onCancel}
        onOk={onOk}
        centered
        title={createConfig.show ? null : TitleDom}
        footer={
          createConfig.show ? null : (
            <>
              <FooterButton onClick={onCancel}>取消</FooterButton>
              <FooterButton type="primary" onClick={onOk}>
                确定
              </FooterButton>
            </>
          )
        }
        destroyOnClose
      >
        {createConfig.show ? (
          <ProgressDom>
            {caculateLoading && (
              <LoadingAnimation {...loadingAnimationProps}>
                <div />
              </LoadingAnimation>
            )}
            {!caculateLoading && (
              <>
                <PercentProgress
                  type="circle"
                  percent={100}
                  {...(great ? {} : { status: 'exception' })}
                />
                <ProgressDescription>
                  {great ? (
                    <>
                      {`[${createConfig.displayName}]已提交，在`}
                      <BuleText
                        onClick={() =>
                          navigate(
                            `/${
                              userInfoData!.companyName
                            }/businisAnalysis/userAnalysis/cohorts`
                          )
                        }
                      >
                        用户分群列表
                      </BuleText>
                      查看创建详情
                    </>
                  ) : (
                    `[${createConfig.displayName}]创建失败`
                  )}
                </ProgressDescription>
                <FooterButton
                  margin="0 8px 0"
                  onClick={handleClick}
                  width="130px"
                >
                  了解结果分群
                </FooterButton>
                <FooterButton
                  margin="0 8px 0"
                  type="primary"
                  width="130px"
                  onClick={onCancel}
                >
                  确认
                </FooterButton>
              </>
            )}
          </ProgressDom>
        ) : (
          <Form
            form={form}
            ref={formRef}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
          >
            <Form.Item
              label="分群显示名"
              name="displayName"
              rules={[{ required: true, message: '请输入群显示名！' }]}
            >
              <Input placeholder="输入24字内的分群显示名" />
            </Form.Item>
            <Form.Item label="分群备注" name="remarks">
              <TextArea
                placeholder="输入50个字内的分群描述"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </Form>
        )}
      </ModalDom>
      <RidingLantern defaultShow={showDrawer} handleCallback={callBackHandle} />
    </>
  )
}
