import styled from 'styled-components'
import { Select } from 'antd'

export const SelectWrapper = styled(Select)`
  margin-left: 8px;
  height: 28px;
  background: #fff;

  .ant-select-selector {
    height: inherit !important;
    color: #67729d !important;
  }
  .ant-select-selection-item {
    line-height: 28px !important;
  }
  .ant-select-arrow {
    right: 3px;
    top: 55%;
    .icon {
      width: 2em;
      height: 2em;
    }
  }
  .ant-select-selection-item .icon {
    display: none !important;
  }
  .icon {
    color: #67729d;
  }
`
