import React, { useState, useEffect, useImperativeHandle, Ref } from 'react'
import SvgIcon from '@/components/SvgIcon'
import LevelItemCom from './LevelItemCom'
import LevelListCom from './LevelListCom'
import {
  FiltersWrapper,
  EditorWrapper,
  Relation,
  RelationMain,
  Extra,
  AddBtn,
} from './styles'

interface Props {
  conditionDatumList: ExtendConditionDatum[]
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  fieldCollectionList: ExtendConditionDatum
  iconPath: string
  openExtend: string
  needAdd?: boolean
  filtersComRef?: Ref<any>
  handleOpenExtend: (val: string) => void
  handleConditionData: (val: ConditionList | null) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
}

function FiltersCom(props: Props) {
  const [openFilterIndex, setOpenFilterIndex] = useState('-1')
  const {
    conditionDatumList,
    filterList,
    conditionData,
    fieldCollectionList,
    iconPath,
    filtersComRef,
    openExtend,
    needAdd = true,
    handleOpenExtend,
    handleConditionData,
    handleFieldCollection,
  } = props

  useEffect(() => {
    if (conditionDatumList && conditionDatumList.length && conditionData) {
      const allEventKey = conditionDatumList
        .map(item => {
          const targetList = item?.list || []
          return targetList.map(event => ({
            labelEn: event.labelEn,
            type: event.type,
          }))
        })
        .flat()

      const finalCondition: any = {
        relation: conditionData.relation,
        list: [],
      }
      // 筛选未在 allEventKey中的数据
      conditionData?.list?.forEach(con => {
        const conListFilterData = con?.list.filter(conListItem =>
          allEventKey.find(
            allItem =>
              allItem.labelEn === conListItem?.condition?.labelEn &&
              allItem.type === conListItem?.condition?.type
          )
        )

        if (conListFilterData.length) {
          const exchangeData = {
            relation: con.relation,
            list: conListFilterData,
          }
          finalCondition.list.push(exchangeData)
        }
      })
      handleConditionData(finalCondition)
    }
  }, [conditionDatumList])

  const setConditionList = (val: ConditionList | null) => {
    handleConditionData(val)
  }

  /* 第一条数据 */
  const getInitItem = () => {
    // console.log('conditionDatumList', conditionDatumList)
    if (!conditionDatumList?.length) {
      return []
    }
    const firstList = conditionDatumList[0].list[0]
    const { dataType } = firstList
    const filter = filterList.find(item => item.type === dataType)!.list[0]
    const initList = {
      condition: firstList,
      filter: {
        ...filter,
        edition: 1, // 版本 1.最新 2.历史版本
        date: '', // 版本的日期
        value: null,
      },
    }
    return initList
  }

  /* 所有数据遍历一级改变relation */
  const getNewConditionList = (relation?: string) => {
    const newConditionList = conditionData!.list.map((item: any) => {
      if (item.list.length === 1) {
        return {
          ...item,
          relation: relation || '且',
        }
      }
      return item
    })
    return newConditionList
  }

  /* 自动添加第一条 */
  const addCondition = () => {
    const initItem = getInitItem()
    /* 没有数据的话 新增一条 默认条件为 且 */
    if (!conditionData) {
      const initConditionList = {
        relation: '',
        list: [
          {
            relation: '',
            list: [initItem],
          },
        ],
      } as ConditionList
      setConditionList(initConditionList)
    } else {
      const relation = conditionData.relation || '且'
      const newItem = {
        relation,
        list: [initItem],
      }
      const newConditionList = getNewConditionList(relation)
      const newList = [...newConditionList, newItem]
      setConditionList({
        relation,
        list: newList,
      })
    }
  }

  useImperativeHandle(filtersComRef, () => ({
    addCondition,
  }))

  /* 添加 / 删除 */
  const actionType = (index: number, newItem?: ConditionItem) => {
    const newList = JSON.parse(JSON.stringify(conditionData!.list))
    if (newItem) {
      newList.splice(index, 1, newItem)
    } else {
      newList.splice(index, 1)
    }
    const newConditionList = {
      relation: conditionData!.relation,
      list: newList,
    }
    setConditionList(newConditionList)
  }

  // console.log('conditionData', conditionData)

  /* 一级 */
  function RenderLevelItem(levelItemProps: LevelProps) {
    const { item, index } = levelItemProps
    /* 一级变化成二级 */
    const addItem = () => {
      const initItem = getInitItem() as ListItem
      actionType(index, {
        relation: '且',
        list: [item.list[0], initItem],
      })
    }
    /* 一级删除 */
    const deleteItem = () => {
      /* 如果只有1条数据  删除 则直接清空  */
      if (conditionData!.list.length === 1) {
        setConditionList(null)
      } else {
        /* 有多条数据 删除某一条 */
        actionType(index)
      }
    }
    /* 替换某个选中的条件 */
    const handleCondition = (value: ExtendConditionDatumList) => {
      const filterItem = filterList.find(it => it.type === value.dataType)!
        .list[0]
      const newItem = {
        relation: item.relation,
        list: [
          {
            condition: value,
            filter: {
              ...filterItem,
              edition: 1, // 版本 1.最新 2.历史版本
              date: '', // 版本的日期
              value: null,
            },
          },
        ],
      }
      actionType(index, newItem)
      setOpenFilterIndex(String(index))
    }
    /* 数据存储处理 */
    const handleSaveItem = (newItem: any) =>
      actionType(index, {
        relation: item.relation,
        list: [newItem],
      })
    return (
      <LevelItemCom
        index={index}
        item={item.list[0]}
        conditionDatumList={conditionDatumList}
        fieldCollectionList={fieldCollectionList}
        openFilter={Number(openFilterIndex) === index}
        filterList={
          filterList.find(it => {
            const ittt = item?.list.length
              ? item.list[0]?.condition?.dataType
              : ''
            return it.type === ittt
          })?.list || []
        }
        needAdd={needAdd}
        iconPath={iconPath}
        openExtend={openExtend}
        handleOpenExtend={handleOpenExtend}
        handleSetFilterIndex={idx => setOpenFilterIndex(String(idx))}
        handleActionType={isAdd => (isAdd ? addItem() : deleteItem())}
        handleCondition={handleCondition}
        handleSaveItem={handleSaveItem}
        handleFieldCollection={handleFieldCollection}
      />
    )
  }

  /* 二级 要遍历list */
  function RenderLevelList(levelListProps: LevelProps) {
    const { item, index } = levelListProps
    const initList = getInitItem()
    const { list } = item

    const addItem = () => {
      const newList = [...list, initList] as ListItem[]
      actionType(index, {
        ...item,
        list: newList,
      })
    }
    const deleteItem = (idx: number) => {
      /* 如果只有1条数据   则删除该组  */
      if (list.length === 1) {
        actionType(index)
      } else if (list.length === 2) {
        /* 只要两条数据 删除则变成一级 */
        actionType(index, {
          ...item,
          list: [item.list[0]],
        })
      } else {
        /* 有多条数据 删除某一条 */
        const newList = JSON.parse(JSON.stringify(list))
        newList.splice(idx, 1)
        actionType(index, {
          ...item,
          list: newList,
        })
      }
    }

    const clickRelation = () => {
      const newList = {
        relation: item.relation === '且' ? '或' : '且',
        list,
      }
      actionType(index, newList)
    }

    const getNewList = (val: ListItem, idx: number) => {
      const newList = JSON.parse(JSON.stringify(list))
      newList.splice(idx, 1, val)
      return newList
    }

    /* 替换某个选中的条件 */
    const handleCondition = (value: ExtendConditionDatumList, idx: number) => {
      const filterItem = filterList.find(
        (it: any) => it.type === value.dataType
      )!.list[0]
      const newListItem = {
        condition: value,
        filter: {
          ...filterItem,
          edition: 1, // 版本 1.最新 2.历史版本
          date: '', // 版本的日期
          value: null,
        },
      }
      const newList = getNewList(newListItem, idx)
      const newItem = {
        relation: item.relation,
        list: newList,
      }
      actionType(index, newItem)
      setOpenFilterIndex(`${index}-${idx}`)
    }

    /* 数据存储处理 */
    const handleSaveItem = (newItem: ListItem, idx: number) => {
      const newList = getNewList(newItem, idx)
      const newConditionItem = {
        relation: item.relation,
        list: newList,
      }
      actionType(index, newConditionItem)
    }

    return (
      <LevelListCom
        index={index}
        list={item.list}
        relation={item.relation}
        filterList={filterList}
        conditionDatumList={conditionDatumList}
        fieldCollectionList={fieldCollectionList}
        openFilterIndex={Number(
          openFilterIndex === '-1' ? -1 : openFilterIndex.split('-')[1]
        )}
        iconPath={iconPath}
        openExtend={openExtend}
        handleOpenExtend={handleOpenExtend}
        handleSetFilterIndex={idx =>
          setOpenFilterIndex(idx === -1 ? String(-1) : `${index}-${idx}`)
        }
        handleSaveItem={(val, idx) => handleSaveItem(val, idx)}
        handleCondition={(val, idx) => handleCondition(val, idx)}
        clickRelation={clickRelation}
        handleFieldCollection={value => handleFieldCollection(value)}
        handleActionType={idx => (idx === -1 ? addItem() : deleteItem(idx))}
      />
    )
  }

  const showExtra = filtersComRef ? !!conditionData : true

  const loading = !conditionDatumList?.length || !filterList?.length

  return (
    <FiltersWrapper className="filterWrapper">
      {conditionData && (
        <EditorWrapper>
          <Relation>
            <em />
            {conditionData.relation && conditionData?.list?.length > 1 && (
              <div
                className="valueName"
                onClick={() => {
                  const relation = conditionData.relation === '且' ? '或' : '且'
                  const newConditionList = getNewConditionList(relation)
                  setConditionList({
                    relation,
                    list: newConditionList,
                  })
                }}
              >
                {conditionData.relation}
              </div>
            )}
          </Relation>
          <RelationMain>
            {conditionData?.list?.map((item: ConditionItem, index: number) => {
              /* 判断是一级还是二级  只有1个list 就是一级 多个 则为2级 */
              if (item.list.length === 1) {
                return <RenderLevelItem item={item} index={index} key={index} />
              }
              return <RenderLevelList item={item} index={index} key={index} />
            })}
          </RelationMain>
        </EditorWrapper>
      )}
      {showExtra && (
        <Extra>
          <AddBtn onClick={addCondition} type="text" disabled={loading}>
            <SvgIcon icon="#icon-shaixuan2" className="icon" />
            添加条件
          </AddBtn>
        </Extra>
      )}
    </FiltersWrapper>
  )
}

export default React.memo(FiltersCom)
