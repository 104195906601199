import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function WeekLogin() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'weekLogin',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'weekLogin',
    boardTitle: '周登录天数分布',
    byTime: 'byWeek',
    toggleType: 'table',
    showLeft: true,
    tips: '使用分布分析，可以计算用户产生某行为的天数分布，选择按周计算，即可获取每周登录天数的分布，也可以将计算的逻辑改为计算次数，了解登录次数的分布情况',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
