import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'

interface userInfoData {
  token: string
  account: Account | null
  list?: any
  appList: AppList[]
  platformList: PlatformList[]
  companyName: string
  systemVersion: string
  menuIconPath: string
}
export interface IUserInfoState {
  flateHeaderArr: IMenuLists[]
  storeHeaderList: IMenuLists[]
  platformPath: string
  menuList: IMenuLists[]
  userInfoData: userInfoData
  chooseGame: string
  chooseGameId: number
  choosePlatName: string
  choosePlatPath: string
  choosePlatUrl: string
  asideList: IMenuLists[]
  openKeys: string[]
  selectedKeys: string[]
  activeKey: string
  checkAnnounce: boolean
  gameId: string // 传值给后端的gameId
  isSetAllRead: boolean // 是否点击全部已读 公告消息
  isauth: boolean
  openMangeBoard: boolean
  sideBareType: number
  pannelRefreshFlag: boolean
  ismicro: number
  currentMicroRoutePath: string
}

const initialState: IUserInfoState = {
  flateHeaderArr: [],
  storeHeaderList: [],
  platformPath: '/businisAnalysis',
  userInfoData: {
    token: '',
    account: null,
    list: [],
    appList: [],
    platformList: [],
    companyName: 'gh',
    systemVersion: '',
    menuIconPath: '',
  },
  chooseGame: '',
  chooseGameId: -1, // 默认选择的游戏id 用来请求应用菜单
  choosePlatName: '', //  跳转的路由大菜单名
  choosePlatPath: 'businisAnalysis', // 跳转的路由小菜单
  choosePlatUrl: '', // 完整的路由地址
  asideList: [],
  menuList: [],
  openKeys: [], // 打开的侧边栏二级
  selectedKeys: ['/businisAnalysis/dataBoard/projectSpace/coreBoard'], // 选中的侧边栏详情
  activeKey: '1', //  头部选中的菜单Id
  checkAnnounce: false, // 查看公告
  openMangeBoard: false, // 管理看板内容
  gameId: '', // 头部选择游戏框的对应gameId
  isSetAllRead: false,
  isauth: true,
  sideBareType: 0, // 0:正常的侧边栏layout， 1：数据看板特殊的侧边栏的layout 2：没有侧边栏的layout
  pannelRefreshFlag: false,
  ismicro: 0,
  currentMicroRoutePath: '',
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setterUserInfoDataState: (
      state: any,
      action: PayloadAction<{ value: any }>
    ) => {
      const { value } = action.payload
      state.userInfoData = value
    },
    setterUserInfoStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IUserInfoState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    setterMsgState: (state: any, action: PayloadAction<any>) => {
      const {
        sideBareType,
        ismicro,
        choosePlatName,
        choosePlatPath,
        choosePlatUrl,
        selectedKeys,
      } = action.payload
      state.sideBareType = sideBareType
      state.ismicro = ismicro
      state.choosePlatName = choosePlatName
      state.choosePlatPath = choosePlatPath
      state.choosePlatUrl = choosePlatUrl
      state.selectedKeys = selectedKeys
    },
  },
  extraReducers: builder => {},
})
export const { setterUserInfoStoreState, setterMsgState } =
  userInfoSlice.actions
export const storeState = (state: RootState) => state.userInfo
export default userInfoSlice.reducer
