import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function ChannelAdditionAndRetention() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'retained',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'retained',
    boardTitle: '各渠道新增留存',
    toggleType: 'table',
    toolBarLeftProps: {
      needDurationTime: true,
      needUnitSelector: false,
      needDropDownSelector: true,
    },
    tips: '此处使用了留存分析计算新增留存，矩阵表中直接展示的是全渠道总体的新增留存，点击首列的”+“号可以查看当天的渠道数据',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
