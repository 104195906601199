import React from 'react'
import { Modal } from 'antd'
import {
  ModalWrapper,
  ModalFooter,
  ModalFooterBtn,
  Title,
  ModalBody,
} from './styles'

interface ModalComProps {
  width?: string
  okText?: string
  okDisabled?: boolean
  cancelText?: string
  closable?: boolean
  openModal: boolean
  title: string | React.ReactNode
  children: React.ReactNode
  handleOk: () => void
  handleCancel: () => void
}
function ModalCom(props: ModalComProps) {
  const {
    width = '408px',
    okText = '确定',
    cancelText = '取消',
    closable = true,
    openModal,
    title,
    children,
    okDisabled = false,
    handleCancel,
    handleOk,
  } = props
  return (
    <Modal
      open={openModal}
      footer={null}
      destroyOnClose
      width={width}
      maskClosable={false}
      closable={closable}
      bodyStyle={{ margin: '-20px -24px' }}
      onCancel={handleCancel}
    >
      <ModalWrapper>
        <ModalBody>
          <Title>{title}</Title>
          {children}
        </ModalBody>
        <ModalFooter>
          <ModalFooterBtn onClick={() => handleCancel()}>
            {cancelText}
          </ModalFooterBtn>
          <ModalFooterBtn
            disabled={okDisabled}
            type="primary"
            onClick={() => handleOk()}
          >
            {okText}
          </ModalFooterBtn>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  )
}

export default ModalCom
