/* eslint-disable prefer-destructuring */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  getTagDataInfo,
  getTagInfo,
  getTagHistory,
} from '@/http/businessAnalysis/tagDetails/'
import { RootState } from '@/store'

export interface TagDetailsState {
  tabType: number
  haveHistoryData: boolean
  startDate: string
  endDate: string
  TagDetailsLoading: boolean
  TagsInfo: TagsInfo
  tagData: TagTableData
  historyData: TagHistory[]
  trendData: any[]
  percentData: any[]
  distrData: any[]
  showDistrData: number[]
  pieData: any[]
  xData: string[]
  xDataGroupList: string[]
  tableHead: any[]
  dataSource: any[]
  unionGroups: string[]
  chooseGroups: string[]
  chooseDate: string
  sortType: string
}

const initialState: TagDetailsState = {
  tabType: 0,
  haveHistoryData: false,
  startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  TagDetailsLoading: true,
  TagsInfo: {
    clusterName: '',
    clusterType: 0,
    clusterZoneOffset: 0,
    displayName: '',
    entityName: '',
    is_history: 0,
    id: 0,
    refreshDelay: 0,
    refreshTime: '',
    remarks: '',
    userId: 0,
    userName: '',
    usersNum: 0,
    backups: 0,
    userTagDef: [
      {
        field: '',
        value: '',
        valueType: '',
        relation: [''],
        group: 0,
        oprator: [''],
      },
    ],
    condition: '',
  },
  tagData: {
    x: [],
    y: [],
    unionGroups: [],
  }, // 接口返回数据
  historyData: [],
  trendData: [],
  percentData: [],
  distrData: [],
  showDistrData: [],
  pieData: [],
  xData: [],
  xDataGroupList: [],
  tableHead: [],
  dataSource: [],
  unionGroups: [],
  chooseGroups: [],
  chooseDate: '',
  sortType: 'less',
}

export const getReduxAllTagInfo = createAsyncThunk(
  'tagDetail/getReduxAllTagInfo',
  async (param: TagsInfoParams) => {
    const response = await getTagInfo(param)
    return response.data
  }
)
export const getReduxAllTagHistoryInfo = createAsyncThunk(
  'tagDetail/getReduxAllTagHistoryInfo',
  async (param: TagHistoryParams) => {
    const response = await getTagHistory(param)
    return response.data
  }
)
export const getReduxAllTagDataInfo = createAsyncThunk(
  'tagDetail/getReduxAllTagDataInfo',
  async (param: TagsDataInfoParams) => {
    const response = await getTagDataInfo(param)
    return response.data
  }
)
export const TagDetailsSlice = createSlice({
  name: 'tagDetails',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof TagDetailsState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    changeTabType: (
      state: any,
      action: PayloadAction<{
        tabType: number
      }>
    ) => {
      const { tabType } = action.payload
      state.tabType = tabType
    },
    changUnionGroups: (
      state: any,
      action: PayloadAction<{
        unionGroups: string[]
      }>
    ) => {
      const { unionGroups } = action.payload
      state.unionGroups = unionGroups
    },
    changeShowDistrData: (
      state: any,
      action: PayloadAction<{
        showDistrData: number[]
      }>
    ) => {
      const { showDistrData } = action.payload
      state.showDistrData = showDistrData
    },
    /* 初始化数据 */
    initStoreState: (state: any) => {
      Object.keys(initialState).forEach((key, index) => {
        if (key !== 'tabType') {
          state[key] = Object.values(initialState)[index]
        }
      })
    },
    // 处理详情表格+图标数据
    handleEchartsData: (state: any, action: PayloadAction<any>) => {
      const {
        xData,
        tableHead,
        dataSource,
        trendData,
        percentData,
        distrData,
        pieData,
      } = action.payload
      state.xDataGroupList = xData
      state.xData = xData
      state.tableHead = tableHead
      state.dataSource = dataSource
      state.trendData = trendData
      state.percentData = percentData
      state.distrData = distrData
      state.pieData = pieData
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getReduxAllTagDataInfo.pending, (state, action) => {
        // state.TagDetailsLoading = true
      })
      .addCase(getReduxAllTagDataInfo.fulfilled, (state, action) => {
        // state.TagDetailsLoading = false
        let tagData = {
          x: [] as string[],
          y: [] as any[],
          unionGroups: [] as string[],
        }
        tagData.x = action.payload?.x || []
        tagData.y = action.payload?.y || []
        tagData.unionGroups = action.payload?.unionGroups
          ? action.payload.unionGroups[0]
          : []
        state.tagData = tagData
      })
    builder
      .addCase(getReduxAllTagInfo.pending, state => {
        state.TagDetailsLoading = true
      })
      .addCase(getReduxAllTagInfo.fulfilled, (state, action) => {
        state.TagDetailsLoading = false
        state.TagsInfo = action.payload
      })
    builder.addCase(getReduxAllTagHistoryInfo.fulfilled, (state, action) => {
      state.historyData = action.payload
      if (action.payload.length > 0) {
        state.haveHistoryData = true
      } else {
        state.haveHistoryData = false
      }
    })
  },
})

export const storeState = (state: RootState) => state.businessAnalysis.tagDetail
export default TagDetailsSlice.reducer
export const {
  changeTabType,
  changUnionGroups,
  initStoreState,
  handleEchartsData,
  changeShowDistrData,
  setterStoreState,
} = TagDetailsSlice.actions
