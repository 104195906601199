import React, { useRef, useEffect, FC, CSSProperties } from 'react'
import * as echarts from 'echarts'

type EChartsOption = echarts.EChartsOption

export interface DistributionProps {
  series: any[]
  styles?: CSSProperties
  showChartLabel?: boolean
}
const DistributionMapChart: FC<DistributionProps> = (
  props: DistributionProps
) => {
  const { series, styles, showChartLabel } = props
  let option: EChartsOption
  const echartsRef: any = useRef(null)
  const seriesArr = [] as any[]
  if (series.length) {
    if (series[0].length > 1) {
      series[0].slice(1).map(() =>
        seriesArr.push({
          type: 'bar',
          label: {
            show: showChartLabel,
            position: 'top',
          },
        })
      )
    } else {
      seriesArr.push({
        type: 'bar',
        label: {
          show: showChartLabel,
          position: 'top',
        },
      })
    }
  }

  useEffect(() => {
    option = {
      dataset: {
        source: series,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        // axisLabel: {
        //   interval: 0,
        // },
      },
      yAxis: {},
      series: seriesArr,
      grid: {
        top: '5%',
        left: '0%',
        right: '3%',
        bottom: '0%',
        containLabel: true,
      },
    }
    const newPromise = new Promise((resolve: any) => {
      resolve()
    })
    const myCharts = echarts.init(echartsRef?.current)
    newPromise.then(() => {
      if (myCharts) {
        myCharts.setOption(option)
      }
    })
    window.addEventListener('resize', () => {
      myCharts.resize()
    })
    return () => {
      myCharts.clear()
    }
  }, [props])

  return (
    <div
      ref={echartsRef}
      style={{ width: '100%', height: '260px', ...styles }}
    />
  )
}
export default DistributionMapChart
