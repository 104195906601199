import React, { FC } from 'react'
import { Statistic } from 'antd'
import RateCom from '@/pages/BusinessAnalysis/components/TemplateRate'
import { Wrapper, TimeWrapper, ContentWrap } from './styles'
import { formatNumber } from '@/utiles/common'

interface RateParams {
  data: IRateIndicatorsData
}

const RateDesc: FC<RateParams> = ({ data }) => {
  const {
    date,
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
    countNumber,
    totalNumber,
    averageNumber,
  } = data

  const rateProps = {
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
  }

  return (
    <Wrapper>
      <div>
        <TimeWrapper>{date}</TimeWrapper>
        <div className="wrapper">
          <div className="rate">
            <Statistic
              className="StatisticWrap"
              style={{ fontSize: 36 }}
              value={formatNumber(countNumber!).value}
              suffix={`${formatNumber(countNumber!).suffix}元`}
            />
          </div>
          {false && <RateCom {...rateProps} />}
        </div>
      </div>
      <ContentWrap>
        <div className="numWrapper">
          <div>
            <span className="title">总和</span>
            <Statistic
              className="StatisticWrap"
              style={{ fontSize: 36 }}
              value={formatNumber(totalNumber!).value}
              suffix={`${formatNumber(totalNumber!).suffix}人`}
            />
          </div>
          <div>
            <span className="title">均值</span>
            <Statistic
              className="StatisticWrap"
              style={{ fontSize: 36 }}
              value={formatNumber(averageNumber!).value}
              suffix={`${formatNumber(averageNumber!).suffix}人`}
            />
          </div>
        </div>
      </ContentWrap>
    </Wrapper>
  )
}

export default RateDesc
