import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

// 加载属性指标
export const getAttribute = (params: {
  gameId: string
}): Promise<IResponse<any>> =>
  axiosInstance.get('/api/econ/v1/common/event/attribute', { params })
// 事件列表
export const getEventList = (
  params: EventsProps
): Promise<IResponse<EventDatum>> =>
  axiosInstance.get('/api/econ/v1/common/event/list', { params })
// 实体列表
export const getEntitiesList = (params: {
  gameId: string
}): Promise<IResponse<EntitiesDatum[]>> =>
  axiosInstance.get('/api/econ/v1/common/entities/list', { params })

// 获取主分组
export const getGroupData = (params: any): Promise<IResponse<any>> =>
  axiosInstance.get('/api/econ/v1/common/group/catalog', { params })

// 获取右侧数据
export const getCaculate = (
  params: GetCaculateParams
): Promise<IResponse<GetCaculateData>> =>
  axiosInstance.post('/api/econ/v1/analysis/event/caculate', params)

// 事件收藏
export const setEventsField = (
  params: any
): Promise<IResponse<HandleFieldArr[]>> =>
  axiosInstance.post('/api/econ/v1/common/event/explore', params)

// 事件分析详细列表
export const getEventsInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/event/infolist', params)

// 路径分析详细列表
export const getPathInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/path/userlist', params)
// 分布分析用户详情列表
export const getDistributionInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/distribution/userlist', params)
// 间隔分析用户详情列表
export const getIntervalInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/interval/userlist', params)
// 漏斗分析用户详情列表
export const getFunnelInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/funnel/userlist', params)
// 留存分析用户详情列表
export const getRetentionInfo = (
  params: any
): Promise<IResponse<CustomTableList[]>> =>
  axiosInstance.post('/api/econ/v1/analysis/retention/userlist', params)
