import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function AccumulateDistribution() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)

  const props = {
    modular: 'accumulateDistribution',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'accumulateDistribution',
    boardTitle: '充值用户周累充分布',
    toggleType: 'table',
    toolBarLeftProps: {
      needIndexSelector: true,
    },
    showLeft: true,
    tips: '通过分布分析，可以非常方便地了解每周充值区间的用户各有多少，需要注意，未充值用户不包括其中',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
