export const defaultBoardState = props => ({
  loading: true, // 是否在加载中
  error: false, // 是否请求错误
  toggleType: props?.toggleType || 'table', // 展示的类型
  byTime: props?.byTime || 'byDay', // 按天/周/月
  modalVisible: false, // 是否打开探索
  timeRange: props?.timeRange, // 查询时间范围
  columns: [], // 表头数据
  groups: [], // 分组
  frozenGroups: [], //
  indicators: [], // 指标
  frozenIndicators: [], // 指标
  dataSource: [], // 表格数据
  xData: [], // x轴数据
  trendData: [], // 折线图
  distrGroupData: [], // 分布图
  cumulativeData: [], //  累计图
  downLoadDataSource: [], // 下载的表格数据
  downLoadHead: [], // 下载的表头数据
  pieData: [], // 饼状图
  reFreshCount: 0, // 刷新次数  (只重新请求 不初始化数据)
  exploreData: null, // 未处理过的数据原型
  descDataList: null, // 展示的数据简介
  checkType: props?.checkType || '留存',
  byDate: '7Day',
  retainedCount: 7,
  checkRange: [props?.timeRange[1], props?.timeRange[1]],
  tableState: {
    tableLoading: true,
    tableDataSource: [],
    tableColumns: [],
  },
  showModalTitle: '', // 弹窗的名称
  modalTableHead: [],
  modalTableDataSource: [],
})

export const handleBoardType = exploreType => {
  let boardType = ''
  switch (exploreType) {
    case 'cordBoard':
      boardType = 'coreExploreBoard'
      break
    case 'activeBoard':
      boardType = 'activeExploreBoard'
      break
    case 'payBoard':
      boardType = 'payExploreBoard'
      break
    default:
      boardType = ''
      break
  }
  return boardType
}

export const handleParams = params => {
  delete params?.distrGroupData
  delete params?.cumulativeData
  delete params?.trendData
  delete params?.downLoadDataSource
  delete params?.downLoadHead
  delete params?.exploreData
  delete params?.tableState
  delete params?.xData
  delete params?.dataSource
  delete params?.columns
  delete params?.frozenIndicators
  delete params?.pieData
  delete params?.indicators
  delete params?.checkRange
  // delete params?.timeRange
  delete params?.modalTableDataSource
  delete params?.modalTableHead
  delete params?.descDataList
  delete params?.frozenGroups
  delete params?.reFreshCount
  delete params?.groups
  delete params?.toggleType
  delete params?.error
  delete params?.operateClick
  // delete params?.checkType
  delete params?.showModalTitle
  let byTimeText = ''
  let retainedCount = params.retainedCount || 7
  const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
  if (needRetainedCountTitle.includes(params.boardTitle)) {
    const frozenByDate = params.byDate
    // 当日 当周 当月
    if (frozenByDate.indexOf('Day') > 0) {
      byTimeText = 'byDay'
      retainedCount = Number(frozenByDate.slice(0, -3))
    }
    if (frozenByDate.indexOf('Month') > 0) {
      byTimeText = 'byMonth'
      retainedCount = Number(frozenByDate.slice(0, -5))
    }
    if (frozenByDate.indexOf('Week') > 0) {
      byTimeText = 'byWeek'
      retainedCount = Number(frozenByDate.slice(0, -4))
    }
    if (frozenByDate.indexOf('current') === 0) {
      retainedCount = 0
    }
    if (frozenByDate.indexOf('next') === 0) {
      retainedCount = 1
    }
  }
  return {
    ...params,
    retainedCount,
    byTime: byTimeText || params.byTime,
  }
}
