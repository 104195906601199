import React from 'react'
import { Statistic } from 'antd'
import {
  Wrapper,
  TimeWrapper,
  CountWrapper,
  DataWrapper,
  TextWrapper,
  ColorWrapper,
} from './styles'
import { getIndicators } from '../utils'
import { formatNumber, parsePercentFloatNum } from '@/utiles/common'

interface ListParams {
  label: string
  value: number | string
  unit: string
  indicatorsName: string
}

interface TopDescProps {
  date?: string
  data?: ListParams[]
  indicators?: string[]
  isBasic?: boolean
}

export default function TopDesc(props: TopDescProps) {
  const { date = '', data, indicators = [], isBasic = false } = props
  const list = getIndicators(data!, indicators, isBasic) as ListParams[]
  // console.log('item', list, data, indicators)
  return (
    <Wrapper>
      <TimeWrapper>{date}</TimeWrapper>
      {list.length && (
        <DataWrapper>
          {list.map((item, index) => (
            <div key={index}>
              <CountWrapper>
                {item.unit !== '%' && (
                  <Statistic
                    className="StatisticWrap"
                    style={{ fontSize: 36 }}
                    value={formatNumber(item.value).value}
                    suffix={formatNumber(item.value).suffix + item.unit || ''}
                  />
                )}
                {item.unit === '%' && (
                  <Statistic
                    className="StatisticWrap"
                    style={{ fontSize: 36 }}
                    value={parsePercentFloatNum(item.value)}
                    suffix="%"
                  />
                )}
              </CountWrapper>
              <TextWrapper>
                <ColorWrapper colorIndex={index} />
                <span>{item.label}</span>
              </TextWrapper>
            </div>
          ))}
        </DataWrapper>
      )}
    </Wrapper>
  )
}
