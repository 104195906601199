import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function BasicDataReport() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'basicsData',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'basic',
    boardTitle: '基础数据报表',
    toggleType: 'table',
    showLeft: true,
    tips: '在同一报表内添加多个指标，可以非常便捷地查看多个核心指标的变化趋势。',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
