import React, { useState, useMemo, useEffect } from 'react'
import { DatePicker, Select, Button } from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { getPicker } from './utils'

const { Option } = Select
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 10px;
  }
  .ant-select-item {
    padding: 5px 5px !important;
  }
  .ant-select {
    line-height: 20px;
    font-size: 12px !important ;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 20px;
  }
  .ant-select-selection-item {
    margin-left: 5px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 20px;
  }
  .ant-select-arrow {
    color: gray;
    right: 5px;
    margin-top: -5px;
    font-size: 11px;
  }
`

const DatePickerWrapper = styled.div`
  font-size: 12px !important;
  height: 20px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  .ant-picker-suffix {
    display: none;
  }
  .ant-picker {
    padding: 0 5px;
    border: unset;
  }
  .ant-picker-input > input {
    line-height: 18px;
    text-align: center;
  }
`
const BtnWrapper = styled(Button)`
  width: 20px !important;
  height: 20px !important;
  border-radius: 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
interface ITimePickertCom {
  handleDateSelector?: (arg: string[]) => void
  byTime: string
  timeRange: string[]
  xData: string[]
  checkRange: string[]
}

function DatePickerSelector(props: ITimePickertCom) {
  const {
    handleDateSelector = () => null,
    byTime,
    timeRange,
    checkRange,
    xData,
  } = props
  const [index, setIndex] = useState<number>(0)
  const [date, setDate] = useState<string>('')

  /* 抛出结果 */
  useEffect(() => {
    if (!date) return
    handleDateSelector([date, date])
  }, [date])

  useEffect(() => {
    if (!xData.length) return
    const num = xData.findIndex(it => it === checkRange[0])
    if (num === -1) {
      setIndex(xData.length - 1)
      setDate(xData[xData.length - 1])
    } else {
      setIndex(num)
      setDate(xData[num])
    }
  }, [xData, checkRange])

  const disabledDate = (current: any) =>
    (current && current > dayjs(timeRange[1])) ||
    (current && current < dayjs(timeRange[0]))

  const picker = useMemo(() => getPicker(byTime), [byTime])

  return (
    <Wrapper>
      <BtnWrapper
        size="small"
        onClick={() => {
          setIndex(index - 1)
          setDate(xData[index - 1])
        }}
        disabled={xData.length === 1 || index === 0}
      >
        <LeftOutlined />
      </BtnWrapper>
      {picker && (
        <DatePickerWrapper>
          <DatePicker
            getPopupContainer={triggerNode => triggerNode.parentElement!}
            onChange={(_, val) => {
              const num = xData.findIndex(x => x === val)
              setIndex(num)
              setDate(val)
            }}
            value={dayjs(date)}
            allowClear={false}
            disabledDate={disabledDate}
            picker={picker}
          />
        </DatePickerWrapper>
      )}
      {!picker && (
        <Select
          size="small"
          value={date}
          dropdownMatchSelectWidth={false}
          onChange={val => {
            const num = xData.findIndex(x => x === val)
            setIndex(num)
            setDate(val)
          }}
        >
          {xData.map(item => (
            <Option value={item} key={item}>
              {`${item}${byTime === 'byWeek' ? '当周' : '季度'}`}
            </Option>
          ))}
        </Select>
      )}
      <BtnWrapper
        size="small"
        onClick={() => {
          setIndex(index + 1)
          setDate(xData[index + 1])
        }}
        disabled={xData.length === 1 || index === xData.length - 1}
      >
        <RightOutlined />
      </BtnWrapper>
    </Wrapper>
  )
}
export default DatePickerSelector
