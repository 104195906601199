import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import { useAppSelector, useAppDispatch } from '@/hook/reduxHook'
import SvgIcon from '@/components/SvgIcon'
import TimePicker from '@/components/Layout/Header/SecondNav/TimePicker'
import {
  initStoreState,
  initialState,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'

interface HeaderProps {
  handleClose: () => void
  selfTitle?: string
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  .name {
    max-width: 400px;
    margin-right: 16px;
    overflow: hidden;
    color: rgb(66, 84, 109);
    font-weight: 500;
    font-size: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  .icon {
    transform: rotate(-135deg);
    margin-right: 8px;
    color: #42546d;
  }
`

const RightButton = styled(Button)`
  transition: all 0.3s;

  &:hover {
    .icon {
      transition: all 0.3s;
      color: #78bbff;
    }
  }
`

const TabWrapper = styled.div`
  height: 48px;
  margin-bottom: 16px;
  padding: 8px 24px;
  background: #fff;
  span {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    padding: 6px 8px;
    color: rgb(61, 144, 255);
    background: rgb(228, 242, 255);
    border-radius: 2px;
    cursor: pointer;
  }
`

function Header(props: HeaderProps) {
  const { handleClose, selfTitle } = props
  const dispatch = useAppDispatch()
  const boardTitle = useAppSelector(data => data.exploreBoard.boardTitle)

  const closeHandle = () => {
    if (handleClose) {
      handleClose()
    }
    dispatch(initStoreState(initialState))
  }

  return (
    <>
      <HeaderWrapper>
        <Left>
          <SvgIcon
            icon="#icon-youjiantou-cu"
            className="icon"
            handleClick={closeHandle}
          />
          <span className="name">{selfTitle || boardTitle}</span>
          <TimePicker />
        </Left>
        <Right>
          {false && (
            <Button style={{ marginRight: 15 }}>
              <VerticalAlignBottomOutlined />
              数据下载
            </Button>
          )}
          <RightButton onClick={closeHandle}>
            <SvgIcon icon="#icon-jiantou" className="icon" />
            退出探索
          </RightButton>
        </Right>
      </HeaderWrapper>
      <TabWrapper>
        <span>探索</span>
      </TabWrapper>
    </>
  )
}

export default Header
