import React, { useState } from 'react'
import styled from 'styled-components'
import PopoverCondition from '@/components/PopoverCondition'
import SvgIcon from '@/components/SvgIcon'

const Wrapper = styled.div`
  width: 100%;
`

const ConditionAndToogleWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 20px;
  justify-content: space-between;
  .hover-toogle {
    visibility: hidden;
  }
`
interface IToogleWrapper {
  clickFlag?: boolean
}
const ToogleWrapper = styled.div<IToogleWrapper>`
  height: 20px;
  border-radius: 3px 3px 3px 3px;
  text-align: center;
  display: flex;
  align-items: center;
  &:hover {
    background: #ecf2fe;
    cursor: pointer;
  }
  .toogle-biaoge {
    margin: 0 7px 0 8px;
    width: 15px;
    height: 15px;
    transition: all 0.1s ease-in-out;
  }
`

const ContentToogleWrapper = styled(ToogleWrapper)`
  margin-bottom: 5px;
  background: ${({ clickFlag }) => (clickFlag ? '#ecf2fe' : 'whtie')};
  height: 30px;
  .toogle-icon {
    width: 19px;
    height: 19px;
    color: ${({ clickFlag }) => (clickFlag ? '#0052D9' : '#8C8C8C')};
    margin: 6px 14px 6px 12px;
  }
`

const ToogleTextWrapper = styled.div`
  /* width: 36px; */
  // height: 17px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #5a5a5a;
`
// type PopoverConditionHandle = React.ElementRef<typeof PopoverCondition>

// const ConditionWrapper = styled.div``

const ContentWrapper = styled.div`
  width: 137px;
  background: #feffff;
  box-shadow: 0px 8px 12px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #dcdcdc;
  padding: 7px 7px 2px 7px;
`
export interface ItoogleIconListItem {
  iconName: string
  name: string
  type: string
}
interface IConditionAndToogle {
  toogleChange: (type: string) => void
  children: React.ReactElement
  toogleIconList: ItoogleIconListItem[]
  toggleItem: ItoogleIconListItem
}
export default function ConditionAndToogle(props: IConditionAndToogle) {
  const [visible, setVisible] = useState<boolean>(false)
  const { toogleChange, children, toogleIconList, toggleItem } = props
  const handleContentToogleClick: (
    componentType: string
  ) => void = componentType => {
    toogleChange(componentType)
    setVisible(false)
  }

  const Content = () => (
    <ContentWrapper>
      {toogleIconList.map((item, index) => (
        <ContentToogleWrapper
          key={index}
          clickFlag={toggleItem.type === item.type}
          onClick={() => handleContentToogleClick(item.type)}
        >
          <SvgIcon icon={`#${item.iconName}`} className="icon toogle-icon" />
          <ToogleTextWrapper>{item.name}</ToogleTextWrapper>
        </ContentToogleWrapper>
      ))}
    </ContentWrapper>
  )

  const ContentName = () => (
    <ToogleWrapper>
      <SvgIcon
        icon={`#${toggleItem.iconName}`}
        className="icon toogle-biaoge"
      />
      <ToogleTextWrapper>{toggleItem.name}</ToogleTextWrapper>
    </ToogleWrapper>
  )

  return (
    <Wrapper>
      <ConditionAndToogleWrapper>
        {children}
        <div className="hover-toogle">
          <PopoverCondition
            Content={<Content />}
            ContentName={<ContentName />}
            visible={visible}
            changeVisible={(arg: boolean) => setVisible(arg)}
          />
        </div>
      </ConditionAndToogleWrapper>
    </Wrapper>
  )
}
