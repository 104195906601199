import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  behaviorSequence,
  extendCondition,
  fieldCollectionList,
  getEventList,
  getBehaviorSequenceData,
  filterList,
} from '@/http/businessAnalysis/sequence'

export interface ISequenceDetail {
  status: boolean
  error: boolean
  gameId: string
  uid: string
  userId: number
  conditionUserDatumList: ExtendConditionDatum[]
  conditionEventDatumList: ExtendConditionDatum[]
  conditionVirtualDatumList: ExtendConditionDatum[]
  userCollectionList: ExtendConditionDatum
  eventCollectionList: ExtendConditionDatum
  virtualCollectionList: ExtendConditionDatum
  // conditionData: ConditionList | null
  behaviorSequenceData: BehaviorSequence
  checkUserList: string[]
  showAttributes: boolean
  eventList: EventDatum | null
  checkEventList: CheckEventListProps | null
  sequenceData: BehaviorSequenceData | null
  checkConditionVirtualList: string[]
  filterList: FilterDatum[]
  behaviorSequenceDataProps: BehaviorSequenceDataProps | null
  uidArr: string[]
  leftLoading: boolean
  refreshCount: number
}
const initialState: ISequenceDetail = {
  status: true,
  error: false,
  gameId: '',
  uid: '',
  userId: -1,
  conditionUserDatumList: [], // 用户属性列表
  userCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 用户属性收藏列表
  eventCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 事件属性收藏列表
  virtualCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 虚拟与维度属性收藏列表
  // conditionData: null,
  behaviorSequenceData: {
    userInfo: [],
    tagInfo: [],
  },
  checkUserList: [], // 勾选的用户组
  eventList: null,
  checkEventList: null, // 勾选事件项
  sequenceData: null,
  showAttributes: false, // 显示属性的开关
  conditionEventDatumList: [], // 事件属性列表
  conditionVirtualDatumList: [], // 虚拟和维度表事件属性列表
  checkConditionVirtualList: [], // 维度表的勾选项
  filterList: [],
  behaviorSequenceDataProps: null, // 右侧的请求参数
  uidArr: [],
  leftLoading: true, // 左侧的加载
  refreshCount: 0, // 刷新次数
}

/* 获取用户属性属性列表 */
export const getUserExtendCondition = createAsyncThunk(
  'sequenceDetail/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)
/* 用户收藏列表 */
export const getUserCollectionList = createAsyncThunk(
  'sequenceDetail/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 获取 事件列表 */
export const getEventCondition = createAsyncThunk(
  'sequenceDetail/getEventCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)
/* 事件收藏列表 */
export const getEventCollectionList = createAsyncThunk(
  'sequenceDetail/getEventCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)
/* 获取 虚拟和维度表列表 */
export const getExtendVirtualCondition = createAsyncThunk(
  'sequenceDetail/getExtendVirtualCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)
/* 虚拟与维度收藏列表 */
export const getVirtualCollectionList = createAsyncThunk(
  'sequenceDetail/getVirtualCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 用户行为序列-左侧数据 */
export const behaviorSequenceFunc = createAsyncThunk(
  'sequenceDetail/behaviorSequence',
  async (params: { gameId: string; uid: string; dimension: string }) => {
    const result = await behaviorSequence(params)
    return result.data
  }
)
export const eventListFunc = createAsyncThunk(
  'sequenceDetail/eventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)
export const getBehaviorSequenceDataFunc = createAsyncThunk(
  'sequenceDetail/getBehaviorSequenceData',
  async (params: BehaviorSequenceDataProps) => {
    const result = await getBehaviorSequenceData(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'sequenceDetail/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

export const sequenceDetailSlice = createSlice({
  name: 'sequenceDetail',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof ISequenceDetail; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        uid: string
        userId: number
        uidArr: string
      }>
    ) => {
      const { gameId, uid, userId, uidArr } = action.payload
      state.gameId = gameId
      state.uid = uid
      state.userId = userId
      state.uidArr = uidArr.split(',')
    },
    setRequestStatus: (
      state: any,
      action: PayloadAction<{
        status: boolean
        error: boolean
      }>
    ) => {
      const { status, error } = action.payload
      state.status = status
      state.error = error
    },
    resetState: state => (state = initialState),
  },

  extraReducers: builder => {
    /* 用户列表 */
    builder.addCase(getUserExtendCondition.fulfilled, (state, action) => {
      state.conditionUserDatumList = action.payload
    })
    /* 用户收藏列表 */
    builder.addCase(getUserCollectionList.fulfilled, (state, action) => {
      state.userCollectionList = action.payload
    })
    /* 事件列表 */
    builder.addCase(getEventCondition.fulfilled, (state, action) => {
      state.conditionEventDatumList = action.payload
    })
    /* 事件收藏列表 */
    builder.addCase(getEventCollectionList.fulfilled, (state, action) => {
      state.eventCollectionList = action.payload
    })
    /* 虚拟与维度列表 */
    builder.addCase(getExtendVirtualCondition.fulfilled, (state, action) => {
      state.conditionVirtualDatumList = action.payload || []
    })
    /* 虚拟与维度收藏列表 */
    builder.addCase(getVirtualCollectionList.fulfilled, (state, action) => {
      state.virtualCollectionList = action.payload
    })
    /* 用户行为序列-左侧数据  */
    builder
      .addCase(behaviorSequenceFunc.pending, state => {
        state.leftLoading = true
      })
      .addCase(behaviorSequenceFunc.fulfilled, (state, action) => {
        state.leftLoading = false
        const userInfoNameArr = action.payload.userInfo.map(item => item.nameEn)
        state.checkUserList = userInfoNameArr || []
        state.behaviorSequenceData = action.payload
      })
    builder.addCase(eventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    builder
      .addCase(getBehaviorSequenceDataFunc.pending, state => {
        state.status = true
        state.error = false
      })
      .addCase(getBehaviorSequenceDataFunc.fulfilled, (state, action) => {
        state.status = false
        state.error = false
        state.sequenceData = action.payload
      })
      .addCase(getBehaviorSequenceDataFunc.rejected, state => {
        state.error = true
      })

    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
  },
})
export const sequenceDetailState = (state: RootState) => state.sequenceDetail
export default sequenceDetailSlice.reducer
export const {
  setterStoreState,
  initGameIdAndUser,
  resetState,
  setRequestStatus,
} = sequenceDetailSlice.actions
