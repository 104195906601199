export const getTitle = (type: string, date: string) => {
  let timeType = '' as string
  switch (type) {
    case 'byDay':
      timeType = '日'
      break
    case 'byWeek':
      timeType = '周'
      break
    case 'byMonth':
      timeType = '月'
      break
    case 'byQuarter':
      timeType = '季度'
      break
    case 'byYear':
      timeType = '年'
      break
    default:
      timeType = ''
      break
  }
  timeType = timeType ? `(${timeType})` : ''
  return `${date}${timeType}`
}

interface DataListParams {
  label: string
  value: string | number
  unit: string
  indicatorsName: string
}

export const getIndicators = (
  dataList: DataListParams[],
  indicators: string[],
  isBasic: boolean
) => {
  if (!dataList) return []
  const basicMustArr = ['付费总金额', '注册玩家数', '付费玩家数'] // 如果有这三个就必须带上
  const arr = indicators.length
    ? dataList.filter(item => indicators.includes(item.indicatorsName))
    : dataList
  if (isBasic) {
    // 对数据处理
    const needArr = arr.filter(item =>
      basicMustArr.includes(item.indicatorsName)
    )
    const noNeedArr = arr.filter(
      item => !basicMustArr.includes(item.indicatorsName)
    )
    const len = needArr.length
    if (len === 3) {
      return needArr
    }
    return [...needArr, ...noNeedArr].slice(0, 3)
  }
  return arr.slice(0, 3)
}
