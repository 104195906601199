import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { getTreeList } from '@/http/manageCenter/AccountManage'

export interface IManageCenterState {
  TreeList: IHGetTreeList[]
}

const initialState: IManageCenterState = {
  TreeList: [],
}

export const getReduxAcountTreeList = createAsyncThunk(
  'ManageCenter/getReduxAcountTreeList',
  async () => {
    const response = await getTreeList()
    console.log(response.data, '111111=>')
    return response.data
  }
)

export const accountManagementSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IManageCenterState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getReduxAcountTreeList.pending, state => {})
      .addCase(getReduxAcountTreeList.fulfilled, (state, action) => {
        console.log(action.payload, 'action.payload')
        state.TreeList = action?.payload?.list
      })
      .addCase(getReduxAcountTreeList.rejected, state => {
        // console.log(state, 'error')
      })
  },
})
export const storeState = (state: RootState) =>
  state.manageCenter.accountManagement
