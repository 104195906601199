import React, { useState } from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'

export default function PayFrequency() {
  const [type, setType] = useState('trend')
  const getToggle = (value: string) => setType(value)
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'payFrequency',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'payFrequency',
    boardTitle: '日充值总次数',
    toggleType: 'trend',
    toolBarLeftProps: {
      needIndexSelector: false,
    },
    showTop: type !== 'trend',
    showTopRate: type === 'trend',
    getToggle,
    tips: '通过"充值"事件的"总次数"计算每天的充值次数',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }
  return <TemplateBoard {...props} />
}
