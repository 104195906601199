import styled from 'styled-components'
import { Button } from 'antd'

export const HistoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  overflow: hidden;
`

export const Title = styled.div`
  height: 48px;
  padding-left: 16px;
  color: #42546d;
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
`
export const Main = styled.div`
  height: calc(100% - 48px);
  overflow-y: auto;
  > span {
    display: block;
    width: 100%;
  }
`
export const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  margin-top: 4px;
  padding: 0 8px;
  :hover {
    background-color: #f6f8fa;
    border-radius: 2px;
    .action {
      background-color: rgb(246, 248, 250);
      opacity: 1;
    }
  }
`
export const ItemParams = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
  overflow: hidden;
  line-height: 32px;
  white-space: nowrap;
  .index {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 6px 0 8px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    text-align: center;
    vertical-align: top;
    background-color: #818bad;
    border-radius: 2px;
  }
  .empty {
    display: inline-block;
    height: 32px;
    margin-left: 16px;
    overflow: hidden;
    font-size: 12px;
    line-height: 32px;
    white-space: nowrap;
    vertical-align: top;
    color: rgb(114, 158, 185);
  }
`
export const Simple = styled.span`
  margin-left: 16px;
  font-size: 12px;
  span {
    color: #000;
  }

  .right {
    margin-left: 8px;
    padding: 6px;
    color: rgb(61, 144, 255);
    background-color: #e4f2ff;
    border-radius: 2px;
    .icon {
      color: rgb(61, 144, 255);
      width: 15px;
      height: 15px;
    }
    .num {
      margin-left: 6px;
      padding: 2px 6px;
      font-size: 12px;
      background-color: #fff;
      border-radius: 10px;
      color: rgb(61, 144, 255);
    }
  }
`
export const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  display: flex;
  align-items: center;
  min-width: 60px;
  height: 32px;
  opacity: 0;
  transition: opacity 0.3s;
`
export const ActionButton = styled(Button)`
  min-width: 40px;
  height: 24px;
  margin-left: 8px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
  font-weight: 400;
  text-shadow: none;
  background-color: #fff;
  border: 1px solid #d9dfe6;
  color: #42546d;
  :hover {
    border-color: rgb(61, 144, 255);
    /* background-color: rgb(61, 144, 255); */
    color: rgb(61, 144, 255);
  }
  :last-child {
    color: #fff;
    border-color: rgb(61, 144, 255);
    background-color: rgb(61, 144, 255);
  }
`

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  color: #8d9eb9;
  .icon {
    font-size: 80px;
  }
`
