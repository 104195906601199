import styled from 'styled-components'
import { AutoComplete } from 'antd'

export const SelectComWrapper = styled.div`
  display: inline-block;
  min-width: 120px;
  height: 28px;
  vertical-align: top;
`

export const SelectWrapper = styled(AutoComplete)`
  display: inline-block;
  min-width: 120px;
  height: 28px;
  vertical-align: top;
  font-size: 14px !important;
  vertical-align: top;
  color: rgb(66, 84, 109);
  .ant-select-selector {
    padding: 0 7px !important;
    height: 28px !important;
    color: #67729d !important;
    border: 1px solid #f0f2f5 !important;
  }
  .ant-select-selection-search {
    right: 4px !important;
    left: 4px !important;
  }
  .ant-select-selection-search-input {
    height: 26px !important;
  }
`
