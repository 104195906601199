import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import { getPannelCondition } from '@/http/businessAnalysis/pannel'
import PannelTab from './PannelTab'
import { useAppSelector, useAppDispatch } from '@/hook/reduxHook'
import { setterUserInfoStoreState, storeState } from '@/store/userInfo'
import EmptyCom from '@/components/EmptyCom'

const Wrapper = styled.div`
  min-width: 1390px;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const EmptyWrapper = styled.div``

const OperateWrapper = styled.div``

export interface IItem extends IReportList {
  width: number
}

export default function Pannel() {
  const [pannelData, setPannelData] = useState<IGetPannelCondition | null>(null)
  const dispatch = useAppDispatch()
  const [conditionDatas, setConditionDatas] = useState<IItem[]>([])
  const storeData = useAppSelector(storeState)
  const { pannelRefreshFlag } = storeData
  const { pathname } = useLocation()
  const { id } = useParams()
  const handleLayoutData = (layoutDatas: { id: number; width: number }[]) => {
    const arr: IItem[] = []
    layoutDatas.forEach(tab => {
      pannelData?.reportList.forEach(item => {
        if (item.reportId === tab.id) {
          let obj: IItem = {
            width: 0,
            id: 0,
            reportId: 0,
            panelId: 0,
            reportInfo: [],
          }
          obj.width = tab.width
          obj = {
            ...obj,
            ...item,
          }
          arr.push(obj)
        }
      })
    })
    return arr
  }
  useEffect(() => {
    getPannelCondition(Number(id))
      .then(res => setPannelData(res.data))
      .catch(err => console.log(err))
    setConditionDatas([])
  }, [pannelRefreshFlag, pathname])

  useEffect(() => {
    if (pannelData && pannelData.layout) {
      setConditionDatas([...handleLayoutData(JSON.parse(pannelData.layout))])
    }
  }, [pannelData])
  useEffect(() => {
    dispatch(
      setterUserInfoStoreState({
        type: 'sideBareType',
        value: 1,
      })
    )
  }, [])

  const addReportsClick = () => {
    dispatch(setterUserInfoStoreState({ type: 'openMangeBoard', value: true }))
  }
  return (
    <Wrapper>
      {conditionDatas.length ? (
        conditionDatas.map((tab, index) => <PannelTab key={index} item={tab} />)
      ) : (
        <EmptyContainer>
          <EmptyWrapper>
            <EmptyCom description=" " />
          </EmptyWrapper>
          <OperateWrapper>
            <Button type="primary" onClick={() => addReportsClick()}>
              添加图表
            </Button>
          </OperateWrapper>
        </EmptyContainer>
      )}
    </Wrapper>
  )
}
