import React, { useMemo, useEffect } from 'react'
import { useSetState } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import { Modal, message } from 'antd'
import DataTable from '@/components/DataTable'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import { setterStoreState } from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import EchartsCom from './EchartsCom'
import { getTableData } from '@/pages/BusinessAnalysis/components/TemplateBoard/ContentWrap/getBoardData'
import {
  handleBoardType,
  handleParams,
} from '@/pages/BusinessAnalysis/components/TemplateBoard/ContentWrap/common'
import CreateModal from '@/pages/BusinessAnalysis/components/CreateResultGroupModal'
import { createResultGroup } from '@/http/businessAnalysis/userAnalysis/attribute'
import {
  handleRetainedModalHead,
  handleRetainedModalSource,
  handleDefaultModalSource,
  handleDefaultModalHead,
} from '@/pages/BusinessAnalysis/components/TemplateBoard/ContentWrap/handleBoardData'
import { ContentComWrapper } from './styles'

interface PageProps {
  page: number
  pageSize: number
}

interface SendData {
  displayName: string // 分群名
  remarks: string // 分群简介
}

function ContentCom() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const tableType = ['table']
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const companyName = useAppSelector(state => state.exploreBoard.companyName)
  const exploreType = useAppSelector(state => state.exploreBoard.exploreType)
  const groups = useAppSelector(state => state.exploreBoard.groups)
  const trendData = useAppSelector(state => state.exploreBoard.trendData)
  const xData = useAppSelector(state => state.exploreBoard.xData)
  const indicators = useAppSelector(state => state.exploreBoard.indicators)
  const toggleType = useAppSelector(state => state.exploreBoard.toggleType)
  const exploreData = useAppSelector(state => state.exploreBoard.exploreData)
  const byTime = useAppSelector(state => state.exploreBoard.byTime)
  const pieData = useAppSelector(state => state.exploreBoard.pieData)
  const checkRange = useAppSelector(state => state.exploreBoard.checkRange)
  const checkType = useAppSelector(state => state.exploreBoard.checkType)
  const frozenGroups = useAppSelector(state => state.exploreBoard.frozenGroups)
  const boardTitle = useAppSelector(state => state.exploreBoard.boardTitle)
  const tableState = useAppSelector(state => state.exploreBoard.tableState)
  const refreshProps = useAppSelector(state => state.exploreBoard.refreshProps)
  const showChartLabel = useAppSelector(
    state => state.exploreBoard.showChartLabel
  )
  const storeDownLoadHead = useAppSelector(
    state => state.exploreBoard.downLoadHead
  )
  const storeDownLoadDataSource = useAppSelector(
    state => state.exploreBoard.downLoadDataSource
  )
  const distrGroupData = useAppSelector(
    state => state.exploreBoard.distrGroupData
  )
  const cumulativeData = useAppSelector(
    state => state.exploreBoard.cumulativeData
  )
  const showModalTitle = useAppSelector(
    state => state.exploreBoard.showModalTitle
  )
  const modalTableHead = useAppSelector(
    state => state.exploreBoard.modalTableHead
  )
  const toolBarLeftProps = useAppSelector(
    state => state.exploreBoard.toolBarLeftProps
  )
  const frozenIndicators = useAppSelector(
    state => state.exploreBoard.frozenIndicators
  )

  const [pageProps, setPageProps] = useSetState<PageProps>({
    page: 1,
    pageSize: 10,
  })

  const [showModal, setShowModal] = React.useState<object | null>(null)
  const [messageApi, contextHolder] = message.useMessage()

  const lineChartsData = () => {
    switch (toggleType) {
      case 'trend':
        return trendData
      case 'cumulative':
        return cumulativeData
      case 'numerical':
        return trendData // 数值分布
      case 'percentage':
        return cumulativeData // 百分比分布
      default:
        return []
    }
  }

  const handleCloseModal = () => {
    dispatch(
      setterStoreState({
        type: 'showModalTitle',
        value: '',
      })
    )
  }

  const clickShowTitle = (text: string) => {
    dispatch(
      setterStoreState({
        type: 'showModalTitle',
        value: text,
      })
    )
  }

  const handleNavigate = (props: object) => {
    const boardType = handleBoardType(exploreType)
    const params = {
      ...props,
      ...refreshProps,
      boardType: boardType,
    }
    const resultParams = handleParams(params)
    navigate(`/${companyName}/customInfo`, {
      state: resultParams,
    })
  }

  const handleCreateModal = (props: object) => {
    const boardType = handleBoardType(exploreType)
    const params = {
      ...props,
      ...refreshProps,
      boardType: boardType,
    }
    const resultParams = handleParams(params)
    setShowModal(resultParams)
  }

  const modalTableDataSource = useMemo(() => {
    if (showModalTitle && exploreData) {
      let modalDataSource: any[] = []
      const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
      if (needRetainedCountTitle.includes(boardTitle)) {
        modalDataSource = handleRetainedModalSource(
          exploreData,
          checkType,
          showModalTitle
        )
      } else {
        modalDataSource = handleDefaultModalSource(exploreData, showModalTitle)
      }
      return modalDataSource
    }
    return []
  }, [showModalTitle, exploreData])

  const modalTableColumns = useMemo(() => {
    if (showModalTitle && exploreData) {
      const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
      if (needRetainedCountTitle.includes(boardTitle)) {
        const result = handleRetainedModalHead(
          exploreData,
          handleNavigate,
          handleCreateModal,
          checkType,
          showModalTitle,
          boardTitle
        )
        return result
      }
      if (exploreData.z.length) {
        return handleDefaultModalHead(
          exploreData,
          byTime,
          showModalTitle,
          boardTitle,
          handleNavigate,
          handleCreateModal
        )
      }
      return modalTableHead
    }
    return []
  }, [showModalTitle, exploreData])

  useEffect(() => {
    const result = getTableData({
      needDropDownSelector: toolBarLeftProps.needDropDownSelector,
      checkRange,
      storeDownLoadDataSource,
      storeDownLoadHead,
      checkType,
      exploreData,
      boardTitle,
      tableState,
      clickShowTitle,
      handleNavigate,
      handleCreateModal,
    }) as any
    dispatch(
      setterStoreState({
        type: 'tableState',
        value: {
          tableLoading: false,
          tableDataSource: result.tableDataSource,
          tableColumns: result.tableColumns,
        },
      })
    )
    if (
      toolBarLeftProps.needDropDownSelector &&
      (checkType === '留存' || checkType === '流失')
    ) {
      dispatch(
        setterStoreState({
          type: 'downLoadHead',
          value: result.downLoadHead,
        })
      )
      dispatch(
        setterStoreState({
          type: 'downLoadDataSource',
          value: result.downLoadDataSource,
        })
      )
    }
  }, [checkType, checkRange])

  const handleCallback = async (modalProps: SendData) => {
    const { displayName, remarks } = modalProps
    const finalJumpParams = {
      ...showModal,
      source: 'panel',
      remarks,
      displayName,
      gameId,
      userId: userInfoData!.account!.id,
      phone: userInfoData!.account!.phone,
    }
    const resData = await createResultGroup(finalJumpParams)
    if (resData.code === 0) {
      messageApi.success(resData.message)
      return true
    }
    messageApi.error(resData.message)
    return false
  }

  return (
    <ContentComWrapper>
      {contextHolder}
      {tableType.includes(toggleType) && (
        <DataTable
          loading={tableState.tableLoading}
          height="680px"
          dataSource={tableState.tableDataSource}
          columns={tableState.tableColumns}
          style={{ overflowX: 'hidden' }}
        />
      )}
      {!tableType.includes(toggleType) && (
        <EchartsCom
          lineSeries={lineChartsData()}
          indicators={indicators}
          frozenIndicators={frozenIndicators}
          groups={groups}
          frozenGroups={frozenGroups}
          xData={xData}
          distrGroupData={distrGroupData!}
          pieData={pieData}
          styles={{ height: '100%' }}
          toggleType={toggleType}
          byTime={byTime}
          showChartLabel={showChartLabel}
        />
      )}
      <Modal
        title={`${showModalTitle}分组详情`}
        open={!!showModalTitle}
        onCancel={() => handleCloseModal()}
        width="80%"
        footer={null}
        destroyOnClose
      >
        <DataTable
          pagination={
            modalTableDataSource.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  total: modalTableDataSource.length,
                  pageSize: pageProps.pageSize,
                  current: pageProps.page,
                  showSizeChanger: true,
                  onChange: (pages, pageSize) => {
                    setPageProps({
                      page: pages,
                      pageSize: pageSize,
                    })
                  },
                  showTotal: t => `共${t}条记录`,
                }
              : false
          }
          columns={modalTableColumns}
          dataSource={modalTableDataSource}
        />
      </Modal>
      <CreateModal
        showCreate={!!showModal}
        handleCallback={handleCallback}
        cancelCallback={() => setShowModal(null)}
      />
    </ContentComWrapper>
  )
}
export default React.memo(ContentCom)
