import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function OldActiveUser() {
  const props = {
    boardTitle: '活跃老玩家数',
    modular: 'oldActiveUser',
    exploreType: 'activeBoard',
    tips: '根据“生命周期天数”筛选出生命周期天数大于5天（相当于至少注册后7天）的用户，以此作为老玩家，计算其有效活跃用户数。',
    unit: '人',
    byTime: 'byDay',
  }
  return <TemplateCard {...props} />
}
export default OldActiveUser
