import React, { useState } from 'react'
import moment from 'moment'
import { Switch } from 'antd'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import {
  showChartLabelAction,
  setterStoreState,
  payPanelExploreFunc,
  coreBoardExploreFunc,
  activeBoardExploreFunc,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import SvgIcon from '@/components/SvgIcon'
import PopoverTips from '@/components/PopoverTips'
import {
  RightWrapper,
  ActionIcon,
  ShowChartLabel,
  ShowDataType,
  ShapeWrapper,
} from './styles'
import { handleExportCsv } from '@/utiles/common'

const lineCharts = ['trend', 'cumulative']
const defaultRefreshTime = `最后更新：${moment().format('YYYY-MM-DD HH:mm:ss')}`
function RightCom() {
  const dispatch = useAppDispatch()
  const exploreType = useAppSelector(data => data.exploreBoard.exploreType)
  const boardTitle = useAppSelector(data => data.exploreBoard.boardTitle)
  const refreshProps = useAppSelector(data => data.exploreBoard.refreshProps)
  const trendData = useAppSelector(data => data.exploreBoard.trendData)
  const toggleType = useAppSelector(data => data.exploreBoard.toggleType)
  const distrData = useAppSelector(data => data.exploreBoard.distrData)
  const cumData = useAppSelector(data => data.exploreBoard.cumulativeData)
  const disGroupData = useAppSelector(data => data.exploreBoard.distrGroupData)
  const xData = useAppSelector(state => state.exploreBoard.xData)
  const downLoadHead = useAppSelector(state => state.exploreBoard.downLoadHead)
  const downLoadDataSource = useAppSelector(
    state => state.exploreBoard.downLoadDataSource
  )
  const showChartLabel = useAppSelector(
    data => data.exploreBoard.showChartLabel
  )
  const toogleTypeList = useAppSelector(
    data => data.exploreBoard.toogleTypeList
  )

  const [refreshTime, setRefreshTime] = useState<string>(defaultRefreshTime)

  const handleReFresh = () => {
    const time = moment().format('YYYY-MM-DD HH:mm:ss')
    setRefreshTime(`最后更新：${time}`)
    if (exploreType === 'payBoard') {
      return dispatch(payPanelExploreFunc(refreshProps))
    }
    if (exploreType === 'cordBoard') {
      return dispatch(coreBoardExploreFunc(refreshProps))
    }
    if (exploreType === 'activeBoard') {
      return dispatch(activeBoardExploreFunc(refreshProps))
    }
  }

  const handleDownCsv = () => {
    handleExportCsv({
      downLoadHead,
      downLoadDataSource,
      xData,
      boardTitle,
    })
  }

  return (
    <RightWrapper>
      <PopoverTips placement="top" content={refreshTime} isBlackStyle>
        <ActionIcon onClick={handleReFresh}>
          <SvgIcon icon="#icon-shuaxin1" className="icon" />
        </ActionIcon>
      </PopoverTips>
      <PopoverTips
        placement="top"
        content="按当前视图导出明细数据"
        isBlackStyle
      >
        <ActionIcon onClick={handleDownCsv}>
          <SvgIcon icon="#icon-xiazai" className="icon" />
        </ActionIcon>
      </PopoverTips>
      {lineCharts.includes(toggleType) && (
        <ShowChartLabel>
          <span>显示数值</span>
          <Switch
            size="small"
            checked={showChartLabel}
            onChange={val =>
              dispatch(
                showChartLabelAction({
                  trendData,
                  distrData,
                  cumData,
                  disGroupData,
                  showChartLabel: val,
                })
              )
            }
            className="switchStyle"
          />
        </ShowChartLabel>
      )}
      <ShowDataType>
        {toogleTypeList.map(item => (
          <ShapeWrapper
            selected={toggleType === item.type}
            key={item.type}
            onClick={() =>
              dispatch(
                setterStoreState({
                  type: 'toggleType',
                  value: item.type,
                })
              )
            }
          >
            <PopoverTips placement="top" content={item.name} isBlackStyle>
              <span>
                <SvgIcon icon={`#${item.iconName}`} className="icon" />
              </span>
            </PopoverTips>
          </ShapeWrapper>
        ))}
      </ShowDataType>
    </RightWrapper>
  )
}

export default RightCom
