import styled from 'styled-components'
import { Select, InputNumber } from 'antd'

export const CurrentDateComWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 28px;
  vertical-align: top;
  width: 100%;
  .word {
    margin: 0 4px;
    color: #67729d;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-input-number-focused .ant-input-number-handler-wrap {
    visibility: visible !important;
  }
  .ant-input-number-focused .ant-input-number-input-wrap {
    padding: 0 26px 0 4px !important;
  }
  .ant-input-number .ant-input-number-handler-wrap {
    border-color: #f0f0f0;
    border-radius: 0 2px 2px 0;
    visibility: hidden;
    opacity: 0;
  }
  .ant-input-number-input {
    padding: 0 4px;
    text-align: right;
    transition: none;
    height: 26px !important;
    font-size: 14px;
  }
  .ant-input-number-handler {
    color: #67729d;
  }
  .ant-input-number-focused .ant-input-number-input-wrap {
    padding: 0 26px 0 4px !important;
  }
  .ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1 !important;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 28px !important;
    .ant-select-selection-item {
      line-height: 26px !important;
    }
  }
`

export const SelectWrapper = styled(Select)`
  margin-right: 4px;
  font-size: 14px !important;
  vertical-align: top;
  color: #67729d;
`

export const NumberWrapper = styled(InputNumber)`
  width: 60px;
  height: 28px !important;
  color: #67729d;
  font-size: 14px !important;
  vertical-align: top;
  border: 1px solid #f0f2f5;
  border-radius: 2px;
  padding: 0;
  .ant-input-number-focused .ant-input-number-handler-wrap {
    visibility: visible !important;
  }
  :hover {
    background-color: #fff;
    border-color: rgb(109, 173, 253);
  }
`
