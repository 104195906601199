import React, { useEffect, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import {
  InitDataProps,
  ToolBarLeftProps,
  resetState,
  initStoreState,
  initToolBarState,
  payPanelExploreFunc,
  coreBoardExploreFunc,
  activeBoardExploreFunc,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import Header from './Header'
import Body from './Body'
import { Wrapper } from './styles'

interface IDataModal {
  isShowDataModal: boolean
  handleClose: () => void
  frozenData: InitDataProps
  toolBarLeftProps?: ToolBarLeftProps
}

export default function ExploreModal(props: IDataModal) {
  const DataModalRef = React.useRef<HTMLDivElement>(null)
  const { isShowDataModal, handleClose, frozenData, toolBarLeftProps } = props

  const dispatch = useAppDispatch()
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)

  const byTime = useAppSelector(state => state.exploreBoard.byTime)
  const exploreGameId = useAppSelector(state => state.exploreBoard.gameId)
  const byDate = useAppSelector(state => state.exploreBoard.byDate)
  const timeRange = useAppSelector(state => state.exploreBoard.timeRange)
  const retainedCount = useAppSelector(
    state => state.exploreBoard.retainedCount
  )
  const goCalculateCount = useAppSelector(
    state => state.exploreBoard.goCalculateCount
  )
  const conditionData = useAppSelector(
    state => state.exploreBoard.conditionData
  )
  const groupDataList = useAppSelector(
    state => state.exploreBoard.groupDataList
  )

  useLayoutEffect(() => {
    dispatch(resetState())
  }, [])

  useEffect(() => {
    const initProps = {
      gameId,
      userId: userInfoData!.account!.id,
      companyName: userInfoData.companyName,
    }
    if (isShowDataModal) {
      if (!exploreGameId) {
        dispatch(
          initStoreState({
            ...frozenData,
            gameId,
            userId: userInfoData!.account!.id,
            companyName: userInfoData.companyName,
          })
        ) // 将数据转化
        if (toolBarLeftProps) {
          // 初始化数据呈现类型
          dispatch(initToolBarState(toolBarLeftProps))
        }
      }

      const groupData = groupDataList.length
        ? JSON.stringify(groupDataList)
        : ''
      const conData = conditionData ? JSON.stringify(conditionData) : ''
      if (timeRange.length) {
        const exploreParams = {
          ...frozenData,
          ...initProps,
          startDate: timeRange.length ? timeRange[0] : frozenData.timeRange[0],
          endDate: timeRange.length ? timeRange[1] : frozenData.timeRange[1],
          byTime: byTime || frozenData.byTime,
          grouping: groupData || undefined,
          condition: conData || undefined,
          retainedCount,
        }
        let retainedCountNum = 0 as number
        let byTimeText = '' as string
        const needRetainedCountTitle = [
          '各渠道新增留存',
          '7日LTV(不支持设备Id)',
        ] // 需要转换的表单
        if (needRetainedCountTitle.includes(frozenData.boardTitle)) {
          const frozenByDate = byDate || frozenData!.byDate!
          // 当日 当周 当月
          if (frozenByDate.indexOf('Day') > 0) {
            byTimeText = 'byDay'
            retainedCountNum = Number(frozenByDate.slice(0, -3))
          }
          if (frozenByDate.indexOf('Month') > 0) {
            byTimeText = 'byMonth'
            retainedCountNum = Number(frozenByDate.slice(0, -5))
          }
          if (frozenByDate.indexOf('Week') > 0) {
            byTimeText = 'byWeek'
            retainedCountNum = Number(frozenByDate.slice(0, -4))
          }
          if (frozenByDate.indexOf('current') === 0) {
            retainedCountNum = 0
          }
          if (frozenByDate.indexOf('next') === 0) {
            retainedCountNum = 1
          }
        }
        // console.log('测试测试测试', retainedCount, byTimeText)
        if (needRetainedCountTitle.includes(frozenData.boardTitle)) {
          const resultProps = {
            ...exploreParams,
            retainedCount: retainedCountNum,
            byTime: byTimeText,
            // retainedCount: 1,
            // byTime: 'byDay',
          }
          if (frozenData.exploreType === 'payBoard') {
            dispatch(payPanelExploreFunc(resultProps))
          }
          if (frozenData.exploreType === 'cordBoard') {
            dispatch(coreBoardExploreFunc(resultProps))
          }
          if (frozenData.exploreType === 'activeBoard') {
            dispatch(activeBoardExploreFunc(resultProps))
          }
        } else {
          if (frozenData.exploreType === 'payBoard') {
            dispatch(payPanelExploreFunc(exploreParams))
          }
          if (frozenData.exploreType === 'cordBoard') {
            dispatch(coreBoardExploreFunc(exploreParams))
          }
          if (frozenData.exploreType === 'activeBoard') {
            dispatch(activeBoardExploreFunc(exploreParams))
          }
        }
      }
    }
    if (!isShowDataModal) {
      dispatch(resetState())
    }
  }, [
    isShowDataModal,
    byTime,
    timeRange,
    goCalculateCount,
    byDate,
    retainedCount,
  ])

  return ReactDOM.createPortal(
    <Wrapper
      isShowDataModal={isShowDataModal}
      ref={DataModalRef}
      id="exploreBoard"
    >
      {isShowDataModal && gameId && (
        <>
          <Header
            handleClose={() => {
              dispatch(resetState())
              handleClose()
            }}
          />
          <Body />
        </>
      )}
    </Wrapper>,
    document.body
  )
}
