import React, { useMemo, useState } from 'react'
import { SectionComWrapper, NumberWrapper } from './styles'

interface Props {
  handleValue: (value: any) => void
  filterValue: any
}

function SectionCom(props: Props) {
  const { handleValue, filterValue } = props
  const beforeValue = useMemo(() => filterValue?.split(',')[0], [filterValue])
  const afterValue = useMemo(() => filterValue?.split(',')[1], [filterValue])
  const [beforeFocused, setBeforeFocused] = useState<boolean>(false)
  const [afterFocused, setAfterFocused] = useState<boolean>(false)

  return (
    <SectionComWrapper>
      <NumberWrapper
        size="small"
        autoFocus={beforeFocused}
        value={beforeValue}
        // @ts-ignore
        formatter={value => (Number(value) > 0 ? `+${value}` : value)}
        // @ts-ignore
        onChange={value => {
          /* 起始值 必须小于 结束值 */
          //  起始值 大于 结束值 则结束值 等于输入值
          if (Number(value) > Number(afterValue)) {
            handleValue(`${value},${value}`)
          } else {
            handleValue(`${value},${afterValue}`)
          }
        }}
        onFocus={() => setBeforeFocused(true)}
        onBlur={() => setBeforeFocused(false)}
      />
      <span className="word">与</span>
      <NumberWrapper
        size="small"
        autoFocus={afterFocused}
        value={afterValue}
        // @ts-ignore
        formatter={value => (Number(value) > 0 ? `+${value}` : value)}
        // @ts-ignore
        onChange={value => {
          /* 结束值 不能 小于 起始值 */
          //  结束值 小于 起始值 则起始值 等于输入值
          if (Number(value) < Number(beforeValue)) {
            handleValue(`${value},${value}`)
          } else {
            handleValue(`${beforeValue},${value}`)
          }
        }}
        onFocus={() => setAfterFocused(true)}
        onBlur={() => setAfterFocused(false)}
      />
      <span className="word">之间</span>
    </SectionComWrapper>
  )
}

export default SectionCom
