import React from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'

export default function LTVTable() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const grouping = [
    {
      dataType: '文本',
      dataTypeEn: 'text',
      descIcon: 'icon-wenben',
      descText: '预制属性',
      hasOption: false,
      id: 0,
      index: 0,
      infoData: {
        attributeName: 'platform',
        attributeType: '预制属性',
        createUser: '',
        dataType: '文本',
        displayName: '登录方式',
        id: 24,
        labelId: '24',
        number: 0,
        remarks: '',
        subject: '',
        timeZone: '',
        updateTime: '',
      },
      isCollection: false,
      isHistory: false,
      label: '登录方式',
      labelEn: 'platform',
      type: 1,
      value: [],
      valueType: 1,
    },
  ]
  const props = {
    modular: 'ltv',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'ltv',
    boardTitle: '7日LTV(不支持设备Id)',
    toggleType: 'table',
    grouping,
    toolBarLeftProps: {
      needDurationTime: true,
      needUnitSelector: false,
      needDropDownSelector: true,
    },
    tips: '展示某日新增的用户的LTV，阶段均值计算的是分析时段内，LTV的加权均值（根据每日新增用户数进行加权），可以点击左侧“+”号查看各渠道的LTV',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
