import React from 'react'
// import dayjs from 'dayjs'
import dayjs from 'dayjs'

import { DatePicker, DatePickerProps } from 'antd'
import { DatePickerComWrapper, DatePickerWrapper } from './styles'

interface Props {
  handleValue: (value: any) => void
  filterValue: string
  isMoreThen: boolean // 是否 是  大于等于
}

function DatePickerCom(props: Props) {
  const { handleValue, filterValue, isMoreThen } = props

  const onChange: DatePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      handleValue(dateStrings)
    }
  }

  // const disabledDate: DatePickerProps['disabledDate'] = current =>
  //   current && current > dayjs().endOf('day')

  return (
    <DatePickerComWrapper>
      <DatePickerWrapper>
        <div>
          <DatePicker
            size="small"
            placeholder="请选择日期"
            format="YYYY-MM-DD HH:mm:ss"
            className="timePicker"
            suffixIcon={null}
            allowClear={false}
            getPopupContainer={triggerNode => triggerNode.parentElement!}
            showTime={{
              defaultValue: isMoreThen
                ? dayjs('00:00:00', 'HH:mm:ss')
                : dayjs('23:59:59', 'HH:mm:ss'),
            }}
            value={
              filterValue ? dayjs(filterValue, 'YYYY-MM-DD HH:mm:ss') : null
            }
            // disabledDate={disabledDate}
            onChange={onChange}
            dateRender={current => {
              const style: React.CSSProperties = {}
              const a = dayjs(current).isBefore(
                filterValue ? filterValue.split(',')[1] : ''
              )
              const b = dayjs(
                filterValue ? filterValue.split(',')[0] : ''
              ).isBefore(current)
              const c = dayjs(current).isSame(
                filterValue ? filterValue.split(',')[0] : '',
                'day'
              )
              const d = dayjs(current).isSame(
                filterValue ? filterValue.split(',')[1] : '',
                'day'
              )
              const e = dayjs(current).isSame(
                dayjs().format('YYYY-MM-DD'),
                'day'
              )

              let classNames = ''
              if (a && b) {
                classNames = 'chooseTime'
              } else {
                classNames = 'ant-picker-cell-inner'
              }
              if (d) {
                classNames = 'hideBoundaryRight'
              }
              if (c) {
                classNames = 'showBoundaryLeft'
              }
              if (c && d) {
                classNames = 'showOneDay'
              }
              let todayStyle
              if (e) {
                todayStyle = 'todayStyle'
              } else {
                todayStyle = ''
              }
              return (
                <div className={`${classNames} ${todayStyle}`} style={style}>
                  {current.date()}
                </div>
              )
            }}
          />
        </div>
      </DatePickerWrapper>
    </DatePickerComWrapper>
  )
}

export default DatePickerCom
