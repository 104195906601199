import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

// 探索条件
export const extendCondition = (
  params: ExtendConditionProps
): Promise<IResponse<ExtendConditionDatum[]>> =>
  axiosInstance.get('/api/econ/v1/common/extendCondition', { params })
// 探索收藏列表
export const collectionList = (
  params: ExploreHistoryListProps
): Promise<IResponse<ExploreCollectListData[]>> =>
  axiosInstance.get('/api/econ/v1/common/getCollectionList', { params })
// 探索属性收藏列表
export const fieldCollectionList = (
  params: CollectionProps
): Promise<IResponse<ExtendConditionDatum>> =>
  axiosInstance.get('/api/econ/v1/common/getFieldCollection', { params })
// 探索字段类型过滤器
export const filterList = (): Promise<IResponse<FilterDatum[]>> =>
  axiosInstance.get('/rapb/econ/v1/common/filter')
// 探索历史列表
export const exploreHistoryList = (
  params: ExploreHistoryListProps
): Promise<IResponse<ExploreHistoryList>> =>
  axiosInstance.get('/api/econ/v1/common/getExploreHistory', { params })
// 探索属性收藏
export const setFieldCollection = (
  params: FieldCollectionProps
): Promise<IResponse<SetFieldCollectionProps>> =>
  axiosInstance.post('/api/econ/v1/common/fieldCollection', params)
// 探索收藏
export const exploreCollection = (
  params: ExploreCollectionProps
): Promise<IResponse<IHConditionObj>> =>
  axiosInstance.post('/api/econ/v1/common/exploreCollection', params)
// 探索收藏移除或重命名
export const collectionModify = (
  params: CollectionModifyProps
): Promise<IResponse<IHConditionObj>> =>
  axiosInstance.put('/api/econ/v1/common/collectionModify', params)
// 探索计算
export const getExploreData = (
  params: any
): Promise<IResponse<IHConditionObj>> =>
  axiosInstance.get('/api/econ/v1/common/getData', { params })
// 加载属性指标
export const getAttribute = (params: {
  gameId: string
}): Promise<IResponse<any>> =>
  axiosInstance.get('/rapb/econ/v1/common/event/attribute', { params })
// 加载用户属性
export const getUserAttribute = (params: { gameId: string }): Promise<any> =>
  axiosInstance.get('/rapb/econ/v1/common/loadUserProps', { params })

// ------------------------- 看板的探索 -----------------------------------

// 付费看板
export const payPanelExplore = (
  params: PayPanelExploreProps
): Promise<IResponse<ExploreData>> =>
  axiosInstance.post('/api/econ/v1/payPanel/explore', params)
// 付费看板 详情
export const payPanelExploreDetail = (params: any): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/payPanel/userList', params)
// 核心看板
export const coreBoardExplore = (
  params: PayPanelExploreProps
): Promise<IResponse<ExploreData>> =>
  axiosInstance.post('/api/econ/v1/corePlate/explore', params)
// 核心看板 详情
export const coreBoardExploreDetail = (params: any): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/corePlate/userList', params)
// 活跃看板
export const activeBoardExplore = (
  params: PayPanelExploreProps
): Promise<IResponse<ExploreData>> =>
  axiosInstance.post('/api/econ/v1/activePanel/explore', params)
// 活跃看板 详情
export const activeBoardExploreDetail = (
  params: any
): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/activePanel/userList', params)
// 活跃看板 详情
export const getLoadFilters = (
  params: GetLoadFiltersParams
): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/common/loadfilters', params)
export const getTestLoadFilters = (
  params: TestGetLoadFiltersParams
): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/common/loadUidFilters', params)
