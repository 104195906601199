import styled from 'styled-components'

export const UniteWrapper = styled.div`
  padding: 0 20px;
  margin-left: 14px;
  height: 27px;
  background: #f2f2f2;
  /* cursor: pointer; */
`

export const UnitTextWrapper = styled.div`
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgb(103, 144, 157);
  line-height: 26px;
`

export const ContentWrapper = styled.div`
  width: 320px;
  height: 200px;
  padding: 4px;
`
export const LeftWrapper = styled.div`
  width: 136px;
  height: 100%;
  padding-right: 4px;
  overflow-x: hidden;
  overflow-y: auto;
`

interface IsActive {
  active: boolean
}

export const Item = styled.div<IsActive>`
  width: 132px;
  height: 24px;
  margin-bottom: 4px;
  padding: 0 8px;
  color: rgb(66, 84, 109);
  font-size: 12px;
  line-height: 24px;
  border-radius: 2px;
  cursor: pointer;
  background: ${props => (props.active ? 'rgb(228,242,255)' : 'unset')};
  :hover {
    background: rgb(246, 248, 250);
  }
`
export const Default = styled.div`
  float: right;
  height: 16px;
  margin-top: 4px;
  padding: 0 4px;
  color: rgb(61, 144, 255);
  font-size: 12px;
  line-height: 16px;
  background-color: #fff;
  border-radius: 2px;
`

export const RightWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 176px;
  height: 192px;
  background-color: rgb(246, 248, 250);
`

export const RightTitle = styled.div`
  margin-top: 16px;
  color: rgb(103, 144, 157);
  font-size: 12px;
  line-height: 16px;
`
export const RightDate = styled.div`
  position: relative;
  display: inline-block;
  width: 94px;
  height: 40px;
  margin-top: 32px;
  padding: 4px 10px;
  text-align: center;
  :before {
    position: absolute;
    top: 16px;
    left: -4px;
    width: 102px;
    height: 32px;
    background-color: rgb(61, 144, 255);
    border-radius: 2px;
    content: '';
  }
  .time {
    position: absolute;
    top: 4px;
    left: 10px;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    width: 74px;
    height: 32px;
    em {
      display: inline-block;
      color: rgb(32, 45, 63);
      font-weight: 500;
      font-size: 24px;
      font-style: normal;
      line-height: 32px;
    }
  }
  :after {
    position: absolute;
    top: 0;
    left: 0;
    width: 94px;
    height: 40px;
    background-color: hsla(0, 0%, 100%, 0.85);
    border-radius: 2px;
    box-shadow: 0 12px 16px rgb(52 63 169 / 24%);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    content: '';
  }
`
