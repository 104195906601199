import React from 'react'

interface Props {
  handleClick?: () => void
  icon: string
  className?: string
  stopPropagation?: boolean
}

function SvgIcon(props: Props) {
  const { icon, handleClick, className, stopPropagation = false } = props
  return (
    <svg
      aria-hidden="true"
      className={className}
      onClick={e => {
        if (stopPropagation) {
          e.stopPropagation()
        }
        handleClick ? handleClick() : null
      }}
    >
      <use xlinkHref={icon} />
    </svg>
  )
}

export default SvgIcon
