import styled from 'styled-components'

export const GroupsWrapper = styled.div`
  margin-top: 10px;
  padding-left: 12px;
`

export const GroupItem = styled.div`
  height: 40px;
  border-radius: 2px;
  transition: all 0.3s;
`

export const OrderIconNum = styled.div`
  display: inline-block;
  width: 35px;
  height: 22px;
  margin: 10px 8px;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
  color: #42546d;
  background-color: #c7ccdd;
  .icon {
    color: #42546d;
    margin-right: 2px;
  }
`
export const OrderNum = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 10px 8px;
  padding: 2px 5px;
  color: rgb(55, 63, 108);
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  background-color: rgb(188, 224, 250);
  border-radius: 2px;
`
interface DragProps {
  hasIcon: boolean
}
export const Drag = styled.div<DragProps>`
  z-index: 9;
  display: inline-block;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 6px;
  color: rgb(103, 144, 157);
  font-style: normal;
  line-height: 22px;
  text-align: center;
  background-color: rgb(246, 248, 250);
  border-radius: 2px;
  cursor: grab;
  opacity: 0;
  transition: all 0.3s;
  margin-left: ${props => (props.hasIcon ? '-44px' : '-28px')};
  width: ${props => (props.hasIcon ? '36px' : '22px')};
  height: ${props => (props.hasIcon ? '23px' : '22px')};

  .icon {
    font-size: 14px;
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
`

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  position: relative;
  width: 100%;
  min-height: 24px;
  line-height: 24px;
  :hover {
    background-color: rgb(246, 248, 250);
    .hoverBtn {
      background-color: #fff;
      border-color: #d9dfe6;
    }
    ${Drag},
    .delete {
      opacity: 1;
    }
    ${OrderIconNum}, ${OrderNum} {
      opacity: 0;
    }
    .edit {
      background-color: #fff;
      border-color: 0 0 4px rgba(61, 144, 255, 0.45);
    }
  }
`

export const ActionLeft = styled.div`
  display: flex;
  align-items: center;
  height: auto;
`

export const Trigger = styled.div`
  display: inline-block;
  align-self: center;
  min-width: 40px;
  height: 28px;
  margin: 2px 4px 0 0;
  padding: 0 6px;
  font-size: 14px;
  line-height: 26px;
  white-space: nowrap;
  vertical-align: top;
  background-color: rgb(240, 242, 245);
  border: 1px solid rgb(240, 242, 245);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    margin-right: 2px;
    color: rgb(103, 144, 157);
    font-size: 14px;
    vertical-align: text-bottom;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
  }
  .label {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    color: rgb(103, 144, 157);
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
    .sign {
      color: rgb(61, 144, 255);
    }
  }
  :hover {
    border-color: rgba(61, 144, 255) !important;
  }
`

export const EditIcon = styled.div`
  margin-left: 4px;
  display: inline-block;
  height: 28px;
  padding: 0 4px;
  line-height: 26px;
  text-align: center;
  vertical-align: top;
  background-color: #f0f2f5;
  border: 1px solid #f0f2f5;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    color: rgb(103, 114, 157);
  }
  :hover {
    border-color: rgba(61, 144, 255) !important;
    .icon {
      color: rgba(61, 144, 255);
    }
  }
`

export const ActionRight = styled.div`
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 6px;
  cursor: pointer;
  .icon {
    width: 16px;
    height: 16px;
    color: rgb(103, 114, 157);
    font-size: 16px;
    vertical-align: sub;
    transition: color 0.3s;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
  }
  :hover {
    .icon {
      color: red;
    }
  }
`

export const Extra = styled.div`
  margin-top: 8px;
  .add {
    display: inline-block;
    margin-right: 16px;
    padding: 4px;
    color: rgb(61, 144, 255);
    font-size: 14px;
    line-height: 16px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
    :hover {
      background-color: #e4f2ff;
      .icon {
        color: #fff;
        background-color: #3d90ff;
      }
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    padding-top: 2px;
    color: rgb(61, 144, 255);
    font-size: 12px;
    text-align: center;
    background-color: rgb(228, 242, 255);
    transition: all 0.3s;
  }
`
