import styled from 'styled-components'

export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`

export const DividerWrapper = styled.div`
  width: 0px;
  height: 15px;
  background: #ffffff;
  border-right: 1px solid #b5b5b5;
  margin: 0 16px;
`
export const NumberSubmitWrap = styled.div`
  padding: 10px;
  background: #feffff;
  box-shadow: 0px 8px 12px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  width: 264px;
  height: 101px;
  .content {
    display: flex;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      span {
        margin-right: 6px;
      }
    }
    .inpNumber {
      margin-left: 11px;
      width: 100px;
    }
  }
  .numberSubmit {
    margin-top: 20px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .cancel,
    .submit {
      width: 65px;
      height: 28px;
      font-size: 14px;
      line-height: 26px;
      text-align: center;
      border-radius: 2px;
    }
    .cancel {
      margin-right: 8px;
      background: #fff;
      border: 1px solid #d9d9d9;
    }
    .submit {
      background: #1890ff;
      border: 1px solid #1890ff;
      color: #fff;
    }
  }
`

// export const RangeDiv = styled.div`
//   padding: 0 8px;
//   min-width: 86px;
//   height: 28px;
//   line-height: 28px;
//   text-align: left;
//   color: #43536d;
//   cursor: pointer;
// `
