import React, { useState } from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function DAU() {
  const [type, setType] = useState('table')
  const getToggle = (value: string) => setType(value)
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)

  const props = {
    modular: 'dau',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'dau',
    boardTitle: 'DAU',
    toggleType: 'table',
    showLeft: type !== 'trend',
    showLeftRate: type === 'trend',
    getToggle,
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
