import React from 'react'
import moment from 'moment'
import { getDateText, colWidth } from '@/utiles/common'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import {
  PlusSquareOutIcon,
  PlusSquareOutWrapper,
  RightIcon,
  ColumnItem,
} from './styles'

const hasValue = (val = 0, unit = '') => {
  if (val === '-' || Number.isNaN(val)) {
    return '-'
  }
  return `${Number(val)}${unit}`
}

const getDateUnit = (text, date, isTitle = false) => {
  const dateText = text.slice(-1)
  switch (dateText) {
    case '日':
      return `(${moment(date).format('dd')})`
    case '周':
      return isTitle ? '当周' : '周'
    case '月':
      return isTitle ? '当月' : '月'
    default:
      return ''
  }
}

const isDate = item => {
  if (
    item === '当日' ||
    item === '当周' ||
    item === '当月' ||
    (item.indexOf('第') === 0 && item.indexOf('日') > -1) ||
    (item.indexOf('第') === 0 && item.indexOf('周') > -1) ||
    (item.indexOf('第') === 0 && item.indexOf('月') > -1)
  ) {
    return true
  }
  return false
}

const getLTVDownLoad = exploreData => {
  let downLoadHead = [
    {
      title: '初始事件的发生时间',
      dataIndex: 'date',
      key: 'date',
    },
  ]
  let downLoadDataSource = []
  const { y, z, groupLabels } = exploreData
  const resultY = y[0]
  resultY.forEach(item => {
    const name = Object.keys(item)[0]
    const firstValueArr = Object.values(item)[0][0]
    const yArr = Object.values(item)[0]
    let obj = {
      date: name,
      key: name,
    }
    let zObj = null
    z.forEach((itemZ, indexZ) => {
      zObj = {
        ...obj,
        ...zObj,
        [`${itemZ}Recharge`]: hasValue(firstValueArr.extendValues[indexZ]),
        [`${itemZ}LTV`]: hasValue(firstValueArr.otherValues[indexZ]),
      }
    })
    yArr.forEach((itemY, indexY) => {
      groupLabels.forEach((yIte, yIteIndex) => {
        obj = {
          ...obj,
          [yIte]: indexY ? itemY.groupCols[yIteIndex] : '总体',
          ...zObj,
        }
      })
      downLoadDataSource.push(obj)
    })
  })
  groupLabels.forEach(item => {
    downLoadHead.push({
      title: item,
      dataIndex: item,
      key: item,
    })
  })
  z.forEach((item, index) => {
    if (index === 0) {
      downLoadHead.push({
        title: item,
        dataIndex: item,
        key: item,
      })
    } else {
      const recharge = `第${index - 1}天充值`
      const LTV = `LTV${index - 1}`
      downLoadHead.push({
        title: recharge,
        dataIndex: `${item}Recharge`,
        key: `${item}Recharge`,
      })
      downLoadHead.push({
        title: LTV,
        dataIndex: `${item}LTV`,
        key: `${item}LTV`,
      })
    }
  })
  return {
    downLoadHead,
    downLoadDataSource,
  }
}

// LTV | 各渠道新增留存
export const handleRetained = (
  exploreData,
  boardTitle,
  checkType,
  clickShowTitle,
  handleNavigate,
  handleCreateModal
) => {
  // console.log('boardTitle', boardTitle)
  let tableDataSource = []
  let tableColumn = []
  let downLoadHead = []
  let downLoadDataSource = []
  const { y, z, groupLabels, columnIndex } = exploreData
  const resultY = checkType === '留存' ? y[0] : y[1]
  const defaultTableHead = [
    {
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        // 转时间戳在排序
        const timeA = Number(moment(a.date).format('X'))
        const timeB = Number(moment(b.date).format('X'))
        return timeA - timeB
      },
      render: text => {
        let unitTime = getDateUnit(z[z.length - 1], text, true)
        if (Object.values(resultY[0])[0].length > 1) {
          return (
            <PlusSquareOutWrapper>
              <PlusSquareOutIcon onClick={() => clickShowTitle(text)} />
              {text + unitTime}
            </PlusSquareOutWrapper>
          )
        }
        return <div>{text + unitTime}</div>
      },
    },
  ]
  tableColumn = defaultTableHead
  downLoadHead = [
    {
      ...defaultTableHead,
      title: '初始事件的发生时间',
    },
  ]
  const arr = []
  const headObj = {
    title: '指标',
    dataIndex: '指标',
    key: '指标',
  }
  resultY.forEach(item => {
    const name = Object.keys(item)[0]
    const firstValueArr = Object.values(item)[0][0]
    const yArr = Object.values(item)[0]
    let obj = {
      date: name,
      key: name,
    }
    let valueObj = { date: name }
    let percentObj = { date: name }
    let LTVObj = { date: name }
    z.forEach((itemZ, indexZ) => {
      obj = {
        ...obj,
        [`${itemZ}percentValues`]: hasValue(
          firstValueArr.percentValues[indexZ]
        ),
        [itemZ]: hasValue(firstValueArr.values[indexZ], firstValueArr.unit),
        [`${itemZ}LTV`]: firstValueArr.otherValues.length
          ? hasValue(firstValueArr.otherValues[indexZ])
          : 0,
      }
    })

    yArr.forEach((itemY, indexY) => {
      groupLabels.forEach((yIte, yIteIndex) => {
        valueObj = {
          ...valueObj,
          [yIte]: indexY ? itemY.groupCols[yIteIndex] : '总体',
        }
        percentObj = {
          ...percentObj,
          [yIte]: indexY ? itemY.groupCols[yIteIndex] : '总体',
        }
        LTVObj = {
          ...LTVObj,
          [yIte]: indexY ? itemY.groupCols[yIteIndex] : '总体',
        }
      })
      z.forEach((itemZ, indexZ) => {
        valueObj = {
          ...valueObj,
          [itemZ]: hasValue(itemY.values[indexZ], itemY.unit),
        }
        percentObj = {
          ...percentObj,
          [itemZ]: hasValue(itemY.percentValues[indexZ] / 100, '%'),
        }
        LTVObj = {
          ...LTVObj,
          [itemZ]: itemY.otherValues.length
            ? hasValue(itemY.otherValues[indexZ])
            : 0,
        }
        if (indexZ === 0) {
          let valueObjA = {
            指标:
              boardTitle === '7日LTV(不支持设备Id)'
                ? '付费人数'
                : `${checkType}人数`,
          }
          let percentObjA = {
            指标:
              boardTitle === '7日LTV(不支持设备Id)'
                ? '付费占比率'
                : `${checkType}率`,
          }
          let LTVObjA = { 指标: 'LTV' }
          valueObj = Object.assign(valueObj, valueObjA)
          percentObj = Object.assign(percentObj, percentObjA)
          LTVObj = Object.assign(LTVObj, LTVObjA)
        }
      })
      if (boardTitle === '7日LTV(不支持设备Id)') {
        downLoadDataSource.push(valueObj, percentObj, LTVObj)
      } else {
        downLoadDataSource.push(valueObj, percentObj)
      }
    })
    return arr.push(obj)
  })
  tableDataSource = arr
  groupLabels.forEach(item => {
    downLoadHead.push({
      title: item,
      dataIndex: item,
      key: item,
    })
  })
  z.forEach((item, index) => {
    const tableHeadItem = {
      title: item,
      dataIndex: item,
      key: item,
      width: colWidth,
      defaultSortOrder: '',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
      render: (itm, params, tableIndex) => {
        let text = ''
        let dateMoment = getDateUnit(item, params.date)
        const dateText = `${params.date}${dateMoment}`
        const canClick = Number(itm) && columnIndex[index]
        const RightIconDiv = props => (
          <RightIcon>
            <PopoverTips placement="top" content="创建结果分群" isBlackStyle>
              <div>
                <SvgIcon
                  icon="#icon-tianjia1"
                  className="icon"
                  handleClick={() => handleCreateModal(props)}
                />
              </div>
            </PopoverTips>
          </RightIcon>
        )
        if (isDate(item)) {
          if (boardTitle === '7日LTV(不支持设备Id)') {
            const continueText = item
            const indicatorsNum = params[z[0]]
            if (checkType === '留存') {
              text = `在${dateText}进行用户注册的${indicatorsNum}个用户中，有${
                params[`${item}LTV`]
              }个在${continueText}进行了付费事件，占比${
                params[`${item}percentValues`]
              }%，且截至${continueText}LTV为${itm}`
            }
            if (checkType === '流失') {
              text = `在${dateText}进行用户注册的${indicatorsNum}个用户中，有${
                params[`${item}`]
              }个在${continueText}都没有进行了付费事件，占比${
                params[`${item}percentValues`]
              }%流失_同时展示_非阶段_阶段累计提示id`
            }
          }
          if (boardTitle === '各渠道新增留存') {
            const continueText = item
            const indicatorsNum = params[z[0]]
            const activeText =
              checkType === '留存' ? '进行了活跃' : '都没有进行活跃'
            text = `在${dateText}进行新增的${indicatorsNum}个用户中，有${itm}个在${continueText}${activeText}，占比${
              params[`${item}percentValues`]
            }%`
          }
          const clickProps = {
            firName: '事件分析',
            secName: '用户列表',
            sliceDate: params.date,
            dateValue: params.date,
            eventName: Object.values(y[0][tableIndex])[0][0].groupCols.join(
              ','
            ),
            sliceGroupVal: Object.values(y[0][tableIndex])[0][0].groupCols.join(
              ','
            ),
            columnIndex: columnIndex[index],
          }
          return (
            <PopoverTips isBlackStyle content={text}>
              <div>
                <ColumnItem canClick={canClick}>
                  {boardTitle === '7日LTV(不支持设备Id)' && (
                    <div onClick={() => canClick && handleNavigate(clickProps)}>
                      {checkType === '留存' && (
                        <span>{params[`${item}LTV`]}</span>
                      )}
                      {checkType === '流失' && <span>{params[`${item}`]}</span>}
                    </div>
                  )}
                  {boardTitle === '各渠道新增留存' && (
                    <span
                      onClick={() => canClick && handleNavigate(clickProps)}
                    >
                      {itm}
                    </span>
                  )}
                  {!!canClick && RightIconDiv(clickProps)}
                </ColumnItem>
                {itm !== '-' && (
                  <div>
                    {params[`${item}percentValues`]}
                    <span>%</span>
                  </div>
                )}
                {itm !== '-' &&
                  checkType === '留存' &&
                  boardTitle === '7日LTV(不支持设备Id)' && <div>{itm}</div>}
              </div>
            </PopoverTips>
          )
        }
        let joinText = ''
        joinText = `${dateText}有${itm}个用户参与了新增`
        const clickProps = {
          firName: '事件分析',
          secName: '用户列表',
          sliceDate: params.date,
          sliceGroupVal: Object.values(y[0][tableIndex])[0][0].groupCols.join(
            ','
          ),
          columnIndex: columnIndex[0],
        }
        return (
          <PopoverTips isBlackStyle content={joinText}>
            <ColumnItem canClick={canClick}>
              <div onClick={() => canClick && handleNavigate(clickProps)}>
                {itm}
              </div>
              {!!canClick && RightIconDiv(clickProps)}
            </ColumnItem>
          </PopoverTips>
        )
      },
    }
    downLoadHead.push(tableHeadItem)
    if (index === 0) {
      downLoadHead.push(headObj)
    }
    tableColumn.push(tableHeadItem)
  })
  // console.log('tableColumn', tableColumn)
  // console.log('tableDataSource', tableDataSource)
  if (boardTitle === '7日LTV(不支持设备Id)') {
    downLoadHead = getLTVDownLoad(exploreData).downLoadHead
    downLoadDataSource = getLTVDownLoad(exploreData).downLoadDataSource
  }
  return {
    tableColumn,
    tableDataSource,
    downLoadHead,
    downLoadDataSource,
  }
}

export const handleRetainedModalHead = (
  exploreData,
  handleNavigate,
  handleCreateModal,
  checkType,
  showModalTitle,
  boardTitle
) => {
  const { y, groupLabels, z, columnIndex } = exploreData
  // 数量大于1 才有弹窗
  const modalTableHead = []
  groupLabels.forEach(item => {
    modalTableHead.push({
      title: item,
      dataIndex: item,
      key: item,
      width: colWidth,
      ellipsis: true,
      defaultSortOrder: '',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
    })
  })
  z.forEach((item, index) => {
    const modalHeadItem = {
      title: item,
      dataIndex: item,
      key: item,
      ellipsis: true,
      width: colWidth,
      defaultSortOrder: '',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
      render: (itm, params, tableIndex) => {
        const resultY = checkType === '留存' ? y[0] : y[1]
        const canClick = Number(itm) && columnIndex[index]
        let text = ''
        let dateMoment = getDateUnit(item, showModalTitle)
        const dateText = `${showModalTitle}${dateMoment}`
        const dateIndex = resultY.findIndex(
          it => Object.keys(it)[0] === showModalTitle
        )
        const itemY = Object.values(resultY[dateIndex])[0][tableIndex + 1]
        if (isDate(item)) {
          if (boardTitle === '7日LTV(不支持设备Id)') {
            const continueText = item
            const indicatorsNum = params[z[0]]
            if (checkType === '留存') {
              // 在2022-12-05(一)进行用户注册的1038个用户中，有201个在第1日进行了付费事件，占比19.36%，且截至第1日LTV为35.03
              text = `在${dateText}进行用户注册的${indicatorsNum}个用户中，有${
                params[`${item}LTV`]
              }个在${continueText}进行了付费事件，占比${
                params[`${item}percentValues`]
              }%，且截至${continueText}LTV为${itm}`
            }
            if (checkType === '流失') {
              text = `在${dateText}进行用户注册，有${indicatorsNum}个在${continueText}都没有进行了付费事件，占比${
                params[`${item}percentValues`]
              }%流失_同时展示_非阶段_阶段累计提示id`
            }
          }
          if (boardTitle === '各渠道新增留存') {
            const continueText = item
            const indicatorsNum = params[z[0]]
            const activeText =
              checkType === '留存' ? '进行了活跃' : '都没有进行活跃'
            text = `在${dateText}进行新增的${indicatorsNum}个用户中，有${itm}个在${continueText}${activeText}，占比${
              params[`${item}percentValues`]
            }%`
          }
          const bottomDiv = (
            <>
              <div>
                {params[`${item}percentValues`]}
                <span>%</span>
              </div>
              {checkType === '留存' &&
                boardTitle === '7日LTV(不支持设备Id)' && <div>{itm}</div>}
            </>
          )
          const contentDiv = (
            <>
              {boardTitle === '7日LTV(不支持设备Id)' && (
                <>
                  {checkType === '留存' && <span>{params[`${item}LTV`]}</span>}
                  {checkType === '流失' && <span>{params[`${item}`]}</span>}
                </>
              )}
              {boardTitle === '各渠道新增留存' && <span>{itm}</span>}
            </>
          )
          if (canClick) {
            const clickProps = {
              firName: '事件分析',
              secName: '用户列表',
              sliceDate: showModalTitle,
              dateValue: showModalTitle,
              eventName: itemY.groupCols.join(','),
              sliceGroupVal: itemY.groupCols.join(','),
              columnIndex: columnIndex[index],
            }
            return (
              <PopoverTips isBlackStyle content={text}>
                <div>
                  <ColumnItem
                    onClick={() => {
                      canClick && handleNavigate(clickProps)
                    }}
                    canClick
                  >
                    {contentDiv}
                    <RightIcon>
                      <PopoverTips
                        placement="top"
                        content="创建结果分群"
                        isBlackStyle
                      >
                        <div>
                          <SvgIcon
                            icon="#icon-tianjia1"
                            className="icon"
                            handleClick={() => handleCreateModal(clickProps)}
                          />
                        </div>
                      </PopoverTips>
                    </RightIcon>
                  </ColumnItem>
                  {itm !== '-' && bottomDiv}
                </div>
              </PopoverTips>
            )
          }
          return (
            <PopoverTips isBlackStyle content={text}>
              <div>
                <div>{contentDiv}</div>
                {itm !== '-' && bottomDiv}
              </div>
            </PopoverTips>
          )
        }
        let joinText = ''
        const groupsLabels = itemY.groupCols.join(',')
        joinText = `${dateText}有${itm}个${groupsLabels}参与了用户注册`
        // handleCreateModal
        if (canClick) {
          const clickProps = {
            firName: '事件分析',
            secName: '用户列表',
            sliceDate: showModalTitle,
            sliceGroupVal: itemY.groupCols.join(','),
            columnIndex: columnIndex[0],
          }
          return (
            <PopoverTips isBlackStyle content={joinText}>
              <ColumnItem canClick>
                <div onClick={() => handleNavigate(clickProps)}>{itm}</div>
                <RightIcon>
                  <PopoverTips
                    placement="top"
                    content="创建结果分群"
                    isBlackStyle
                  >
                    <div>
                      <SvgIcon
                        icon="#icon-tianjia1"
                        className="icon"
                        handleClick={() => handleCreateModal(clickProps)}
                      />
                    </div>
                  </PopoverTips>
                </RightIcon>
              </ColumnItem>
            </PopoverTips>
          )
        }
        return (
          <PopoverTips isBlackStyle content={joinText}>
            <div>{itm}</div>
          </PopoverTips>
        )
      },
    }
    modalTableHead.push(modalHeadItem)
  })
  return modalTableHead
}

export const handleRetainedModalSource = (
  exploreData,
  checkType,
  showModalTitle
) => {
  const { groupLabels, z, y } = exploreData
  let yArrItem = []
  const modalDataSource = []
  const resultY = checkType === '留存' ? y[0] : y[1]
  yArrItem = resultY.filter(item => Object.keys(item)[0] === showModalTitle)
  const yItem = Object.values(yArrItem[0])[0].slice(1)
  yItem.forEach((item, index) => {
    let obj = {
      key: index,
    }
    groupLabels.forEach((itm, idx) => {
      obj = {
        ...obj,
        [itm]: item.groupCols[idx],
      }
    })
    z.forEach((itemZ, indexZ) => {
      obj = {
        ...obj,
        [`${itemZ}LTV`]: item.otherValues ? item.otherValues[indexZ] || 0 : 0,
        [`${itemZ}percentValues`]: item.percentValues[indexZ],
        [itemZ]: item.values[indexZ],
      }
    })
    modalDataSource.push(obj)
  })
  return modalDataSource
}

export const handleDefaultModalSource = (exploreData, showModalTitle) => {
  const { groupLabels, z, y } = exploreData
  const modalDataSource = []
  const dataArr = y.filter(item => Object.keys(item)[0] === showModalTitle)
  let arr = Object.values(dataArr[0])[0].slice(1)
  arr.forEach((item, index) => {
    let obj = {
      key: index,
    }
    groupLabels.forEach((groupName, groupIndex) => {
      obj = {
        ...obj,
        [groupName]: item.groupCols[groupIndex],
      }
    })
    obj = {
      ...obj,
      allUser: item.values[0],
    }
    z.forEach((itemZ, indexZ) => {
      obj = {
        ...obj,
        [itemZ]: item.values[indexZ + 1],
        [`${itemZ}percentValues`]: item.percentValues[indexZ + 1],
      }
    })
    modalDataSource.push(obj)
  })
  return modalDataSource
}

export const handleDefaultModalHead = (
  exploreData,
  byTime,
  showModalTitle,
  boardTitle,
  handleNavigate,
  handleCreateModal
) => {
  // 弹窗表头
  const modalTableHead = []
  const { groupLabels, z, columnIndex, unionGroups } = exploreData
  groupLabels.forEach(item => {
    modalTableHead.push({
      title: item,
      dataIndex: item,
      key: item,
      ellipsis: true,
      defaultSortOrder: '',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
    })
  })
  modalTableHead.push({
    title: '全部用户',
    dataIndex: 'allUser',
    key: 'allUser',
    defaultSortOrder: '',
    ellipsis: true,
    align: 'center',
    width: colWidth,
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => a.allUser - b.allUser,
    render: (text, records, index) => {
      const unitTime =
        boardTitle === '周登录天数分布'
          ? showModalTitle
          : showModalTitle + getDateText(byTime, showModalTitle)
      const groupsLabels = unionGroups[index].join(',')
      let resultText = ''
      if (boardTitle === '充值用户周累充分布') {
        resultText = `${unitTime}参与付费事件的${groupsLabels}用户总共有${text}个`
      }
      if (boardTitle === '在线时间分布') {
        resultText = `${unitTime}参与用户登出的${groupsLabels}用户总共有${text}个`
      }
      if (boardTitle === '周登录天数分布') {
        resultText = `${unitTime}参与用户登录的${groupsLabels}用户总共有${text}个`
      }
      const canClick = Number(text) && columnIndex[0]
      if (canClick) {
        const clickProps = {
          firName: '事件分析',
          secName: '用户列表',
          sliceDate: showModalTitle,
          sliceGroupVal: unionGroups[index].join(','),
          columnIndex: columnIndex[0],
        }
        return (
          <PopoverTips isBlackStyle content={resultText}>
            <ColumnItem canClick>
              <div onClick={() => handleNavigate(clickProps)}>{text}</div>
              <RightIcon>
                <PopoverTips
                  placement="top"
                  content="创建结果分群"
                  isBlackStyle
                >
                  <div>
                    <SvgIcon
                      icon="#icon-tianjia1"
                      className="icon"
                      handleClick={() => handleCreateModal(clickProps)}
                    />
                  </div>
                </PopoverTips>
              </RightIcon>
            </ColumnItem>
          </PopoverTips>
        )
      }
      return (
        <PopoverTips isBlackStyle content={resultText}>
          <div>{text}</div>
        </PopoverTips>
      )
    },
  })
  z.forEach((item, index) => {
    const tableItem = {
      title: item,
      dataIndex: item,
      key: item,
      defaultSortOrder: '',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
      render: (text, records, idx) => {
        const dateText =
          boardTitle === '周登录天数分布'
            ? showModalTitle
            : showModalTitle + getDateText(byTime, showModalTitle)
        const groupsLabels = unionGroups[idx].join(',')
        let contentText = ''
        let scopeText = ''
        if (item[0] === '(' || item[0] === '[') {
          if (index === 0) {
            const scopeItem = item.split(',')[1].slice(0, -1)
            scopeText = `.小于${scopeItem}`
          } else if (index === z.length - 1) {
            const scopeItem = item.split(',')[0].slice(1)
            scopeText = `.大于等于${scopeItem}`
          } else {
            const scopeItemA = item.split(',')[0].slice(1)
            const scopeItemB = item.split(',')[1].slice(0, -1)
            scopeText = `.介于${scopeItemA}和${scopeItemB}(包括${scopeItemA}不包括${scopeItemB})`
          }
          if (boardTitle === '充值用户周累充分布') {
            contentText = `${dateText}参与付费事件的${groupsLabels}用户中，有${text}个用户的支付金额.${scopeText}，占比${
              records[`${item}percentValues`]
            }%`
          }
          if (boardTitle === '在线时间分布') {
            contentText = `${dateText}参与用户登出的${groupsLabels}用户中，有${text}个用户的在线时长.${scopeText}，占比${
              records[`${item}percentValues`]
            }%`
          }
        } else if (boardTitle === '周登录天数分布') {
          scopeText = item.slice(0, -1)
          contentText = `${dateText}参与用户登录的${groupsLabels}用户中，有${text}个用户的等于${scopeText}，占比${
            records[`${item}percentValues`]
          }%`
        } else if (boardTitle === '在线时间分布' && index === 0) {
          return text
        }
        const canClick = Number(text) && columnIndex[index + 1]
        return (
          <PopoverTips isBlackStyle content={contentText}>
            <div>
              <div
                style={{
                  textDecoration: canClick ? 'underline' : '',
                  cursor: canClick ? 'pointer' : 'default',
                }}
                onClick={() => {
                  canClick &&
                    handleNavigate({
                      firName: '事件分析',
                      secName: '用户列表',
                      sliceDate: showModalTitle,
                      sliceGroupVal: unionGroups[index].join(','),
                      columnIndex: columnIndex[index + 1],
                    })
                }}
              >
                <div>{text}</div>
              </div>
              {text !== '-' && (
                <div>
                  {records[`${item}percentValues`]}
                  <span>%</span>
                </div>
              )}
            </div>
          </PopoverTips>
        )
      },
    }
    modalTableHead.push(tableItem)
  })
  return modalTableHead
}
