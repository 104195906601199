const payMenu = [
  {
    id: 1,
    pid: 0,
    label: '日期',
    key: '日期',
  },
  // {
  //   label: '阶段汇总',
  //   key: '阶段汇总',
  // },
]

const retainedMenu = [
  {
    id: 1,
    label: '留存',
    key: '留存',
    pid: 0,
  },
  {
    id: 2,
    label: '流失',
    key: '流失',
    pid: 0,
  },
]

export const getMenuList = (title: string) => {
  switch (title) {
    case '付费率':
    case '登录方式统计':
    case '礼包购买情况':
    case '近7日累充排名':
      return payMenu
    case '各渠道新增留存':
    case '7日LTV(不支持设备Id)':
      return retainedMenu
    default:
      return []
  }
}
export const byTimeList = [
  { id: 1, label: '按天', key: 'byDay', pid: 0, child: [] },

  { id: 2, label: '按周', key: 'byWeek', pid: 0, child: [] },

  { id: 3, label: '按月', key: 'byMonth', pid: 0, child: [] },

  { id: 4, label: '按季度', key: 'byQuarter', pid: 0, child: [] },

  { id: 5, label: '按年', key: 'byYear', pid: 0, child: [] },
]
