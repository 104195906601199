import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function WeekActiveUser() {
  const props = {
    boardTitle: '周有效活跃用户数',
    modular: 'weekActiveUser',
    exploreType: 'activeBoard',
    tips: '粒度选择为“按周”计算，可以获取本周的去重用户数',
    unit: '人',
    byTime: 'byWeek',
  }
  return <TemplateCard {...props} />
}
export default WeekActiveUser
