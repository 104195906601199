import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function OnlineDuration() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'onlineDuration',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'onlineDuration',
    boardTitle: '在线时间分布',
    toggleType: 'table',
    showLeft: true,
    tips: '在线时长的计算，使用了分布分析，并且运用了登出事件中的在线时长（需在数据采集时记录），计算结果为每个时长区间（秒为单位）下的用户数。包含当天时间的数据可能不完整，仅做展示参考',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
