import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import {
  exploreCollection,
  setFieldCollection,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  getExtendCondition,
  getFieldCollectionList,
  getFilterList,
  setterStoreState,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import FilterConditionCom from '@/components/FilterConditionCom'
import ExtendGroupsConditionCom from '@/components/ExtendGroupsConditionCom'
import CollectModalCom from '../CollectModalCom'
import {
  SearchWrapper,
  SearchWrap,
  Footer,
  Filters,
  Groups,
  SearchTitle,
  Btn,
} from './styles'

interface Props {
  hasGroup?: boolean
  submitFunction?: (val: any) => void
}

function Search(props: Props) {
  const { hasGroup = true, submitFunction } = props
  const dispatch = useAppDispatch()
  const [openExtend, setOpenExtend] = useState<string>('-1')
  const [showCollection, setShowCollection] = useState<boolean>(false)
  const userId = useAppSelector(state => state.exploreBoard.userId)
  const gameId = useAppSelector(state => state.exploreBoard.gameId)
  const modular = useAppSelector(state => state.exploreBoard.modular)
  const iconPath = useAppSelector(state => state.exploreBoard.iconPath)
  const filterList = useAppSelector(state => state.exploreBoard.filterList)
  const conditionDatumList = useAppSelector(
    state => state.exploreBoard.conditionDatumList
  )
  const conditionData = useAppSelector(
    state => state.exploreBoard.conditionData
  )

  const fieldCollectionList = useAppSelector(
    state => state.exploreBoard.fieldCollectionList
  )
  const groupDataList = useAppSelector(
    state => state.exploreBoard.groupDataList
  )
  const goCalculateCount = useAppSelector(
    state => state.exploreBoard.goCalculateCount
  )
  const [messageApi, contextHolder] = message.useMessage()

  const [collectItem, setCollectItem] = useState<ExploreHistoryListData | null>(
    null
  )
  const [collectName, setCollectName] = useState<string>('')

  useEffect(() => {
    if (gameId) {
      dispatch(
        getExtendCondition({
          gameId,
          userId,
        })
      ) // 请求属性列表
      dispatch(
        getFieldCollectionList({
          userId,
          gameId,
        })
      )
      dispatch(getFilterList())
    }
  }, [gameId])

  const handleConditionData = (val: ConditionList | null) => {
    dispatch(
      setterStoreState({
        type: 'conditionData',
        value: val,
      })
    )
  }

  const handleFieldCollection = async (arr: HandleFieldArr[]) => {
    if (!arr?.length) return
    const result = await setFieldCollection({
      gameId,
      userId,
      requestData: arr,
    })
    if (result.code === 0) {
      dispatch(
        getExtendCondition({
          gameId,
          userId,
        })
      )
      dispatch(
        getFieldCollectionList({
          gameId,
          userId,
        })
      )
    }
  }

  const handleGroupDataList = (result: GroupItem[]) => {
    dispatch(
      setterStoreState({
        type: 'groupDataList',
        value: result,
      })
    )
    const searchWrapperDom = document.getElementById('searchWrapper')
    if (searchWrapperDom && result.length > groupDataList.length) {
      setTimeout(() => {
        searchWrapperDom.scrollTop = searchWrapperDom.scrollHeight
      }, 100)
    }
  }

  /*
    计算
  */
  const handleSubmit = () => {
    const groupData = groupDataList.length ? JSON.stringify(groupDataList) : ''
    const condData = conditionData ? JSON.stringify(conditionData) : ''
    if (conditionData) {
      const exceptArray = ['有值', '无值']
      const testCondition = conditionData ? JSON.stringify(conditionData) : '{}'
      if (testCondition !== '{}') {
        const parseCondition = JSON.parse(testCondition)
        const targetItem = parseCondition.list.find((item: any) =>
          item.list.find(
            (listItem: any) =>
              !listItem.filter.value &&
              !exceptArray.includes(listItem.filter.label)
          )
        )
        if (targetItem) {
          return messageApi.error('筛选条件参数错误')
        }
      }
    }
    if (submitFunction) {
      submitFunction({
        condition: condData,
        grouping: groupData,
      })
    } else {
      dispatch(
        setterStoreState({
          type: 'goCalculateCount',
          value: goCalculateCount + 1,
        })
      )
    }
    setShowCollection(true)
  }

  const handleCollection = () => {
    const groupData = groupDataList.length ? JSON.stringify(groupDataList) : ''
    const condData = conditionData ? JSON.stringify(conditionData) : ''
    setCollectItem({
      condition: condData,
      grouping: groupData,
    })
  }

  const handleCancel = () => {
    setCollectItem(null)
    setCollectName('')
  }

  const handleOk = () => {
    exploreCollection({
      userId,
      gameId,
      name: collectName,
      condition: collectItem!.condition,
      grouping: collectItem!.grouping,
      identification: modular,
    }).then(res => {
      if (res.code === 0) {
        messageApi.success('收藏成功')
      }
      handleCancel()
    })
  }

  const collectProps = {
    collectItem: collectItem,
    openModal: !!collectItem,
    title: '创建收藏',
    collectName,
    okText: '创建',
    okDisabled: !collectName,
    handleOk,
    handleCancel,
    handleName: (val: string) => setCollectName(val),
  }

  return (
    <SearchWrapper>
      {contextHolder}
      <SearchWrap id="searchWrapper">
        <Filters>
          <SearchTitle>指标筛选</SearchTitle>
          <FilterConditionCom
            conditionDatumList={conditionDatumList}
            openExtend={openExtend}
            handleOpenExtend={val => setOpenExtend(val)}
            filterList={filterList}
            conditionData={conditionData}
            fieldCollectionList={fieldCollectionList}
            iconPath={iconPath}
            handleConditionData={val => handleConditionData(val)}
            handleFieldCollection={val => handleFieldCollection(val)}
          />
        </Filters>
        {hasGroup && (
          <Groups>
            <SearchTitle>分组项</SearchTitle>
            <ExtendGroupsConditionCom
              groupDataList={groupDataList}
              iconPath={iconPath}
              groupConditionDatumList={conditionDatumList}
              groupFieldCollectionList={fieldCollectionList}
              maxLength={10}
              handleGroupDataList={handleGroupDataList}
              handleFieldCollection={handleFieldCollection}
            />
          </Groups>
        )}
      </SearchWrap>
      <Footer>
        {showCollection && <Btn onClick={handleCollection}>收藏</Btn>}
        <Btn type="primary" onClick={handleSubmit}>
          计算
        </Btn>
      </Footer>
      <CollectModalCom {...collectProps} />
    </SearchWrapper>
  )
}

export default Search
