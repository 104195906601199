import React from 'react'
import { Select } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import { SelectWrapper } from './styles'

const { Option } = Select

interface IDropdownUnitSelector {
  byTime: string
  handleDropDownSelect: (arg: string) => void
  byTimeList: IByTime[]
  needBorder?: boolean
  style?: React.CSSProperties
}

const defaultStyle = {
  marginLeft: '8px',
}

export default function DropdownUnitSelector(props: IDropdownUnitSelector) {
  const {
    handleDropDownSelect,
    byTime,
    byTimeList,
    needBorder = false,
    style,
  } = props

  return (
    <SelectWrapper
      value={byTime || 'byDay'}
      size="small"
      bordered={needBorder}
      // @ts-ignore
      onChange={(val: string) => handleDropDownSelect(val)}
      popupClassName="popupClass"
      dropdownMatchSelectWidth={false}
      showArrow={needBorder}
      suffixIcon={<SvgIcon icon="#icon-xiala1" className="icon" />}
      style={style || defaultStyle}
    >
      {byTimeList.map(item => (
        <Option value={item.key} key={item.key}>
          {item.label}
        </Option>
      ))}
    </SelectWrapper>
  )
}
