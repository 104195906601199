import { axiosInstance } from '@/http'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

// 用户标签详情
export const getTagInfo = (
  params: TagsInfoParams
): Promise<IResponse<TagsInfo>> =>
  axiosInstance.get('/api/econ/v1/userAnalyze/tagInfo', { params })

// 用户标签详情数据
export const getTagDataInfo = (
  params: TagsDataInfoParams
): Promise<IResponse<resTagTableData>> =>
  axiosInstance.post('/api/econ/v1/userAnalyze/tagDataInfo', params)

// 标签历史版本
export const getTagHistory = (
  params: TagHistoryParams
): Promise<IResponse<TagHistory[]>> =>
  axiosInstance.get('/api/econ/v1/userAnalyze/tagHistory', { params })

// 删除标签
export const deleteTag = (params: TagHistoryParams): Promise<IResponse<any>> =>
  axiosInstance.delete('/api/econ/v1/userAnalyze/tagDelete', { params })
