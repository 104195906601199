import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ColumnsType } from 'antd/lib/table/interface'
import { RootState } from '@/store'
import {
  extendCondition,
  fieldCollectionList,
  exploreHistoryList,
  filterList,
  collectionList,
  exploreCollection,
  payPanelExplore,
  coreBoardExplore,
  activeBoardExplore,
} from '@/http/businessAnalysis/exploreBoard/condition'

export interface ToolBarLeftProps {
  needDurationTime?: boolean
  needPopoverNumber?: boolean
  needUnitSelector?: boolean
  needDropDownSelector?: boolean
  needDatePickerSelector?: boolean
  needIndexSelector?: boolean
}
export interface InitDataProps {
  boardTitle: string
  byTime: string
  timeRange: string[]
  modular: string
  exploreType: string
  grouping?: GroupItem[]
  days?: number
  byDate?: string // 7日lvt / 各渠道新增留存
  condition?: ConditionList | null
}
export interface FrozenDataProps extends InitDataProps {
  gameId: string
  userId: number
  companyName: string
}

interface TableState {
  tableLoading: boolean
  tableColumns: ColumnsType<any>[]
  tableDataSource: object[]
}

export interface IExploreBoard {
  gameId: string
  boardTitle: string
  userId: number
  modular: string
  exploreType: string
  closeLeft: boolean
  toggleType: string
  timeRange: string[]
  timeType: number[]
  companyName: string
  xData: string[]
  trendData: IBasicTrendList[]
  distrData: IBasicDistribution[] | null
  distrGroupData: IBasicDistributionGroup | null
  cumulativeData: IBasicTrendList[]
  byTime: string
  indicators: string[] // 指标
  frozenIndicators: string[]
  groups: string[] // 分组
  frozenGroups: string[]
  checkRange: string[]
  checkType: '日期' | '阶段汇总' | '留存' | '流失'
  retainedCount: number
  byDate: string
  showChartLabel: boolean
  toolBarLeftProps: ToolBarLeftProps
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  groupDataList: GroupItem[]
  exploreHistoryLoading: boolean
  exploreHistoryList: ExploreHistoryListData[]
  exploreCollectionLoading: boolean
  exploreCollectionList: ExploreCollectListData[]
  toogleTypeList: ToogleTypeList[]
  exploreDataLoading: boolean
  exploreData: ExploreData | null
  showModalTitle: string
  modalTableHead: any[]
  pieData: PieChart[][]
  goCalculateCount: number
  refreshProps: any
  tableState: TableState
  downLoadDataSource: object[]
  downLoadHead: ColumnsType<any>[]
}
export const initialState: IExploreBoard = {
  gameId: '',
  boardTitle: '', //  标题
  companyName: '', // 公司名
  userId: -1, // 用户 id
  modular: '', // 报表类型
  exploreType: '', // 看板类型 基础看板
  closeLeft: false, // 是否关闭左边 按那个箭头按钮
  toggleType: '', // 显示的图形  默认显示图表/根据外面带进来
  timeRange: [], // 时间范围
  timeType: [2, 2], // 动静态设置
  indicators: [], // 指标
  frozenIndicators: [], // 不会被改变的指标
  groups: [], // 分组
  frozenGroups: [], // 不会被改变的分组
  xData: [], // Echarts 折线图 x轴
  trendData: [], //  趋势图 （折现图）
  distrData: [], // 分布图 （柱状图）
  distrGroupData: null, // 分布（柱状组图）
  cumulativeData: [], // 累计图 （折线图）
  pieData: [], // 饼图
  byTime: 'byDay', // 按天 按月 按周  也从外面拿
  checkType: '日期', // 也从外面拿
  checkRange: ['', ''], // 也从外面拿
  retainedCount: 7, // 连续N天未登录
  byDate: '', // 7天 当天 当周 次周 4周
  showChartLabel: false, // 显示数值
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 属性列表
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  goCalculateCount: 1,
  filterList: [], // 条件列表
  groupDataList: [], // 分组项
  conditionData: null, // 指标筛选
  exploreHistoryLoading: false, // 探索历史加载
  exploreHistoryList: [], // 探索历史列表
  exploreCollectionLoading: false, // 探索收藏加载
  exploreCollectionList: [], // 探索收藏 列表
  exploreDataLoading: true, // 探索内容的Loading
  exploreData: null, // 探索的数据
  toogleTypeList: [], // 呈现的数据形式 表格/折线图/柱状图
  showModalTitle: '', // 弹窗表格的标题 也可以用来判断是否展示弹窗表格
  modalTableHead: [], // 弹窗表格表头
  toolBarLeftProps: {
    needDurationTime: false, // 时间段选择器
    needPopoverNumber: false, // 日期填充
    needUnitSelector: true,
    needDropDownSelector: false,
    needDatePickerSelector: false,
  },
  refreshProps: null, // 记录请求的参数 用于刷新功能
  tableState: {
    tableLoading: true, // 表格的状态
    tableDataSource: [], // 表格的数据
    tableColumns: [], // 表格的表头
  },
  downLoadDataSource: [],
  downLoadHead: [],
}

/* 获取属性列表 */
export const getExtendCondition = createAsyncThunk(
  'exploreBoard/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'exploreBoard/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)
/* 探索收藏列表 */
export const getCollectionList = createAsyncThunk(
  'exploreBoard/getCollectionList',
  async (params: ExploreHistoryListProps) => {
    const result = await collectionList(params)
    return result.data
  }
)

/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'exploreBoard/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 操作历史列表 */
export const getExploreHistoryList = createAsyncThunk(
  'exploreBoard/exploreHistoryList',
  async (params: ExploreHistoryListProps) => {
    const result = await exploreHistoryList(params)
    return result.data
  }
)

/* 探索收藏操作  */
export const exploreCollectionFunc = createAsyncThunk(
  'exploreBoard/exploreCollection',
  async (params: ExploreCollectionProps) => {
    const result = await exploreCollection(params)
    return result.data
  }
)

/* 探索 -- 付费看板  */
export const payPanelExploreFunc = createAsyncThunk(
  'exploreBoard/payPanelExploreFunc',
  async (params: PayPanelExploreProps) => {
    const result = await payPanelExplore(params)
    return {
      data: result.data,
      refreshProps: params,
    }
  }
)
/* 探索 -- 核心看板  */
export const coreBoardExploreFunc = createAsyncThunk(
  'exploreBoard/coreBoardExplore',
  async (params: PayPanelExploreProps) => {
    const result = await coreBoardExplore(params)
    return {
      data: result.data,
      refreshProps: params,
    }
  }
)
/* 探索 -- 活跃看板  */
export const activeBoardExploreFunc = createAsyncThunk(
  'exploreBoard/activeBoardExplore',
  async (params: PayPanelExploreProps) => {
    const result = await activeBoardExplore(params)
    return {
      data: result.data,
      refreshProps: params,
    }
  }
)

export const exploreBoardSlice = createSlice({
  name: 'exploreBoard',
  initialState,
  reducers: {
    resetState: state => initialState,
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IExploreBoard; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    showChartLabelAction: (state, action: PayloadAction<any>) => {
      const { showChartLabel, trendData, distrData, cumData } = action.payload
      state.showChartLabel = showChartLabel
      const newTrendData = trendData.map((item: any) => ({
        ...item,
        symbol: null,
        label: {
          show: showChartLabel,
          position: 'top',
        },
      }))
      state.trendData = newTrendData
      const newDistrData = distrData.map((item: any) => ({
        ...item,
        symbol: null,
        label: {
          show: showChartLabel,
          position: 'top',
        },
      }))
      state.distrData = newDistrData
      const newCumData = cumData.map((item: any) => ({
        ...item,
        symbol: null,
        label: {
          show: showChartLabel,
          position: 'top',
        },
      }))
      state.cumulativeData = newCumData
    },
    /* 初始化数据 */
    initStoreState: (state: any, action: PayloadAction<FrozenDataProps>) => {
      const value = action.payload
      /* 需要初始化的值 */
      Object.keys(value).forEach((key, index) => {
        const itemValue = Object.values(value)[index]
        state[key] = itemValue
        if (key === 'grouping' && itemValue) state.groupDataList = itemValue
        if (key === 'condition' && itemValue) state.conditionData = itemValue
      })
    },
    initToolBarState: (state: any, action: PayloadAction<ToolBarLeftProps>) => {
      state.toolBarLeftProps = {
        ...state.toolBarLeftProps,
        ...action.payload,
      }
    },
    handleEchartsData: (state: any, action: PayloadAction<any>) => {
      const {
        columns,
        dataSource,
        xData,
        trendData,
        cumulativeData,
        indicators,
        toogleTypeList,
        distrGroupData,
        groups,
        modalTableHead,
        pieData,
        downLoadDataSource,
        downLoadHead,
      } = action.payload
      // console.log('最终数据', action.payload)
      state.xData = xData
      state.trendData = trendData
      // state.distrData = distrData
      state.distrGroupData = distrGroupData
      state.cumulativeData = cumulativeData
      state.pieData = pieData
      state.indicators =
        indicators.length > 10 ? indicators.slice(0, 10) : indicators // 最大数量为10
      state.frozenIndicators = indicators
      state.groups = groups.length > 5 ? groups.slice(0, 2) : groups // 默认只选2个
      state.frozenGroups = groups
      state.modalTableHead = modalTableHead
      state.toogleTypeList = toogleTypeList
      state.toggleType = toogleTypeList[0].type
      state.downLoadDataSource = downLoadDataSource
      state.downLoadHead = downLoadHead
      state.exploreDataLoading = false
      state.tableState = {
        tableLoading: true,
        tableDataSource: dataSource,
        tableColumns: columns,
      }
    },
  },

  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder
      .addCase(getCollectionList.pending, (state, action) => {
        state.exploreCollectionLoading = true
      })
      .addCase(getCollectionList.fulfilled, (state, action) => {
        state.exploreCollectionLoading = false
        state.exploreCollectionList = action.payload
      })
    builder
      .addCase(getExploreHistoryList.pending, (state, action) => {
        state.exploreHistoryLoading = true
      })
      .addCase(getExploreHistoryList.fulfilled, (state, action) => {
        state.exploreHistoryLoading = false
        state.exploreHistoryList = action.payload.list || []
      })
    builder
      .addCase(payPanelExploreFunc.pending, (state, action) => {
        state.exploreDataLoading = true
      })
      .addCase(payPanelExploreFunc.fulfilled, (state, action) => {
        const { data, refreshProps } = action.payload
        state.exploreData = data
        state.refreshProps = refreshProps
      })
    builder
      .addCase(coreBoardExploreFunc.pending, (state, action) => {
        state.exploreDataLoading = true
      })
      .addCase(coreBoardExploreFunc.fulfilled, (state, action) => {
        const { data, refreshProps } = action.payload
        state.exploreData = data
        state.refreshProps = refreshProps
      })
    builder
      .addCase(activeBoardExploreFunc.pending, (state, action) => {
        state.exploreDataLoading = true
      })
      .addCase(activeBoardExploreFunc.fulfilled, (state, action) => {
        const { data, refreshProps } = action.payload
        state.exploreData = data
        state.refreshProps = refreshProps
      })
  },
})
export const exploreBoardState = (state: RootState) => state.exploreBoard
export default exploreBoardSlice.reducer
export const {
  setterStoreState,
  initStoreState,
  initToolBarState, // 初始化边栏功能
  showChartLabelAction, // 显示数值
  resetState, // 还原数据
  handleEchartsData, // 处理数据
} = exploreBoardSlice.actions
