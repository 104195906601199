import React, { useState } from 'react'
import { Popover } from 'antd'
import moment from 'moment'
import {
  UnitTextWrapper,
  UniteWrapper,
  ContentWrapper,
  LeftWrapper,
  RightWrapper,
  Item,
  Default,
  RightTitle,
  RightDate,
} from './styles'

function TimePicker() {
  const [activeId, setActiveId] = useState<number>(8)
  const [visible, setVisible] = useState<boolean>(false)
  const [hoverId, setHoverId] = useState<number>(0)

  const getTime = (hour: number) => moment().add({ hour }).format('HH:mm')

  const list = [
    {
      label: 'UTC+08:00',
      value: getTime(0),
    },
    {
      label: '当地时间',
      value: getTime(0),
    },
    {
      label: 'UTC-12:00',
      value: getTime(4),
    },
    {
      label: 'UTC-11:00',
      value: getTime(5),
    },
    {
      label: 'UTC-10:00',
      value: getTime(6),
    },
    {
      label: 'UTC-09:00',
      value: getTime(7),
    },
    {
      label: 'UTC-07:00',
      value: getTime(9),
    },
    {
      label: 'UTC-03:00',
      value: getTime(13),
    },
    {
      label: 'UTC±00:00',
      value: getTime(16),
    },
  ]

  const content = (
    <ContentWrapper>
      <LeftWrapper onMouseLeave={() => setHoverId(activeId)}>
        {list.map((item, index) => (
          <Item
            key={index}
            onClick={() => {
              setActiveId(index)
              setVisible(false)
            }}
            onMouseOver={() => setHoverId(index)}
            active={activeId === index}
          >
            {item.label}
            {index === 0 && <Default>默认</Default>}
          </Item>
        ))}
      </LeftWrapper>
      <RightWrapper>
        <RightTitle>
          {hoverId === activeId ? list[activeId].label : list[hoverId].label}
          {hoverId === 0 && activeId === 0 && <span>（默认）</span>}
        </RightTitle>
        <RightDate>
          <div className="time">
            <em>
              {hoverId === activeId
                ? list[activeId].value.split(':')[0]
                : list[hoverId].value.split(':')[0]}
            </em>
            <em>：</em>
            <em>
              {hoverId === activeId
                ? list[activeId].value.split(':')[1]
                : list[hoverId].value.split(':')[1]}
            </em>
          </div>
        </RightDate>
      </RightWrapper>
    </ContentWrapper>
  )

  // const handleVisibleChange = (newVisible: boolean) => {
  //   setVisible(newVisible)
  // }

  return (
    <Popover
      placement="bottomLeft"
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={() => null}
    >
      <UniteWrapper>
        <UnitTextWrapper>{list[activeId].label}</UnitTextWrapper>
      </UniteWrapper>
    </Popover>
  )
}

export default TimePicker
