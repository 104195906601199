import React, { useState, useMemo } from 'react'
import { InputNumber } from 'antd'
import PopoverCondition from '@/components/PopoverCondition'
import PopoverTips from '@/components/PopoverTips'
import { NumberSubmitWrap, NumberSubmit } from './styles'

interface PopoverConditionProps {
  days: number
  handleDays: (val: number) => void
}

function PopoverNumber(props: PopoverConditionProps) {
  const { days, handleDays } = props
  const [visible, setVisible] = useState<boolean>(false) // 打开数字填充器
  const [day, setDay] = useState(days)
  /* 提交数字 */
  const handleSubmit = () => {
    handleDays(day)
    setVisible(false)
  }
  const handleClose = () => {
    setVisible(false)
    setDay(days)
  }
  const Content = useMemo(
    () => (
      <NumberSubmitWrap>
        <div className="content">
          <div className="title">
            <span>连续N天未登录</span>
            <PopoverTips content="即回流的规则，连续N天未登录后再次登录的用户会被算作回流用户，默认值为7，必须输入数字" />
          </div>
          <InputNumber
            className="inpNumber"
            min={1}
            max={90}
            defaultValue={day}
            precision={0}
            // @ts-ignore
            onChange={val => setDay(val)}
          />
        </div>
        <NumberSubmit>
          <div className="cancel" onClick={handleClose}>
            取消
          </div>
          <div className="submit" onClick={handleSubmit}>
            确定
          </div>
        </NumberSubmit>
      </NumberSubmitWrap>
    ),
    [day]
  )
  return (
    <PopoverCondition
      visible={visible}
      changeVisible={(arg: boolean) => (arg ? setVisible(true) : handleClose())}
      Content={Content}
      ContentName="调整参数"
    />
  )
}

export default PopoverNumber
