import React from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'

export default function PayUserNumber() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'payUserNumber',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'payUserNumber',
    boardTitle: '日充值用户数',
    toggleType: 'trend',
    toolBarLeftProps: {
      needIndexSelector: true,
    },
    showTop: true,
    tips: '通过"充值"事件和使用“是否首次付费”（虚拟属性）进行筛选的"充值"事件的"触发用户数"计算每天的充值用户数（按日去重）',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
