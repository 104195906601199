import React from 'react'
import {
  ContentWrapper,
  Top,
  ConditionWrapper,
  RelationEditor,
  RelationWrapper,
  RelationContent,
  MainItemWrapper,
  Bottom,
  ListWrapper,
  ListItem,
  // EmptyList,
} from '../../CollectCom/styles'

interface ContentProps {
  item: ExploreHistoryListData
  needGroup?: boolean
}
function Content(props: ContentProps) {
  const { item, needGroup = true } = props
  const group = item.grouping ? JSON.parse(item.grouping) : []
  const cond: ConditionList | null = item.condition
    ? JSON.parse(item.condition)
    : null
  return (
    <ContentWrapper>
      <Top>
        <div className="title">指标筛选</div>
        {!item.condition && <span>无筛选</span>}
        {item.condition && (
          <ConditionWrapper>
            <RelationEditor>
              {!!cond!.relation && (
                <RelationWrapper>
                  <em />
                  <div className="value">{cond!.relation}</div>
                </RelationWrapper>
              )}
              <RelationContent>
                {cond!.list.map((itm, index) => {
                  if (itm.list.length === 1) {
                    return (
                      <MainItemWrapper key={index}>
                        <i className="dot" />
                        {itm.list[0].condition.label}
                        <span className="op">{itm.list[0].filter.label}</span>
                        {itm.list[0].filter.value}
                      </MainItemWrapper>
                    )
                  }
                  return (
                    <MainItemWrapper isList key={index}>
                      <RelationEditor>
                        <RelationWrapper>
                          <em />
                          <div className="value">{itm.relation}</div>
                        </RelationWrapper>
                        <RelationContent>
                          {itm.list.map((it, idx) => (
                            <MainItemWrapper key={idx}>
                              <i className="dot" />
                              {it.condition.label}
                              <span className="op">{it.filter.label}</span>
                              {it.filter.value}
                            </MainItemWrapper>
                          ))}
                        </RelationContent>
                      </RelationEditor>
                    </MainItemWrapper>
                  )
                })}
              </RelationContent>
            </RelationEditor>
          </ConditionWrapper>
        )}
      </Top>
      {needGroup && (
        <Bottom>
          <div className="title">分组项</div>
          {!item.grouping && <span>无筛选</span>}
          {item.grouping && (
            <ListWrapper>
              {group.map((itm: { label: string }) => (
                <ListItem key={itm.label}>
                  <span className="dot" />
                  {itm.label}
                </ListItem>
              ))}
            </ListWrapper>
          )}
        </Bottom>
      )}
    </ContentWrapper>
  )
}

export default Content
