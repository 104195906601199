import styled from 'styled-components'
import { Button } from 'antd'

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const SearchWrap = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
`

export const Filters = styled.div`
  min-height: 50%;
  margin: 0 8px;
  padding: 0 0 20px;
  border-bottom: 1px solid rgb(240, 242, 245);
`

export const Groups = styled.div`
  margin: 0 8px px;
  padding: 0 0 20px;
  border-top: 1px solid rgb(240, 240, 240);
`

export const SearchTitle = styled.div`
  height: 48px;
  padding-left: 16px;
  color: rgb(66, 84, 109);
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  text-align: right;
  border-top: 1px solid rgb(240, 240, 240);
`

export const Btn = styled(Button)`
  margin-left: 20px;
`
