import React from 'react'
import moment from 'moment'
import PopoverTips from '@/components/PopoverTips'
import { getDateText, colWidth, parsePercentFloatNum } from '@/utiles/common'
import SvgIcon from '@/components/SvgIcon'
import {
  PlusSquareOutIcon,
  PlusSquareOutWrapper,
  RightIcon,
  ColumnItem,
} from './styles'

/*
  y 有两个数组  第一个为留存 第二个为流失
*/
export const handleRetainedBoard = exploreData => {
  const { x } = exploreData
  return {
    xData: x,
  }
}

export const formatFloat = function (f) {
  let m = 10 ** 2
  return Math.round(f * 100 * m, 10) / m
}

/* 排序的方法 */
export const sortFun = (a, b) => {
  if (typeof (a * 1) === 'number' && !Number.isNaN(a * 1)) {
    return a - b
  }
  const unitArr = ['%', '次', 's', '个', '人', '元']
  const isDollarUnit = a.slice(-2) === '美元'
  if (isDollarUnit) {
    const valueA = Number(a.slice(0, -2))
    const valueB = Number(b.slice(0, -2))
    return valueA - valueB
  }
  const lastText = a.slice(-1)
  if (unitArr.includes(lastText)) {
    const valueA = formatFloat(a.slice(0, -1))
    const valueB = formatFloat(b.slice(0, -1))
    // console.log(111, valueA, valueB)
    return valueA - valueB
  }
  // 否则按长度排序
  return a.length - b.length
}

export const handleNoZ = (
  exploreData,
  byTime,
  handleNavigate,
  handleCreateModal,
  boardTitle
) => {
  const { x, y, unionGroups, groupLabels, columnIndex } = exploreData
  const xData = x // 折线图/柱状图 x轴
  const trendData = [] // 趋势图
  const cumulativeData = [] // 累计图
  const pieData = [] // 饼图 饼图组
  const distrGroupGrouping = [] // 柱状图的grouping
  const tableHead = [] // 表头数据
  const dataSource = [] // 表格数据
  const indicators = y.map(item => Object.keys(item)[0]) // 指标
  // 表头不需要合并
  if (!groupLabels.length) {
    tableHead.push({
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        // 转时间戳在排序
        const timeA = Number(moment(a.date).format('X'))
        const timeB = Number(moment(b.date).format('X'))
        return timeA - timeB
      },
      render: text => {
        const unitTime = getDateText(byTime, text)
        return text + unitTime
      },
    })
  } else {
    // 表头需要合并
    const sharedOnCell = (_, index) => {
      const g = Object.values(y[0])[0].length // 按照y轴 发生了总次数取合并数量
      const val = Math.floor(index / g) * g
      if (val === index) {
        return { rowSpan: g }
      }
      return { rowSpan: 0 }
    }
    tableHead.push({
      title: '日期',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      onCell: sharedOnCell,
      sorter: (a, b) => {
        // 转时间戳在排序
        const timeA = Number(moment(a.date).format('X'))
        const timeB = Number(moment(b.date).format('X'))
        return timeA - timeB
      },
      render: text => {
        const unitTime = getDateText(byTime, text)
        return text + unitTime
      },
    })
    groupLabels.forEach(item => {
      tableHead.push({
        key: item,
        title: item,
        dataIndex: item,
        align: 'center',
        width: colWidth,
        defaultSortOrder: '',
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => sortFun(a[item], b[item]),
        render: text => {
          if (
            typeof text === 'string' &&
            text.slice(-1) === '%' &&
            text !== '-'
          ) {
            const unit = text.slice(-1)
            const valueNum = Number(text.slice(0, -1))
            return parsePercentFloatNum(valueNum) + unit
          }
          return text
        },
      })
    })
  }
  x.forEach((date, dateIndex) => {
    let dateItemArr = [] // 同一个日期
    y.forEach(itemY => {
      const name = Object.keys(itemY)[0]
      const yArr = Object.values(itemY)[0]
      let dataSourceItem = {
        date, // 日期
        key: date,
      }
      yArr.forEach(yArrItem => {
        const { unit, values, groupCols } = yArrItem
        groupLabels.forEach((labelItem, labelIndex) => {
          dataSourceItem = {
            ...dataSourceItem,
            key: date + groupCols.join(''),
            [labelItem]: groupCols[labelIndex],
          }
        })
        const resultValue =
          values[dateIndex] === '-' ? '-' : `${values[dateIndex]}${unit}`
        dataSourceItem = {
          ...dataSourceItem,
          [name]: resultValue,
        }
        let indexObj = dateItemArr.findIndex(
          item => Object.keys(item)[0] === groupCols.join(',')
        )
        if (indexObj !== -1) {
          const itemObj = Object.values(dateItemArr[indexObj])[0]
          const newItemObj = {
            [groupCols]: {
              ...itemObj,
              ...dataSourceItem,
            },
          }
          dateItemArr.splice(indexObj, 1, newItemObj)
        } else {
          dateItemArr.push({ [groupCols]: dataSourceItem })
        }
      })
    })
    dateItemArr.map(itm => dataSource.push(Object.values(itm)[0]))
  })
  y.forEach((itemY, indexY) => {
    const name = Object.keys(itemY)[0]
    const yArr = Object.values(itemY)[0]
    const pieArr = []
    const distrGroupGroupItem = []
    // 没有分组的情况
    if (!groupLabels.length) {
      pieArr.push({
        name,
        value: yArr[0].totalNum,
        title: name,
      })
    }
    tableHead.push({
      key: name,
      title: name,
      dataIndex: name,
      align: 'center',
      width: colWidth,
      isCalculation: yArr[0].isCalculation,
      defaultSortOrder:
        boardTitle === '近7日累充排名' && name.indexOf('付费总金额') !== -1
          ? 'descend'
          : '',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => sortFun(a[name], b[name]),
      render: (text, records, index) => {
        let resultText = text
        if (typeof text === 'string' && text.slice(-1) === '%') {
          const unit = '%'
          const valueNum = Number(text.slice(0, -1))
          resultText = parsePercentFloatNum(valueNum) + unit
        }
        const canClick = Number(text) && columnIndex[indexY]
        if (canClick) {
          const len = unionGroups.length ? unionGroups.length : 1
          return (
            <ColumnItem>
              <div
                onClick={() => {
                  handleNavigate({
                    firName: '事件分析',
                    secName: '用户列表',
                    sliceDate: records.date,
                    sliceGroupVal: yArr[index % len].groupCols.join(','),
                    columnIndex: columnIndex[indexY],
                  })
                }}
              >
                {resultText}
              </div>
              <RightIcon>
                <PopoverTips
                  placement="top"
                  content="创建结果分群"
                  isBlackStyle
                >
                  <div>
                    <SvgIcon
                      icon="#icon-tianjia1"
                      className="icon"
                      handleClick={() =>
                        handleCreateModal({
                          firName: '事件分析',
                          secName: '用户列表',
                          sliceDate: records.date,
                          sliceGroupVal: yArr[index % len].groupCols.join(','),
                          columnIndex: columnIndex[indexY],
                        })
                      }
                    />
                  </div>
                </PopoverTips>
              </RightIcon>
            </ColumnItem>
          )
        }
        return <div>{resultText}</div>
      },
    })
    yArr.forEach(yArrItem => {
      trendData.push({
        name: groupLabels.length
          ? `${name}.${yArrItem.groupCols.join(',')}`
          : name,
        type: 'line',
        symbol: 'none',
        data: yArrItem.values,
        unit: yArrItem.unit,
        percentValues: yArrItem.percentValues,
        indicatorsName: name,
      })
      distrGroupGroupItem.push([
        `${groupLabels.length ? yArrItem.groupCols.join(',') : ''}`,
        Number(yArrItem.totalNum),
      ])
      let cumulativeValue = [] // 累加
      yArrItem.values.forEach((item, index) => {
        cumulativeValue.push(
          index === 0 ? Number(item) : Number(item) + cumulativeValue[index - 1]
        )
      })

      cumulativeData.push({
        name: groupLabels.length
          ? `${name}.${yArrItem.groupCols.join(',')}`
          : name,
        type: 'line',
        symbol: 'none',
        unit: yArrItem.unit,
        data: cumulativeValue,
        indicatorsName: name,
      })
      // 有分组
      if (groupLabels.length) {
        pieArr.push({
          name: `${yArrItem.groupCols.join(',')}`,
          value: yArrItem.totalNum,
          title: name,
        })
      }
    })
    pieData.push(pieArr)
    distrGroupGrouping.push({
      [name]: distrGroupGroupItem,
    })
  })
  const groupsArr = unionGroups.map(item => item.join(','))
  const distrGroup = {
    product: groupsArr, // 没有分组 则是单根柱子显示
    grouping: distrGroupGrouping,
  }
  return {
    downLoadHead: tableHead,
    downLoadDataSource: dataSource,
    tableHead,
    dataSource,
    xData,
    pieData,
    trendData,
    distrGroup,
    cumulativeData,
    indicators,
    groupsArr,
  }
}

// 需要用到的报表    【   充值用户周累充分布   在线时间分布  周登录天数分布
export const handleZ = (
  exploreData,
  byTime,
  boardTitle,
  clickShowTitle,
  handleNavigate,
  handleCreateModal
) => {
  const { x, y, groupLabels, z, columnIndex } = exploreData
  const xData = x // 折线图/柱状图 x轴
  const dataSource = [] // 表格数据
  const trendData = []
  const cumulativeData = []
  const pieData = []
  // 表头数据
  const defaultTableHead = [
    {
      title: '事件发生时间',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        // 转时间戳在排序
        const timeA = Number(moment(a.date).format('X'))
        const timeB = Number(moment(b.date).format('X'))
        return timeA - timeB
      },
      render: text => {
        const unitTime = getDateText(byTime, text)
        if (groupLabels.length) {
          return (
            <PlusSquareOutWrapper>
              <PlusSquareOutIcon onClick={() => clickShowTitle(text)} />
              {text + unitTime}
            </PlusSquareOutWrapper>
          )
        }
        return text + unitTime
      },
    },
    {
      title: '指标',
      dataIndex: '指标',
      key: '指标',
    },
    {
      title: '全部用户',
      dataIndex: 'allUser',
      key: 'allUser',
      defaultSortOrder: '',
      width: colWidth,
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.allUser - b.allUser,
      render: (text, records, index) => {
        const unitTime =
          boardTitle === '周登录天数分布'
            ? records.date
            : records.date + getDateText(byTime, records.date)
        let resultText = ''
        if (boardTitle === '充值用户周累充分布') {
          resultText = `${unitTime}参与付费事件的用户总共有${text}个`
        }
        if (boardTitle === '在线时间分布') {
          resultText = `${unitTime}有效活跃用户数总共有${text}个`
        }
        if (boardTitle === '周登录天数分布') {
          resultText = `${unitTime}参与用户登录的用户总共有${text}个`
        }
        const canClick = Number(text) && columnIndex[0]
        if (canClick) {
          return (
            <PopoverTips isBlackStyle content={resultText}>
              <ColumnItem>
                <div
                  onClick={() => {
                    canClick &&
                      handleNavigate({
                        firName: '事件分析',
                        secName: '用户列表',
                        sliceDate: records.date,
                        sliceGroupVal: '总体',
                        columnIndex: columnIndex[0],
                      })
                  }}
                >
                  {text}
                </div>
                <RightIcon>
                  <PopoverTips
                    placement="top"
                    content="创建结果分群"
                    isBlackStyle
                  >
                    <div>
                      <SvgIcon
                        icon="#icon-tianjia1"
                        className="icon"
                        handleClick={() =>
                          handleCreateModal({
                            firName: '事件分析',
                            secName: '用户列表',
                            sliceDate: records.date,
                            sliceGroupVal: '总体',
                            columnIndex: columnIndex[0],
                          })
                        }
                      />
                    </div>
                  </PopoverTips>
                </RightIcon>
              </ColumnItem>
            </PopoverTips>
          )
        }
        return (
          <PopoverTips isBlackStyle content={resultText}>
            <div>{text}</div>
          </PopoverTips>
        )
      },
    },
  ]
  const downLoadHead = [...defaultTableHead]
  const downLoadDataSource = []
  const tableHead = defaultTableHead.filter(item => item.key !== '指标')
  groupLabels.forEach(item => {
    downLoadHead.push({
      title: item,
      dataIndex: item,
      key: item,
    })
  })
  z.forEach((item, index) => {
    const tableItem = {
      title: item,
      dataIndex: item,
      key: item,
      defaultSortOrder: '',
      align: 'center',
      width: colWidth,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a[item] - b[item],
      render: (text, records) => {
        const dateText =
          boardTitle === '周登录天数分布'
            ? records.date
            : records.date + getDateText(byTime, records.date)
        let contentText = ''
        let scopeText = ''
        if (item[0] === '(' || item[0] === '[') {
          if (index === 0) {
            const scopeItem = item.split(',')[1].slice(0, -1)
            scopeText = `小于${scopeItem}`
          } else if (index === z.length - 1) {
            const scopeItem = item.split(',')[0].slice(1)
            scopeText = `大于等于${scopeItem}`
          } else {
            const scopeItemA = item.split(',')[0].slice(1)
            const scopeItemB = item.split(',')[1].slice(0, -1)
            scopeText = `介于${scopeItemA}和${scopeItemB}(包括${scopeItemA}不包括${scopeItemB})`
          }
          if (boardTitle === '充值用户周累充分布') {
            contentText = `${dateText}参与付费事件的用户中，有${text}个用户的支付金额${scopeText}，占比${
              records[`${item}percentValues`]
            }%`
          }
          if (boardTitle === '在线时间分布') {
            contentText = `${dateText}有效活跃用户数中，有${text}个用户的在线时长${scopeText}，占比${
              records[`${item}percentValues`]
            }%`
          }
        } else if (boardTitle === '周登录天数分布') {
          scopeText = item.slice(0, -1)
          contentText = `${dateText}参与用户登录的用户中，有${text}个用户的等于${scopeText}，占比${
            records[`${item}percentValues`]
          }%`
        } else if (boardTitle === '在线时间分布' && index === 0) {
          return `${text}`
        }
        const canClick = Number(text) && columnIndex[index + 1]
        if (canClick) {
          return (
            <PopoverTips isBlackStyle content={contentText}>
              <div>
                <ColumnItem>
                  <div
                    onClick={() => {
                      handleNavigate({
                        firName: '事件分析',
                        secName: '用户列表',
                        sliceDate: records.date,
                        sliceGroupVal: '总体',
                        columnIndex: columnIndex[index + 1],
                      })
                    }}
                  >
                    {text}
                  </div>
                  <RightIcon>
                    <PopoverTips
                      placement="top"
                      content="创建结果分群"
                      isBlackStyle
                    >
                      <div>
                        <SvgIcon
                          icon="#icon-tianjia1"
                          className="icon"
                          handleClick={() =>
                            handleCreateModal({
                              firName: '事件分析',
                              secName: '用户列表',
                              sliceDate: records.date,
                              sliceGroupVal: '总体',
                              columnIndex: columnIndex[index + 1],
                            })
                          }
                        />
                      </div>
                    </PopoverTips>
                  </RightIcon>
                </ColumnItem>
                <div>
                  {records[`${item}percentValues`]}
                  <span>%</span>
                </div>
              </div>
            </PopoverTips>
          )
        }
        return (
          <PopoverTips isBlackStyle content={contentText}>
            <div>
              <div>
                <div>{text}</div>
              </div>
              <div>
                {records[`${item}percentValues`]}
                <span>%</span>
              </div>
            </div>
          </PopoverTips>
        )
      },
    }
    downLoadHead.push(tableItem)
    tableHead.push(tableItem)
    const trendDataArr = []
    const { unit } = Object.values(y[0])[0][0]
    const cumulativeDataArr = []
    y.forEach(itemY => {
      const yArr = Object.values(itemY)[0]
      const trendValue = yArr[0].values.slice(1)
      const cumulativeValue = yArr[0].percentValues.slice(1)
      trendDataArr.push(trendValue[index])
      cumulativeDataArr.push(cumulativeValue[index])
    })
    if (boardTitle === '在线时间分布' && item.indexOf('平均在线时长') !== -1)
      // eslint-disable-next-line curly, nonblock-statement-body-position
      return
    trendData.push({
      name: item,
      type: 'line',
      symbol: 'none',
      data: trendDataArr,
      unit: unit,
      percentValues: cumulativeDataArr,
      indicatorsName: item,
    })
    cumulativeData.push({
      name: item,
      type: 'line',
      symbol: 'none',
      data: cumulativeDataArr,
      indicatorsName: item,
    })
  })
  y.forEach((itemY, index) => {
    const name = Object.keys(itemY)[0]
    const yItem = Object.values(itemY)[0][0]
    Object.values(itemY)[0].forEach((yIt, yIndex) => {
      let valueObj = {
        date: name,
        指标: '用户数',
      }
      let percentObj = {
        date: name,
        指标: '占比',
      }
      yIt.values.forEach((itemValue, indexValue) => {
        if (indexValue === 0) {
          valueObj = {
            ...valueObj,
            allUser: itemValue,
          }
          percentObj = {
            ...percentObj,
            allUser: `${yIt.percentValues[0] / 100}%`,
          }
        } else {
          groupLabels.forEach((yIte, yIteIndex) => {
            valueObj = {
              ...valueObj,
              [yIte]: yIndex ? yIt.groupCols[yIteIndex] : '总体',
            }
            percentObj = {
              ...percentObj,
              [yIte]: yIndex ? yIt.groupCols[yIteIndex] : '总体',
            }
          })
          const unitItem =
            indexValue === 1 && boardTitle === '在线时间分布' ? 's' : ''
          valueObj = {
            ...valueObj,
            [z[indexValue - 1]]: `${itemValue}${unitItem}`,
          }
          const percentVal =
            indexValue === 1 && boardTitle === '在线时间分布'
              ? '-'
              : `${yIt.percentValues[indexValue] / 100}%`
          percentObj = {
            ...percentObj,
            [z[indexValue - 1]]: `${percentVal}`,
          }
        }
      })
      downLoadDataSource.push(valueObj, percentObj)
    })
    let obj = {
      date: name,
      allUser: yItem.values[0],
      key: `${name}-${index}`,
    }
    z.forEach((itemZ, indexZ) => {
      obj = {
        ...obj,
        [itemZ]: !yItem.unit
          ? Number(yItem.values[indexZ + 1])
          : `${yItem.values[indexZ + 1]}${yItem.unit}`,
        [`${itemZ}percentValues`]: yItem.percentValues[indexZ + 1],
      }
    })
    return dataSource.push(obj)
  })
  // console.log('dataSource', dataSource)
  return {
    downLoadHead,
    downLoadDataSource,
    tableHead,
    dataSource,
    xData,
    pieData,
    trendData,
    cumulativeData,
    modalTableHead: [],
  }
}
