import { Empty } from 'antd'
import React from 'react'
import styled from 'styled-components'

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

interface IEmptyCom {
  description?: string
}
export default function EmptyCom(props: IEmptyCom) {
  const { description } = props
  return (
    <EmptyWrapper>
      <Empty description={description || '暂无数据'} />
    </EmptyWrapper>
  )
}
