import React, { useEffect, useRef } from 'react'
import type { InputRef } from 'antd'
import ModalCom from '@/components/ModalCom'
import { InputWrapper } from './styles'

interface CollectModalComProps {
  collectName: string
  title: string
  openModal: boolean
  okText?: string
  inpCursor?: string
  okDisabled?: boolean
  handleCancel: () => void
  handleOk: () => void
  handleName: (val: string) => void
}

function CollectModalCom(props: CollectModalComProps) {
  const {
    openModal,
    title,
    collectName,
    okText,
    okDisabled,
    inpCursor = 'start',
    handleName,
    handleOk,
    handleCancel,
  } = props
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        inputRef.current!.focus({
          cursor: inpCursor as 'start' | 'end',
        })
      }, 100)
    }
  }, [openModal])

  const modalProps = {
    openModal,
    title,
    okDisabled,
    okText,
    handleOk,
    handleCancel,
  }
  return (
    <ModalCom {...modalProps}>
      <InputWrapper
        ref={inputRef}
        value={collectName}
        maxLength={20}
        placeholder="请输入收藏名称"
        onChange={e => {
          handleName(e.target.value)
        }}
      />
    </ModalCom>
  )
}

export default CollectModalCom
