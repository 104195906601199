import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function MonthActiveUser() {
  const props = {
    boardTitle: '月有效活跃用户数',
    modular: 'monthActiveUser',
    exploreType: 'activeBoard',
    tips: '将粒度改为“按月”，计算的是当月的去重用户数',
    unit: '人',
    byTime: 'byMonth',
  }
  return <TemplateCard {...props} />
}
export default MonthActiveUser
