import React, { useState, useEffect, useMemo } from 'react'
import { Select, Button, message } from 'antd'
import { useAppSelector } from '@/hook/reduxHook'
import SvgIcon from '@/components/SvgIcon'
import { getLoadFilters } from '@/http/businessAnalysis/exploreBoard/condition'
import {
  SelectTagsWrapper,
  SelectTags,
  ModalWrapper,
  EditWrapper,
  EditTitle,
  EditMain,
  FooterWrapper,
  TextAreaWrapper,
} from './styles'

const { Option } = Select
interface Props {
  handleValue: (value: string) => void
  filterValue: string
  labelEn: string
  tableType: number
}

interface Opt {
  label: string
  value: string
}

function SelectTagsCom(props: Props) {
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)

  const { handleValue, filterValue, labelEn, tableType } = props

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const [optList, setOptList] = useState<Opt[]>([])

  useEffect(() => {
    setTextAreaValue(filterValue ? filterValue.replaceAll(',', '\n') : '')
  }, [filterValue])

  const getData = async () => {
    const result = await getLoadFilters({
      gameId,
      filts: labelEn,
      tableType: tableType,
      userId: userInfoData!.account!.id,
    })
    const arr: any[] = []
    result.data?.map((item: string) => {
      if (item === filterValue) {
        return arr.push({
          label: item,
          value: item,
          style: {
            fontWeight: '400',
            background: '#e4f2ff',
          },
        })
      }
      return arr.push({
        label: item,
        value: item,
      })
    })
    setOptList(arr)
  }

  useEffect(() => {
    getData()
  }, [labelEn])

  const canCopy = useMemo(() => {
    const textLen = textAreaValue.split('\n').filter(item => item).length
    if (!textLen && !filterValue?.length) {
      return false
    }
    return true
  }, [textAreaValue, filterValue])

  const handleChange = (value: string[]) => {
    const arr = [...value]
    if (arr.length && arr[arr.length - 1].indexOf(',') > -1) {
      arr.splice(
        value.length - 1,
        1,
        value[value.length - 1].replaceAll(',', '')
      )
    }
    handleValue(arr.join(','))
  }

  const RenderChild = optList.map((item, index) => (
    // @ts-ignore
    <Option key={item.value + index} value={item.value}>
      {item.label}
    </Option>
  ))

  const handleClose = () => {
    setOpenModal(false)
    setTextAreaValue('')
  }

  return (
    <SelectTagsWrapper>
      <SelectTags
        mode="tags"
        size="small"
        value={filterValue ? filterValue.split(',') : []}
        style={{ width: '100%' }}
        // @ts-ignore
        onChange={handleChange}
        dropdownMatchSelectWidth={200}
      >
        {RenderChild}
      </SelectTags>
      <SvgIcon
        icon="#icon-bianji1"
        className="icon editIcon"
        handleClick={() => setOpenModal(true)}
      />
      <ModalWrapper
        open={openModal}
        onCancel={handleClose}
        closable={false}
        footer={null}
        maskStyle={{ zIndex: 1030 }}
        zIndex={9999}
        width={520}
        centered
        destroyOnClose
      >
        <EditWrapper>
          <EditTitle>
            <span>批量添加</span>
            <span className="subtitle">
              使用回车换行分隔 (
              <span>
                限制输入逗号
                <i>,</i>
              </span>
              )
            </span>
          </EditTitle>
          <EditMain>
            <TextAreaWrapper
              value={textAreaValue}
              onChange={e => {
                console.log(
                  123,
                  e.target.value,
                  e.target.value.slice(-1) === ','
                )
                if (e.target.value.slice(-1) === ',') return
                // const value = e.target.value.split('\n')
                setTextAreaValue(e.target.value)
              }}
              rows={7}
            />
          </EditMain>
          <FooterWrapper>
            <Button
              className="btn copyBtn"
              onClick={() => {
                if (!canCopy) {
                  message.error('条件为空，请输入条件后复制')
                } else {
                  navigator.clipboard.writeText(textAreaValue).then(() => {
                    message.success('筛选条件已保存到剪贴板')
                  })
                }
              }}
            >
              全选复制
            </Button>
            <Button className="btn copyBtn" onClick={handleClose}>
              取消
            </Button>
            <Button
              className="btn okBtn"
              onClick={() => {
                const value = textAreaValue.split('\n').filter(item => item)
                handleValue([...new Set(value)]?.join(','))
                handleClose()
              }}
            >
              完成
            </Button>
          </FooterWrapper>
        </EditWrapper>
      </ModalWrapper>
    </SelectTagsWrapper>
  )
}

export default SelectTagsCom
