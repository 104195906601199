import { combineReducers } from '@reduxjs/toolkit'
import { accountManagementSlice } from '@/pages/ManageCenter/AccountManagement/redux'
import { userGroupManageSlice } from '@/pages/ManageCenter/UserGroupManage/redux'
import { applyPermissionSlice } from '@/pages/ManageCenter/ApplyPermission/redux'

const manageCenter = combineReducers({
  accountManagement: accountManagementSlice.reducer,
  userGroupManage: userGroupManageSlice.reducer,
  applyPermission: applyPermissionSlice.reducer,
})
export default manageCenter
