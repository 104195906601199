import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd'
import { useAppSelector, useAppDispatch } from '@/hook/reduxHook'
import {
  getExploreHistoryList,
  setterStoreState,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import { exploreCollection } from '@/http/businessAnalysis/exploreBoard/condition'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import RenderContent from './Content'
import EmptyBoxCom from '../EmptyCom'
import CollectModalCom from '../CollectModalCom'
import {
  HistoryWrapper,
  Title,
  Main,
  ItemWrapper,
  ItemParams,
  Simple,
  ActionWrapper,
  ActionButton,
  EmptyList,
} from './styles'

interface HistoryComProps {
  goSearch: () => void
}

function HistoryCom(props: HistoryComProps) {
  const { goSearch } = props
  const dispatch = useAppDispatch()
  const [messageApi, contextHolder] = message.useMessage()
  const modular = useAppSelector(state => state.exploreBoard.modular)
  const gameId = useAppSelector(state => state.exploreBoard.gameId)
  const userId = useAppSelector(state => state.exploreBoard.userId)
  const exploreHistoryList = useAppSelector(
    state => state.exploreBoard.exploreHistoryList
  )
  const exploreHistoryLoading = useAppSelector(
    state => state.exploreBoard.exploreHistoryLoading
  )

  const [collectItem, setCollectItem] = useState<ExploreHistoryListData | null>(
    null
  )
  const [collectName, setCollectName] = useState<string>('')

  useEffect(() => {
    dispatch(
      getExploreHistoryList({
        gameId,
        userId,
        identification: modular,
      })
    )
  }, [])

  const goAction = (item: ExploreHistoryListData) => {
    dispatch(
      setterStoreState({
        type: 'conditionData',
        value: item.condition ? JSON.parse(item.condition) : item.condition,
      })
    )
    dispatch(
      setterStoreState({
        type: 'groupDataList',
        value: item.grouping ? JSON.parse(item.grouping) : [],
      })
    )
    goSearch()
  }

  const handleCancel = () => {
    setCollectItem(null)
    setCollectName('')
  }

  const handleOk = () => {
    exploreCollection({
      userId,
      gameId,
      name: collectName,
      condition: collectItem!.condition,
      grouping: collectItem!.grouping,
      identification: modular,
    }).then(res => {
      if (res.code === 0) {
        messageApi.success('收藏成功')
      }
      handleCancel()
    })
  }

  const collectProps = {
    collectItem: collectItem,
    openModal: !!collectItem,
    title: '创建收藏',
    collectName,
    okText: '创建',
    okDisabled: !collectName,
    handleOk,
    handleCancel,
    handleName: (val: string) => setCollectName(val),
  }

  return (
    <HistoryWrapper>
      {contextHolder}
      <Title>历史查询列表</Title>
      {!exploreHistoryLoading && (
        <Main>
          {!!exploreHistoryList.length && (
            <Main>
              {exploreHistoryList.map(item => {
                let condNum = 0
                let groupNum = 0
                if (item.condition) {
                  JSON.parse(item.condition).list.forEach(
                    (itm: { list: string | any[] }) => {
                      condNum += itm.list.length
                    }
                  )
                }
                if (item.grouping) {
                  const arr = JSON.parse(item.grouping)
                  groupNum = arr.length
                }
                return (
                  <PopoverTips
                    placement="bottom"
                    content={<RenderContent item={item} />}
                    overlayInnerStyle={{
                      width: '240px',
                      fontSize: '14px',
                      borderRadius: '2px',
                      padding: '6px 10px',
                    }}
                    key={item.key}
                  >
                    <ItemWrapper>
                      <ItemParams>
                        <span className="index">{item.key}</span>
                        {!condNum && <div className="empty">无筛选</div>}
                        {!!condNum && (
                          <Simple>
                            <span>指标筛选</span>
                            <span className="right">
                              <SvgIcon
                                icon="#icon-shaixuan2"
                                className="icon"
                              />
                              <span className="num">{condNum}</span>
                            </span>
                          </Simple>
                        )}
                        {!groupNum && <div className="empty">无分组</div>}
                        {!!groupNum && (
                          <Simple>
                            <span>分组项</span>
                            <span className="right">
                              <SvgIcon icon="#icon-fenzu" className="icon" />
                              <span className="num">{groupNum}</span>
                            </span>
                          </Simple>
                        )}
                      </ItemParams>
                      <ActionWrapper className="action">
                        <ActionButton
                          onClick={() => {
                            setCollectItem(item)
                          }}
                        >
                          收藏
                        </ActionButton>
                        <ActionButton onClick={() => goAction(item)}>
                          执行
                        </ActionButton>
                      </ActionWrapper>
                    </ItemWrapper>
                  </PopoverTips>
                )
              })}
            </Main>
          )}
          {!exploreHistoryList.length && <EmptyBoxCom />}
        </Main>
      )}
      {exploreHistoryLoading && (
        <EmptyList>
          <Spin size="large" />
          <div>数据加载</div>
        </EmptyList>
      )}
      <CollectModalCom {...collectProps} />
    </HistoryWrapper>
  )
}

export default HistoryCom
