import React, { useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import ActiveUser from './ActiveUser'
import OldActiveUser from './OldActiveUser'
import MonthActiveUser from './MonthActiveUser'
import WeekActiveUser from './WeekActiveUser'
import ChannelAdditionAndRetention from './ChannelAdditionAndRetention'
import DAU from './DAU/index'
import Channel from './Channel'
import Life from './Life'
import WeekLogin from './WeekLogin'
import OnlineDuration from './OnlineDuration'
import { useAppDispatch } from '@/hook/reduxHook'
import { setterUserInfoStoreState } from '@/store/userInfo'

const Wrapper = styled.div`
  width: 100%;
  min-width: 1390px;
  // margin-bottom: 50px;
  & > div {
    width: 100%;
    margin-bottom: 25px;
    height: 100%;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`

const TwoComWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  & > div:nth-child(1) {
    margin-right: 20px;
  }
  & > div {
    width: calc((100% - 20px) / 2);
    height: 380px;
  }
`
const CounterTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    flex: 1;
    margin-right: 15px;
    width: 24%;
  }
  & > div:last-child {
    margin-right: 0 !important;
  }
`

export default function ActiveBoard() {
  const dispatch = useAppDispatch()
  useLayoutEffect(() => {
    if (document.getElementById('dataBoardLayoutId')) {
      document.getElementById('dataBoardLayoutId')!.scrollTop = 0
    }
  }, [])

  useEffect(() => {
    dispatch(
      setterUserInfoStoreState({
        type: 'sideBareType',
        value: 1,
      })
    )
  }, [])
  return (
    <Wrapper>
      <CounterTabsWrapper>
        <ActiveUser />
        <OldActiveUser />
        <WeekActiveUser />
        <MonthActiveUser />
      </CounterTabsWrapper>
      <DAU />
      <TwoComWrapper>
        <Life />
        <Channel />
      </TwoComWrapper>
      <WeekLogin />
      <OnlineDuration />
      <ChannelAdditionAndRetention />
    </Wrapper>
  )
}
