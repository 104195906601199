/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Button } from 'antd'
import type { MenuProps } from 'antd'
import {
  ModalWrapper,
  ConditionRight,
  ContentWrapper,
  Top,
  LabelWrapper,
  OperatorSelect,
  MenuItemWrapper,
  Bottom,
  MenuWrapper,
} from './styles'
import SvgIcon from '@/components/SvgIcon'
import MyIcon from '@/components/MyIcon'
import PopoverTips from '@/components/PopoverTips'
import FilterValue from './FilterValue'
import FilterLabel from './FilterLabel'
import { getNewFilter } from '../utils'

interface Props {
  iconPath: string
  open: boolean
  chooseItem: ListItem
  filterList: FilterList[]
  handleOpen: (val: boolean) => void // 是否打开modal
  handleSaveItem: (value: ListItem) => void
}

function ConditionRightModal(props: Props) {
  const { open, chooseItem, filterList, iconPath, handleOpen, handleSaveItem } =
    props

  // console.log('12332, ConditionRightModal', chooseItem)

  const [storageItem, setStorageItem] = useState(chooseItem)
  const menuItem: MenuProps['items'] = filterList.map((item, index) => ({
    key: index.toString(),
    label: (
      <MenuItemWrapper
        active={item.label === storageItem?.filter.label}
        onClick={() => {
          /* 相同点击没用 不同点击切换 */
          if (item.label !== storageItem?.filter.label) {
            const newFilter = getNewFilter(storageItem, item)
            const newItem = {
              ...storageItem,
              filter: newFilter,
            }
            setStorageItem(newItem)
          }
        }}
      >
        <div className="contentItem">
          <div>
            {item.icon && (
              <div className="iconWrapper">
                <MyIcon
                  type={item.icon}
                  scriptUrl={iconPath}
                  className="icon"
                />
              </div>
            )}
            {item.label}
          </div>
          {!!item.info && (
            <PopoverTips content={item.info} placement="right" isBlackStyle />
          )}
        </div>
      </MenuItemWrapper>
    ),
  }))
  const content = (
    <ContentWrapper>
      <Top>
        <LabelWrapper>{storageItem?.condition?.label}</LabelWrapper>
        <OperatorSelect
          dropdownRender={() => <MenuWrapper items={menuItem} />}
          placement="bottomLeft"
          trigger={['click']}
          overlayStyle={{
            zIndex: 9999,
          }}
        >
          <Button>{storageItem?.filter?.label}</Button>
        </OperatorSelect>
        <FilterValue
          item={storageItem}
          handleValue={value => {
            const newItem = {
              ...storageItem,
              filter: {
                ...storageItem?.filter,
                value,
              },
            }
            setStorageItem(newItem)
          }}
        />
      </Top>
      <Bottom>
        <SvgIcon
          icon="#icon-bangzhuchuangyixiangfaideadengpaofaguang"
          className="icon descIcon"
        />
        <div className="desc">{storageItem?.filter.desc}</div>
      </Bottom>
    </ContentWrapper>
  )

  return (
    <ModalWrapper
      placement="bottomLeft"
      trigger="click"
      content={content}
      open={open}
      needMask={open}
      setOpen={value => {
        if (value) {
          return handleOpen(true)
        }
        /* 关闭 并将最新的数据发出去 */
        handleOpen(false)
        handleSaveItem(storageItem)
      }}
      overlayInnerStyle={{
        width: 'auto',
        padding: 0,
      }}
    >
      <ConditionRight className="hoverBtn" isOpen={open}>
        <div className="operator">
          {storageItem?.filter.label !== '元素位置' &&
            storageItem?.filter.label}
          <FilterLabel filter={storageItem?.filter} />
        </div>
      </ConditionRight>
    </ModalWrapper>
  )
}

export default ConditionRightModal
