import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  extendCondition,
  fieldCollectionList,
  filterList,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  getAttribute,
  getEventList,
  getEntitiesList,
  getCaculate,
} from '@/http/businessAnalysis/customAnalysis/events'
import { getDefaultMoment } from '@/utiles/common'

export interface TimeRange {
  endDate: string
  endDateType: number
  otherDates: string
  startDate: string
  startDateType: number
}

export interface IAnalysisEvent {
  gameId: string
  userId: number
  byTime: string
  timeRange: TimeRange
  iconPath: string
  toggleType: string
  columns: []
  showChartLabel: boolean
  dataSource: any[]
  tableLoading: boolean
  xData: string[]
  cumulativeData: IBasicTrendList[]
  trendData: IBasicTrendList[]
  distrGroupData: IBasicDistributionGroup | null
  pieData: PieChart[][]
  lineType: string[]
  yAxisType: boolean[]
  indicators: string[]
  frozenIndicators: string[]
  tableType: string[]
  groups: string[]
  frozenGroups: string[]
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  groupDataList: GroupItem[]
  indicatorsData: EventIndicatorsData[]
  eventList: EventDatum | null
  attributeList: EventAttListData | null
  entitiesList: EntitiesDatum[]
  splitEventGroupList: GroupItem[]
  splitEventNameArr: string[]
  caculateLoading: boolean
  caculateData: GetCaculateData | null
  canSave: boolean
  goCalculateCount: number
  showOverText: boolean
  refreshProps: null | GetCaculateParams
}
const fileDefault = {
  icon: 'icon-jiaxingshoucangtianchong',
  iconPath: '',
  list: [],
  name: '收藏',
  type: 0,
}
const initialState: IAnalysisEvent = {
  gameId: '',
  userId: -1,
  byTime: 'byDay', // 按天
  toggleType: 'trend', //  查看的数据类型 默认查看趋势图
  timeRange: getDefaultMoment, // 日期
  showChartLabel: false, // 显示数值
  tableLoading: true, // 表格加载
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 属性列表
  showOverText: false, // 是否数据量超出 显示文案
  goCalculateCount: 0,
  xData: [],
  columns: [],
  dataSource: [],
  indicators: [],
  frozenIndicators: [],
  groups: [],
  frozenGroups: [],
  cumulativeData: [],
  trendData: [],
  pieData: [],
  distrGroupData: null,
  lineType: [], // 图标类型
  tableType: ['date', 'upDate'], // 表格查看
  yAxisType: [],
  fieldCollectionList: fileDefault, // 属性收藏列表
  filterList: [], // 条件列表
  groupDataList: [], // 分组项
  conditionData: null, // 指标筛选  全局筛选
  indicatorsData: [], // 分析指标数据
  eventList: null, // 事件列表
  attributeList: null, // 属性指标
  entitiesList: [], // 实体列表
  splitEventGroupList: [], // 事件拆分 的 分组项
  splitEventNameArr: [], //  勾选的事件拆分项
  caculateData: null, // 右侧数据
  caculateLoading: false,
  canSave: false, // 是否可以保存报表
  refreshProps: null, // 刷新的参数 （保留上次请求的参数 不带新的参数）
}

/* 获取属性列表 */
export const getExtendCondition = createAsyncThunk(
  'analysisEvent/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)
/* 属性收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'analysisEvent/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)
/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'analysisEvent/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)
/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'analysisEvent/getAttributeFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)
/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'analysisEvent/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)
/* 实体列表 */
export const getEntitiesListFunc = createAsyncThunk(
  'analysisEvent/getEntitiesListFunc',
  async (gameId: string) => {
    const result = await getEntitiesList({ gameId })
    return result.data
  }
)
// 获取右侧数据
export const getCaculateFunc = createAsyncThunk(
  'analysisEvent/getCaculate',
  async (params: GetCaculateParams) => {
    const result = await getCaculate(params)
    return {
      data: result.data,
      params,
    }
  }
)
export const analysisEventSlice = createSlice({
  name: 'analysisEvent',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisEvent; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
        hasState: boolean
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
      state.groupDataList = []
      state.goCalculateCount = 0
      state.indicatorsData = []
      state.eventList = null
      state.attributeList = null
      state.caculateLoading = true
      state.caculateData = null
    },
    initLocationState: (state: any, action: any) => {
      const {
        dateType,
        event,
        filts,
        gameId,
        userId,
        group,
        startTime,
        endTime,
        goCalculateCount,
      } = action.payload
      state.caculateLoading = true
      state.byTime = dateType
      state.indicatorsData = event
      state.conditionData = filts ? JSON.parse(filts) : ''
      state.groupDataList = group ? JSON.parse(group) : []
      state.userId = userId
      state.gameId = gameId
      state.timeRange = {
        startDate: startTime,
        endDate: endTime,
      }
      state.goCalculateCount = goCalculateCount
    },
    initSplitEvent: (
      state: any,
      action: PayloadAction<{
        splitEventItem: GroupItem
        splitEventNameArr: string[]
      }>
    ) => {
      const { splitEventItem, splitEventNameArr } = action.payload
      state.splitEventGroupList = [splitEventItem]
      state.splitEventNameArr = splitEventNameArr
    },
    handleTableType: (state: any, action: PayloadAction<any>) => {
      const { columns, dataSource } = action.payload
      state.dataSource = dataSource
      state.columns = columns
      state.tableLoading = false
    },
    setExtendList: (state: any, action: PayloadAction<any>) => {
      const { extendList, fieldList } = action.payload
      state.conditionDatumList = extendList
      state.fieldCollectionList = fieldList
    },
    resetState: state => (state = initialState),
    handleEchartsData: (state: any, action: PayloadAction<any>) => {
      const {
        xData,
        trendData,
        cumulativeData,
        indicators,
        toogleTypeList,
        distrGroupData,
        groups,
        modalTableHead,
        pieData,
        lineType,
      } = action.payload
      state.xData = xData
      state.trendData = trendData
      state.distrGroupData = distrGroupData
      state.cumulativeData = cumulativeData
      state.lineType = lineType
      state.yAxisType = lineType.map(() => false)
      state.pieData = pieData
      state.indicators = indicators
      state.frozenIndicators = indicators
      state.groups = groups.length > 5 ? groups.slice(0, 2) : groups
      state.frozenGroups = groups
      state.modalTableHead = modalTableHead
      state.toogleTypeList = toogleTypeList
      state.caculateLoading = false
      state.showOverText = groups.length === 1000
    },
    setGetData: (state: any) => {
      state.caculateLoading = true
      state.caculateData = null
    },
  },

  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
      const fileList: ExtendConditionDatumList[] = []
      action.payload.forEach(itm => {
        itm.list.forEach(it => {
          if (it.isCollection) {
            return fileList.push(it)
          }
        })
      })
      const { iconPath } = action.payload[0]
      state.fieldCollectionList = {
        ...fileDefault,
        iconPath,
        list: fileList,
      }
      state.iconPath = iconPath
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    /* 加载属性指标 */
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      state.attributeList = action.payload
    })
    /* 实体列表 */
    builder.addCase(getEntitiesListFunc.fulfilled, (state, action) => {
      state.entitiesList = action.payload
    })
    builder
      .addCase(getCaculateFunc.pending, (state, action) => {
        state.caculateLoading = true
      })
      .addCase(getCaculateFunc.fulfilled, (state, action) => {
        state.canSave = true
        const { data, params } = action.payload
        state.refreshProps = params
        const newY: YProps[] = []
        data.y.forEach(item => {
          const index = Object.values(item)[0][0].serialNum - 1
          newY[index] = item
        })
        const newData = {
          ...data,
          y: newY,
        }
        state.caculateData = newData
      })
  },
})
export const analysisEventState = (state: RootState) => state.analysisEvent

export default analysisEventSlice.reducer
export const {
  setterStoreState,
  initGameIdAndUser,
  resetState,
  initSplitEvent,
  handleEchartsData,
  handleTableType,
  setExtendList,
  initLocationState,
  setGetData,
} = analysisEventSlice.actions
