import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Drawer, Carousel, Button } from 'antd'
import GroupOne from '@/assets/img/groupOne.png'
import GroupTwo from '@/assets/img/groupTwo.png'
import GroupThree from '@/assets/img/groupThree.png'
import GroupFour from '@/assets/img/groupFour.png'

interface CarouselItem {
  title: string // 标题
  icon: string // 图片路径
  description: React.ReactElement | string // 简介
  notes: string // 注意事项
  button: React.ReactElement // 按钮
}

interface DivItem {
  color?: string // 字体颜色
  fontSize?: string // 字体大小
  textAlign?: string // 对其方式
}

interface Props {
  defaultShow?: boolean
  handleCallback?: Function
}

const TextColorBlue = styled.span`
  color: #5978f9;
`

const FooterDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: none;
    background-color: #f6f7f8;
  }
  .ant-drawer-body {
    padding: 40px 0 0;
    background-color: #f6f7f8;
    div {
      height: 100%;
    }
    .ant-carousel .slick-dots li button {
      background-color: #3d90ff;
    }
  }
`

const FooterCarousel = styled(Carousel)``

const ContentBox = styled.div`
  display: flex !important;
`

const LeftContent = styled.div`
  padding-right: 45px;
  width: 50%;
  text-align: right !important;
`

const ImageContent = styled.img`
  display: inline-block !important;
  width: 320px;
  height: 220px;
`

const RightContent = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 45px;
  width: 50%;
  height: 220px !important;
`

const ContentDiv = styled.span`
  display: inline-block;
  margin: 8px 0 0;
  width: 322px;
  font-size: ${(props: DivItem) => props.fontSize || '14px'};
  color: ${(props: DivItem) => props.color || '#323234'};
  text-align: ${(props: DivItem) => props.textAlign || 'left'};
`
const ClickButton = styled(Button)`
  margin-left: 8px;
`

const RidingLantern = (props: Props) => {
  const { defaultShow = false, handleCallback } = props

  const carouselRef = useRef<any>(null)

  useEffect(() => {
    if (carouselRef?.current) {
      carouselRef.current.goTo(0)
    }
  }, [defaultShow])

  const nextPage = () => {
    const { current } = carouselRef
    if (current) {
      current.next()
    }
  }

  const prevPage = () => {
    const { current } = carouselRef
    if (current) {
      current.prev()
    }
  }

  const onClose = () => {
    if (handleCallback) {
      handleCallback()
    }
  }

  const CarouselConfig = [
    {
      title: '一.什么是结果分群',
      description:
        '在分析过程中，可将分析结果中的某组用户直接保存为用户群，即为结果分群。创建完成后，可针对该群用户进行更深入的下钻分析。',
      notes: '',
      icon: GroupOne,
      button: (
        <ClickButton type="primary" onClick={nextPage}>
          下一步
        </ClickButton>
      ),
    },
    {
      title: '二. 如何创建结果分群',
      description: (
        <>
          分析模型中，当选择统计
          <TextColorBlue>参与某事件的用户数 </TextColorBlue>
          时，鼠标移入数据表中的用户数，可点击图标
        </>
      ),
      notes: '注：每人最多创建50个',
      icon: GroupTwo,
      button: (
        <>
          <ClickButton onClick={prevPage}>上一步</ClickButton>
          <ClickButton type="primary" onClick={nextPage}>
            下一步
          </ClickButton>
        </>
      ),
    },
    {
      title: '三. 如何使用结果分群',
      description:
        '完成创建后，可在分析模型中以该分群作为筛选条件或分组条件，对分群中的用户进行下钻分析。',
      notes: '',
      icon: GroupThree,
      button: (
        <>
          <ClickButton onClick={prevPage}>上一步</ClickButton>
          <ClickButton type="primary" onClick={nextPage}>
            下一步
          </ClickButton>
        </>
      ),
    },
    {
      title: '四. 如何管理结果分群',
      description: (
        <>
          鼠标移入导航栏
          <TextColorBlue>用户分析</TextColorBlue>
          ，点击查看
          <TextColorBlue>用户分群 </TextColorBlue>
          ，可在分群列表中查看、编辑或删除自建的结果分群。
        </>
      ),
      notes: '',
      icon: GroupFour,
      button: (
        <>
          <ClickButton onClick={prevPage}>上一步</ClickButton>
          <ClickButton type="primary" onClick={onClose}>
            完成
          </ClickButton>
        </>
      ),
    },
  ]

  return (
    <FooterDrawer
      title=""
      placement="bottom"
      width={500}
      onClose={onClose}
      open={defaultShow}
    >
      <FooterCarousel ref={carouselRef}>
        {CarouselConfig.map((item: CarouselItem) => (
          <ContentBox key={item.title}>
            <LeftContent>
              <ImageContent src={item.icon} />
            </LeftContent>
            <RightContent>
              <ContentDiv>
                <ContentDiv color="#3d90ff" fontSize="16px">
                  {item.title}
                </ContentDiv>
                <ContentDiv>{item.description}</ContentDiv>
                {item.notes && (
                  <ContentDiv color="#606265" fontSize="12px">
                    {item.notes}
                  </ContentDiv>
                )}
              </ContentDiv>
              <ContentDiv textAlign="right">{item.button}</ContentDiv>
            </RightContent>
          </ContentBox>
        ))}
      </FooterCarousel>
    </FooterDrawer>
  )
}

export default RidingLantern
