/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
import React, { useState } from 'react'
import { Radio, DatePicker } from 'antd'
import dayjs from 'dayjs'
import type { DatePickerProps, RadioChangeEvent } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import PopoverTips from '@/components/PopoverTips'
import ExtendConditionCom from '@/components/ExtendConditionCom'
import ConditionRightModal from '../ConditionRightModal'
import {
  ConditionItem,
  RelationEditor,
  RelationLeft,
  RelationRight,
  ActionRow,
  ActionLeft,
  ActionCondition,
  ActionRight,
  ActionAdd,
  ActionDelete,
  HistoryWrapper,
  EditContentWrapper,
  EditContent,
  VersionWrapper,
  Title,
  Word,
} from './styles'

const dateFormat = 'YYYY/MM/DD'
const newVersionData = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
interface Props {
  index: number
  list: ListItem[]
  relation: string
  filterList: FilterDatum[]
  openFilterIndex: number
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  iconPath: string
  openExtend?: string | null
  handleOpenExtend?: (val: string) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
  handleSetFilterIndex: (val: number) => void
  handleSaveItem: (item: ListItem, idx: number) => void
  handleCondition: (val: ExtendConditionDatumList, idx: number) => void
  clickRelation: () => void
  handleActionType: (val: number) => void
}

interface ModalProps {
  itm: ListItem
  idx: number
  open: boolean
  handleOpen: (val: boolean) => void
}
interface RightModalProps {
  itm: ListItem
  idx: number
}

interface VersionProps {
  versionType: number
  versionDate: string
  setVersionType: (val: number) => void
  setVersionDate: (val: string) => void
}

function LevelListCom(props: Props) {
  const {
    index,
    list,
    relation,
    filterList,
    conditionDatumList,
    fieldCollectionList,
    openFilterIndex, // 是否打开该选择条件
    iconPath,
    openExtend, // 是否打开ExtendConditionCom
    handleOpenExtend, // 控制是否打开ExtendConditionCom
    handleSetFilterIndex, // 打开某个条件选择框
    handleSaveItem,
    handleCondition,
    clickRelation,
    handleActionType,
    handleFieldCollection,
  } = props

  const [openPop, setOpenPop] = useState<boolean>(false)
  const [openVersion, setOpenVersion] = useState<boolean>(false)
  function RenderConditionLeftModal(modalProps: ModalProps) {
    const { itm, idx, open, handleOpen } = modalProps
    const chooseItem = {
      label: itm.condition.label,
      descIcon: itm.condition.descIcon,
      descText: itm.condition.descText,
    }
    return (
      <ExtendConditionCom
        chooseItem={chooseItem}
        conditionDatumList={conditionDatumList}
        fieldCollectionList={fieldCollectionList}
        iconPath={iconPath}
        openPop={open}
        setOpenPop={val => handleOpen(val)}
        handleCondition={val => handleCondition(val, idx)}
        handleFieldCollection={val => handleFieldCollection(val)}
      />
    )
  }

  function RenderConditionRightModal(modalProps: RightModalProps) {
    const { itm, idx } = modalProps
    return (
      <ConditionRightModal
        chooseItem={itm}
        iconPath={iconPath}
        open={idx === openFilterIndex}
        handleOpen={(val: boolean) =>
          val ? handleSetFilterIndex(idx!) : handleSetFilterIndex(-1)
        }
        filterList={
          filterList.find(it => it.type === itm.condition.dataType)?.list || []
        }
        handleSaveItem={val => handleSaveItem(val, idx)}
      />
    )
  }

  function RenderVersion(versionProps: VersionProps) {
    const { setVersionType, setVersionDate, versionType, versionDate } =
      versionProps
    const onChange = (e: RadioChangeEvent) => {
      e.stopPropagation()
      setVersionType(e.target.value)
    }
    const onChangeTime: DatePickerProps['onChange'] = (date, dateString) => {
      setVersionDate(dateString)
    }
    const disabledDate: DatePickerProps['disabledDate'] = current =>
      // Can not select days before today and today
      current && current > dayjs().subtract(1, 'days')

    return (
      <EditContentWrapper>
        <Title>
          选择版本
          <PopoverTips
            isBlackStyle
            placement="top"
            content={
              <div>
                <div>最新版本：按用户最新的标签值计算</div>
                <div>动态匹配：按用户在事件发生当日的标签值计算</div>
                <div>历史版本：按用户在某个特定日期的标签值计算</div>
              </div>
            }
            overlayInnerBlackStyle={{
              maxWidth: '315px',
            }}
          >
            <span>
              <SvgIcon icon="#icon-tishi" className="icon" />
            </span>
          </PopoverTips>
        </Title>
        <EditContent>
          <Radio.Group onChange={onChange} value={versionType}>
            <Radio value={1}>最新版本</Radio>
            <Radio value={2}>历史版本</Radio>
          </Radio.Group>
          {versionType === 2 && (
            <DatePicker
              allowClear={false}
              onChange={onChangeTime}
              size="small"
              value={dayjs(versionDate, dateFormat)}
              format={dateFormat}
              popupStyle={{ zIndex: 9999 }}
              disabledDate={disabledDate}
            />
          )}
        </EditContent>
      </EditContentWrapper>
    )
  }

  function RenderVersionCom(modalProps: RightModalProps) {
    const { itm, idx } = modalProps
    const [versionType, setVersionType] = useState<number>(1)
    const [versionDate, setVersionDate] = useState<string>(newVersionData)
    return (
      <HistoryWrapper>
        <Word>按</Word>
        <PopoverTips
          placement="bottomRight"
          trigger="click"
          content={
            <RenderVersion
              versionType={versionType}
              versionDate={versionDate}
              setVersionType={setVersionType}
              setVersionDate={setVersionDate}
            />
          }
          overlayInnerStyle={{
            width: '365px',
            position: 'relative',
          }}
          open={openVersion}
          setOpen={val => {
            if (!val) {
              handleSaveItem(
                {
                  ...itm,
                  filter: {
                    ...itm.filter,
                    edition: versionType,
                    date: versionType === 1 ? newVersionData : versionDate,
                  },
                },
                idx
              )
            }
            setOpenVersion(val)
          }}
        >
          <VersionWrapper isOpen={openVersion} className="hoverBtn">
            历史版本
          </VersionWrapper>
        </PopoverTips>
      </HistoryWrapper>
    )
  }
  return (
    <ConditionItem>
      <RelationEditor>
        <RelationLeft>
          <div className="sub">◆</div>
          <em />
          <div className="relationValue" onClick={clickRelation}>
            {relation}
          </div>
        </RelationLeft>
        <RelationRight>
          {list.map((itm, idx: number) => (
            <ActionRow key={idx}>
              <ActionLeft>
                <ActionCondition>
                  <RenderConditionLeftModal
                    itm={itm}
                    idx={idx}
                    open={
                      openExtend ? openExtend === `${index}-${idx}` : openPop
                    }
                    handleOpen={val =>
                      handleOpenExtend
                        ? handleOpenExtend(
                            openExtend === `${index}-${idx}`
                              ? '-1'
                              : `${index}-${idx}`
                          )
                        : setOpenPop(val)
                    }
                  />
                  {itm.condition.type === 4 && (
                    <RenderVersionCom itm={itm} idx={idx} />
                  )}
                  <RenderConditionRightModal itm={itm} idx={idx} />
                </ActionCondition>
              </ActionLeft>
              <ActionRight className="actionRight">
                <PopoverTips
                  isBlackStyle
                  placement="top"
                  content="添加并列条件"
                >
                  <ActionAdd onClick={() => handleActionType(-1)}>
                    <SvgIcon icon="#icon-shaixuan2" className="icon" />
                  </ActionAdd>
                </PopoverTips>
                <PopoverTips isBlackStyle placement="top" content="删除项目">
                  <ActionDelete onClick={() => handleActionType(idx)}>
                    <SvgIcon icon="#icon-guanbi2fill" className="icon" />
                  </ActionDelete>
                </PopoverTips>
              </ActionRight>
            </ActionRow>
          ))}
        </RelationRight>
      </RelationEditor>
    </ConditionItem>
  )
}

export default React.memo(LevelListCom)
