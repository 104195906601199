import React from 'react'
import CTable from '@/components/CTable'
import type { IColumns, IDataTableProps } from './styles'
import { Wrapper } from './styles'

export default function DataTable<T>(props: IDataTableProps<T>) {
  const {
    dataSource,
    columns,
    handleSort = () => null,
    loading = false,
    height = '260px',
    style,
    tableProps,
    pagination,
  } = props
  return (
    <Wrapper>
      <CTable<IColumns>
        loading={loading}
        dataSource={dataSource as any}
        columns={columns}
        height={height}
        style={style}
        handleSort={handleSort}
        tableProps={tableProps}
        pagination={pagination}
      />
    </Wrapper>
  )
}
