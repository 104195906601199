import React from 'react'
import SelectTagsCom from './components/SelectTagsCom' //  下拉框输入选择 可以批量添加
import SelectCom from './components/SelectCom' // 下拉框
import InputCom from './components/InputCom'
import SectionCom from './components/SectionCom' // 区间
import RangePickerCom from './components/RangePickerCom' // 时间段
import DatePickerCom from './components/DatePickerCom' // 时间选择器
import CurrentDateCom from './components/CurrentDateCom'
import RelativeTime from './components/RelativeTime'
import ElementPosition from './components/ElementPosition' // 元素位置

interface Props {
  item: ListItem
  handleValue: (value: unknown) => void
}

function FilterValue(props: Props) {
  const { item, handleValue } = props
  switch (item?.filter.label!) {
    // 多选   下拉框输入选择 可以批量添加
    case '等于':
    case '不等于':
    case '存在元素':
    case '不存在元素':
      return (
        <SelectTagsCom
          labelEn={item.condition.labelEn}
          tableType={item.condition.type}
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    // 单选
    case '包括':
    case '不包括':
      return (
        <SelectCom
          labelEn={item.condition.labelEn}
          tableType={item.condition.type}
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    // 输入框
    case '小于':
    case '大于':
      return (
        <InputCom
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    case '小于等于':
    case '大于等于':
      if (item.condition.dataType === '时间') {
        return (
          <DatePickerCom
            filterValue={item.filter.value}
            handleValue={value => handleValue(value)}
            isMoreThen={item.filter.label === '大于等于'}
          />
        )
      }
      return (
        <InputCom
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    case '区间':
      return (
        <SectionCom
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )

    case '位于区间':
      return (
        <RangePickerCom
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    case '相对当前日期':
      return (
        <CurrentDateCom
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    case '相对事件发生时刻':
      return (
        <RelativeTime
          filterValue={item.filter.value}
          handleValue={value => handleValue(value)}
        />
      )
    case '元素位置':
      return (
        <ElementPosition
          labelEn={item.condition.labelEn}
          tableType={item.condition.type}
          handleValue={value => handleValue(value)}
          filterValue={item.filter.value}
        />
      )
    case '有值':
    case '无值':
    case '为真':
    case '为假':
    case '存在对象满足':
    case '没有对象满足':
    case '全部对象满足':
      return <div />
    default:
      return <div />
  }
}

export default FilterValue
