import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Progress } from 'antd'

interface IWrapper {
  height: string
}

const Wrapper = styled.div<IWrapper>`
  width: 100%;
  height: ${({ height }) => height} !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContentWrapper = styled.div`
  width: 100%;
`
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TipsWrapper = styled.div`
  margin: 7px 0 6px 0;
  text-align: center;
`

interface ILoadingAnimation {
  children: React.ReactElement
  error: boolean
  status: boolean
  height: string
  width?: number
  hasBottom?: boolean
  handleRetryClick?: () => void
  handleCancellClick?: () => void
}
export default function LoadingAnimation(props: ILoadingAnimation) {
  const {
    children,
    status,
    error,
    height,
    handleRetryClick,
    hasBottom = true,
    handleCancellClick = null,
    width,
  } = props
  const [requestTime, setRequestTime] = useState<number>(0)
  useEffect(() => {
    let times = 10
    const timeId = setInterval(() => {
      if (requestTime < 80) {
        setRequestTime(requestTime + 1)
      } else if (requestTime < 100 && requestTime >= 80) {
        times = 20
        if (!status) {
          times = 5
          setRequestTime(requestTime + 1)
        } else {
          setRequestTime(94)
          clearInterval(timeId)
        }
      }
    }, times)
    return () => clearInterval(timeId)
  }, [requestTime, status, error])

  return (
    <Wrapper height={height}>
      {status ? (
        <LoadingWrapper>
          <Progress
            type="circle"
            strokeColor="#52ABFD"
            width={width || 60}
            percent={error ? 0 : requestTime}
          />
          {status && hasBottom && !error ? (
            <>
              <TipsWrapper>加载中，请等待</TipsWrapper>
              <Button size="small" type="ghost" onClick={handleRetryClick}>
                {handleCancellClick ? '点击刷新' : '点击刷新'}
              </Button>
            </>
          ) : (
            <>
              <TipsWrapper>网络错误，稍后重试。</TipsWrapper>
              <Button size="small" type="primary" onClick={handleRetryClick}>
                点击重试
              </Button>
            </>
          )}
        </LoadingWrapper>
      ) : (
        <ContentWrapper>{children}</ContentWrapper>
      )}
    </Wrapper>
  )
}
