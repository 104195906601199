import { Spin } from 'antd'
import React, { useMemo, lazy } from 'react'
import styled from 'styled-components'
import { IItem } from '..'
import { Wrapper } from '@/pages/BusinessAnalysis/components/TemplateBoard/styles'

const PannelDistribution = lazy(
  () => import('../components/PannelDistribution')
)
const PannelEvents = lazy(() => import('../components/PannelEvents'))
const PannelFlows = lazy(() => import('../components/PannelFlows'))
const PannelFunnel = lazy(() => import('../components/PannelFunnel'))
const PannelInterval = lazy(() => import('../components/PannelInterval'))
const PannelRetention = lazy(() => import('../components/PannelRetention'))
const PannelTags = lazy(() => import('../components/PannelTags'))
const PannelAttribute = lazy(() => import('../components/PannelAttribute'))

interface IPannelTab {
  item: IItem
}

interface IWrapper {
  width: number
}

const PannelWrapper = styled(Wrapper)<IWrapper>`
  width: ${({ width }) =>
    width === 0.5 ? 'calc(50% - 20px)' : 'calc(100% - 20px)'};
  margin-right: 20px;
  margin-bottom: 20px;
`
const pannelTabObj: {
  [key: string]: (item: IItem) => React.ReactElement
} = {
  events: (item: any) => <PannelEvents item={item} />,
  retention: (item: IItem) => <PannelRetention item={item} />,
  funnel: (item: IItem) => <PannelFunnel item={item} />,
  distribution: (item: IItem) => <PannelDistribution item={item} />,
  interval: (item: IItem) => <PannelInterval item={item} />,
  flows: (item: IItem) => <PannelFlows item={item} />,
  attribute: (item: IItem) => <PannelAttribute item={item} />,
  tags: (item: IItem) => <PannelTags item={item} />,
}
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default function PannelTab(props: IPannelTab) {
  const { item } = props
  const { type } = item!.reportInfo[0]
  const PannelItem: any = useMemo(() => pannelTabObj[type], [item])
  const LoadingAnimation = () => (
    <LoadingWrapper>
      <Spin size="large" />
    </LoadingWrapper>
  )

  return (
    <PannelWrapper width={item!.width}>
      <React.Suspense fallback={<LoadingAnimation />}>
        <PannelItem item={item} />
      </React.Suspense>
    </PannelWrapper>
  )
}
