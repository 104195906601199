import styled from 'styled-components'
import { Button } from 'antd'

export const CollectWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  overflow: hidden;
`

export const Title = styled.div`
  height: 48px;
  padding-left: 16px;
  color: #42546d;
  font-weight: 500;
  font-size: 14px;
  line-height: 48px;
`

export const Main = styled.div`
  height: calc(100% - 48px);
  overflow-y: auto;
  > span {
    display: block;
    width: 100%;
  }
`
export const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  margin-top: 4px;
  padding: 0 8px;
  :hover {
    background-color: #f6f8fa;
    border-radius: 2px;
    .action {
      background-color: rgb(246, 248, 250);
      opacity: 1;
    }
  }
`
export const ItemParams = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
  overflow: hidden;
  line-height: 32px;
  white-space: nowrap;
  .index {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 6px 0 8px;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    text-align: center;
    vertical-align: top;
    background-color: #818bad;
    border-radius: 2px;
  }
  .label {
    color: #42546d;
    display: inline-block;
    max-width: 200px;
    margin: 8px 8px 8px 16px;
    overflow: hidden;
    color: var(--text-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }
`

export const ActionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 4px;
  display: flex;
  align-items: center;
  min-width: 60px;
  height: 32px;
  opacity: 0;
  transition: opacity 0.3s;
`
export const ActionButton = styled(Button)`
  min-width: 40px;
  height: 24px;
  margin-left: 8px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
  font-weight: 400;
  text-shadow: none;
  background-color: #fff;
  border: 1px solid #d9dfe6;
  color: #42546d;
  :hover {
    border-color: rgb(61, 144, 255);
    /* background-color: rgb(61, 144, 255); */
    color: rgb(61, 144, 255);
  }
  :last-child {
    color: #fff;
    border-color: rgb(61, 144, 255);
    background-color: rgb(61, 144, 255);
  }
`

export const ContentWrapper = styled.div`
  padding: 8px;
`
export const Top = styled.div`
  padding-bottom: 16px;
  .title {
    height: 20px;
    color: #42546d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`
export const ConditionWrapper = styled.div`
  margin-top: 18px;
  font-size: 12px;
`
export const RelationEditor = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow-y: auto;
  max-height: 155px;
`
export const RelationWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 24px;
  margin-right: 4px;
  em {
    position: absolute;
    top: 0;
    left: 12px;
    width: 1px;
    height: 100%;
    background-color: #d9dfe6;
    transition: all 0.3s;
  }
  .value {
    position: absolute;
    top: 50%;
    left: 12px;
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 16px;
    padding: 0 2px;
    color: #67729d;
    font-size: 12px;
    line-height: 16px;
    background-color: #f0f2f5;
    border: 0;
    transform: translate3d(-50%, 25%, 0);
    cursor: default;
    transition: all 0.3s;
    border-radius: 2px;
    margin-top: -12px;
    text-align: center;
  }
`
export const RelationContent = styled.div`
  flex: 1 1;
`
interface MainItemWrapperProps {
  isList?: boolean
}
export const MainItemWrapper = styled.div<MainItemWrapperProps>`
  padding-left: ${props => (props.isList ? 0 : '10px')};
  margin: 4px 0;
  color: #42546d;
  font-weight: 400;
  line-height: 14px;
  white-space: pre-wrap;
  word-break: break-all;
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 8px;
    vertical-align: middle;
    background: #a3acc5;
    border-radius: 2px;
  }
  .op {
    padding: 0 8px;
    color: #67729d;
  }
`
export const Bottom = styled.div`
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
  .title {
    height: 20px;
    color: #42546d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`

export const ListWrapper = styled.div`
  margin-top: 12px;
  font-size: 12px;
`
export const ListItem = styled.div`
  margin-bottom: 8px;
  color: #42546d;
  font-weight: 400;
  line-height: 14px;
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 8px;
    vertical-align: middle;
    background: rgba(61, 144, 255, 0.45);
    border-radius: 2px;
  }
`

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  color: #8d9eb9;
  .icon {
    font-size: 80px;
  }
`
