/* eslint-disable curly */
/* eslint-disable prettier/prettier */
import React, { memo } from 'react'
import SvgIcon from '@/components/SvgIcon'
import PopoverTips from '@/components/PopoverTips'
import {
  ListItem,
  FavoriteIconBox,
  FavoriteIcon,
  UnFavoriteIcon,
} from './styles'

interface Props {
  item: ExtendConditionDatumList
  hoverItem: ExtendConditionDatumList | null
  chooseItemLabel: string
  searchWord: string
  groupName: string[] // 用来告诉分组项选择了哪些
  handleFieldCollection: (
    item: ExtendConditionDatumList,
    num: number | undefined
  ) => void
  handleHoverItem: (value: ExtendConditionDatumList | null) => void
  handleCondition: (item: ExtendConditionDatumList) => void
}
function ListItemCom(props: Props) {
  const {
    item,
    hoverItem,
    chooseItemLabel,
    groupName,
    searchWord,
    handleHoverItem,
    handleFieldCollection,
    handleCondition,
  } = props
  const { label } = item
  const RenderLabel = () => {
    if (searchWord) {
      const nameArr = item.label.split(searchWord)
      const searchSpan = <span className="searchSpan">{searchWord}</span>
      return (
        <span className="label">
          {nameArr[0]}
          {searchSpan}
          {nameArr[1]}
        </span>
      )
    }
    return <span className="label">{label}</span>
  }
  return (
    <ListItem
      isChoose={chooseItemLabel === item.label}
      isCollected={item.isCollection}
      onClick={e => {
        e.stopPropagation()
        if (item.label === chooseItemLabel || groupName.includes(item.label))
          // eslint-disable-next-line nonblock-statement-body-position
          return
        handleCondition(item)
      }}
      isDisabled={groupName.includes(item.label)}
      onMouseEnter={() => {
        if ((hoverItem && hoverItem.label) === item.label) return
        handleHoverItem(item)
      }}
      isHover={hoverItem?.infoData.labelId === item.infoData.labelId}
    >
      {RenderLabel()}
      <div className="typeWrapper">
        <SvgIcon icon={`#${item.descIcon}`} className="icon typeIcon" />
        <span>{item.dataType}</span>
      </div>
      <div className="info">
        <span>{item.descText}</span>
      </div>
      <div className="tooltipIcon">
        <PopoverTips
          placement="top"
          content={item.isCollection ? '取消收藏' : '点击收藏'}
          isBlackStyle
        >
          <FavoriteIconBox isCollected={item.isCollection}>
            {!item.isCollection && (
              <UnFavoriteIcon
                className="icon unfavoriteIcon"
                icon="#icon-shoucang"
              />
            )}
            <FavoriteIcon
              stopPropagation
              isCollected={item.isCollection}
              className="icon favoriteIcon"
              icon="#icon-jiaxingshoucangtianchong"
              handleClick={() => {
                const scrollTop =
                  document.getElementById('ContainerList')?.scrollTop
                handleFieldCollection(item, scrollTop || undefined)
              }}
            />
          </FavoriteIconBox>
        </PopoverTips>
      </div>
    </ListItem>
  )
}

export default memo(ListItemCom)
