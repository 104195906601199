import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'

export interface IApplyPermissionState {
  checkBoxArrId: number[]
}

const initialState: IApplyPermissionState = {
  checkBoxArrId: [],
}

export const applyPermissionSlice = createSlice({
  name: 'applyPermission',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IApplyPermissionState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
  },
})

export const { setterStoreState } = applyPermissionSlice.actions
export const storeState = (state: RootState) =>
  state.manageCenter.applyPermission
