import styled from 'styled-components'
import { InputNumber } from 'antd'

export const SectionComWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  .word {
    margin: 0 5px;
    color: #67729d;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    vertical-align: top;
  }
`

export const NumberWrapper = styled(InputNumber)`
  width: 80px;
  height: 28px !important;
  color: #67729d;
  font-size: 14px !important;
  vertical-align: top;
  border: 1px solid #f0f2f5;

  :hover {
    background-color: #fff;
    border-color: rgb(109, 173, 253);
  }
`
