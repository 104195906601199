/* eslint-disable indent */
import React, { useRef, useEffect, FC, CSSProperties } from 'react'
import * as echarts from 'echarts'

type EChartsOption = echarts.EChartsOption

export interface LineProps {
  series: PieChart[]
  styles?: CSSProperties
}

const PieChart: FC<LineProps> = ({ series, styles }) => {
  let option: EChartsOption
  const echartsRef: any = useRef(null)
  useEffect(() => {
    option = {
      tooltip: {
        trigger: 'item',
        extraCssText: 'minWidth: 260px',
        formatter(item: any) {
          let text =
            '<div style="display:flex;align-items: center;justify-content:space-between">'
          text += `<div>${item.marker} ${item.name}</div>`
          text += `<div>${Number(item.value) >= 0 ? Number(item.value) : '-'}`
          text += `<span style="margin-left: 4px">(${item.percent}%)</span></div>`
          text += '</div>'
          return text
        },
      },
      label: {
        formatter: (item: { name: string; percent: number }) =>
          `${item.name} \n ${item.percent || 0}%`,
      },
      series: [
        {
          // name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          data: series,
        },
      ],
    }
    const newPromise = new Promise((resolve: any) => {
      resolve()
    })
    const myCharts = echarts.init(echartsRef?.current)
    newPromise.then(() => {
      if (myCharts) {
        myCharts.setOption(option)
      }
    })
    window.addEventListener('resize', () => {
      myCharts.resize()
    })
  }, [])
  return (
    <div
      ref={echartsRef}
      style={{ width: '100%', height: '260px', ...styles }}
    />
  )
}
export default PieChart
