import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'

export const Wrapper = styled.div`
  align-items: center;
`
export const PieWrapper = styled.div`
  position: relative;
  .leftIcon {
    position: absolute;
    left: 25px;
    top: 50%;
    z-index: 1;
    cursor: pointer;
  }
  .title {
    text-align: center;
    font-size: 23px;
    font-weight: 400;
    color: #000000;
  }
  .rightIcon {
    position: absolute;
    right: 25px;
    top: 50%;
    z-index: 1;
  }
`
export const PlusSquareOutWrapper = styled.div`
  /* padding-left: 20px; */
  width: 180px;
  text-align: center;
`
export const PlusSquareOutIcon = styled(PlusSquareOutlined)`
  margin-right: 5px;
  &:hover {
    color: rgb(61, 144, 255);
  }
`

export const RightIcon = styled.div`
  margin-left: 5px;
  display: none;
`
interface ColumnItemProps {
  canClick: boolean
}
export const ColumnItem = styled.div<ColumnItemProps>`
  text-decoration: ${props => (props.canClick ? 'underline' : 'unset')};
  cursor: ${props => (props.canClick ? 'pointer' : 'unset')};
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    ${RightIcon} {
      display: inline-block;
    }
  }
`
