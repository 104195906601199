import React, { useEffect } from 'react'
import { useSetState } from 'ahooks'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/hook/reduxHook'
import LoadingAnimation from '@/components/LoadingAnimation'
import ExploreBoard from '@/pages/BusinessAnalysis/ExploreBoard'
import { createResultGroup } from '@/http/businessAnalysis/userAnalysis/attribute'
import CreateModal from '@/pages/BusinessAnalysis/components/CreateResultGroupModal'
import { handleExportCsv, debounceHook } from '@/utiles/common'
import {
  getBoardData,
  processBoardData,
  getTableData,
} from './ContentWrap/getBoardData'
import { defaultBoardState, handleParams } from './ContentWrap/common'
import HeadLayout from './HeadLayout'
import ContentWrap from './ContentWrap'
import ModalTable from './ContentWrap/ModalTable'
import { Wrapper, EmptyData } from './styles'

interface SendData {
  displayName: string // 分群名
  remarks: string // 分群简介
}
interface TemplateBoardProps {
  tips?: string
  modular: string
  dataType: string
  boardTitle: string
  boardType: string
  exploreType: string
  toggleType: string
  showLeft?: boolean
  showTop?: boolean
  showRate?: boolean
  showTopRate?: boolean
  showLeftRate?: boolean
  grouping?: GroupItem[] // 分组项
  toolBarLeftProps?: any // 左边项
  checkType?: string
  getToggle?: (val: string) => void
  byTime?: string
  timeRange: string[]
  boardCondition?: object
}

function TemplateBoard(props: TemplateBoardProps) {
  const {
    tips,
    modular,
    boardTitle,
    toggleType = 'table',
    dataType,
    exploreType,
    boardType,
    showLeft = false,
    showTop = false,
    showRate = false,
    showTopRate = false,
    showLeftRate = false,
    grouping,
    toolBarLeftProps,
    checkType = '留存',
    getToggle,
    byTime,
    timeRange,
    boardCondition,
  } = props

  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const defaultState = defaultBoardState({
    toggleType,
    checkType,
    byTime,
    timeRange,
  })
  const [boardState, setBoardState] = useSetState<any>(defaultState)
  const [showModal, setShowModal] = React.useState<object | null>(null)

  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)

  const dimension = useAppSelector(data => data.boardStore.dimension)
  const lastBoardType = useAppSelector(data => data.boardStore.lastBoardType)
  const refreshBoardCount = useAppSelector(
    data => data.boardStore.refreshBoardCount
  )
  const boardConditionData = useAppSelector(
    data => data.boardStore.boardConditionData
  )
  const requestCondition = () => {
    if (boardCondition) {
      if (boardConditionData) {
        return {
          relation: '',
          list: [...boardConditionData.list, boardCondition],
        }
      }
      return {
        relation: '',
        list: [boardCondition],
      }
    }
    return boardConditionData || undefined
  }

  const frozenData = {
    ...boardState,
    boardTitle,
    gameId,
    userId: userInfoData!.account!.id,
    modular,
    exploreType,
    boardType,
    companyName: userInfoData!.companyName,
    startDate: boardState.timeRange[0],
    endDate: boardState.timeRange[1],
    grouping: grouping || undefined,
    condition: requestCondition(),
    dimension,
  }

  const commonProps = {
    dataType,
    xData: boardState.xData,
    dataSource: boardState.dataSource,
    trendData: boardState.trendData,
    distrGroupData: boardState.distrGroupData,
    cumulativeData: boardState.cumulativeData,
    columns: boardState.columns,
  }

  const clickShowTitle = (text: string) =>
    setBoardState({
      showModalTitle: text,
    })

  const handleNavigate = (navigateProps: any) => {
    const params = {
      ...navigateProps,
      ...frozenData,
      condition: requestCondition()
        ? JSON.stringify(requestCondition())
        : undefined,
      grouping: grouping ? JSON.stringify(grouping) : undefined,
    }
    const resultParams = handleParams(params)
    navigate(`/${userInfoData!.companyName}/customInfo`, {
      state: resultParams,
    })
  }

  const handleCreateModal = (navigateProps: any) => {
    const params = {
      ...navigateProps,
      ...frozenData,
    }
    const resultParams = handleParams(params)
    setShowModal(resultParams)
  }

  /* 请求获取数据 */
  const getData = async () => {
    setBoardState({ loading: true, error: false, exploreData: null })
    const condition = requestCondition()
      ? JSON.stringify(requestCondition())
      : undefined
    const exploreData: any = await getBoardData({
      ...frozenData,
      condition,
      grouping: JSON.stringify(grouping) || undefined,
      dimension,
    })
    if (exploreData!.error || !exploreData?.y) {
      setBoardState({
        loading: false,
        error: true,
        exploreData: null,
      })
    } else {
      const resultData: any = processBoardData({
        exploreData,
        boardTitle,
        byTime: boardState.byTime,
        companyName: userInfoData!.companyName,
        frozenData,
        clickShowTitle,
        handleNavigate,
        handleCreateModal,
      })
      setBoardState({
        ...resultData,
        loading: false,
        error: false,
        tableState: {
          tableLoading: false,
          tableDataSource: resultData.dataSource,
          tableColumns: resultData.columns,
        },
      })
    }
    // setTimeout(() => setModalVisible(true), 500) // 测试
  }

  useEffect(() => {
    setBoardState({
      timeRange: timeRange,
      checkRange: [timeRange[1], timeRange[1]],
    })
  }, [timeRange])

  /* 请求数据 */
  useEffect(() => {
    setBoardState({
      loading: true,
    })
    const pathName = window.location.pathname.split('/').slice(-1)[0]
    if (lastBoardType === pathName) {
      getData()
    }
  }, [
    lastBoardType,
    refreshBoardCount,
    boardConditionData,
    boardState.byTime,
    boardState.timeRange,
    boardState.reFreshCount,
    gameId,
    dimension,
    boardState.byDate,
    boardState.days,
    boardState.retainedCount,
  ])

  /* 处理数据  */
  useEffect(() => {
    if (boardState.exploreData) {
      const result = getTableData({
        boardTitle,
        needDropDownSelector: toolBarLeftProps?.needDropDownSelector || false,
        storeDownLoadDataSource: boardState.downLoadDataSource,
        storeDownLoadHead: boardState.downLoadHead,
        checkType: boardState.checkType,
        checkRange: boardState.checkRange,
        exploreData: boardState.exploreData,
        tableState: boardState.tableState,
        clickShowTitle,
        handleNavigate,
        handleCreateModal,
      }) as any
      setBoardState({
        tableState: {
          tableLoading: false,
          tableDataSource: result.tableDataSource,
          tableColumns: result.tableColumns,
        },
      })
      if (
        (boardState.checkType === '留存' || boardState.checkType === '流失') &&
        toolBarLeftProps?.needDropDownSelector
      ) {
        setBoardState({
          downLoadHead: result.downLoadHead,
          downLoadDataSource: result.downLoadDataSource,
        })
      }
    }
  }, [boardState.exploreData, boardState.checkType, boardState.checkRange])

  const handleDownloadClick = () => {
    handleExportCsv({
      downLoadHead: boardState.downLoadHead,
      downLoadDataSource: boardState.downLoadDataSource,
      xData: boardState.xData,
      boardTitle,
    })
  }

  const handleOperateClick = (e: string) => {
    switch (e) {
      case 'fangda':
        setBoardState({
          modalVisible: true,
        })
        break
      case 'shuaxin':
        debounceHook(getData)
        break
      case 'xiazai':
        debounceHook(handleDownloadClick)
        break
      default:
        break
    }
  }

  const HeadLayoutProps = {
    title: boardTitle,
    tips: tips,
    ...toolBarLeftProps,
    ...boardState,
    frozenIndicators: boardState.frozenIndicators,
    needIndexSelector: boardState.toggleType !== 'table',
    handleChangeState: (type: string, value: any) => {
      if (type === 'operateClick') handleOperateClick(value)
      if (type === 'toggleType') setBoardState({ toggleType: value })
      setBoardState({
        [type]: value,
      })
      if (type === 'toggleType' && getToggle) {
        getToggle(value)
      }
    },
  }

  const loadingAnimationProps = {
    handleCancellClick: () => console.log('calcell'),
    handleRetryClick: () => getData(),
    status: boardState.loading,
    error: boardState.error,
    height: '260px',
  }

  const contentProps = {
    ...boardState,
    ...commonProps,
    descDate: boardState.xData?.[boardState.xData!.length - 1],
    descDataList: boardState.descDataList,
    frozenIndicators: boardState.frozenIndicators,
    groups: boardState.groups,
    frozenGroups: boardState.frozenGroups,
    pieData: boardState.pieData,
    showLeft,
    showTop,
    showRate,
    showTopRate,
    showLeftRate,
  }

  // console.log('boardState', boardState.trendData)

  const exploreModalProps = {
    isShowDataModal: boardState.modalVisible,
    handleClose: () =>
      setBoardState({
        modalVisible: false,
      }),
    frozenData: handleParams(frozenData),
    toolBarLeftProps,
  }

  const modalTableProps = {
    boardTitle,
    frozenData,
    companyName: userInfoData!.companyName,
    byTime: boardState.byTime,
    checkType: boardState.checkType,
    exploreData: boardState.exploreData,
    showModalTitle: boardState.showModalTitle,
    modalTableHead: boardState.modalTableHead,
    handleClose: () => setBoardState({ showModalTitle: '' }),
  }

  const handleCallback = async (modalProps: SendData) => {
    const { displayName, remarks } = modalProps
    const finalJumpParams = {
      ...showModal,
      source: 'panel',
      remarks,
      displayName,
      gameId,
      userId: userInfoData!.account!.id,
      phone: userInfoData!.account!.phone,
    }
    const resData = await createResultGroup(finalJumpParams)
    if (resData.code === 0) {
      messageApi.success(resData.message)
      return true
    }
    messageApi.error(resData.message)
    return false
  }

  return (
    <Wrapper>
      {contextHolder}
      <HeadLayout {...HeadLayoutProps} />
      {boardState.modalVisible && <ExploreBoard {...exploreModalProps} />}
      <LoadingAnimation {...loadingAnimationProps}>
        <>
          {(!boardState.exploreData || boardState.error) && (
            <EmptyData>该条件暂无数据</EmptyData>
          )}
          {boardState.exploreData && (
            <>
              <ContentWrap {...contentProps} />
              <ModalTable {...modalTableProps} />
            </>
          )}
        </>
      </LoadingAnimation>
      <CreateModal
        showCreate={!!showModal}
        handleCallback={handleCallback}
        cancelCallback={() => setShowModal(null)}
      />
    </Wrapper>
  )
}

export default React.memo(TemplateBoard)
