import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function Register() {
  const props = {
    boardTitle: '注册用户数',
    modular: 'register',
    exploreType: 'cordBoard',
    tips: '此处以"注册"作为新增事件，可按新增的定义替换为"创角"或"首次登录事件"（"登录"+"是否首次"属性组成"首次登录"）',
    unit: '人',
    byTime: 'byDay',
  }
  return <TemplateCard {...props} />
}
export default Register
