import styled from 'styled-components'

export const RangePickerComWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

export const RangePickerWrapper = styled.div`
  height: 28px;
  color: #67729d;
  font-size: 14px;
  vertical-align: top;
  transition: all 0.3s;

  .ant-picker-range {
    padding: 2px 11px 2px;
  }

  .ant-picker-range-separator {
    padding: 0 4px;
    color: #67729d;
  }
  .ant-picker-input input {
    max-width: 145px;
    font-size: 14px;
    color: #42546d;
  }
  .ant-picker-active-bar {
    margin-left: 4px;
    background-color: #3d90ff;
  }

  .chooseTime {
    background: #e9f6fe;
  }

  .hideBoundaryLeft {
    background: #89b5df;
    border-radius: 10px 0 0 10px;
    color: white;
  }
  .hideBoundaryRight {
    background: #3d90ff;
    border-radius: 0 10px 10px 0;
    color: white;
  }
  .showBoundaryRight {
    border-radius: 0 10px 10px 0;
    background: #1890ff;
    color: white;
  }
  .showBoundaryLeft {
    border-radius: 10px 0 0 10px;
    background: #1890ff;
    color: white;
  }
  .showOneDay {
    border-radius: 10px 10px 10px 10px;
    background: #1890ff;
    color: white;
  }
  .todayStyle {
    background: #1890ff !important;
    border-radius: 50% 50% !important;
    color: white !important;
    position: relative !important;
    &::before {
      position: absolute;
      content: '';
      right: -4px;
      bottom: -4px;
      width: 0px;
      height: 0px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #3d90ff #3d90ff transparent;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }

  .ant-picker-cell-range-end::before,
  .ant-picker-cell-selected::before,
  .ant-picker-cell-range-start::before,
  .ant-picker-dropdown .ant-picker-cell-in-range::before {
    background: unset !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    /* border-width: 5px 5px;
    border-style: solid;
    border-color: #3d90ff #3d90ff transparent transparent !important; */
    border: 5px solid #3d90ff #3d90ff transparent transparent !important;
    transform: rotateX(180deg);
    top: unset;
    left: unset;
    right: -4px !important;
    bottom: -4px !important;
  }
`
