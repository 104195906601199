import moment from 'moment'
/**
 * 获取本周周一和周日日期
 */
export function getCurrentWeek() {
  const weekOfday = moment().format('E')
  const start = moment()
    .subtract(weekOfday - 1, 'days')
    .format('YYYYMMDD')
  const end = moment()
    .add(7 - weekOfday, 'days')
    .format('YYYYMMDD')
  return [start, end]
}

/**
 * 获取前 i 周的周一和周日日期，并以数组的方式返回。
 * 当 i=1，获取的是上周一和上周日的日期；
 * 当 i=2，获取的是上上周一和上上周日的日期
 * ...以此类推
 * @param i
 */
export function getLastWeek(i) {
  // eslint-disable-next-line radix
  const weekOfDay = parseInt(moment().format('E')) // 计算今天是这周第几天
  const lastModay = moment()
    .subtract(weekOfDay + 7 * i - 1, 'days')
    .format('YYYY-MM-DD') // 周一日期
  const lastSunday = moment()
    .subtract(weekOfDay + 7 * (i - 1), 'days')
    .format('YYYY-MM-DD') // 周日日期
  return [lastModay, lastSunday]
}

/**
 * 获取后 i 周的周一和周日日期，并以数组的方式返回。
 * 当 i=1，获取的是下周一和下周日的日期；
 * 当 i=2，获取的是下下周一和下下周日的日期
 * ...以此类推
 * @param i
 */
export function getNextWeek(i) {
  // eslint-disable-next-line radix
  const weekOfDay = parseInt(moment().format('E')) // 计算今天是这周第几天
  const nextMonday = moment()
    .add(7 - weekOfDay + 7 * (i - 1) + 1, 'days')
    .format('YYYY-MM-DD') // 周一日期
  const nextSunday = moment()
    .add(7 - weekOfDay + 7 * i, 'days')
    .format('YYYY-MM-DD') // 周日日期
  return [nextMonday, nextSunday]
}
