import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SpinLoading = () => (
  <LoadingWrapper>
    <Spin />
  </LoadingWrapper>
)

export default SpinLoading
