import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

export const getIntervalData = (params: {}): Promise<IResponse<any>> =>
  axiosInstance.post('/apg/econ/v1/analysis/interval', { ...params })
