import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 200px;
  flex: none;
`

export const TimeWrapper = styled.div`
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
`
export const ContentWrap = styled.div`
  .rate {
    margin-top: 4px;
    font-size: 45px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 21px;
  }
  .numWrapper {
    > div {
      display: flex;
      align-items: center;
    }
  }
`
