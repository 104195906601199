/* eslint-disable react/jsx-one-expression-per-line */
import React, { FC } from 'react'
import { Statistic } from 'antd'
import { getIndicators } from '../utils'
import { formatNumber } from '@/utiles/common'
import {
  Wrapper,
  TimeWrapper,
  CountWrapper,
  TabWrapper,
  TotalWrapper,
  TextWrapper,
  ColorWrapper,
  // StatisticWrap,
} from './styles'

interface ListParams {
  label: string
  value: number | string
  unit: string
  indicatorsName: string
}

interface LeftDescProps {
  date?: string
  data?: ListParams[]
  indicators?: string[]
  isBasic?: boolean
}
const LeftDesc: FC<LeftDescProps> = ({
  date,
  data,
  indicators = [],
  isBasic = false,
}) => {
  const list = getIndicators(data!, indicators, isBasic) as ListParams[]
  return (
    <Wrapper>
      <TimeWrapper> {date}</TimeWrapper>
      {list.length && (
        <TotalWrapper>
          {list.map((item, index) => (
            <TabWrapper key={index}>
              <CountWrapper>
                <Statistic
                  className="StatisticWrap"
                  style={{ fontSize: 36 }}
                  value={formatNumber(item.value).value}
                  suffix={formatNumber(item.value).suffix + item.unit || ''}
                />
              </CountWrapper>
              <TextWrapper>
                <ColorWrapper colorIndex={index} />
                <span>{item.label}</span>
              </TextWrapper>
            </TabWrapper>
          ))}
        </TotalWrapper>
      )}
    </Wrapper>
  )
}
export default LeftDesc
