import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function PayRateBoard() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)

  const props = {
    modular: 'payRate',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'payRate',
    boardTitle: '付费率与新增付费率',
    toggleType: 'trend',
    showTop: true,
    tips: '付费率同样使用了公式，并设置了以百分比的形式进行展示',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
