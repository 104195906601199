import React from 'react'
import { Table } from 'antd'
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table'

interface ICTable<T> {
  columns?: ColumnsType<T>
  dataSource: T[]
  handleSort?: any
  loading?: boolean
  height?: string
  tableProps?: any
  style?: React.CSSProperties
  pagination?: false | TablePaginationConfig
}

export default function CTable<T extends object>(props: ICTable<T>) {
  const {
    columns,
    dataSource,
    handleSort,
    loading = false,
    height,
    // style,
    tableProps,
    pagination = false,
  } = props

  const getYNum = React.useMemo(() => {
    let y = 0
    if (typeof height === 'string') {
      if (height.indexOf('px') >= 0) {
        const heightText = height.replace('px', '')
        y = Number(heightText)
      } else {
        y = Number(height)
      }
    }
    if (typeof height === 'number') {
      y = height
    }
    return `${y - 50}px`
  }, [height])

  return (
    <Table<T>
      style={{ height }}
      loading={loading}
      showSorterTooltip={false}
      sticky
      pagination={pagination}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content', y: getYNum }}
      size="small"
      bordered
      onChange={(selectedRowKeys, selectedRows, info) => {
        handleSort(info)
      }}
      {...tableProps}
    />
  )
}
