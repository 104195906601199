import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'

interface MyIconProps {
  scriptUrl: string
  type: string
  className?: string
}
function MyIcon(props: MyIconProps) {
  const { scriptUrl, type, className } = props
  const SelfIcon = createFromIconfontCN({
    scriptUrl, // 在 iconfont.cn 上生成
  })
  return <SelfIcon type={type} className={className} />
}

export default MyIcon
