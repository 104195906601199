import React, { useState } from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import { getLoadFilters } from '@/http/businessAnalysis/exploreBoard/condition'
import { SelectWrapper } from './styles'

interface Props {
  handleValue: (value: any) => void
  filterValue: any
  labelEn: string
  tableType: number
}
interface Opt {
  label: string
  value: string
}

function SelectCom(props: Props) {
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)

  const { handleValue, filterValue, labelEn, tableType } = props

  const [open, setOpen] = useState<boolean>(false)
  const [optList, setOptList] = useState<Opt[]>([])
  const [changeVal, setChangeVal] = useState<string>('')

  const getData = async () => {
    const result = await getLoadFilters({
      gameId,
      filts: labelEn,
      tableType: tableType,
      userId: userInfoData!.account!.id,
    })
    const arr: any[] = []
    result.data?.map((item: string) => {
      if (item === filterValue) {
        return arr.push({
          label: item,
          value: item,
          style: {
            fontWeight: '400',
            background: '#e4f2ff',
          },
        })
      }
      return arr.push({
        label: item,
        value: item,
        key: item,
      })
    })
    setOptList(arr)
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <SelectWrapper
      showSearch
      dropdownMatchSelectWidth={false}
      size="small"
      popupClassName="popupClass"
      filterOption={(inputValue, option) => {
        if (open && !changeVal) {
          return true
        }
        return (
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        )
      }}
      onDropdownVisibleChange={val => {
        setOpen(val)
        if (!val) setChangeVal('')
      }}
      value={filterValue}
      onChange={val => {
        setChangeVal(val as string)
        handleValue(val)
      }}
      options={optList}
    />
  )
}

export default SelectCom
