import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function ActiveUser() {
  const props = {
    boardTitle: '有效活跃用户数',
    modular: 'active',
    exploreType: 'cordBoard',
    tips: '有效活跃用户数可以通过“登录.触发用户数”来进行计算，即有登录行为的用户的去重数。',
    unit: '人',
    byTime: 'byDay',
  }
  return <TemplateCard {...props} />
}
export default ActiveUser
