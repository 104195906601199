import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { getDistributionData } from '@/http/businessAnalysis/customAnalysis/distribution'
import {
  extendCondition,
  fieldCollectionList,
  filterList,
  collectionList,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  getAttribute,
  getEventList,
  getEntitiesList,
} from '@/http/businessAnalysis/customAnalysis/events'

interface IAnalysisDistribution {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  bothConditionDatumList: ExtendConditionDatum[]
  groupConditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  groupFieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  groupDataList: GroupItem[]
  eventList: EventDatum | null
  attributeList: EventAttListData | null
  entitiesList: EntitiesDatum[]
  userEvent: any | null
  rangeUserEvent: any | null
  distributionAnalysisDatas: DistributionData | null
}
const initialState: IAnalysisDistribution = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 用户参与事件的属性列表
  bothConditionDatumList: [], // 同时展示的属性列表
  groupConditionDatumList: [], // 分组项的属性列表
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  filterList: [], // 条件列表
  groupDataList: [], // 分组项
  groupFieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 分组项的属性列表
  conditionData: null, // 指标筛选
  userEvent: null, // 用户参与事件
  rangeUserEvent: null, // 区间用户事件
  eventList: null, // 事件列表
  attributeList: null, // 属性指标
  entitiesList: [], // 实体列表
  distributionAnalysisDatas: {
    x: [],
    y: null,
    z: [],
    groupColsSorted: [],
  },
}

/* 用户参与事件属性列表 */
export const getExtendCondition = createAsyncThunk(
  'analysisDistribution/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 同时展示属性列表 */
export const getBothExtendCondition = createAsyncThunk(
  'analysisDistribution/getBothExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 分组项属性列表 */
export const getGroupExtendCondition = createAsyncThunk(
  'analysisDistribution/getGroupExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'analysisDistribution/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 获取收藏列表 */
export const getCollectionList = createAsyncThunk(
  'analysisDistribution/getCollectionList',
  async (params: ExploreHistoryListProps) => {
    const result = await collectionList(params)
    return result.data
  }
)
/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'analysisDistribution/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)
/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'analysisDistribution/getAttributeFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)
/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'analysisDistribution/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)
/* 实体列表 */
export const getEntitiesListFunc = createAsyncThunk(
  'analysisDistribution/getEntitiesListFunc',
  async (gameId: string) => {
    const result = await getEntitiesList({ gameId })
    return result.data
  }
)

// 获取分布分析的右侧数据
export const getDistributionDataQuery = createAsyncThunk(
  'analysisDistribution/getDistributionDataQuery',
  async (params: any) => {
    const res = await getDistributionData({ ...params })
    return res.data
  }
)

export const getAttributeCollection = createAsyncThunk(
  'analysisFlows/getAttributeCollection',
  async (params: any) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

export const analysisDistributionSlice = createSlice({
  name: 'analysisDistribution',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisDistribution; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
  },
  extraReducers: builder => {
    builder.addCase(getDistributionDataQuery.fulfilled, (state, action) => {
      state.distributionAnalysisDatas = action.payload
    })
    /* 用户参与事件属性 */
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    /* 同时展示的属性 */
    builder.addCase(getBothExtendCondition.fulfilled, (state, action) => {
      state.bothConditionDatumList = action.payload
    })
    /* 分组项属性列表 */
    builder.addCase(getGroupExtendCondition.fulfilled, (state, action) => {
      state.groupConditionDatumList = action.payload
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getCollectionList.fulfilled, (state, action) => {
      // console.log('getCollectionList', action.payload)
    })
    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    /* 加载属性指标 */
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      state.attributeList = action.payload
    })
    /* 实体列表 */
    builder.addCase(getEntitiesListFunc.fulfilled, (state, action) => {
      state.entitiesList = action.payload
    })
  },
})

export const analysisDistributionState = (state: RootState) =>
  state.analysisDistribution
export default analysisDistributionSlice.reducer
export const { setterStoreState, initGameIdAndUser } =
  analysisDistributionSlice.actions
