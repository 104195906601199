import React from 'react'
import { Popover } from 'antd'
import styled from 'styled-components'

interface IPopoverCondition {
  Content: React.ReactElement
  ContentName: React.ReactElement | string
  visible?: boolean
  isBodyRender?: boolean
  changeVisible?: (arg: boolean) => void
}

const Wrapper = styled.div`
  &&& .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    padding-top: 0px;
  }
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: none !important;
    padding: 0 !important;
  }

  .timePick {
    box-shadow: none;
  }
`
const TitleWrapper = styled.div`
  cursor: pointer;
  color: #43536d;
  white-space: nowrap;
`
export default function PopoverCondition(props: IPopoverCondition) {
  const { Content, ContentName, visible, changeVisible, isBodyRender } = props

  return (
    <Wrapper>
      <Popover
        getPopupContainer={triggerNode =>
          isBodyRender ? document.body : triggerNode.parentElement!
        }
        content={Content}
        trigger="click"
        placement="bottomLeft"
        destroyTooltipOnHide
        open={visible}
        onOpenChange={() => !!changeVisible && changeVisible(!visible)}
      >
        {typeof ContentName === 'string' && (
          <TitleWrapper>{ContentName}</TitleWrapper>
        )}
        {typeof ContentName !== 'string' && ContentName}
      </Popover>
    </Wrapper>
  )
}
