import React from 'react'
import PopoverTips from '@/components/PopoverTips'
import { parsePercentFloatNum } from '@/utiles/common'
import {
  RateWrapper,
  ComparisonRate,
  SameCycleRate,
  RateTitle,
  RateNum,
  Triangle,
  RateText,
} from './style'

interface TemplateRateProps {
  yearOnYear: string
  ringRatio: string
  ringRatioText: string
  yearOnYearText: string
}
function TemplateRate(props: TemplateRateProps) {
  const { yearOnYear, ringRatio, ringRatioText, yearOnYearText } = props
  const ringContent = (
    <ComparisonRate>
      <RateTitle>较环比</RateTitle>
      {ringRatio === '0' && <RateText>0</RateText>}
      {ringRatio !== '0' && (
        <RateNum isUp={Number(ringRatio) > 0}>
          {Number(ringRatio) > 0
            ? `${parsePercentFloatNum(Number(ringRatio))}%`
            : `${parsePercentFloatNum(Number(ringRatio) * -1)}%`}
          <Triangle isUp={Number(ringRatio) > 0} isShow={!!ringRatio} />
        </RateNum>
      )}
    </ComparisonRate>
  )

  const yearContent = (
    <SameCycleRate>
      <RateTitle>同环比</RateTitle>
      {yearOnYear === '-' && <RateText>-</RateText>}
      {yearOnYear === '0' && <RateText>0</RateText>}
      {yearOnYear && yearOnYear !== '0' && yearOnYear !== '-' && (
        <RateNum isUp={Number(yearOnYear) > 0}>
          {Number(yearOnYear) * 1 > 0
            ? `${parsePercentFloatNum(Number(yearOnYear))}%`
            : `${parsePercentFloatNum(Number(yearOnYear) * -1)}%`}
          <Triangle isUp={Number(yearOnYear) > 0} isShow={!!yearOnYear} />
        </RateNum>
      )}
    </SameCycleRate>
  )

  return (
    <RateWrapper>
      {ringRatio && (
        <PopoverTips content={ringRatioText}>{ringContent}</PopoverTips>
      )}
      {!ringRatio && ringContent}
      {(yearOnYear === '-' || yearOnYear === '0') && yearContent}
      {yearOnYear && yearOnYear !== '0' && yearOnYear !== '-' && (
        <PopoverTips content={yearOnYearText}>{yearContent}</PopoverTips>
      )}
    </RateWrapper>
  )
}

export default TemplateRate
