import styled from 'styled-components'

export const PieWrapper = styled.div`
  position: relative;
  height: 100%;
`
export const Wrapper = styled.div`
  height: 100%;
`
export const LeftIcon = styled.div`
  position: absolute;
  left: 25px;
  top: 50%;
  z-index: 1;
  cursor: pointer;
`
export const Title = styled.div`
  text-align: center;
  font-size: 23px;
  font-weight: 400;
  color: #000000;
`
export const RightIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 50%;
  z-index: 1;
  cursor: pointer;
`
