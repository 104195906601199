/* eslint-disable indent */
import React, { useRef, useEffect, FC, CSSProperties, useMemo } from 'react'
import moment from 'moment'
import * as echarts from 'echarts'
import { parseFloatNum, parsePercentFloatNum } from '@/utiles/common'
import './index.css'

type EChartsOption = echarts.EChartsOption

export interface LineProps {
  series: IBasicTrendList[]
  xData: string[]
  styles?: CSSProperties
  isExplore?: boolean
  byTime?: string
  yAxis?: any | any[]
}

interface SeriesResult {
  name: string
  type: string
  symbol?: string
  data: string[]
  company?: string
  unit?: string
}

const TrendChart: FC<LineProps> = (props: LineProps) => {
  const { series, xData, styles, isExplore, byTime, yAxis } = props
  const echartsRef: any = useRef(null)
  /* 需要对数据最后处理  如果data只有一个 则去除symbol */
  const seriesResult = useMemo(() => {
    const seriesResultArr: SeriesResult[] = []
    series.map((item: any) => {
      if (item.data.length === 1) {
        return seriesResultArr.push({
          ...item,
          symbol: '',
        })
      }
      return seriesResultArr.push(item)
    })
    return seriesResultArr
  }, [props])

  useEffect(() => {
    /* echarts数据 */
    const option: EChartsOption = {
      tooltip: {
        trigger: 'axis',
        extraCssText: 'minWidth: 260px',
        className: 'lineChart-tooltip',
        formatter(params: any) {
          let unitTime = ''
          switch (byTime) {
            case 'byDay':
              unitTime = `(${moment(params[0].name).format('dd')})`
              break
            case 'byWeek':
              unitTime = '当周'
              break
            case 'byMonth':
              unitTime = '当月'
              break
            case 'byQuarter':
              unitTime = '季度'
              break
            case 'byYear':
              unitTime = '年'
              break
            default:
              ''
              break
          }
          let panel = `<div class="chartContentWrapper">
          <div>${params[0].name}${unitTime}</div>`
          params.forEach((item: any) => {
            const seriesItem = seriesResult.find(
              it => it.name === item.seriesName
            )
            const unit = seriesItem?.unit || ''
            let resultValue
            if (item.value >= 0) {
              if (unit === '%') {
                resultValue = parsePercentFloatNum(item.value * 1)
              } else {
                resultValue = parseFloatNum(item.value * 1)
              }
            } else {
              resultValue = '-'
            }
            const result = `<div>
            <li class="lineWrapper">
              <span class="spanWrapper">
                ${item.marker} 
                <span class="nameWrapper">
                  ${item.seriesName}
                </span>
              </span>
              <span style="numWrapper">
                ${resultValue}${resultValue !== '-' ? unit : ''}
              </span>
             </li>
            </div>`
            panel += result
          })
          panel += '</div></div>'
          return panel
        },
        position: function (point, params, dom, rect, size) {
          // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
          // 提示框位置
          let x = 0 // x坐标位置
          let y = 0 // y坐标位置

          // 当前鼠标位置
          let pointX = point[0]
          let pointY = point[1]

          // 外层div大小
          // var viewWidth = size.viewSize[0];
          // var viewHeight = size.viewSize[1];

          // 提示框大小
          let boxWidth = size.contentSize[0]
          let boxHeight = size.contentSize[1]

          // boxWidth > pointX 说明鼠标左边放不下提示框
          if (boxWidth > pointX) {
            x = pointX
          } else {
            // 左边放的下
            x = pointX - boxWidth + 50
          }

          // boxHeight > pointY 说明鼠标上边放不下提示框
          if (boxHeight > pointY) {
            y = 5
          } else {
            // 上边放得下
            y = pointY - boxHeight + 5
          }

          return [x, y]
        },
      },
      color: [
        '#34cdc5',
        '#219af2',
        '#a9def4',
        '#f6e1aa',
        '#9975cf',
        '#f56580',
        '#f98349',
        '#009486',
        '#415982',
      ],
      xAxis: {
        type: 'category',
        boundaryGap: !!seriesResult.some(item => item.type === 'bar'),
        // boundaryGap: true,
        axisTick: {
          show: false, // 不显示坐标轴刻度线
        },
        axisLine: {
          show: false,
        },
        data: xData,
        axisLabel: {
          interval: 'auto',
          formatter(value: string) {
            let text = ''
            switch (byTime) {
              case 'byDay':
                text = `${moment(value).format('MM/DD')}`
                break
              case 'byWeek':
                text = `${value}当周`
                break
              case 'byMonth':
                text = `${value}月`
                break
              case 'byQuarter':
                text = `${value}季度`
                break
              case 'byYear':
                text = `${value}年`
                break
              default:
                ''
                break
            }
            return byTime ? text : value
          },
        },
      },
      yAxis: yAxis || {
        type: 'value',
        axisLabel: {
          margin: 20,
        },
      },
      grid: {
        top: '6%',
        left: '2%',
        right: '3%',
        bottom: isExplore ? '10%' : '22%',
        containLabel: true,
      },
      legend: {
        data: seriesResult.map(item => item.name),
        bottom: '0',
        type: 'scroll',
        orient: 'horizontal', // vertical
        left: 'center',
        icon: 'circle', //  这个字段控制形状  类型包括 circle,rect ,roundRect,triangle,diamond,pin,arrow,none
        itemWidth: 10, // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 40, // 设置间距
      },
      series: seriesResult as any,
      dataZoom: [
        {
          type: 'slider',
          show: series.length >= 30,
          xAxisIndex: [0],
          start: 0,
          end: 100,
          bottom: '13%',
          left: '2%',
          right: '1%',
          height: 20,
          textStyle: {
            color: 'white',
          },
          brushSelect: false,
        },
      ],
    }
    const newPromise = new Promise((resolve: any) => {
      resolve()
    })
    const myCharts = echarts.init(echartsRef?.current)
    newPromise.then(() => {
      if (myCharts) {
        myCharts.setOption(option)
      }
    })
    window.addEventListener('resize', () => {
      myCharts.resize()
    })
    return () => {
      myCharts.clear()
      // myCharts.dispose()
    }
  }, [props])

  return (
    <div
      ref={echartsRef}
      style={{ width: '100%', height: '160px', ...styles }}
    />
  )
}
export default TrendChart
