import moment from 'moment'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  getEventList,
  getGroupData,
} from '@/http/businessAnalysis/customAnalysis/flows'
import {
  extendCondition,
  fieldCollectionList,
  filterList,
} from '@/http/businessAnalysis/exploreBoard/condition'

export interface IAnalysisFlows {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  splitEventConditionDatumList: ExtendConditionDatum[][]
  eventConditionDatumList: ExtendConditionDatum[]
  sourceEventConditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  groupFieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  analyzePathTo: ConditionList | null
  conditionData: ConditionList | null
  groupDataList: any
  groupConditionDatumList: ExtendConditionDatum[]
  indicatorsData: any[]
  activeType: {
    eventDesc: string
    eventName: string
    sourceType: '0' | '1'
  }
  sessionDuration: {
    colLimit: number
    rowLimit: number
    fromDate: string // 开始时间
    recentDay: string // 相差几天
    toDate: string // 结束时间
    sessionInterval: number // 会话间隔时长
    sessionType: string // 间隔类型
  }
  splitShow: AnalysisFlowsSplitStructure
  paramsList: AnalysisFlowsCheckAll
  eventGroupData: AnalysisFlowsGroupEventData[]
  eventList: any
  eventCollection: any
  eventDataIndex: number
  checkEventList: CheckEventListProps | null
  splitAttributeData: { [key: string]: any }
}

const fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
const toDate = moment().format('YYYY-MM-DD')

const initialState: IAnalysisFlows = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 用户的属性列表
  splitEventConditionDatumList: [], // 事件拆分的
  eventConditionDatumList: [], // 参与分析事件的属性列表
  sourceEventConditionDatumList: [], // 分析路径以的事件属性
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  filterList: [], // 条件列表
  groupDataList: [], // 且用户符合
  groupConditionDatumList: [], // 分组项的属性列表
  groupFieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 分组项的属性列表
  analyzePathTo: null, // 分析路径以
  activeType: {
    eventDesc: '', // 事件的displayName
    eventName: '', // 事件type
    sourceType: '0', // 分析路径为初始事件还是结束事件
  },
  conditionData: null, // 且用户符合
  indicatorsData: [], // 分析指标数据
  sessionDuration: {
    colLimit: 10,
    rowLimit: 7,
    fromDate, // 开始时间
    recentDay: '', // 相差几天
    toDate, // 结束时间
    sessionInterval: 30, // 会话间隔时长
    sessionType: 'minute', // 间隔类型
  },
  splitShow: {
    // 事件拆分使用与未使用事件内容
    surplus: [],
    use: [],
    lastAllCheckList: [],
  },
  paramsList: {
    checkedList: {},
    indeterminate: {},
    isAllChecked: {},
  },
  eventGroupData: [],
  eventList: {
    explore: [],
    meta: [],
  }, // 事件选择
  eventCollection: [], // 事件收藏
  eventDataIndex: 0,

  // 参与分析的事件
  checkEventList: {},
  splitAttributeData: {},
}

/* 获取用户属性列表 */
export const getExtendCondition = createAsyncThunk(
  'analysisFlows/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取事件属性列表 */
export const getEventConditionData = createAsyncThunk(
  'analysisFlows/getEventConditionData',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

// 获取分析路径以的事件属性
export const getSourceEventConditionData = createAsyncThunk(
  'analysisFlows/getSourceEventConditionData',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'analysisFlows/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'analysisFlows/getFilterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 获取所有分组 */
export const getAllGroupCatalog = createAsyncThunk(
  'analysisFlows/getGroupCatalog',
  async (params: any) => {
    const result = await getGroupData(params)
    return result.data
  }
)

/* 获取全局属性 */
export const getAttributeCollection = createAsyncThunk(
  'analysisFlows/getAttributeCollection',
  async (params: any) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

// 重制 checkList 选中数据
const resetCheckEventList = (stateData: IAnalysisFlows, listEvent: any) => {
  const { meta, explore } = listEvent
  if (meta && meta.length > 0) {
    const findGroup = meta.find((item: any) => item.events?.length > 0)
    if (findGroup) {
      const setObjName = findGroup.groupName
      const setObjData = findGroup.events.slice(0, 10)
      let collecTionData: any = []

      if (explore && explore.length > 0) {
        collecTionData = setObjData.filter((item: any) =>
          explore.some((exp: any) => item.eventName === exp.eventName)
        )
      }
      stateData.checkEventList = {
        [setObjName]: setObjData,
        收藏: collecTionData,
      }
    }
  }
}

// 移除选中不存在的事件与收藏
// const changeCheckEventList = (stateData: IAnalysisFlows, listEvent: any) => {
//   const { meta, explore } = listEvent
//   if (meta && meta.length > 0) {
//     meta.forEach((item: any) => {
//       stateData.checkEventList![item.groupName] = stateData.checkEventList![
//         item.groupName
//       ]?.filter((eventItem: any) =>
//         item.events.find((fatherItem: any) => fatherItem.id === eventItem.id)
//       )
//     })
//   } else {
//     stateData.checkEventList = null
//     return
//   }
//   if (explore && explore.length > 0) {
//     const collectionListArray: Event[] = []
//     Object.keys(stateData.checkEventList!).forEach(item => {
//       stateData.checkEventList![item]?.forEach(eventItem => {
//         const collectionItem = explore.find(
//           (cItem: any) => cItem.id === eventItem.id
//         )
//         if (collectionItem) {
//           collectionListArray.push(collectionItem)
//         }
//       })
//     })

//     stateData.checkEventList!['收藏'] = collectionListArray
//   } else {
//     stateData.checkEventList!['收藏'] = []
//   }
// }

export const analysisFlowsSlice = createSlice({
  name: 'analysisEvent',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisFlows; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
    resetState: state => (state = initialState),
  },
  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    builder.addCase(getEventConditionData.fulfilled, (state, action) => {
      state.eventConditionDatumList = action.payload
    })
    builder.addCase(getSourceEventConditionData.fulfilled, (state, action) => {
      state.sourceEventConditionDatumList = action.payload
    })
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventCollection = action.payload.explore
      state.eventList = action.payload || {
        explore: [],
        meta: [],
      }

      // const stateEventList = state.checkEventList || {}
      // if (Object.keys(stateEventList).length === 0) {
      resetCheckEventList(state, action.payload)
      // } else {
      //   changeCheckEventList(state, action.payload)
      // }
    })
    builder.addCase(getAllGroupCatalog.fulfilled, (state, action) => {
      state.eventGroupData = action.payload
    })
    builder.addCase(getAttributeCollection.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
  },
})

export const analysisFlowsState = (state: RootState) => state.analysisFlow

export default analysisFlowsSlice.reducer

export const { setterStoreState, resetState, initGameIdAndUser } =
  analysisFlowsSlice.actions
