import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 380px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  padding: 18px 30px 30px 30px;

  &:hover {
    .hover-toogle {
      visibility: unset !important;
    }
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
  }
  transition: all 0.3s ease-in-out;
`
export const EmptyData = styled.div`
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 14px;
`
