import styled from 'styled-components'
import { Input } from 'antd'

export const InputWrapper = styled(Input)`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  height: 32px;
  padding: 0 8px;
  color: #42546d;
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #d9dfe6;
  border-radius: 2px;
  transition: all 0.3s;
`
