import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

export const getFunnelData = (params: {}): Promise<IResponse<any>> =>
  axiosInstance.post('/rapb/econ/v1/analysis/funnel', { ...params })
