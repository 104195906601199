import React from 'react'
import { Input } from 'antd'
import { InputComWrapper } from './styles'

interface Props {
  handleValue: (value: any) => void
  filterValue: any
}

function InputCom(props: Props) {
  const { handleValue, filterValue } = props
  return (
    <InputComWrapper>
      <Input value={filterValue} onChange={e => handleValue(e.target.value)} />
    </InputComWrapper>
  )
}

export default InputCom
