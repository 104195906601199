import React from 'react'
import styled from 'styled-components'
import emptyBox from '@/assets/img/emptyBox.png'

const EmptyList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  img {
    margin-top: -50px;
    font-size: 80px;
  }
  div {
    width: 100%;
    text-align: center;
    color: #42546d;
  }
`
export default function EmptyCom() {
  return (
    <EmptyList>
      <img src={emptyBox} alt="" width={300} height={300} />
      <div>暂无数据</div>
    </EmptyList>
  )
}
