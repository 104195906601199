import styled from 'styled-components'
import { Input } from 'antd'
import SvgIcon from '@/components/SvgIcon'

export const EditContentWrapper = styled.div`
  padding: 16px;
  .title {
    margin-bottom: 5px;
    color: #202d3f;
    font-weight: 500;
    font-size: 14px;
  }
`
export const EditContent = styled.div``

export const SelfSectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 8px;
  color: #42546d;
  font-weight: 500;
  font-size: 14px;
`
export const ClearBtnIcon = styled(SvgIcon)`
  color: #42546d;
  margin-right: 4px;
`

export const ClearBtn = styled.div`
  color: #42546d;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :hover {
    color: #3d90ff;
    ${ClearBtnIcon} {
      color: #3d90ff;
    }
  }
`

export const SelfSectionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 348px;
  width: 230px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`

export const SelfSectionContent = styled.div`
  max-height: 240px;
  padding: 8px;
  overflow-y: auto;
`

export const RangeItemWrapper = styled.div`
  width: 100%;
  padding: 4px;
`

export const Symbol = styled.span`
  padding: 0 4px;
`
export const DeleteIconWrapper = styled.span`
  display: inline-block;
  width: 14px;
  margin-left: 4px;
  font-size: 14px;
  cursor: pointer;
  opacity: 0;
  .icon {
    color: #42546d;
  }
  :hover {
    opacity: 1;
    .icon {
      color: #c82829;
    }
  }
`
export const Static = styled.span`
  display: inline-block;
  width: 70px;
  height: 24px;
  color: #8d9eb9;
  line-height: 24px;
  text-align: center;
  background-color: #f6f8fa;
  border: 1px solid #f0f0f0;
`

export const SelfSectionAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 12px;
`

export const AddBtn = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: #42546d;
  cursor: pointer;
  .icon {
    margin-right: 4px;
    color: #42546d;
    font-size: 16px;
  }
  :hover {
    color: #3d90ff;
    .icon {
      color: #3d90ff;
    }
  }
`

export const SetBatch = styled.div`
  width: 100%;
  padding: 12px 8px;
  color: #42546d;
  font-size: 12px;
  background-color: #fbfbfb;
  border-top: 1px solid #f0f0f0;
`
export const StepWrapper = styled(Input)`
  width: 56px;
  margin: 0 4px;
  background-color: #fff;
  height: 24px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 2px;
`
export const StepIconWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
  color: #42546d;
  font-size: 14px;
  cursor: pointer;
  :hover {
    .icon {
      color: #3d90ff;
    }
  }
`
