import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  height: 56px;
  margin-bottom: 16px;
  width: 100%;
  overflow: hidden;
  .ant-select-selector {
    padding: 0 !important;
  }
`

export const TitleOperateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  height: 32px;
`
export const TitleWrapper = styled.div`
  font-size: 23px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 28px;
`

export const OperateWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
  .icon {
    height: 16px;
    width: 16px;
    visibility: hidden;
  }
  &:hover {
    cursor: pointer;
    background: #eeeeee;
    .icon {
      color: #5ba8ff;
    }
  }
`
