import React, { useCallback } from 'react'
import GroupItemCom from './GroupItem'

interface Props {
  icon?: string
  groupDataList: GroupItem[]
  iconPath: string
  groupFieldCollectionList: ExtendConditionDatum
  groupConditionDatumList: ExtendConditionDatum[]
  handleDel: (index: number) => void
  handleGroupDataList: (val: GroupItem[]) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
}

function DragGroup(props: Props) {
  const {
    handleGroupDataList,
    handleFieldCollection,
    handleDel,
    groupDataList = [],
    icon,
    iconPath,
    groupFieldCollectionList,
    groupConditionDatumList,
  } = props

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = JSON.parse(JSON.stringify(groupDataList))
      const arr: GroupItem[] = []
      // console.log('3123', newList, dragIndex, hoverIndex)
      newList.map((item: GroupItem) => {
        if (item.index === dragIndex) {
          const newItem = {
            ...item,
            index: hoverIndex,
          }
          return arr.push(newItem)
        }
        if (item.index === hoverIndex) {
          const newItem = {
            ...item,
            index: dragIndex,
          }
          return arr.push(newItem)
        }
        return arr.push(item)
      })
      const result = arr.sort((a, b) => a.index - b.index)
      handleGroupDataList(result)
    },
    [groupDataList]
  )

  return (
    <div>
      {groupDataList?.map((item: GroupItem, index: number) => (
        <GroupItemCom
          key={index}
          item={item}
          icon={icon}
          index={index}
          groupDataList={groupDataList}
          iconPath={iconPath}
          groupFieldCollectionList={groupFieldCollectionList}
          groupConditionDatumList={groupConditionDatumList}
          moveCard={moveCard}
          handleDel={handleDel}
          groupName={groupDataList.map(itm => itm.label)}
          handleFieldCollection={handleFieldCollection}
          handleCondition={handleGroupDataList}
        />
      ))}
    </div>
  )
}

export default DragGroup
