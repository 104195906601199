import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function TotalRecharge() {
  const props = {
    boardTitle: '付费总金额',
    modular: 'rechargeTotal',
    exploreType: 'cordBoard',
    tips: '通过"通知发货"事件的"支付金额.总和"计算每天的付费总额',
    unit: '美元',
    byTime: 'byDay',
  }
  return <TemplateCard {...props} />
}
export default TotalRecharge
