/* eslint-disable indent */
import React, { useMemo } from 'react'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import {
  IExploreBoard,
  setterStoreState,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import DropdownTimeSelector from '@/components/DropdownTimeSelector'
import DropdownUnitSelector from '@/components/DropdownUnitSelector'
import TimePickers from '@/components/TimePickertCom'
import IndexSelector from '@/components/IndexSelector'
import DatePickerSelector from '@/components/DatePickerSelector'
import SvgIcon from '@/components/SvgIcon'
import PopoverNumber from '@/pages/BusinessAnalysis/components/TemplateBoard/HeadLayout/components/PopoverNumber'
import { getMenuList, screenByTimeList } from './utils'
import { LeftWrapper, DividerWrapper } from './styles'
import { RangeDiv } from '@/pages/BusinessAnalysis/components/TemplateBoard/HeadLayout/styles'

const beforeDay = (time: string) => moment(moment()).diff(time, 'days')

function LeftCom() {
  const dispatch = useAppDispatch()
  const boardTitle = useAppSelector(state => state.exploreBoard.boardTitle)
  const byTime = useAppSelector(state => state.exploreBoard.byTime)
  const timeRange = useAppSelector(state => state.exploreBoard.timeRange)
  const timeType = useAppSelector(state => state.exploreBoard.timeType)
  const toggleType = useAppSelector(state => state.exploreBoard.toggleType)
  const checkType = useAppSelector(state => state.exploreBoard.checkType)
  const checkRange = useAppSelector(state => state.exploreBoard.checkRange)
  const indicators = useAppSelector(state => state.exploreBoard.indicators)
  const groups = useAppSelector(state => state.exploreBoard.groups)
  const frozenGroups = useAppSelector(state => state.exploreBoard.frozenGroups)
  const byDate = useAppSelector(state => state.exploreBoard.byDate)
  const xData = useAppSelector(state => state.exploreBoard.xData)
  const frozenIndicators = useAppSelector(
    state => state.exploreBoard.frozenIndicators
  )
  const retainedCount = useAppSelector(
    state => state.exploreBoard.retainedCount
  )

  const toolBarLeftProps = useAppSelector(
    state => state.exploreBoard.toolBarLeftProps
  )

  const {
    needDurationTime, // 时间段选择器
    needPopoverNumber, // 日期填充
    needUnitSelector = true,
    needDropDownSelector,
    needDatePickerSelector,
  } = toolBarLeftProps

  const handleChangeState = (key: keyof IExploreBoard, value: unknown) => {
    dispatch(
      setterStoreState({
        type: key,
        value,
      })
    )
  }

  const tableTypeArr = ['table']

  /* 按天/周/月/季度/年 */
  function DropdownUnitSelectorCom() {
    const byTimeList = () => {
      if (boardTitle === '周登录天数分布') {
        return screenByTimeList.filter((item: { id: number }) => item.id === 2)
      }
      if (boardTitle === 'FB安装') {
        return screenByTimeList.filter((item: { id: number }) => item.id !== 4)
      }
      return screenByTimeList
    }
    return (
      <DropdownUnitSelector
        byTime={byTime}
        byTimeList={byTimeList()}
        handleDropDownSelect={key => handleChangeState('byTime', key)}
      />
    )
  }

  /* 过去三十天 */
  function TimePickersCom() {
    const currentTime = useMemo(() => {
      const [startDateType, endDateType] = timeType
      const [startDate, endDate] = timeRange

      let startDateText = startDate

      if (startDateType === 1) {
        startDateText = `${beforeDay(startDate)}天前`
      }

      let endDateText = endDate
      if (endDateType === 1) {
        endDateText = `${beforeDay(endDate)}天前`
      }

      return (
        <RangeDiv>
          <SvgIcon icon="#icon-rili" className="icon" />
          {`${startDateText} → ${endDateText}`}
        </RangeDiv>
      )
    }, [timeType, timeRange])
    return (
      <TimePickers
        handleTimePickerOkClick={arg => {
          handleChangeState('timeType', [arg.startDateType, arg.endDateType])
          handleChangeState('timeRange', [arg.startDate, arg.endDate])
        }}
        defaultTimeRange={timeRange}
        defaultContentName={currentTime}
      />
    )
  }

  return (
    <LeftWrapper>
      {/* 时间段选择器   */}
      {needDurationTime && (
        <DropdownTimeSelector
          value={byDate}
          handleDurationTimeSelect={arg => handleChangeState('byDate', arg)}
        />
      )}
      {needPopoverNumber && (
        <PopoverNumber
          days={retainedCount}
          handleDays={arg => handleChangeState('retainedCount', arg)}
        />
      )}
      {/* 按天 按周 按月 按季度 按年 */}
      {needUnitSelector && <DropdownUnitSelectorCom />}
      <DividerWrapper />
      {/* 过去三十天 VS */}
      <TimePickersCom />
      {/* 指标 如果需要 则不是表格才显示 */}
      {!tableTypeArr.includes(toggleType) && frozenIndicators.length > 1 && (
        <>
          <DividerWrapper />
          <IndexSelector
            indicators={indicators}
            handleChangeIndex={(arg: string[]) =>
              handleChangeState('indicators', arg)
            }
            frozenList={frozenIndicators}
            title={`指标 (${indicators.length}/${frozenIndicators.length})`}
          />
        </>
      )}
      {/* 分组 */}
      {!tableTypeArr.includes(toggleType) && frozenGroups.length > 1 && (
        <>
          <DividerWrapper />
          <IndexSelector
            indicators={groups}
            handleChangeIndex={(arg: string[]) =>
              handleChangeState('groups', arg)
            }
            frozenList={frozenGroups}
            title={`分组 (${groups.length}/${frozenGroups.length})`}
          />
        </>
      )}
      {/* 日期/阶段汇总 */}
      {needDropDownSelector && tableTypeArr.includes(toggleType) && false && (
        <>
          <DividerWrapper />
          <DropdownUnitSelector
            byTime={checkType}
            byTimeList={getMenuList(boardTitle)}
            handleDropDownSelect={key => handleChangeState('checkType', key)}
            style={{ margin: '0px' }}
          />
          {needDatePickerSelector && checkType === '日期' && (
            <>
              <DividerWrapper />
              <DatePickerSelector
                xData={xData}
                byTime={byTime}
                timeRange={timeRange}
                checkRange={checkRange}
                handleDateSelector={(val: string[]) => {
                  handleChangeState('checkRange', val)
                }}
              />
            </>
          )}
        </>
      )}
    </LeftWrapper>
  )
}

export default LeftCom
