import { axiosInstance } from '../../index'

interface IResponse<T> {
  code: number
  data: T
  msg: string
  param?: any
}

interface CollectionParams {
  gameId: string
  uid: number
  eventId: number
}

// 获取路径分析列表
export const pathAnalysisList = (
  params: PathAnalysisList
): Promise<IResponse<any>> =>
  axiosInstance.post('/apg/econ/v1/analysis/path', params)

// 添加收藏事件
export const collectEvent = (
  params: CollectionParams
): Promise<IResponse<any>> =>
  axiosInstance.get('/api/econ/v1/common/event/explore', { params })

// 删除收藏事件
export const deleteCollectEvent = (
  params: CollectionParams
): Promise<IResponse<any>> =>
  axiosInstance.get('/api/econ/v1/common/event/explore/delete', { params })

// 事件列表
export const getEventList = (
  params: EventsProps
): Promise<IResponse<EventDatum>> =>
  axiosInstance.get('/api/econ/v1/common/event/list', { params })

// 获取主分组
export const getGroupData = (params: any): Promise<IResponse<any>> =>
  axiosInstance.get('/api/econ/v1/common/group/catalog', { params })

// 新增保存分组设置
export const addOrEditEventGroup = (params: any): Promise<any> =>
  axiosInstance.post('/api/econ/v1/common/group/catalog/save', params)

// 删除住分组设置
export const deleteMasterGroup = (params: any): Promise<any> =>
  axiosInstance.delete('/api/econ/v1/common/group/catalog', params)

// 获取筛选属性
export const getExtendConditions = (params: any): Promise<any> =>
  axiosInstance.get('/api/econ/v1/common/extendCondition', { params })
