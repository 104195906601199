/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react'
import { Radio, DatePicker } from 'antd'
import dayjs from 'dayjs'
import type { DatePickerProps, RadioChangeEvent } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import PopoverTips from '@/components/PopoverTips'
import ExtendConditionCom from '@/components/ExtendConditionCom'
import ConditionRightModal from '../ConditionRightModal'
import {
  ConditionItem,
  ActionRow,
  ActionLeft,
  ActionCondition,
  ActionRight,
  ActionAdd,
  ActionDelete,
  HistoryWrapper,
  EditContentWrapper,
  EditContent,
  VersionWrapper,
  Title,
  Word,
} from './styles'

const dateFormat = 'YYYY/MM/DD'
const newVersionData = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
interface Props {
  item: ListItem
  index: number
  filterList: FilterList[]
  openFilter: boolean
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  iconPath: string
  openExtend: string
  needAdd?: boolean
  handleOpenExtend: (val: string) => void
  handleSetFilterIndex: (val: number) => void
  handleActionType: (isAdd: boolean) => void
  handleCondition: (item: ExtendConditionDatumList) => void
  handleSaveItem: (item: ListItem) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
}

/*
  待优化组件
  handleActionType handleSaveItem handleCondition 可以优化 合并

*/
function RenderLevelItem(props: Props) {
  const {
    item,
    index,
    filterList,
    conditionDatumList,
    fieldCollectionList,
    openFilter, // 是否打开该选择条件
    iconPath,
    openExtend, // 是否打开ExtendConditionCom
    needAdd = true,
    handleOpenExtend, // 控制是否打开ExtendConditionCom
    handleSetFilterIndex, // 打开某个条件选择框
    handleActionType,
    handleCondition,
    handleSaveItem,
    handleFieldCollection,
  } = props

  const [openVersion, setOpenVersion] = useState<boolean>(false)
  const [versionType, setVersionType] = useState<number>(
    item?.filter?.edition || 1
  )
  const [versionDate, setVersionDate] = useState<string>(
    item?.filter?.date || newVersionData
  )

  function RenderConditionLeftModal() {
    const chooseItem = {
      label: item?.condition?.label,
      descIcon: item?.condition?.descIcon,
      descText: item?.condition?.descText,
    }
    return (
      <ExtendConditionCom
        chooseItem={chooseItem}
        iconPath={iconPath}
        conditionDatumList={conditionDatumList}
        fieldCollectionList={fieldCollectionList}
        openPop={openExtend === `${index}`}
        setOpenPop={val =>
          handleOpenExtend(openExtend === '-1' ? `${index}` : '-1')
        }
        handleCondition={handleCondition}
        handleFieldCollection={handleFieldCollection}
      />
    )
  }

  function RenderConditionRightModal() {
    return (
      <ConditionRightModal
        chooseItem={item}
        open={openFilter}
        iconPath={iconPath}
        filterList={filterList}
        handleOpen={(val: boolean) =>
          val ? handleSetFilterIndex(index) : handleSetFilterIndex(-1)
        }
        handleSaveItem={handleSaveItem}
      />
    )
  }

  function RenderVersion() {
    const onChange = (e: RadioChangeEvent) => {
      e.stopPropagation()
      setVersionType(e.target.value)
    }
    const onChangeTime: DatePickerProps['onChange'] = (date, dateString) => {
      setVersionDate(dateString)
    }
    const disabledDate: DatePickerProps['disabledDate'] = current =>
      // Can not select days before today and today
      current && current > dayjs().subtract(1, 'days')

    return (
      <EditContentWrapper>
        <Title>
          选择版本
          <PopoverTips
            isBlackStyle
            placement="top"
            content={
              <div>
                <div>最新版本：按用户最新的标签值计算</div>
                <div>动态匹配：按用户在事件发生当日的标签值计算</div>
                <div>历史版本：按用户在某个特定日期的标签值计算</div>
              </div>
            }
            overlayInnerBlackStyle={{
              maxWidth: '315px',
            }}
          >
            <span>
              <SvgIcon icon="#icon-tishi" className="icon" />
            </span>
          </PopoverTips>
        </Title>
        <EditContent>
          <Radio.Group onChange={onChange} value={versionType}>
            <Radio value={1}>最新版本</Radio>
            <Radio value={2}>历史版本</Radio>
          </Radio.Group>
          {versionType === 2 && (
            <DatePicker
              allowClear={false}
              onChange={onChangeTime}
              size="small"
              value={dayjs(versionDate, dateFormat)}
              format={dateFormat}
              popupStyle={{ zIndex: 9999 }}
              disabledDate={disabledDate}
            />
          )}
        </EditContent>
      </EditContentWrapper>
    )
  }

  function RenderVersionCom() {
    return (
      <HistoryWrapper>
        <Word>按</Word>
        <PopoverTips
          placement="bottomRight"
          trigger="click"
          content={<RenderVersion />}
          overlayInnerStyle={{
            width: '365px',
            position: 'relative',
          }}
          open={openVersion}
          setOpen={val => {
            if (!val) {
              handleSaveItem({
                ...item,
                filter: {
                  ...item.filter,
                  edition: versionType,
                  date: versionType === 1 ? newVersionData : versionDate,
                },
              })
            }
            setOpenVersion(val)
          }}
        >
          <VersionWrapper isOpen={openVersion} className="hoverBtn">
            历史版本
          </VersionWrapper>
        </PopoverTips>
      </HistoryWrapper>
    )
  }

  return (
    <ConditionItem>
      <ActionRow>
        <ActionLeft>
          <ActionCondition>
            <RenderConditionLeftModal />
            {item?.condition?.type === 4 && <RenderVersionCom />}
            <RenderConditionRightModal />
          </ActionCondition>
        </ActionLeft>
        <ActionRight className="actionRight">
          {needAdd && (
            <PopoverTips isBlackStyle placement="top" content="添加并列条件">
              <ActionAdd onClick={() => handleActionType(true)}>
                <SvgIcon icon="#icon-shaixuan2" className="icon" />
              </ActionAdd>
            </PopoverTips>
          )}
          <PopoverTips isBlackStyle placement="top" content="删除项目">
            <ActionDelete onClick={() => handleActionType(false)}>
              <SvgIcon icon="#icon-guanbi2fill" className="icon" />
            </ActionDelete>
          </PopoverTips>
        </ActionRight>
      </ActionRow>
    </ConditionItem>
  )
}

export default React.memo(RenderLevelItem)
