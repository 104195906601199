import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

const grouping = [
  {
    label: '登录方式',
    labelEn: 'platform',
    descIcon: '',
    dataType: '文本',
    dataTypeEn: 'text',
    type: 1,
    queryType: 1,
    descText: '预制属性',
    isCollection: false,
    isHistory: false,
    infoData: {
      id: 1,
      remarks: '',
      attributeType: '预制属性',
      attributeName: 'pay_currency',
      displayName: '支付币种',
      dataType: '文本',
      number: 0,
      subject: '',
      timeZone: '',
      updateTime: '',
      createUser: '',
    },
    disabled: false,
    id: 0,
    index: 0,
    valueType: 1,
    value: [],
    hasOption: false,
  },
  {
    label: '系统',
    labelEn: 'system',
    descIcon: '',
    dataType: '文本',
    dataTypeEn: 'text',
    type: 1,
    queryType: 1,
    descText: '预制属性',
    isCollection: false,
    isHistory: false,
    infoData: {
      id: 1,
      remarks: '',
      attributeType: '预制属性',
      attributeName: 'pay_currency',
      displayName: '支付币种',
      dataType: '文本',
      number: 0,
      subject: '',
      timeZone: '',
      updateTime: '',
      createUser: '',
    },
    disabled: false,
    id: 0,
    index: 0,
    valueType: 1,
    value: [],
    hasOption: false,
  },
]
export default function NewChannelQuantity() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)

  const props = {
    modular: 'channelRegister',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'newUserCount',
    boardTitle: '登录方式统计',
    toggleType: 'trend',
    showTop: true,
    checkType: '日期',
    grouping,
    toolBarLeftProps: {
      needDropDownSelector: true,
      needDatePickerSelector: true,
    },
    tips: '登录方式统计，是通过”渠道“维度对新增指标进行分析。如果希望切换为其他维度，可点击刷新按钮右侧的”探索“，进入探索模块进行调整',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
