import moment from 'moment'
import { getCurrentWeek, getLastWeek } from '../../utiles/DateTimeUtils'

const formatUnit = 'YYYY-MM-DD'

const yesterday = moment().subtract(1, 'days').format(formatUnit)
const today = moment().format(formatUnit)
// 获取本周
const currentWeek1 = getCurrentWeek() // 获取本周开始结束日期 ---》 这周周一是对的

// 获取上周
const lastWeek1 = getLastWeek(1) // 获取上周开始结束日期 ---》上周
// 获取上月
const lastMonthStart = moment()
  .startOf('month')
  .subtract('month', 1)
  .format(formatUnit)
const lastMonthEnd = moment()
  .endOf('month')
  .subtract('month', 1)
  .endOf('month')
  .format(formatUnit)

// 获取本月
const currentMonthStart = moment().startOf('month').format(formatUnit)
const currentMonthEnd = moment()
  .endOf('month')
  .endOf('month')
  .format(formatUnit)
// 过去7天
const last7 = moment().subtract('days', 7).format(formatUnit) // 7天前
const last6 = moment().subtract('days', 6).format(formatUnit) // 7天前
const last30 = moment().subtract('days', 30).format(formatUnit) // 7天前
const last29 = moment().subtract('days', 29).format(formatUnit) // 7天前
const btnList: { name: string; type: number }[] = [
  {
    name: '动态时间',
    type: 1,
  },
  {
    name: '静态时间',
    type: 2,
  },
]
const timeList = [
  {
    timeName: '昨日',
    value: [yesterday, yesterday],
    typeName: '',
  },
  {
    timeName: '今日',
    value: [today, today],
    typeName: '',
  },
  {
    timeName: '上周',
    value: lastWeek1,
    typeName: 'lastWeek',
  },
  {
    timeName: '本周',
    value: [currentWeek1[0], today],
    typeName: 'thisWeek',
  },
  {
    timeName: '上月',
    value: [lastMonthStart, lastMonthEnd],
    typeName: 'lastMonth',
  },
  {
    timeName: '本月',
    value: [currentMonthStart, today],
    typeName: 'thisMonth',
  },
  {
    timeName: '过去7天',
    value: [last7, yesterday],
    typeName: '',
  },
  {
    timeName: '最近7天',
    value: [last6, today],
    typeName: '',
  },
  {
    timeName: '过去30天',
    value: [last30, yesterday],
    typeName: '',
  },
  {
    timeName: '最近30天',
    value: [last29, today],
    typeName: '',
  },
  {
    timeName: '自某日至昨日',
    value: [yesterday, yesterday],
    typeName: '',
  },
  {
    timeName: '自某日至今',
    value: [yesterday, today],
    typeName: '',
  },
]
export {
  btnList,
  yesterday,
  today,
  currentWeek1,
  lastWeek1,
  lastMonthStart,
  lastMonthEnd,
  currentMonthStart,
  currentMonthEnd,
  last7,
  last6,
  last30,
  last29,
  timeList,
}
