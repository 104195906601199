import React, { useRef, useState } from 'react'
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import ExtendConditionCom from '@/components/ExtendConditionCom'
import RenderContent from './components'
import {
  GroupItem,
  ActionRow,
  ActionLeft,
  OrderNum,
  Drag,
  OrderIconNum,
  ActionRight,
} from './styles'

interface GroupItemProps {
  item: GroupItem
  index: number
  icon?: string
  groupDataList: GroupItem[]
  iconPath: string
  groupFieldCollectionList: ExtendConditionDatum
  groupConditionDatumList: ExtendConditionDatum[]
  groupName: string[]
  handleDel: (index: number) => void
  moveCard: (dragIndex: number, hoverIndex: number) => void
  handleFieldCollection: (val: HandleFieldArr[]) => void
  handleCondition: (newList: GroupItem[]) => void
}

function GroupItemCom(props: GroupItemProps) {
  const [openPop, setOpenPop] = useState<boolean>(false)
  const {
    item,
    index,
    icon,
    groupDataList,
    iconPath,
    groupFieldCollectionList,
    groupConditionDatumList,
    groupName,
    handleDel,
    handleFieldCollection,
    moveCard,
    handleCondition: handleConditionData, // 把一个最新的组 丢出去
  } = props
  const ref = useRef<HTMLDivElement>(null)

  const [, drag, dragPreview] = useDrag({
    type: 'card',
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // item 中包含 index 属性，则在 drop 组件 hover 和 drop 是可以根据第一个参数获取到 index 值
    item: { index },
  })
  const [, drop] = useDrop({
    accept: 'card',
    // eslint-disable-next-line no-shadow
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // 拖拽元素下标与鼠标悬浮元素下标一致时，不进行操作
      if (dragIndex === hoverIndex) {
        return
      }

      // 确定屏幕上矩形范围
      const hoverBoundingRect = ref.current!.getBoundingClientRect()

      // 获取中点垂直坐标
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // 确定鼠标位置
      const clientOffset = monitor.getClientOffset()

      // 获取距顶部距离
      // eslint-disable-next-line no-unsafe-optional-chaining
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top

      /**
       * 只在鼠标越过一半物品高度时执行移动。
       *
       * 当向下拖动时，仅当光标低于50%时才移动。
       * 当向上拖动时，仅当光标在50%以上时才移动。
       *
       * 可以防止鼠标位于元素一半高度时元素抖动的状况
       */

      // 向下拖动
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // 向上拖动
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // 执行 move 回调函数
      moveCard(dragIndex, hoverIndex)

      /**
       * 如果拖拽的组件为 Box，则 dragIndex 为 undefined，此时不对 item 的 index 进行修改
       * 如果拖拽的组件为 Card，则将 hoverIndex 赋值给 item 的 index 属性
       */
      if (item.index !== undefined) {
        item.index = hoverIndex
      }
    },
  })
  /**
   * 使用 drag 和 drop 对 ref 进行包裹，则组件既可以进行拖拽也可以接收拖拽组件
   * 使用 dragPreview 包裹组件，可以实现拖动时预览该组件的效果
   */
  dragPreview(drop(ref))

  const handleCondition = (val: ExtendConditionDatumList) => {
    const newItem = {
      ...item,
      ...val,
      valueType: 1, // 默认选择 1
      value: [], // 值
      hasOption: val.dataType === '数值' || val.dataType === '时间',
    }
    const newList = JSON.parse(JSON.stringify(groupDataList))
    newList.splice(index, 1, newItem)
    handleConditionData(newList)
  }

  const chooseItem = {
    label: item.label,
    descIcon: item.descIcon,
    descText: item.descText,
  }

  const RenderGroupItem = (
    <GroupItem>
      <ActionRow>
        <ActionLeft>
          {!icon && <OrderNum>{index + 1}</OrderNum>}
          {icon && (
            <OrderIconNum>
              <SvgIcon icon={`#${icon}`} className="icon" />
              {index + 1}
            </OrderIconNum>
          )}
          <Drag hasIcon={!!icon}>
            <SvgIcon icon="#icon-drag_2" className="icon" />
          </Drag>
          <ExtendConditionCom
            handleCondition={handleCondition}
            chooseItem={chooseItem}
            iconPath={iconPath}
            openPop={openPop}
            setOpenPop={val => setOpenPop(val)}
            handleFieldCollection={handleFieldCollection}
            conditionDatumList={groupConditionDatumList}
            fieldCollectionList={groupFieldCollectionList}
            groupName={groupName}
          />
          {item.hasOption && (
            <RenderContent
              item={item}
              handleCondition={newItem => {
                const newList = JSON.parse(JSON.stringify(groupDataList))
                newList.splice(index, 1, newItem)
                handleConditionData(newList)
              }}
            />
          )}
        </ActionLeft>
        <PopoverTips placement="top" content="删除项目" isBlackStyle>
          <ActionRight className="delete">
            <SvgIcon
              icon="#icon-guanbi1"
              className="icon"
              handleClick={() => handleDel(index)}
            />
          </ActionRight>
        </PopoverTips>
      </ActionRow>
    </GroupItem>
  )
  return <div ref={ref}>{drag(<div ref={drop}>{RenderGroupItem}</div>)}</div>
}

export default GroupItemCom
