import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function ReturnRateOfLostUsers() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'returnUseRate',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'lossUser',
    boardTitle: '流失用户回归率',
    toggleType: 'trend',
    showTop: true,
    toolBarLeftProps: {
      needPopoverNumber: true,
      needUnitSelector: false,
    },
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
