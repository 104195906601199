import React, { useMemo } from 'react'
import moment from 'moment'
import { operateIconList, getToogleIconList } from '@/utiles/common'
import DropdownTimeSelector from '@/components/DropdownTimeSelector'
import DropdownUnitSelector from '@/components/DropdownUnitSelector'
import TimePickersCom from '@/components/TimePickertCom'
import IndexSelector from '@/components/IndexSelector'
import DatePickerSelector from '@/components/DatePickerSelector'
import SvgIcon from '@/components/SvgIcon'
import HeaderOperateLayout from './HeaderOperateLayout'
import PopoverNumber from './components/PopoverNumber'
import { SelectorWrapper, DividerWrapper, RangeDiv } from './styles'
import { getMenuList, byTimeList } from './utils'

interface HeadLayoutProps {
  title: string // 表名
  tips?: string
  toggleType: string // 显示类型
  byTime?: string // 天 周 月 季度 年
  indicators?: string[] // 指标项
  xData: string[]
  frozenIndicators?: string[] // 不会修改的指标项
  groups?: string[] // 分组项
  frozenGroups?: string[] // 不会修改的分组项
  byDate?: string // 7日
  retainedCount?: number // 连续N天未登录
  timeRange: string[] // 过去三天的时间段 2022/08/30 → 2022/09/27
  timeType?: number[] // 动静态
  checkType: string // '留存' | '流失' 日期/阶段汇总
  checkRange: [string, string] // 查看时间段
  needUnitSelector?: boolean // 是否需要 天 周 月 季度 年
  needDurationTime?: boolean // 是否需要 时间段选择器
  needPopoverNumber?: boolean // 是否需要 日期填充
  needDropDownSelector?: boolean // 是否需要 日期/阶段汇总 流失/留存
  needDatePickerSelector?: boolean // 是否需要 日期选择时间
  handleChangeState: (type: string, value: any) => void // 修改store的值
}

const beforeDay = (time: string) => moment(moment()).diff(time, 'days')

function HeadLayout(props: HeadLayoutProps) {
  const {
    title,
    tips,
    toggleType,
    xData,
    byTime = 'byDay',
    indicators = [],
    frozenIndicators = [],
    groups = [],
    frozenGroups = [],
    timeRange,
    timeType = [2, 2],
    byDate = '7Day',
    retainedCount = 7,
    needDurationTime = false,
    checkType,
    checkRange,
    needUnitSelector = true,
    needPopoverNumber = false,
    needDropDownSelector = false,
    needDatePickerSelector = false,
    handleChangeState,
  } = props
  const toogleIconList = getToogleIconList(title)
  const getByTimeList = () => {
    const list = byTimeList
    if (title === '周登录天数分布') {
      return list.filter(item => item.id === 2)
    }
    if (title === 'FB安装') {
      return list.filter(item => item.id !== 4)
    }
    return list
  }

  const contentDom = useMemo(() => {
    const [startDateType, endDateType] = timeType
    const [startDate, endDate] = timeRange

    let startDateText = startDate

    if (startDateType === 1) {
      startDateText = `${beforeDay(startDate)}天前`
    }

    let endDateText = endDate
    if (endDateType === 1) {
      endDateText = `${beforeDay(endDate)}天前`
    }

    return (
      <RangeDiv>
        <SvgIcon icon="#icon-rili" className="icon" />
        {`${startDateText} → ${endDateText}`}
      </RangeDiv>
    )
  }, [timeType, timeRange])

  return (
    <HeaderOperateLayout
      title={title}
      handleType={type => handleChangeState('toggleType', type)}
      operateIconList={operateIconList}
      toogleIconList={toogleIconList}
      toggleItem={toogleIconList.find(it => it.type === toggleType)!}
      operateClick={type => handleChangeState('operateClick', type)}
      tips={tips}
    >
      <SelectorWrapper>
        {/* 时间段选择器 当日/次日/7日/14日/  */}
        {needDurationTime && (
          <DropdownTimeSelector
            value={byDate}
            handleDurationTimeSelect={arg => handleChangeState('byDate', arg)}
          />
        )}
        {/* 连续未登录天数 */}
        {needPopoverNumber && (
          <PopoverNumber
            days={retainedCount}
            handleDays={arg => handleChangeState('retainedCount', arg)}
          />
        )}
        {/* 按 天/周/月/季度/年 */}
        {needUnitSelector && (
          <DropdownUnitSelector
            byTime={byTime}
            byTimeList={getByTimeList()}
            handleDropDownSelect={key => handleChangeState('byTime', key)}
          />
        )}
        <DividerWrapper />
        {/* 过去三十天 VS */}
        <TimePickersCom
          handleTimePickerOkClick={arg => {
            handleChangeState('timeType', [arg.startDateType, arg.endDateType])
            handleChangeState('timeRange', [arg.startDate, arg.endDate])
          }}
          defaultTimeRange={timeRange}
          defaultDateType={timeType}
          defaultContentName={contentDom}
        />
        {/* 指标 */}
        {!!(frozenIndicators?.length && toggleType !== 'table') && (
          <>
            <DividerWrapper />
            <IndexSelector
              indicators={indicators}
              handleChangeIndex={(arg: string[]) =>
                handleChangeState('indicators', arg)
              }
              frozenList={frozenIndicators}
              title={`指标 (${indicators?.length}/${frozenIndicators?.length})`}
            />
          </>
        )}
        {/* 分组 */}
        {!!(frozenGroups?.length && toggleType !== 'table') && (
          <>
            <DividerWrapper />
            <IndexSelector
              indicators={groups}
              handleChangeIndex={(arg: string[]) =>
                handleChangeState('groups', arg)
              }
              frozenList={frozenGroups}
              title={`分组 (${groups?.length}/${frozenGroups?.length})`}
            />
          </>
        )}
        {/* 日期/阶段汇总 */}
        {!!(needDropDownSelector && toggleType === 'table') && false && (
          <>
            <DividerWrapper />
            <DropdownUnitSelector
              byTime={checkType}
              byTimeList={getMenuList(title)}
              handleDropDownSelect={key => handleChangeState('checkType', key)}
              style={{ margin: '0px' }}
            />
            {!!(needDatePickerSelector && checkType === '日期') && (
              <>
                <DividerWrapper />
                <DatePickerSelector
                  xData={xData}
                  byTime={byTime}
                  timeRange={timeRange}
                  checkRange={checkRange}
                  handleDateSelector={(val: string[]) =>
                    handleChangeState('checkRange', val)
                  }
                />
              </>
            )}
          </>
        )}
      </SelectorWrapper>
    </HeaderOperateLayout>
  )
}

export default HeadLayout
