import React, { FC } from 'react'
import RateCom from '@/pages/BusinessAnalysis/components/TemplateRate'
import { Wrapper, TimeWrapper, ContentWrap } from './styles'

interface RateParams {
  data: IRateIndicatorsData
}

const RateDesc: FC<RateParams> = ({ data }) => {
  const {
    date,
    rateNumber,
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
  } = data

  const rateProps = {
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
  }

  return (
    <Wrapper>
      <TimeWrapper>{date}</TimeWrapper>
      <ContentWrap>
        <div className="rate">{`${rateNumber}%`}</div>
        {false && <RateCom {...rateProps} />}
      </ContentWrap>
    </Wrapper>
  )
}

export default RateDesc
