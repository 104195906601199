import React from 'react'
import TemplateCard from '@/pages/BusinessAnalysis/components/TemplateCard'

function MonthRecharge() {
  const props = {
    boardTitle: '当月付费总金额',
    modular: 'thisMonthRecharge',
    exploreType: 'cordBoard',
    tips: '对于关键指标，可以设置成小看板，展示更为直观',
    unit: '美元',
    byTime: 'byMonth',
  }
  return <TemplateCard {...props} />
}
export default MonthRecharge
