import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 30px;
`

export const TimeWrapper = styled.div`
  height: 19px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
`

export const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  height: 54px;
  justify-content: space-between;
  .wrapper {
    display: flex;
    align-items: center;
  }
  .rate {
    margin-top: 4px;
    font-size: 45px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 21px;
  }
  .numWrapper {
    .title,
    .unit {
      font-size: 12px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.65);
    }
    .title {
      margin-right: 12px;
    }
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0);
  }
`
