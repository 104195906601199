import styled from 'styled-components'

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const ActionIcon = styled.div`
  margin-left: 16px;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  line-height: 22px;
  text-align: center;
  background-color: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    width: 16px;
    height: 16px;
    color: #67729d;
    font-size: 16px;
    vertical-align: sub;
    transition: color 0.3s;
  }
  :hover {
    background-color: #f0f2f5;
    .icon {
      color: #3d90ff;
    }
  }
`

export const ShowChartLabel = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  > span {
    margin-right: 8px;
    font-size: 12px;
  }
  .switchStyle {
    min-width: 32px;
    height: 16px;
    color: #42546d;
    font-size: 12px;
    line-height: 16px;
    background-color: #f0f2f5;
    .ant-switch-handle {
      width: 12px;
      height: 12px;
    }
  }
  .ant-switch-checked {
    background-color: #3d90ff !important;
  }
  .ant-switch-checked:focus {
    box-shadow: 0 0 4px rgba(61, 144, 255, 0.45) !important;
  }
`
export const ShowDataType = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`
interface ShapeProps {
  selected: boolean
}
export const ShapeWrapper = styled.div<ShapeProps>`
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background-color: ${props => (props.selected ? '#3d90ff' : '#fff')};
  border: 1px solid #f0f0f0;
  border-right: none;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    color: ${props => (props.selected ? '#fff' : '#67729d')};
    font-size: 14px;
    transition: all 0.3s;
  }
  :last-child {
    border-right: 1px solid #f0f0f0;
  }
`
