import React, { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import './App.css'
import SpinLoading from './components/SpinLoading'

import CoreBoard from '@/pages/BusinessAnalysis/CoreBoard'

import PayBoard from '@/pages/BusinessAnalysis/PayBoard'
// const PayBoard = lazy(() => import('@/pages/BusinessAnalysis/PayBoard'))

import ActiveBoard from '@/pages/BusinessAnalysis/ActiveBoard'

import Pannel from './pages/BusinessAnalysis/Pannel'
// import GroupManagement from '@/pages/BusinessAnalysis/MetaEventManagement/GroupManagement/index'
// import { commonRoutes, accessRoute } from './router'
// import NotFound from '@/pages/NotFound'

const GroupManagement = lazy(
  () =>
    import('@/pages/BusinessAnalysis/MetaEventManagement/GroupManagement/index')
)

const NotFound = lazy(() => import('@/pages/NotFound/404'))

// import CustomDetail from '@/pages/BusinessAnalysis/CustomDetail' // 看板详情
const CustomDetail = lazy(() => import('@/pages/BusinessAnalysis/CustomDetail'))

// import SequenceDetail from '@/pages/BusinessAnalysis/SequenceDetail' // 用户行为序列
const SequenceDetail = lazy(
  () => import('@/pages/BusinessAnalysis/SequenceDetail')
)

// eslint-disable-next-line import/no-cycle
// import Layout from './components/Layout'
const Layout = lazy(() => import('./components/Layout'))

// import TransitLayout from './components/TransitLayout'
const TransitLayout = lazy(() => import('./components/TransitLayout'))
// const CoreBoard = lazy(() => import('@/pages/BusinessAnalysis/CoreBoard'))

// import UserGroupManage from '@/pages/ManageCenter/UserGroupManage/index'
const UserGroupManage = lazy(
  () => import('@/pages/ManageCenter/UserGroupManage/index')
)

// import AccountManagement from './pages/ManageCenter/AccountManagement'
const AccountManagement = lazy(
  () => import('./pages/ManageCenter/AccountManagement')
)
// const ActiveBoard = lazy(() => import('@/pages/BusinessAnalysis/ActiveBoard'))

// import Transit from '@/pages/Transit/index'
const Transit = lazy(() => import('@/pages/Transit/index'))

// import PlatFormTransit from '@/pages/PlatFormTransit/index'
const PlatFormTransit = lazy(() => import('@/pages/PlatFormTransit/index'))

// import MetaEventManagement
// from '@/pages/BusinessAnalysis/MetaEventManagement/MetaManagement/index'
const MetaEventManagement = lazy(
  () =>
    import('@/pages/BusinessAnalysis/MetaEventManagement/MetaManagement/index')
)

// import EventManagement from '@/pages/BusinessAnalysis/MetaEventManagement/EventManagement/index'
const EventManagement = lazy(
  () =>
    import('@/pages/BusinessAnalysis/MetaEventManagement/EventManagement/index')
)

// import UserManagement from '@/pages/BusinessAnalysis/MetaEventManagement/UserManagement/index'
const UserManagement = lazy(
  () =>
    import('@/pages/BusinessAnalysis/MetaEventManagement/UserManagement/index')
)

// import PersonalCenter from '@/pages/personalCenter'
const PersonalCenter = lazy(() => import('@/pages/personalCenter'))

// import ApplicationManage from './pages/ApplicationCenter/ApplicationManage'
const ApplicationManage = lazy(
  () => import('./pages/ApplicationCenter/ApplicationManage')
)
// import WebData from '@/pages/ApplicationCenter/Website/WebData'
// import WebConfiguration from './pages/ApplicationCenter/Website/WebConfiguration'
const WebConfiguration = lazy(
  () => import('./pages/ApplicationCenter/Website/WebConfiguration')
)

// import SdkList from './pages/ApplicationCenter/SDKManage/SdkList/index'
const SdkList = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/SdkList/index')
)

// import PushManage from './pages/ApplicationCenter/SDKManage/PushManage/index'
const PushManage = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/PushManage/index')
)

const AnnouncementManage = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/AnnouncementManage/index')
)
// import AnnouncementAudit from './pages/ApplicationCenter/SDKManage/AnnouncementAudit'

const AnnouncementAudit = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/AnnouncementAudit')
)

// import UserAgreement from './pages/ApplicationCenter/SDKManage/UserAgreement/index'
const UserAgreement = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/UserAgreement/index')
)

// import PrivacyAgreement from './pages/ApplicationCenter/SDKManage/PrivacyAgreement/index'
const PrivacyAgreement = lazy(
  () => import('./pages/ApplicationCenter/SDKManage/PrivacyAgreement/index')
)

// import Events from './pages/BusinessAnalysis/CustomAnalysis/Events'
const Events = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Events')
)

// import Retention from './pages/BusinessAnalysis/CustomAnalysis/Retention'
const Retention = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Retention')
)

// import Funnel from './pages/BusinessAnalysis/CustomAnalysis/Funnel'
const Funnel = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Funnel')
)

// import Distribution from './pages/BusinessAnalysis/CustomAnalysis/Distribution'
const Distribution = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Distribution')
)

// import Interval from './pages/BusinessAnalysis/CustomAnalysis/Interval'
const Interval = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Interval')
)

// import Flows from './pages/BusinessAnalysis/CustomAnalysis/Flows'
const Flows = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/Flows')
)

// import H5pages from './pages/BusinessAnalysis/H5pages/H5Active'
// import ApplyPermission from './pages/ManageCenter/ApplyPermission'
const ApplyPermission = lazy(
  () => import('./pages/ManageCenter/ApplyPermission')
)

// import ApprovalPermission from './pages/ManageCenter/ApprovalPermission'
const ApprovalPermission = lazy(
  () => import('./pages/ManageCenter/ApprovalPermission')
)

// import Attribute from './pages/BusinessAnalysis/UserAnalysis/Attribute'
const Attribute = lazy(
  () => import('./pages/BusinessAnalysis/UserAnalysis/Attribute')
)

// import Cohorts from './pages/BusinessAnalysis/UserAnalysis/Cohorts'
const Cohorts = lazy(
  () => import('./pages/BusinessAnalysis/UserAnalysis/Cohorts')
)

// import Tags from './pages/BusinessAnalysis/UserAnalysis/Tags'
const Tags = lazy(() => import('./pages/BusinessAnalysis/UserAnalysis/Tags'))

// import JumpTo from './pages/BusinessAnalysis/UserAnalysis/components/JumpTo'
const JumpTo = lazy(
  () => import('./pages/BusinessAnalysis/UserAnalysis/components/JumpTo')
)

// import CreateTagGroup from './pages/BusinessAnalysis/UserAnalysis/components/CreateTagGroup'
const CreateTagGroup = lazy(
  () =>
    import('./pages/BusinessAnalysis/UserAnalysis/components/CreateTagGroup')
)
// const Pannel = lazy(() => import('./pages/BusinessAnalysis/Pannel'))

// import SqlSearch from './pages/BusinessAnalysis/CustomAnalysis/SqlSearch'
const SqlSearch = lazy(
  () => import('./pages/BusinessAnalysis/CustomAnalysis/SqlSearch')
)

// import TagDetails from './pages/BusinessAnalysis/UserAnalysis/Tags/TagDetails/index'
const TagDetails = lazy(
  () => import('./pages/BusinessAnalysis/UserAnalysis/Tags/TagDetails/index')
)

// import ReportsManageMent from './pages/BusinessAnalysis/Pannel/ReportsManageMent'
const ReportsManageMent = lazy(
  () => import('./pages/BusinessAnalysis/Pannel/ReportsManageMent')
)

// import Home from './pages/Home'
const Home = lazy(() => import('./pages/Home'))

// import SurveyCenter from './pages/MarketingCenter/SurveyCenter'
const SurveyCenter = lazy(() => import('./pages/MarketingCenter/SurveyCenter'))

// import H5pages from './pages/BusinessAnalysis/H5pages/H5Active'
const H5pages = lazy(() => import('./pages/BusinessAnalysis/H5pages/H5Active'))
const Login = lazy(
  () => import(/* webpackChunkName: "login" */ '@/pages/Login')
)

export interface routeType {
  index?: boolean
  path?: string
  element?: React.ReactElement
  children?: Array<routeType>
  meta?: {
    title: string
  }
}

export const routeList = (companyName: string) => [
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '',
    element: <Navigate to="gh/login" />,
  },
  {
    path: `${companyName}/witchCreate/:type`,
    element: <JumpTo />,
  },
  {
    path: `${companyName}/createTagGroup/:type`,
    element: <CreateTagGroup />,
  },
  {
    path: '/gh/csmsb',
    element: <Layout />,
    children: [
      {
        path: '/gh/csmsb/workOrder',
      },
      {
        path: '/gh/csmsb/csworkOrder',
      },
      {
        path: '/gh/csmsb/csworker',
      },
      {
        path: '/gh/csmsb/replyList',
      },
      {
        path: '/gh/csmsb/UserQuery',
      },
      {
        path: '/gh/csmsb/announcementManage',
      },
      {
        path: '/gh/csmsb/announcementAudit',
      },
    ],
  },
  {
    path: `${companyName}`,
    element: <Layout />,
    children: [
      {
        path: 'businisAnalysis',
        children: [
          {
            path: 'dataBoard',
            meta: {
              title: '数据管理',
            },

            children: [
              {
                path: 'projectSpace',
                meta: {
                  title: '项目空间',
                },
                children: [
                  {
                    path: 'coreBoard',
                    element: <CoreBoard />,
                    meta: {
                      title: '核心看板',
                    },
                  },
                  {
                    path: 'payBoard',
                    element: <PayBoard />,
                    meta: {
                      title: '付费看板',
                    },
                  },
                  {
                    path: 'activeBoard',
                    element: <ActiveBoard />,
                    meta: {
                      title: '活跃看板',
                    },
                  },
                ],
              },

              {
                path: 'myBoard',
                meta: {
                  title: '我的看板',
                },
                children: [
                  {
                    path: 'pannel/:id',
                    element: <Pannel />,
                    meta: {
                      title: '面板',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'customAnalysis',
            meta: {
              title: '行为分析',
            },
            children: [
              {
                path: 'events',
                element: <Events />,
                meta: {
                  title: '事件分析',
                },
              },
              {
                path: 'retention',
                element: <Retention />,
                meta: {
                  title: '留存分析',
                },
              },
              {
                path: 'funnel',
                element: <Funnel />,
                meta: {
                  title: '漏斗分析',
                },
              },
              {
                path: 'distribution',
                element: <Distribution />,
                meta: {
                  title: '分布分析',
                },
              },
              {
                path: 'interval',
                element: <Interval />,
                meta: {
                  title: '间隔分析',
                },
              },
              {
                path: 'flows',
                element: <Flows />,
                // element: <H5pages />,
                meta: {
                  title: '路径分析',
                },
              },
              {
                path: 'sqlSearch',
                element: <SqlSearch />,
                meta: {
                  title: 'sql查询',
                },
              },
            ],
          },
          {
            path: 'userAnalysis',
            meta: {
              title: '用户分析',
            },
            children: [
              {
                path: 'attribute',
                element: <Attribute />,
                meta: {
                  title: '属性分析',
                },
              },
              {
                path: 'cohorts',
                element: <Cohorts />,
                meta: {
                  title: '用户分群',
                },
              },
              {
                path: 'tags',
                element: <Tags />,
                meta: {
                  title: '用户标签',
                },
              },
              {
                path: 'tagDetail',
                element: <TagDetails />,
                meta: {
                  title: '标签详情',
                },
              },
            ],
          },
          {
            path: 'metaEventManagement',
            meta: {
              title: '元事件管理',
            },
            children: [
              {
                path: 'metaEvent',
                element: <MetaEventManagement />,
                meta: {
                  title: '元事件',
                },
              },
              {
                path: 'eventManagement',
                element: <EventManagement />,
                meta: {
                  title: '事件属性',
                },
              },
              {
                path: 'userManagement',
                element: <UserManagement />,
                meta: {
                  title: '用户属性',
                },
              },
              {
                path: 'groupManagement',
                element: <GroupManagement />,
                meta: {
                  title: '事件分组管理',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'authorityCenter',
        children: [
          {
            path: 'userGroupManage',
            element: <UserGroupManage />,
            meta: {
              title: '用户组管理',
            },
          },
          {
            path: 'accountManagement',
            element: <AccountManagement />,
            meta: {
              title: '账号管理',
            },
          },
          {
            path: 'apply',
            element: <ApplyPermission />,
            meta: {
              title: '权限申请',
            },
          },
          {
            path: 'approval',
            element: <ApprovalPermission />,
            meta: {
              title: '权限审批',
            },
          },
        ],
      },

      {
        path: 'applicationCenter',
        children: [
          {
            path: 'applicationManage',
            element: <ApplicationManage />,
            meta: {
              title: '应用管理',
            },
          },
          {
            path: 'sdkManage',
            meta: {
              title: 'SDK接入',
            },
            children: [
              {
                path: 'sdkList',
                element: <SdkList />,
                meta: {
                  title: 'SDK列表',
                },
              },
              {
                path: 'announcementManage',
                element: <AnnouncementManage />,
                meta: {
                  title: '公告管理',
                },
              },

              {
                path: 'userAgreement',
                element: <UserAgreement />,
                meta: {
                  title: '用户协议',
                },
              },
              {
                path: 'privacyAgreement',
                element: <PrivacyAgreement />,
                meta: {
                  title: '隐私协议',
                },
              },

              {
                path: 'pushManage',
                element: <PushManage />,
                meta: {
                  title: '推送管理',
                },
              },
              {
                path: 'announcementAudit',
                element: <AnnouncementAudit />,
                meta: {
                  title: '公告审核',
                },
              },
            ],
          },
          {
            path: 'website',
            meta: {
              title: '官网配置',
            },
            children: [
              // {
              //   path: 'webData',
              //   element: <WebData />,
              //   meta: {
              //     title: '官网数据',
              //   },
              // },
              {
                path: 'WebConfiguration',
                element: <WebConfiguration />,
                meta: {
                  title: '官网配置',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'applicatioResearch',
        meta: {
          title: '用研平台',
        },
        children: [
          {
            path: 'surveyCenter',
            element: <SurveyCenter />,
            meta: {
              title: '问卷中心',
            },
          },
        ],
      },
      {
        path: 'marketingCenter',
        meta: {
          title: '营销中台',
        },
        children: [
          {
            path: 'activity',
            element: <H5pages />,
            meta: {
              title: 'h5活动配置',
            },
          },
        ],
      },
      {
        path: 'customInfo',
        element: <CustomDetail />,
        meta: {
          title: '数据看板详情',
        },
      },
      {
        path: 'sequenceDetail',
        element: <SequenceDetail />,
        meta: {
          title: '用户行为序列',
        },
      },
    ],
  },
  {
    path: `${companyName}`,
    element: <TransitLayout />,
    children: [
      {
        path: 'game',
        element: <Transit />,
        meta: {
          title: '游戏中转页',
        },
      },
      {
        path: 'platform',
        element: <PlatFormTransit />,
        meta: {
          title: '平台中转页',
        },
      },
    ],
  },
  {
    path: `${companyName}`,
    element: <Layout />,
    children: [
      {
        path: 'personalCenter',
        element: <PersonalCenter />,
        meta: {
          title: '个人中心',
        },
      },
    ],
  },

  {
    path: `${companyName}`,
    element: <Layout />,
    children: [
      {
        path: 'reportsManageMent',
        element: <ReportsManageMent />,
        meta: {
          title: '报表管理',
        },
      },
    ],
  },
  {
    path: `${companyName}`,
    children: [
      {
        element: <Login />,
        path: 'login',
        meta: {
          title: '登录',
        },
      },
    ],
  },
  {
    path: '*',
    meta: {
      title: 'notFoundPage',
    },
    element: <NotFound />,
  },
]
export default function App() {
  const menuList = routeList('gh')
  // return useRoutes(menuList)
  return (
    <React.Suspense fallback={<SpinLoading />}>
      {useRoutes(menuList)}
    </React.Suspense>
  )
}
// export function useQiankunStateForSlave() {
//   const [globalState, setGlobalState] = useState<any>({
//     slogan: 'Hello MicroFrontend',
//   })

//   return {
//     globalState,
//     setGlobalState,
//   }
// }
