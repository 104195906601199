import React from 'react'
import { ConfigProvider } from 'antd'
// import 'moment/locale/zh-cn'
import 'dayjs/locale/zh-cn'
import {
  // registerMicroApps,
  initGlobalState,
  MicroAppStateActions,
} from 'qiankun'

import zhCN from 'antd/es/locale/zh_CN'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import moment from 'moment'
import dayjs from 'dayjs'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { store, persistor } from './store'
import './index.css'
import App from './App'
// import { useAppSelector } from './hook/reduxHook'
// import { storeState } from '@/store/userInfo'
import reportWebVitals from './reportWebVitals'
import './http/index'

dayjs.locale('zh-cn')
// moment.locale('zh-cn')
const rooter = ReactDOM.createRoot(
  document.getElementById('rooter') as HTMLElement
)
rooter.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#4fa1ff',
                fontSize: 14,
              },
            }}
            locale={zhCN}
          >
            <App />
          </ConfigProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </DndProvider>
)
reportWebVitals()

// const states = useAppSelector(storeState)

// console.log(store.getState(), 'statessss')

export const actions: MicroAppStateActions = initGlobalState({})
// actions.onGlobalStateChange((state, prev) => {
//   // state: 变更后的状态; prev 变更前的状态

//   console.log(state, prev, 'ssome')
// })
// actions.setGlobalState(state)
// registerMicroApps([
//   {
//     name: 'reactApp1',
//     entry: 'http://localhost:8000',
//     container: '#csms',
//     activeRule: '/gh/csms',
//     props: {
//       name: 123,
//     },
//   },
// ])
