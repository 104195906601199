import styled from 'styled-components'

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ComparisonRate = styled.div``
export const SameCycleRate = styled(ComparisonRate)``
export const RateTitle = styled.span`
  width: 57px;
  height: 26px;
  font-size: 12px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  margin-right: 11px;
`
interface ITriangle {
  isUp: boolean
  isShow?: boolean
}
export const RateNum = styled.span<ITriangle>`
  width: 66px;
  height: 26px;
  font-size: 12px;
  font-family: HelveticaNeue-, HelveticaNeue;
  font-weight: normal;
  color: ${props => (props.isUp ? '#52C41A' : '#F5222D')};
  line-height: 30px;
`

export const RateText = styled.span`
  width: 66px;
  height: 26px;
  font-size: 12px;
  font-family: HelveticaNeue-, HelveticaNeue;
  font-weight: normal;
  line-height: 30px;
  color: #818bad;
`

export const Triangle = styled.div<ITriangle>`
  display: ${({ isShow }) => (isShow ? 'inline-block' : 'none')};
  margin-left: 11px;
  margin-right: 4px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: ${props => (props.isUp ? '6px solid #52C41A' : 'unset')};
  border-top: ${props => (!props.isUp ? '6px solid #F5222D' : 'unset')};
`
