import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 246px;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  padding: 19px 17px 0px 17px;
`
export const SearchWrapper = styled.div``
export const SelectedWrapper = styled.div`
  height: 234px;
  overflow-x: hidden;
  overflow-y: scroll;
`
export const DividerWrapper = styled.div`
  width: 211px;
  border: 1px solid #cbcbcb;
  margin: 14px 0 7px 0;
`
export const SelectAllWrapper = styled.div``

export const FilterOne = styled.div`
  position: absolute;
  right: 0;
  width: 65px;
  height: 21px;
  background: #ffffff;
  border-radius: 3px 3px 3px 3px;
  display: none;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #178ffe;
  text-align: center;
  line-height: 21px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background: #178ffe;
    color: #ffffff;
  }
`

export const CheckBoxWrapper = styled.div`
  position: relative;
  width: 196px;
  height: 28px;
  border-radius: 3px 3px 3px 3px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;

  &:hover {
    background: #f0f2f5;
    ${FilterOne} {
      display: block;
    }
  }
`
export const TextWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;
  display: block;
`
