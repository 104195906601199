import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

const grouping = [
  {
    label: '购买产品(礼包)名称',
    labelEn: 'payment_name',
    descIcon: '',
    dataType: '文本',
    dataTypeEn: 'text',
    type: 1,
    queryType: 1,
    descText: '预制属性',
    isCollection: false,
    isHistory: false,
    infoData: {
      id: 1,
      remarks: '',
      attributeType: '预制属性',
      attributeName: 'pay_currency',
      displayName: '支付币种',
      dataType: '文本',
      number: 0,
      subject: '',
      timeZone: '',
      updateTime: '',
      createUser: '',
    },
    disabled: false,
    id: 0,
    index: 0,
    valueType: 1,
    value: [],
    hasOption: false,
  },
]

export default function GiftBagPurchase() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'paymentSituation',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'giftBuy',
    boardTitle: '礼包购买情况',
    toggleType: 'table',
    checkType: '日期',
    showTop: true,
    grouping,
    toolBarLeftProps: {
      needDropDownSelector: true,
      needDatePickerSelector: true,
    },
    tips: '对于多维度多指标这种复杂的数据结构，推荐使用表格进行展示，通过表格顶部控件进行日期的切换',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
