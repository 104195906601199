import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface BoardState {
  lastBoardType: string
  boardConditionData: ConditionList | null
  openCondition: boolean
  showBoardSetting: boolean
  refreshBoardCount: number
  timeRangeBoard: string[]
  dimension: string
}

const initialState: BoardState = {
  boardConditionData: null,
  lastBoardType: '',
  openCondition: false,
  refreshBoardCount: 0,
  timeRangeBoard: [],
  showBoardSetting: false,
  dimension: 'uid', // 统计维度
}

export const BoardStoreSlice = createSlice({
  name: 'boardStore',
  initialState,
  reducers: {
    setterBoardStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof BoardState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
  },
})
export const { setterBoardStoreState } = BoardStoreSlice.actions
export default BoardStoreSlice.reducer
