import styled from 'styled-components'
import { TablePaginationConfig } from 'antd/es/table'

export interface DataType {
  key: string
  date: string
  registerNum: number
  createNum: number
  activeNum: number
  payTotal: number
  payPlayerNum: number
  payPenetration: string
  arpu: string
  arppu: string
}
export interface IDataTableProps<T> {
  dataSource: T[]
  columns: any
  handleSort?: any
  loading?: boolean
  height?: string
  style?: React.CSSProperties
  handleScrollTop?: (val: number) => void
  tableProps?: any
  pagination?: false | TablePaginationConfig
}

export interface IColumns {
  sortOrder: any
  title: string
  dataIndex: string
  key: string
  align: string
  sorter: boolean
  sortDirections: string[] | undefined
  render?: (records: string) => JSX.Element
}
export const Wrapper = styled.div`
  /* width: 100%;
height: 100%; */
  width: 100%;
  margin-top: 7px;
  overflow: hidden;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0px !important;
  }
  .ant-table-thead > tr > th {
    background: #f6f8fa;
    font-size: 14px;
    line-height: 32px;
  }
  .ant-table-tbody > tr > td {
    color: #8b96a6;
    font-size: 12px;
    line-height: 28px;
  }
  .ant-table-column-sorters {
    justify-content: center;
  }
  .ant-table-column-title {
    flex: unset;
  }

  .ant-table-sticky-scroll {
    display: none;
  }

  .ant-table-tbody {
    // tr:hover:not(.ant-table-expanded-row) > td,
    tr.ant-table-row:hover > td,
    tr.ant-table-cell-row-hover > td,
    .ant-table-row-hover,
    .ant-table-row-hover > td {
      background-color: #f6f8fa;
    }
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    height: 226px;
  }
`
