import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function ActiveChannel() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const grouping = [
    {
      dataType: '文本',
      dataTypeEn: 'text',
      descIcon: 'icon-wenben',
      descText: '预制属性',
      hasOption: false,
      id: 0,
      index: 0,
      infoData: {
        attributeName: 'platform',
        attributeType: '预制属性',
        createUser: '',
        dataType: '文本',
        displayName: '登录方式',
        id: 24,
        labelId: '24',
        number: 0,
        remarks: '',
        subject: '',
        timeZone: '',
        updateTime: '',
      },
      isCollection: false,
      isHistory: false,
      label: '登录方式',
      labelEn: 'platform',
      type: 1,
      value: [],
      valueType: 1,
    },
  ]
  const props = {
    modular: 'activeUserChannel',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'channel',
    boardTitle: '活跃用户数（按渠道）',
    toggleType: 'trend',
    grouping,
    checkType: '日期',
    toolBarLeftProps: {
      needDropDownSelector: true,
      needDatePickerSelector: true,
    },
    showTop: true,
    tips: '以“渠道”作为分组条件进行计算，查看各渠道下的DAU数据',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
