import React from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'

export default function Payments() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'paySituation',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'payMent',
    boardTitle: '付费情况',
    toggleType: 'table',
    showLeft: true,
    tips: '通过事件分析，可以将多个付费指标放于一个报表展示',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
