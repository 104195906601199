const payMenu = [
  {
    id: 1,
    label: '日期',
    key: '日期',
    pid: 0,
  },
  {
    id: 2,
    label: '阶段汇总',
    key: '阶段汇总',
    pid: 0,
  },
]

const retainedMenu = [
  {
    id: 1,
    label: '留存',
    key: '留存',
    pid: 0,
  },
  {
    id: 2,
    label: '流失',
    key: '流失',
    pid: 0,
  },
]

export const getMenuList = (title: string) => {
  switch (title) {
    case '付费率':
    case '登录方式统计':
    case '礼包购买情况':
    case '活跃用户生命周期构成':
    case '有效活跃用户数（按渠道）':
      return payMenu
    case '各渠道新增留存':
    case '7日LTV(不支持设备Id)':
      return retainedMenu
    default:
      return []
  }
}

export const screenByTimeList = [
  {
    id: 1,
    label: '按天',
    key: 'byDay',
    pid: 0,
  },
  {
    id: 2,
    key: 'byWeek',
    label: '按周',
    pid: 0,
  },
  {
    id: 3,
    key: 'byMonth',
    label: '按月',
    pid: 0,
  },
  {
    id: 4,
    key: 'byQuarter',
    label: '按季度',
    pid: 0,
  },
  {
    id: 5,
    key: 'byYear',
    label: '按年',
    pid: 0,
  },
]
