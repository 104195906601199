import React from 'react'
import moment from 'moment'
import CsvExport from 'csv-exportor'
import { message } from 'antd'
import MyIcon from '@/components/MyIcon'
import { IconSpan } from '@/pages/BusinessAnalysis/MetaEventManagement/EventManagement/styles'

export const colWidth = '150px' // 报表表格的宽度

export const operateIconList = [
  {
    iconName: 'icon-shuaxin1',
    title: '刷新',
    name: 'shuaxin',
  },
  {
    iconName: 'icon-fangda',
    title: '探索',
    name: 'fangda',
  },
  {
    iconName: 'icon-xiazai',
    title: '下载',
    name: 'xiazai',
  },
]

export const getToogleIconList = title => {
  const arr = [
    {
      iconName: 'icon-biaoge',
      name: '数据表',
      type: 'table',
    },
    {
      iconName: 'icon-tubiao-zhexiantu',
      name: '趋势图',
      type: 'trend',
    },
    {
      iconName: 'icon-tubiao-zhuzhuangtu',
      name: '分布图',
      type: 'distribution',
    },
    {
      iconName: 'icon-tubiao-bingtu',
      name: '饼状分布',
      type: 'pie',
    },
    {
      iconName: 'icon-leijishouyi',
      name: '累计图',
      type: 'cumulative',
    },
  ]
  if (
    title === '各渠道新增留存' ||
    title === '近7日累充排名' ||
    title === '7日LTV(不支持设备Id)'
  ) {
    return arr.filter(item => item.name === '数据表')
  }
  if (title === '付费率' || title === '流失用户回归率') {
    return arr.filter(item => item.name === '数据表' || item.name === '趋势图')
  }
  if (
    title === '有效活跃用户数(按渠道)' ||
    title === '首充购买情况' ||
    title === '活跃用户生命周期构成'
  ) {
    return arr
  }
  if (
    title === '充值用户周累充分布' ||
    title === '周登录天数分布' ||
    title === '在线时间分布'
  ) {
    const newArr = [
      {
        iconName: 'icon-biaoge',
        name: '数据表',
        type: 'table',
      },
      {
        iconName: 'icon-duijimianji',
        name: '数值分布',
        type: 'numerical',
      },
      {
        iconName: 'icon-duijimianjitu',
        name: '百分比分布',
        type: 'percentage',
      },
    ]
    return newArr
  }
  return arr.filter(item => item.name !== '饼状分布')
}

/* 7天前 截至日期是当天 */
export const getDefaultRangeTime = [
  moment().subtract('week', 1).format('YYYY-MM-DD'),
  moment().format('YYYY-MM-DD'),
]

export const getDefaultMoment = {
  endDate: moment().format('YYYY-MM-DD'),
  endDateType: 2,
  otherDates: '',
  startDate: moment().subtract('week', 1).format('YYYY-MM-DD'),
  startDateType: 2,
}

/* 避免百分比科学计算 */
export const parsePercentFloatNum = num =>
  parseFloat((num * 100).toPrecision(12)).toFixed(2)
export const parseFloatNum = num => parseFloat(num.toPrecision(12))

// 定义url字符串拼接的方法
const setUrlQuery = options => {
  const { url, query } = options
  let resultUrl = url
  if (!url) return ''
  if (query) {
    const queryArr = []
    // eslint-disable-next-line array-callback-return
    Object.keys(query).map(item => {
      const value = query[item]
      if (value) {
        queryArr.push(`${item}=${value}`)
      }
    })
    if (resultUrl.indexOf('?') !== -1) {
      resultUrl = `${resultUrl}&${queryArr.join('&')}`
    } else {
      resultUrl = `${resultUrl}?${queryArr.join('&')}`
    }
  }
  return resultUrl
}

/* 下载文件 */
export const downloadFile = (url, query, name) => {
  const resultUrl = setUrlQuery({ url, query })
  const token = localStorage.getItem('appToken')
  const xhr = new XMLHttpRequest()
  // GET请求,请求路径url,async(是否异步)
  xhr.open('GET', resultUrl, true)
  // 设置请求头参数的方式
  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
  // 设置响应类型为 blob
  xhr.responseType = 'blob'
  // 关键部分
  xhr.onload = function (e) {
    // 如果请求执行成功
    if (this.status === 200) {
      let filename = ''
      let downloadTitle = xhr
        .getResponseHeader('Content-Disposition')
        .split(';')[1]
        .split('=')[1]
      filename = decodeURI(downloadTitle).split('-')
      const blob = this.response
      const a = document.createElement('a')
      // 创键临时url对象
      const fileUrl = URL.createObjectURL(blob)
      // 创建下载链接
      a.href = fileUrl
      a.download = `${name}_${filename[1]}` // 创建下载名
      a.click()
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(fileUrl)
    }
  }
  // 发送请求
  xhr.send()
}

export const downloadPostFile = (url, query, name, callback) => {
  const token = localStorage.getItem('appToken')
  const xhr = new XMLHttpRequest()
  xhr.open('POST', url, true)
  xhr.setRequestHeader('content-type', 'application/json')
  xhr.setRequestHeader('Accept', 'application/json, text/plain, */*')
  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
  xhr.responseType = 'blob' // 返回类型blob
  // 关键部分
  xhr.onload = function (e) {
    // 如果请求执行成功
    if (this.status === 200) {
      // let filename = ''
      // let downloadTitle = xhr
      //   .getResponseHeader('Content-Disposition')
      //   .split(';')[1]
      //   .split('=')[1]
      // filename = decodeURI(downloadTitle).split('-')
      const blob = this.response
      const a = document.createElement('a')
      // 创键临时url对象
      const fileUrl = URL.createObjectURL(blob)
      // 创建下载链接
      a.href = fileUrl
      a.download = `${name}` // 创建下载名
      a.click()
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(fileUrl)
    }
    if (callback) {
      callback(this.status)
    }
  }
  xhr.send(JSON.stringify(query))
}

/* 图片前缀 */
export const imgUrl = process.env.REACT_APP_ManageCenterImg

export const passwordLevel = value => {
  let note = ''
  let str = ''
  // 密码为六位及以上并且大小写字母数字特殊字符三项都包括
  const strongRegex = /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\W).*$/g
  // 密码为六位及以上并且大小写字母、数字、特殊字符三项中有两项，强度是中等
  const mediumRegex =
    /^(?=.{6,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*\W))|((?=.*[0-9])(?=.*\W))|((?=.*[A-Z])(?=.*\W))).*$/g
  const enoughRegex = /(?=.{6,}).*/g
  if (strongRegex.test(value)) {
    str = '强'
    note = '建议使用大小写字母、数字、符号两种类型组合的密码，长度至少为6位。'
  } else if (mediumRegex.test(value)) {
    // console.log('中等密码-----',value)
    note = '建议使用大小写字母、数字、符号两种类型组合的密码，长度至少为6位。'
    str = '中'
  } else if (enoughRegex.test(value)) {
    str = '弱'
    note = '建议使用大小写字母、数字、符号两种类型组合的密码，长度至少为6位。'
  }
  return { str, note }
}

/* 手机号码处理 */
export const geTel = tel =>
  `${tel.substring(0, 3)}****${tel.substr(tel.length - 4)}`

/* 验证手机号码 */
export const checkPhoneNum = val => {
  const reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
  return reg.test(val)
}

/* 验证邮箱地址 */
export const checkEmail = val => {
  const regEmail =
    /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  return regEmail.test(val)
}
/* 邮箱地址处理 */
export const hideEmailInfo = email => {
  const splitted = email.split('@')
  let part1 = splitted[0]
  const avg = part1.length / 2
  part1 = part1.substring(0, part1.length - avg)
  const part2 = splitted[1]
  return `${part1}...@${part2}`
}

/* 清除缓存信息 */
export const clearLocalData = () => {
  localStorage.removeItem('appToken')
  localStorage.removeItem('copyId')
}

/* 复制功能 */
export const handleCopy = val => {
  const oInput = document.createElement('input')
  oInput.value = val
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  message.success('复制成功')
}

export function getDistanceDays(date1, date2) {
  const date1TimeStamp = new Date(date1).getTime()
  const date2TimeStamp = new Date(date2).getTime()
  return (date2TimeStamp - date1TimeStamp) / (24 * 60 * 60 * 1000)
}

/**
 * @description: 下载表格成csv
 * @param {*} header 表头
 * @param {*} data 表格内容
 * @param {*} name 表名
 * @return {*}
 */
export const ExportCsv = (header, data, name) => {
  const fileName = `${name}.csv`
  CsvExport.downloadCsv(data, { header: header }, fileName)
}

/**
 * @description: 获取日期的后缀
 * @param {*} byTime 时间的格式 按天/按周/
 * @param {*} date 日期
 * @return {*} text
 */
export const getDateText = (byTime, date) => {
  let unitTime = ''
  switch (byTime) {
    case 'byDay':
      unitTime = `(${moment(date).format('dd')})`
      break
    case 'byWeek':
      unitTime = '当周'
      break
    case 'byMonth':
      unitTime = '当月'
      break
    case 'byQuarter':
      unitTime = '季度'
      break
    case 'byYear':
      unitTime = '年'
      break
    default:
      ''
      break
  }
  return unitTime
}

/* 获取类型图标 */
export const getTypeIcon = (text, getTypeArr) => {
  if (!getTypeArr.length) {
    return <span>{text}</span>
  }
  const iconItem = getTypeArr.find(itm => itm.dataType === text)
  return (
    <IconSpan>
      <MyIcon
        type={iconItem.icon}
        scriptUrl={iconItem.iconPath}
        className="icon"
      />
      {text}
    </IconSpan>
  )
}

/**
 * @description: 导出生成Csv
 * downLoadHead 表头
 * downLoadDataSource 表格数据
 * xData 时间轴
 * boardTitle 表明
 * @return {*}
 */
export const handleExportCsv = ({
  downLoadHead,
  downLoadDataSource,
  xData,
  boardTitle,
}) => {
  let header = downLoadHead.map(item => item.title)
  let tableData = JSON.parse(JSON.stringify(downLoadDataSource))
  let newData = []
  tableData.map(e => {
    let newE = {}
    Object.values(e).forEach((it, idx) => {
      if (it.indexOf('%') !== -1) {
        const val = it.slice(0, -1)
        const newVal = parsePercentFloatNum(val)
        newE = {
          ...newE,
          [Object.keys(e)[idx]]: `${newVal}%`,
        }
      } else {
        newE = {
          ...newE,
          [Object.keys(e)[idx]]: it,
        }
      }
      delete newE.key
    })
    return newData.push(newE)
  })
  const startTime = xData.slice(0)[0]
  const endTime = xData.slice(-1)[0]
  const date = `${startTime}-${endTime}`
  const name = `${boardTitle}_${date}`
  ExportCsv(header, newData, name)
}
/**
 * @description:  单位转换
 * @param {*} num
 * @return {*}
 */
export function formatNumber(num) {
  num = Number(num)
  if (!num && num !== 0) {
    return {
      value: '-',
      suffix: '',
    }
  }
  if (num < 10000) {
    return {
      value: `${num}`,
      suffix: '',
    }
  }
  return {
    value: `${parseFloatNum(num / 10000)}`,
    suffix: '万',
  }
}

let oldTime = 0
export const debounceHook = (fn, times = 500) => {
  let newTime = new Date().getTime()
  if ((oldTime && newTime - oldTime > times) || !oldTime) {
    oldTime = newTime
    fn()
  }
}
