import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { getFunnelData } from '@/http/businessAnalysis/customAnalysis/funnel'
import {
  extendCondition,
  fieldCollectionList,
  filterList,
  collectionList,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  getAttribute,
  getEventList,
} from '@/http/businessAnalysis/customAnalysis/events'

interface ListItem {
  indexName: string
  checked: boolean
  id?: number
}

interface IAnalysisFunnel {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  eventConditionDatumList: ExtendConditionDatum[]
  stepConditionDatumList: ExtendConditionDatum[][]
  fieldCollectionList: ExtendConditionDatum
  groupFieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  groupDataList: GroupItem[]
  indicatorsData: any[]
  groups: string[]
  frozenGroups: ListItem[]
  groupConditionDatumList: ExtendConditionDatum[]
  funnelAnalysisDatas: FunnelData | null
  eventList: EventDatum | null
  sessionDuration: {
    sessionInterval: number
    sessionType: string
  }
  selRelateItem: any | null
}

const initialState: IAnalysisFunnel = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 属性列表
  eventConditionDatumList: [], // 事件属性列表
  stepConditionDatumList: [], // 漏斗步骤属性列表
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  filterList: [], // 条件列表
  groups: [], // 分组
  frozenGroups: [], // 不会被改变的分组
  groupDataList: [], // 分组项
  groupConditionDatumList: [], // 分组项的属性列表
  indicatorsData: [],
  groupFieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 分组项的属性列表
  conditionData: null, // 指标筛选
  funnelAnalysisDatas: {
    x: [],
    y: [],
    z: [],
  },
  eventList: null, // 事件列表
  sessionDuration: {
    sessionInterval: 30,
    sessionType: '分',
  },
  selRelateItem: null,
}

/* 获取属性列表 */
export const getExtendCondition = createAsyncThunk(
  'analysisFunnel/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取属性列表 */
export const getEventExtendCondition = createAsyncThunk(
  'analysisFunnel/getEventExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 全局筛选 */
/* 获取属性列表 */
export const stepExtendCOndition = createAsyncThunk(
  'analysisFunnel/stepExtendCOndition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'analysisFunnel/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 获取收藏列表 */
export const getCollectionList = createAsyncThunk(
  'analysisFunnel/getCollectionList',
  async (params: ExploreHistoryListProps) => {
    const result = await collectionList(params)
    return result.data
  }
)
/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'analysisFunnel/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)
/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'analysisFunnel/getLoadPropQuotasFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)
/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'analysisFunnel/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)

export const getAttributeCollection = createAsyncThunk(
  'analysisFlows/getAttributeCollection',
  async (params: any) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

export const getFunnelDataQuery = createAsyncThunk(
  'analysisFunnel/getFunnelDataQuery',
  async (params: any) => {
    const res = await getFunnelData({ ...params })
    return res.data
  }
)
export const analysisFunnelSlice = createSlice({
  name: 'analysisFunnel',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisFunnel; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
    resetInitialState: state => (state = initialState),
  },

  extraReducers: builder => {
    builder.addCase(getFunnelDataQuery.fulfilled, (state, action) => {
      state.funnelAnalysisDatas = action.payload
    })
    // 分组项和全局筛选属性列表
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    // 事件属性列表
    builder.addCase(getEventExtendCondition.fulfilled, (state, action) => {
      state.eventConditionDatumList = action.payload
    })
    // 获取收藏列表
    builder.addCase(getCollectionList.fulfilled, (state, action) => {
      console.log('action.payload', action.payload)
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      console.log('getLoadPropQuotasFunc', action.payload)
    })
  },
})

export const analysisFunnelState = (state: RootState) => state.analysisFunnel
export default analysisFunnelSlice.reducer
export const { setterStoreState, initGameIdAndUser, resetInitialState } =
  analysisFunnelSlice.actions
