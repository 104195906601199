/* eslint-disable indent */
import React, { useRef, useMemo, useCallback, CSSProperties } from 'react'
import { Carousel } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import TrendChart from '@/components/Echarts/TrendChart'
import DistributionGroupChart from '@/components/Echarts/DistributionGroupChart'
import PieChart from '@/components/Echarts/PieChart'
import { PieWrapper, LeftIcon, Title, RightIcon, Wrapper } from './styles'

interface Props {
  lineSeries: IBasicTrendList[]
  distrGroupData: IBasicDistributionGroup
  pieData: PieChart[][]
  xData: string[]
  indicators: string[]
  frozenIndicators: string[]
  groups: string[]
  frozenGroups: string[]
  toggleType: string
  styles: CSSProperties
  byTime: string
  showChartLabel?: boolean
}

function EchartsCom(props: Props) {
  const {
    lineSeries,
    distrGroupData, // 柱状组
    xData,
    indicators,
    frozenIndicators,
    groups,
    styles,
    toggleType,
    byTime,
    pieData,
    frozenGroups,
    showChartLabel = false,
  } = props

  const pieRef = useRef<any>(null)

  const echartsType = useMemo(() => {
    const trendArr = ['trend', 'cumulative', 'numerical', 'percentage'] // 折线图
    if (trendArr.includes(toggleType)) {
      return 'trendChart'
    }
    // 要区分 柱状组/单柱状
    if (toggleType === 'distribution') {
      // @ts-ignore
      if (distrGroupData) {
        return 'distributionGroupChart'
      }
      return 'distributionMapChart'
    }
    if (toggleType === 'pie') {
      return 'pieChart'
    }
  }, [props])

  const getTrendChartData = (series: IBasicTrendList[]) => {
    let seriesData: any[] = series
    // 指标过滤
    if (frozenIndicators.length) {
      const arr: any[] = []
      seriesData.forEach(item => {
        if (indicators.length) {
          if (indicators.some(it => item.name.indexOf(it) > -1)) {
            if (frozenGroups.length) {
              if (groups.some(it => item.name.indexOf(it) > -1)) {
                arr.push(item)
              }
            } else {
              arr.push(item)
            }
          }
        }
      })
      seriesData = arr
    }
    //  百分比 颜色
    if (toggleType === 'percentage') {
      return seriesData.map(item => ({
        ...item,
        areaStyle: {},
        emphasis: {
          focus: 'series',
        },
      }))
    }
    return seriesData
  }

  const distributionData = () => {
    if (!distrGroupData) return []
    let productArr = groups
    const groupingArr: any[][] = []
    // grouping 跟指标挂钩
    distrGroupData.grouping.forEach(item => {
      const key = Object.keys(item)[0]
      const itemGroup = Object.values(item)[0]
      if (frozenIndicators.length) {
        const arr = []
        if (indicators.includes(key)) {
          arr.push(key)
          itemGroup.forEach((gItem: [string, number]) => {
            if (frozenGroups.length) {
              if (groups.includes(gItem[0])) {
                arr.push(gItem[1])
              }
            } else {
              arr.push(gItem[1])
            }
          })
        }
        groupingArr.push(arr)
      }
    })
    return [['product', ...productArr], ...groupingArr]
  }

  const getPieData = () => {
    const pieDataArr = [] as any[]
    pieData.forEach(itemArr => {
      const arrPie = [] as any[]
      // @ts-ignore
      if (indicators.includes(itemArr[0].title)) {
        itemArr.forEach(itm => {
          if (frozenGroups.length) {
            if (groups.includes(itm.name)) {
              arrPie.push(itm)
            }
          }
        })
        pieDataArr.push(arrPie)
      }
    })
    return pieDataArr
  }

  const getSeriesData = useMemo(() => {
    switch (toggleType) {
      case 'trend':
      case 'cumulative':
      case 'numerical':
      case 'percentage':
        return getTrendChartData(lineSeries!)
      case 'distribution':
        return distributionData()
      case 'pie':
        return getPieData()
      default:
        return null
    }
  }, [props])

  // console.log('z最终输出的数据', echartsType, toggleType, getSeriesData)

  const TrendCom = useCallback(
    () => (
      <TrendChart
        series={getSeriesData!}
        xData={xData}
        styles={styles}
        isExplore
        byTime={byTime}
      />
    ),
    [getSeriesData]
  )
  const DistributionGroupChartCom = useCallback(
    () => (
      <DistributionGroupChart
        series={getSeriesData!}
        styles={styles}
        showChartLabel={showChartLabel}
      />
    ),
    [getSeriesData]
  )

  return (
    <>
      {echartsType === 'trendChart' && <TrendCom />}
      {/* 分布图 多组别 柱状图 */}
      {echartsType === 'distributionGroupChart' && (
        <DistributionGroupChartCom />
      )}
      {echartsType === 'pieChart' && (
        <PieWrapper>
          {getSeriesData!.length > 1 && (
            <LeftIcon onClick={() => pieRef.current.prev()}>
              <LeftOutlined />
            </LeftIcon>
          )}
          <Carousel ref={pieRef}>
            {getSeriesData!.map((item: any, index) => (
              <Wrapper key={index}>
                <Title>{item.length ? item[0].title : ''}</Title>
                <PieChart series={item || []} styles={styles} />
              </Wrapper>
            ))}
          </Carousel>
          {getSeriesData!.length > 1 && (
            <RightIcon onClick={() => pieRef.current.next()}>
              <RightOutlined />
            </RightIcon>
          )}
        </PieWrapper>
      )}
    </>
  )
}

export default React.memo(EchartsCom)
