import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userIntroducer from './userInfo'
import businessAnalysis from './businessAnalysis'
import manageCenter from './manageCenter'
import downLoad from './downLoad'
import exploreBoard from '@/pages/BusinessAnalysis/ExploreBoard/redux/index'
import sequenceDetail from '@/pages/BusinessAnalysis/SequenceDetail/redux/index'
import analysisEvent from '@/pages/BusinessAnalysis/CustomAnalysis/Events/redux/index'
import analysisInterval from '@/pages/BusinessAnalysis/CustomAnalysis/Interval/redux/index'
import analysisDistribution from '@/pages/BusinessAnalysis/CustomAnalysis/Distribution/redux/index'
import analysisFunnel from '@/pages/BusinessAnalysis/CustomAnalysis/Funnel/redux/index'
import analysisFlow from '@/pages/BusinessAnalysis/CustomAnalysis/Flows/redux/index'
import userAnalysisAttribute from '@/pages/BusinessAnalysis/UserAnalysis/Attribute/redux/index'
import customAnalysisRetention from '@/pages/BusinessAnalysis/CustomAnalysis/Retention/redux/index'
import createTagGroup from '@/pages/BusinessAnalysis/UserAnalysis/components/CreateTagGroup/redux/index'
import boardStore from './BoardStore'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'exploreBoard',
    'payBoard',
    'activeBoard',
    'sequenceDetail',
    'analysisEvent',
    'analysisInterval',
    'analysisDistribution',
    'analysisFunnel',
    'boardStore',
    'userAnalysisAttribute',
    'analysisFlow',
  ],
}
export const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    userInfo: userIntroducer,
    downLoad,
    manageCenter,
    exploreBoard,
    analysisEvent,
    sequenceDetail,
    analysisInterval,
    analysisDistribution,
    analysisFunnel,
    analysisFlow,
    userAnalysisAttribute,
    businessAnalysis,
    customAnalysisRetention,
    createTagGroup,
    boardStore,
  })
)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
