import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function NearlyDays() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const grouping = [
    {
      dataType: '文本',
      dataTypeEn: 'text',
      descIcon: 'icon-wenben',
      descText: '预制属性',
      hasOption: false,
      id: 0,
      index: 0,
      infoData: {
        attributeName: 'uid',
        attributeType: '预制属性',
        createUser: '',
        dataType: '文本',
        displayName: '账号ID',
        id: 32,
        labelId: '32',
        number: 0,
        remarks: '',
        subject: '',
        timeZone: '',
        updateTime: '',
      },
      isCollection: false,
      isHistory: false,
      label: '账号ID',
      labelEn: 'uid',
      type: 1,
      value: [],
      valueType: 1,
    },
  ]
  const props = {
    modular: 'accumulatedRanking',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'nearlyDays',
    boardTitle: '近7日累充排名',
    toggleType: 'table',
    checkType: '日期',
    toolBarLeftProps: {
      needIndexSelector: false,
      needDropDownSelector: true,
      needDatePickerSelector: true,
    },
    grouping,
    tips: '根据账户ID进行分组，计算最近7日充值金额的排名',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
