/*

  数值 const menuList =

  文本 const menuList =

  布尔 const menuList =

  时间 const menuList =

对象组  const menuList =

列表 const menuList =

*/

/*
  数值
   等于 不等于 值不变
   小于 小等 大于 大等 不变
   区间 默认值为 0 到 1
   其余改变
*/
const handleNumber = (oldFilter: any, newFilter: any) => {
  if (
    (oldFilter.label === '等于' && newFilter.label === '不等于') ||
    (oldFilter.label === '不等于' && newFilter.label === '等于')
  ) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  const sameArr = ['小于', '小于等于', '大于', '大于等于']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  if (newFilter.label === '区间') {
    return {
      ...newFilter,
      value: '0, 1',
    }
  }
  return {
    ...newFilter,
    value: null,
  }
}

/*
 时间
*/
const handleTime = (oldFilter: any, newFilter: any) => {
  const sameArr = ['小于等于', '大于等于']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  if (newFilter.label === '相对当前日期') {
    return {
      ...newFilter,
      value: '-1, 1',
    }
  }
  if (newFilter.label === '相对事件发生时刻') {
    return {
      ...newFilter,
      value: '-1D, 1D',
    }
  }
  return {
    ...newFilter,
    value: null,
  }
}

/*
  文本
   等于 不等于 值不变
   包括 不包括 不变
   其余改变
*/
const handleText = (oldFilter: FilterList, newFilter: FilterList) => {
  const equalArr = ['等于', '不等于']
  if (
    equalArr.includes(oldFilter.label) &&
    equalArr.includes(newFilter.label)
  ) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  const sameArr = ['包括', '不包括']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  return {
    ...newFilter,
    value: null,
  }
}

/* 布尔 */
const handleBoolean = (oldFilter: FilterList, newFilter: FilterList) => {
  const sameArr = ['为真', '为假']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: newFilter.label === '为真',
    }
  }
  return {
    ...newFilter,
    value: null,
  }
}

/* 对象 */
const handleObj = (oldFilter: FilterList, newFilter: FilterList) => ({
  ...newFilter,
  value: null,
})

/* 对象组 */
const handleObjArr = (oldFilter: FilterList, newFilter: FilterList) => ({
  ...newFilter,
  value: null,
})

/* 列表 */
const handleList = (oldFilter: FilterList, newFilter: FilterList) => {
  const sameArr = ['存在位置', '不存在位置']
  if (sameArr.includes(oldFilter.label) && sameArr.includes(newFilter.label)) {
    return {
      ...newFilter,
      value: oldFilter.value,
    }
  }
  if (newFilter.label === '元素位置') {
    return {
      ...newFilter,
      value: `1,等于,${''}`,
    }
  }
  return {
    ...newFilter,
    value: null,
  }
}

/*
先观察Condition类型
*/
export const getNewFilter = (item: ListItem, newFilter: FilterList) => {
  const { condition, filter } = item
  // console.log('condition.type', condition.dataType)
  switch (condition.dataType) {
    case '数值':
      return handleNumber(filter, newFilter)
    case '文本':
      return handleText(filter, newFilter)
    case '时间':
      return handleTime(filter, newFilter)
    case '布尔':
      return handleBoolean(filter, newFilter)
    case '对象':
      return handleObj(filter, newFilter)
    case '对象组':
      return handleObjArr(filter, newFilter)
    case '列表':
      return handleList(filter, newFilter)
    default:
      return null
  }
}
