import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function ArppuBoard() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'ARRPU',
    exploreType: 'cordBoard',
    boardType: 'coreExploreBoard',
    dataType: 'arpu',
    boardTitle: 'ARPU与ARPPU',
    toggleType: 'trend',
    showTop: true,
    tips: 'ARPU与ARPPU使用了公式：当天的"充值.充值金额.总和"/当天的"登录.触发用户数"（即活跃用户）或当天的"充值.触发用户数"（即充值用户）',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
