import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 220px;
  /* height: ; */
`
export const TimeWrapper = styled.div`
  // width: 100px;
  height: 17px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  // color: pink;
`

export const CountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  .count {
    max-width: 158px;
    height: 53px;
    font-size: 40px;
    font-family: HelveticaNeue-, HelveticaNeue;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);
    line-height: 53px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .unit {
    margin-left: 6px;
    max-width: 38px;
    height: 24px;
    font-size: 18px;
    font-family: HelveticaNeue-, HelveticaNeue;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
  .ant-statistic-content-value-int {
    font-size: 40px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-statistic-content-suffix {
    margin-left: 12px;
    font-size: 18px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.85);
  }
`
export const TabWrapper = styled.div`
  height: 70px;
  margin-bottom: 16px;
`
export const TotalWrapper = styled.div``

export const TextWrapper = styled.div`
  height: 17px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  display: flex;
  align-items: center;
`

interface IColorWrapper {
  colorIndex: number
}
export const ColorWrapper = styled.div<IColorWrapper>`
  width: 13px;
  height: 13px;
  background: #f98349;
  border-radius: 50% 50%;
  margin-right: 6px;
  background: ${({ colorIndex }) =>
    // eslint-disable-next-line no-nested-ternary
    colorIndex === 0 ? '#34cdc5' : colorIndex === 1 ? '#219af2' : '#a9def4'};
`
