import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  getEventList,
  getAttribute,
  getEntitiesList,
} from '@/http/businessAnalysis/customAnalysis/events'
import { getExtendConditions } from '@/http/businessAnalysis/customAnalysis/flows'
import {
  fieldCollectionList,
  filterList,
} from '@/http/businessAnalysis/exploreBoard/condition'

export interface InitialState {
  eventList: EventDatum
  iconPath: string
  fieldCollectionList: ExtendConditionDatum
  conditionDatumList: ExtendConditionDatum[]
  startConditionDatumList: ExtendConditionDatum[]
  endConditionDatumList: ExtendConditionDatum[]
  conditionEventDatumList: ExtendConditionDatum[]
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  pieConditionData: ConditionList | null
  attributeList: EventAttListData | null
  backConditionData: ConditionList | null
  entitiesList: EntitiesDatum[]
  indicatorsData: EventIndicatorsData[]
  splitEventNameArr: string[]
  splitEventGroupList: GroupItem[]
}

export const initialState: InitialState = {
  eventList: {
    meta: [],
    explore: [],
  },
  iconPath: '', // iconfont 地址
  filterList: [], // 条件列表
  fieldCollectionList: {
    // 且用户符合收藏
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  conditionDatumList: [], // 用户属性列表
  conditionData: null, // 全局筛选数据
  pieConditionData: null, // 初始事件选择数据
  backConditionData: null, // 回访事件选择事件属性
  startConditionDatumList: [], // 初始事件属性
  endConditionDatumList: [], // 回访事件
  conditionEventDatumList: [], // 事件属性列表
  entitiesList: [], // 实体列表
  attributeList: null, // 属性指标
  indicatorsData: [], // 分析指标数据
  splitEventNameArr: [], //  勾选的事件拆分项
  splitEventGroupList: [], // 事件拆分 的 分组项
}

/* 初始事件属性 */
export const getStartExtendEventCondition = createAsyncThunk(
  'customAnalysisRetention/getStartExtendEventCondition',
  async (params: ExtendConditionProps) => {
    const result = await getExtendConditions(params)
    return result.data
  }
)

/* 回访事件属性 */
export const getBackExtendEventCondition = createAsyncThunk(
  'customAnalysisRetention/getBackExtendEventCondition',
  async (params: ExtendConditionProps) => {
    const result = await getExtendConditions(params)
    return result.data
  }
)

/* 事件属性列表 */
export const getExtendEventCondition = createAsyncThunk(
  'customAnalysisRetention/getExtendEventCondition',
  async (params: ExtendConditionProps) => {
    const result = await getExtendConditions(params)
    return result.data
  }
)

/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'customAnalysisRetention/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'customAnalysisRetention/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'customAnalysisRetention/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 获取属性列表 */
export const getExtendCondition = createAsyncThunk(
  'customAnalysisRetention/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await getExtendConditions(params)
    return result.data
  }
)

/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'customAnalysisRetention/getAttributeFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)

/* 实体列表 */
export const getEntitiesListFunc = createAsyncThunk(
  'analysisDistribution/getEntitiesListFunc',
  async (gameId: string) => {
    const result = await getEntitiesList({ gameId })
    return result.data
  }
)

export const CustomAnalysisRetention = createSlice({
  name: 'customAnalysisRetention',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof InitialState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
  },

  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })

    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getStartExtendEventCondition.fulfilled, (state, action) => {
      state.startConditionDatumList = action.payload
    })

    builder.addCase(getBackExtendEventCondition.fulfilled, (state, action) => {
      state.endConditionDatumList = action.payload
    })

    builder.addCase(getExtendEventCondition.fulfilled, (state, action) => {
      state.conditionEventDatumList = action.payload
    })

    /* 加载属性指标 */
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      state.attributeList = action.payload
    })
    /* 实体列表 */
    builder.addCase(getEntitiesListFunc.fulfilled, (state, action) => {
      state.entitiesList = action.payload
    })
  },
})

export const customAnalysisRetention = (state: RootState) =>
  state.customAnalysisRetention

export default CustomAnalysisRetention.reducer

export const { setterStoreState, initGameIdAndUser } =
  CustomAnalysisRetention.actions
