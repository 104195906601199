import moment from 'moment'
import {
  payPanelExplore,
  coreBoardExplore,
  activeBoardExplore,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  handleRetainedBoard,
  handleNoZ,
  handleZ,
} from '@/pages/BusinessAnalysis/ExploreBoard/Body/common'
import { handleRetained } from './handleBoardData'
import { parsePercentFloatNum, parseFloatNum } from '@/utiles/common'

export const getPayPanelExplore = async props => {
  const result = await payPanelExplore(props)
  if (result.code === 0) {
    return {
      ...result.data,
      error: false,
    }
  }
  return {
    error: true,
  }
}
export const getCoreBoardExplore = async props => {
  const result = await coreBoardExplore(props)
  if (result.code === 0) {
    return {
      ...result.data,
      error: false,
    }
  }
  return {
    error: true,
  }
}
export const getActiveBoardExplore = async props => {
  const result = await activeBoardExplore(props)
  if (result.code === 0) {
    return {
      ...result.data,
      error: false,
    }
  }
  return {
    error: true,
  }
}

export const getBoardData = async props => {
  const { timeRange, byTime, boardTitle } = props

  const exploreParams = {
    ...props,
    startDate: timeRange[0],
    endDate: timeRange[1],
    byTime: byTime,
  }
  let retainedCount = 0
  let byTimeText = ''
  const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
  if (needRetainedCountTitle.includes(boardTitle)) {
    const frozenByDate = props.byDate
    // 当日 当周 当月
    if (frozenByDate.indexOf('Day') > 0) {
      byTimeText = 'byDay'
      retainedCount = Number(frozenByDate.slice(0, -3))
    }
    if (frozenByDate.indexOf('Month') > 0) {
      byTimeText = 'byMonth'
      retainedCount = Number(frozenByDate.slice(0, -5))
    }
    if (frozenByDate.indexOf('Week') > 0) {
      byTimeText = 'byWeek'
      retainedCount = Number(frozenByDate.slice(0, -4))
    }
    if (frozenByDate.indexOf('current') === 0) {
      retainedCount = 0
    }
    if (frozenByDate.indexOf('next') === 0) {
      retainedCount = 1
    }
  }
  if (needRetainedCountTitle.includes(props.boardTitle)) {
    const resultProps = {
      ...exploreParams,
      retainedCount,
      byTime: byTimeText,
      // retainedCount: 1,
      // byTime: 'byDay',
    }
    if (props.exploreType === 'payBoard') {
      const result = await getPayPanelExplore(resultProps)
      return result
    }
    if (props.exploreType === 'cordBoard') {
      const result = await getCoreBoardExplore(resultProps)
      return result
    }
    if (props.exploreType === 'activeBoard') {
      const result = await getActiveBoardExplore(resultProps)
      return result
    }
  } else {
    if (props.exploreType === 'payBoard') {
      const result = await getPayPanelExplore(exploreParams)
      return result
    }
    if (props.exploreType === 'cordBoard') {
      const result = await getCoreBoardExplore(exploreParams)
      return result
    }
    if (props.exploreType === 'activeBoard') {
      const result = await getActiveBoardExplore(exploreParams)
      return result
    }
  }
}

const getDescDataList = ({
  lastDate,
  ringRatio,
  ringRatioDate,
  yearOnYear,
  trendData,
  distrGroup,
  cumulativeData,
  totalNumber,
  xLen,
}) => {
  // console.log('trendData', trendData)
  const descTrend = trendData.map(item => ({
    label: item.name,
    value: item.data[item.data.length - 1],
    unit: item.unit,
    indicatorsName: item.indicatorsName,
  }))
  const descDistribution = distrGroup?.grouping.map(item => {
    const valueArr = Object.values(item)[0]
      .flat()
      .filter(it => typeof it === 'number')
    const total = valueArr.reduce((prev, curr) => prev + curr, 0)
    const name = Object.keys(item)[0]
    return {
      label: name,
      value: total,
      unit: '',
      indicatorsName: name,
    }
  })
  const descCumulative = cumulativeData.map(item => ({
    label: item.name,
    value: item.data[item.data.length - 1],
    unit: '',
    indicatorsName: item.indicatorsName,
  }))
  let ringRatioText = '' // 环比
  let yearOnYearText = '' //  同比
  if (ringRatio) {
    if (ringRatio * 1 > 0) {
      const percentage = parsePercentFloatNum(ringRatio * 1)
      ringRatioText = `对比${ringRatioDate}增长了${percentage}%`
    } else {
      const percentage = parsePercentFloatNum(ringRatio * -1)
      ringRatioText = `对比${ringRatioDate}下降了${percentage}%`
    }
  }
  if (yearOnYear) {
    const yearDate = moment(lastDate).subtract(7, 'd').format('YYYY-MM-DD')
    if (yearOnYear * 1 > 0) {
      const percentage = parsePercentFloatNum(yearOnYear * -1)
      yearOnYearText = `对比${yearDate}增长了${percentage}%`
    } else {
      const percentage = parsePercentFloatNum(yearOnYear * -1)
      yearOnYearText = `对比${yearDate}下降了${percentage}%`
    }
  }

  const rate = {
    date: lastDate,
    rateNumber: trendData[0].percentValues.slice(-1)[0],
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
    countNumber: trendData[0].data.slice(-1)[0] * 1,
    totalNumber: Number(totalNumber) || 0,
    averageNumber: totalNumber
      ? parseFloatNum(totalNumber / xLen).toFixed(2)
      : 0,
    indicatorsName: trendData[0].indicatorsName,
  }
  const data = {
    trend: descTrend,
    distribution: descDistribution,
    cumulative: descCumulative,
    numerical: descTrend,
    percentage: descCumulative.map(item => ({
      ...item,
      unit: '%',
    })),
    rate,
    pie: descCumulative,
  }
  return data
}

export const processBoardData = props => {
  const {
    exploreData,
    boardTitle,
    byTime,
    clickShowTitle,
    handleNavigate,
    handleCreateModal,
  } = props
  const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
  // 第一个是留存 第二个是流失
  if (needRetainedCountTitle.includes(boardTitle)) {
    const result = handleRetainedBoard(exploreData)
    return {
      columns: [],
      dataSource: [],
      indicators: [],
      frozenIndicators: [],
      groups: [],
      frozenGroups: [],
      xData: result.xData,
      modalTableHead: [],
      exploreData,
      descDataList: null,
    }
  }
  // 无Z轴 处理所有的图形
  if (!exploreData.z.length) {
    const result = handleNoZ(
      exploreData,
      byTime,
      handleNavigate,
      handleCreateModal,
      boardTitle
    )
    const descDataList = getDescDataList({
      trendData: result.trendData,
      distrGroup: result.distrGroup,
      cumulativeData: result.cumulativeData,
      ringRatio: exploreData.ringRatio,
      yearOnYear: exploreData.yearOnYear,
      ringRatioDate: exploreData.x.slice(-2)[0],
      lastDate: exploreData.x.slice(-1)[0],
      totalNumber: Object.values(exploreData.y[0])[0][0].totalNum,
      xLen: exploreData.x.length,
    })
    return {
      columns: result.tableHead,
      dataSource: result.dataSource,
      xData: result.xData,
      pieData: result.pieData,
      trendData: result.trendData,
      distrGroupData: result.distrGroup,
      cumulativeData: result.cumulativeData,
      indicators: result.indicators,
      frozenIndicators: result.indicators,
      groups: result.groupsArr,
      frozenGroups: result.groupsArr,
      downLoadDataSource: result.downLoadDataSource,
      downLoadHead: result.downLoadHead,
      descDataList,
      exploreData,
    }
  }
  // 有分组项 有 Z
  if (exploreData.z.length) {
    const result = handleZ(
      exploreData,
      byTime,
      boardTitle,
      clickShowTitle,
      handleNavigate,
      handleCreateModal
    )
    const descDataList = getDescDataList({
      trendData: result.trendData,
      distrGroup: result.distrGroup,
      cumulativeData: result.cumulativeData,
      ringRatio: exploreData.ringRatio,
      yearOnYear: exploreData.yearOnYear,
      ringRatioDate: exploreData.x.slice(-2)[0],
      lastDate: exploreData.x.slice(-1)[0],
      // totalNumber: 0.
    })
    return {
      exploreData,
      columns: result.tableHead,
      dataSource: result.dataSource,
      xData: result.xData,
      pieData: result.pieData,
      trendData: result.trendData,
      distrGroupData: null,
      cumulativeData: result.cumulativeData,
      indicators: exploreData.indicators,
      frozenIndicators: exploreData.indicators,
      groups: [],
      frozenGroups: [],
      modalTableHead: result.modalTableHead,
      downLoadDataSource: result.downLoadDataSource,
      downLoadHead: result.downLoadHead,
      descDataList,
    }
  }
}

export const getTableData = props => {
  const {
    needDropDownSelector,
    storeDownLoadDataSource,
    storeDownLoadHead,
    checkType,
    exploreData,
    tableState,
    checkRange,
    boardTitle,
    clickShowTitle,
    handleNavigate,
    handleCreateModal,
  } = props
  // console.log('checkType', checkType)
  let resultDataSource = []
  let resultColumns = []
  let tableLoading = true
  if (needDropDownSelector) {
    if (checkType === '日期') {
      const sameDate = checkRange[0]
      // 取某一天的数据
      resultDataSource = storeDownLoadDataSource.filter(
        item => item.date === sameDate
      )
      resultColumns = storeDownLoadHead.filter(item => item.title !== '日期')
    }
    if (checkType === '阶段汇总') {
      // 阶段汇总
      resultColumns = storeDownLoadHead.filter(item => item.title !== '日期')
      let dataSourceItem = null
      const arr = []
      const { groupLabels, y } = exploreData
      const firstIndicators = Object.values(y[0])[0]
      firstIndicators.forEach(item => {
        dataSourceItem = {
          key: item.groupCols.join(','),
        }
        indicators.forEach(itemType => {
          groupLabels.forEach((it, idx) => {
            dataSourceItem = {
              ...dataSourceItem,
              [it]: item.groupCols[idx],
            }
          })
          dataSourceItem = {
            ...dataSourceItem,
            [itemType]: item.isCalculation
              ? (item.totalNum / item.values.length).toFixed(2)
              : item.totalNum,
          }
        })
        arr.push(dataSourceItem)
      })
      resultDataSource = arr
    }
    if (checkType === '留存' || checkType === '流失') {
      const result = handleRetained(
        exploreData,
        boardTitle,
        checkType,
        clickShowTitle,
        handleNavigate,
        handleCreateModal
      )
      return {
        tableLoading: false,
        tableColumns: result.tableColumn,
        tableDataSource: result.tableDataSource,
        downLoadHead: result.downLoadHead,
        downLoadDataSource: result.downLoadDataSource,
      }
    }
  } else {
    resultDataSource = tableState.tableDataSource
    resultColumns = tableState.tableColumns
  }
  tableLoading = false
  return {
    tableLoading,
    tableDataSource: resultDataSource,
    tableColumns: resultColumns,
  }
}
