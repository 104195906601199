import React, { useState, useEffect, FC, useMemo } from 'react'
import { Input, Checkbox } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import List from 'rc-virtual-list'
import PopoverCondition from '../PopoverCondition'
import {
  Wrapper,
  SearchWrapper,
  SelectedWrapper,
  DividerWrapper,
  SelectAllWrapper,
  CheckBoxWrapper,
  TextWrapper,
  FilterOne,
} from './styles'

interface HandleCheckProps {
  val: string
  type: string
  checked: boolean
}
interface CheckBoxComProps {
  optList: string[]
  indicators: string[]
  isCheckAll: boolean
  isSearching: boolean
  count?: number // 默认不限制条数
  needCheckAll: boolean
  handleCheck: (props: HandleCheckProps) => void
}

interface InpProps {
  handleSearch: (val?: string) => void
  searchWord?: string
}

const InputCom: FC<InpProps> = ({ searchWord, handleSearch }) => {
  const [word, setWord] = useState<string>('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value)
    handleSearch(e.target.value)
  }

  return (
    <SearchWrapper>
      <Input
        allowClear
        placeholder="请输入"
        defaultValue={word}
        onChange={handleChange}
        style={{ width: 210 }}
        addonAfter={<SearchOutlined />}
      />
    </SearchWrapper>
  )
}

const CheckBoxCom: FC<CheckBoxComProps> = ({
  optList,
  isSearching,
  indicators, // 已选中的项
  isCheckAll,
  count,
  needCheckAll,
  handleCheck,
}) => {
  const onChange = (props: any) => handleCheck(props)
  return (
    <SelectedWrapper>
      {/*  不需要 / 无项 / 在查询 就没有全选按钮 */}
      {needCheckAll && !!optList.length && !isSearching && (
        <SelectAllWrapper>
          <CheckBoxWrapper>
            <Checkbox
              checked={isCheckAll}
              onChange={e => {
                onChange({
                  val: '',
                  checked: e.target.checked,
                  type: 'all',
                })
              }}
            >
              全选
            </Checkbox>
          </CheckBoxWrapper>
        </SelectAllWrapper>
      )}
      <List data={optList} height={200} itemKey={item => item}>
        {item => (
          <CheckBoxWrapper key={item}>
            <Checkbox
              onChange={e => {
                onChange({
                  val: item,
                  checked: e.target.checked,
                  type: 'normal',
                })
              }}
              disabled={
                count
                  ? indicators.length >= count && !indicators.includes(item)
                  : false
              }
              checked={indicators.some(val => item === val)}
              value={item}
            >
              <TextWrapper>{item}</TextWrapper>
            </Checkbox>
            <FilterOne
              onClick={() =>
                onChange({
                  val: item,
                  checked: false,
                  type: 'only',
                })
              }
            >
              只看此项
            </FilterOne>
          </CheckBoxWrapper>
        )}
      </List>
    </SelectedWrapper>
  )
}

interface IndexSelectorProps {
  handleChangeIndex: (val: string[]) => void
  indicators: string[]
  frozenList: string[]
  title: string
  count?: number // 默认不限制条数
  needCheckAll?: boolean // 默认需要全选
}

export default function IndexSelector(props: IndexSelectorProps) {
  const {
    handleChangeIndex,
    indicators,
    frozenList,
    title,
    count,
    needCheckAll = true,
  } = props
  const [optList, setOptList] = useState<string[]>(frozenList)
  const [isSearching, setIsSearching] = useState<boolean>(false) // 是否在搜索
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    setOptList(frozenList)
  }, [frozenList])

  const handleSearch = (val: string) => {
    const list = frozenList.filter((item: string) => item.indexOf(val) >= 0)
    setIsSearching(!!val)
    if (val.trim()) {
      setOptList(list)
    } else {
      setOptList(frozenList)
    }
  }

  const RenderInp = useMemo(
    () => <InputCom handleSearch={(val?: string) => handleSearch(val || '')} />,
    [optList]
  )

  const isCheckAll = useMemo(() => {
    /* 是否全选 有搜索 搜索项 == 过滤的(indicators) 无  所有 ==indicators  */
    if (frozenList?.length === optList?.length) {
      return indicators.length === frozenList.length
    }
    const lessIndicators = [] // 过滤后选中的项
    const lessAllName = optList.map(item => item)
    // eslint-disable-next-line array-callback-return
    lessAllName.map(item => {
      if (indicators.some((itm: string) => itm === item)) {
        lessIndicators.push(item)
      }
    })
    return lessIndicators.length === optList.length
  }, [optList, [indicators]])

  const Content = useMemo(
    () => (
      <Wrapper>
        {RenderInp}
        <DividerWrapper />
        <CheckBoxCom
          isCheckAll={isCheckAll}
          indicators={indicators}
          optList={optList}
          isSearching={isSearching}
          count={count}
          needCheckAll={needCheckAll}
          handleCheck={({ val, type, checked }) => {
            /* 分为两种情况  1 搜索 2 不搜索 */
            /* 不搜索 改全局 */
            if (frozenList?.length === optList?.length) {
              const arr = JSON.parse(JSON.stringify(indicators)) as string[]
              /* 只查看一项 */
              if (type === 'only') {
                return handleChangeIndex([val])
              }
              /* 全选 */
              if (type === 'all') {
                if (checked) {
                  const optListArr = optList.map(item => item) // 当前所有
                  return handleChangeIndex(optListArr)
                }
                return handleChangeIndex([])
              }
              /* 选局部 */
              if (type === 'normal') {
                if (checked) {
                  /* 勾选 */
                  arr.push(val)
                  return handleChangeIndex(arr)
                }
                /* 取消 */
                const index = arr.findIndex((itm: string) => itm === val)
                arr.splice(index, 1)
                return handleChangeIndex(arr)
              }
            } else {
              /* 搜索 改局部 */
              const nowCheckedArr = [...indicators] as string[] // 当前所有勾选的项
              const optListArr = optList.map(item => item) // 当前搜索的所有项
              const lessArr = [] as string[] // 除去当前搜索的 选中项
              // eslint-disable-next-line array-callback-return
              nowCheckedArr.map(item => {
                if (!optListArr.some((itm: string) => itm === item)) {
                  lessArr.push(item)
                }
              })
              /* 只查看一项 */
              if (type === 'only') {
                return handleChangeIndex([val])
              }
              /* 全选 */
              if (type === 'all') {
                if (checked) {
                  const resultArr = [...optListArr, ...nowCheckedArr]
                  return handleChangeIndex(resultArr)
                }
                return handleChangeIndex(lessArr)
              }
              /* 选局部 */
              if (type === 'normal') {
                if (checked) {
                  /* 勾选 */
                  nowCheckedArr.push(val)
                  return handleChangeIndex(nowCheckedArr)
                }
                /* 取消 */
                const index = nowCheckedArr.findIndex(
                  (itm: string) => itm === val
                )
                nowCheckedArr.splice(index, 1)
                return handleChangeIndex(nowCheckedArr)
              }
            }
          }}
        />
      </Wrapper>
    ),
    [optList, indicators]
  )

  return (
    <PopoverCondition
      visible={visible}
      changeVisible={val => {
        setVisible(val)
        if (!val) {
          setTimeout(() => {
            setOptList(frozenList)
            setIsSearching(false)
          }, 300)
        }
      }}
      Content={Content}
      ContentName={title}
    />
  )
}
