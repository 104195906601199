import React, { useEffect } from 'react'
import moment from 'moment'
import { useSetState } from 'ahooks'
import { useAppSelector } from '@/hook/reduxHook'
import LoadingAnimation from '@/components/LoadingAnimation'
import ExploreBoard from '@/pages/BusinessAnalysis/ExploreBoard'
import { ExportCsv } from '@/utiles/common'
import { getRate, getRingRatioDate, getResultData } from './getRate'
import ContentCom from './ContentCom'
import { EmptyData } from './ContentCom/styles'

interface TemplateCardProps {
  boardTitle: string
  modular: string
  exploreType: string
  tips: string
  byTime: string
  unit: string
}

const defaultDate = moment().format('YYYY-MM-DD')

function TemplateCard(props: TemplateCardProps) {
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)
  const lastBoardType = useAppSelector(data => data.boardStore.lastBoardType)
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const dimension = useAppSelector(data => data.boardStore.dimension)
  const refreshBoardCount = useAppSelector(
    data => data.boardStore.refreshBoardCount
  )
  const boardConditionData = useAppSelector(
    data => data.boardStore.boardConditionData
  )

  const { boardTitle, modular, exploreType, tips, byTime, unit = '' } = props
  const defaultProps = {
    modalVisible: false,
    loading: true,
    error: false,
  }

  const [state, setState] = useSetState<any>(defaultProps)

  const requestData = async () => {
    const condition = boardConditionData
      ? JSON.stringify(boardConditionData)
      : undefined
    const exploreParams = {
      modular,
      gameId,
      userId: userInfoData!.account!.id,
      byTime: byTime,
      startDate: timeRangeBoard.length ? timeRangeBoard[1] : defaultDate,
      endDate: timeRangeBoard.length ? timeRangeBoard[1] : defaultDate,
      condition,
      dimension,
    }
    return getResultData(exploreType, exploreParams)
  }

  const getData = async () => {
    setState({ loading: true })
    const result: any = await requestData()
    if (result.error) {
      setState({
        error: true,
        loading: false,
        rate: null,
      })
    } else {
      const item: any = Object.values(result.y[0])[0]
      const totalNumber = item[0].totalNum
      const ringRatioDate = getRingRatioDate(byTime, result.x[0])
      const rate = getRate({
        date: result.x[0],
        byTime,
        yearOnYear: result.yearOnYear,
        ringRatio: result.ringRatio,
        totalNumber: totalNumber,
        ringRatioDate: ringRatioDate,
      })
      setState({
        loading: false,
        rate,
        downLoadHead: ['日期', boardTitle],
        downLoadDataSource: [
          {
            date: result.x[0],
            totalNumber: totalNumber,
          },
        ],
        ...result,
      })
    }
  }

  useEffect(() => {
    const pathName = window.location.pathname.split('/').slice(-1)[0]
    if (lastBoardType === pathName) {
      getData()
    }
  }, [
    lastBoardType,
    boardConditionData,
    refreshBoardCount,
    timeRangeBoard,
    dimension,
  ])

  const loadingAnimationProps = {
    handleCancellClick: () => console.log('calcell'),
    handleRetryClick: () => getData(),
    status: state.loading,
    error: state.error,
    height: '204px',
    width: 60,
  }

  const handleDownLoad = () => {
    const name = `${boardTitle}_${state.rate.date}`
    ExportCsv(state.downLoadHead, state.downLoadDataSource, name)
  }

  const contentProps = {
    boardTitle,
    tips,
    unit,
    data: state.rate,
    downLoadHead: state.downLoadHead,
    downLoadDataSource: state.downLoadDataSource,
    handleRefresh: () => getData(),
    handleExplore: () =>
      setState({
        modalVisible: true,
      }),
    handleDownLoad: handleDownLoad,
  }

  const exploreModalProps = {
    isShowDataModal: state.modalVisible,
    frozenData: {
      ...props,
      timeRange: [
        timeRangeBoard.length ? timeRangeBoard[1] : defaultDate,
        timeRangeBoard.length ? timeRangeBoard[1] : defaultDate,
      ],
      dimension,
    },
    handleClose: () =>
      setState({
        modalVisible: false,
      }),
  }

  return (
    <div>
      <LoadingAnimation {...loadingAnimationProps}>
        <>
          {(!state.rate || state.error) && (
            <EmptyData>该条件暂无数据</EmptyData>
          )}
          {state.rate && <ContentCom {...contentProps} />}
        </>
      </LoadingAnimation>
      {state.modalVisible && <ExploreBoard {...exploreModalProps} />}
    </div>
  )
}

export default TemplateCard
