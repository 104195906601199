import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import {
  extendCondition,
  filterList,
  fieldCollectionList,
} from '@/http/businessAnalysis/exploreBoard/condition'
import { getAttribute } from '@/http/businessAnalysis/customAnalysis/events'

interface EventAttribute {
  condition: any[]
  default: any[]
  eventAttList: any[]
  firstAndLast: any[]
  index: any[]
}

export interface IAnalysisFlows {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  userConditionDatumList: ExtendConditionDatum[]
  eventConditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  eventAttribute: EventAttribute
  // conditionData: any
}

const defaultEventAttribute = {
  condition: [],
  default: [],
  eventAttList: [],
  firstAndLast: [],
  index: [],
}

const initialState: IAnalysisFlows = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 全部属性列表
  userConditionDatumList: [], // 用户属性列表
  eventConditionDatumList: [], // 事件属性列表
  fieldCollectionList: {
    // 且用户符合收藏
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  filterList: [], // 条件列表
  // conditionData: null
  eventAttribute: defaultEventAttribute, // 事件属性指标
}

/* 获取且用户符合属性列表 */
export const getExtendCondition = createAsyncThunk(
  'createTagGroup/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取用户属性列表 */
export const getUserExtendCondition = createAsyncThunk(
  'createTagGroup/getUserExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取事件属性列表 */
export const getEventExtendCondition = createAsyncThunk(
  'createTagGroup/getEventExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'createTagGroup/getFilterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)

/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'createTagGroup/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'createTagGroup/getAttributeFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)

export const createTagGroup = createSlice({
  name: 'createTagGroup',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisFlows; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
  },

  extraReducers: builder => {
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    builder.addCase(getUserExtendCondition.fulfilled, (state, action) => {
      state.userConditionDatumList = action.payload
    })
    builder.addCase(getEventExtendCondition.fulfilled, (state, action) => {
      state.eventConditionDatumList = action.payload
    })
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
    /* 加载属性指标 */
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      state.eventAttribute = action.payload
    })
  },
})

export const createTagGroupState = (state: RootState) => state.createTagGroup

export default createTagGroup.reducer

export const { setterStoreState, initGameIdAndUser } = createTagGroup.actions
