import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDwonLoadState {
  downLoadList: DownListProps[]
  hideList: string[]
}

const initialState: IDwonLoadState = {
  downLoadList: [],
  hideList: [],
}

export const downLoadSlice = createSlice({
  name: 'downLoad',
  initialState,
  reducers: {
    setterDownLoadStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IDwonLoadState; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    clearDownLoad: (state: any) => {
      state.downLoadList = []
      state.hideList = []
    },
  },
})
export const { clearDownLoad, setterDownLoadStoreState } = downLoadSlice.actions
export default downLoadSlice.reducer
