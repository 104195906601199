import styled from 'styled-components'
import { Button } from 'antd'

export const FiltersWrapper = styled.div`
  margin-top: 10px;
  padding-left: 12px;
`

export const EditorWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 4px 0;
`
export const Relation = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 24px;
  margin-right: 4px;
  em {
    position: absolute;
    top: 0;
    left: 12px;
    width: 1px;
    height: 100%;
    transition: all 0.3s;
    background-color: #f0f0f0;
  }
  .valueName {
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    color: rgb(61, 144, 255);
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    background-color: #fff;
    border: 1px solid rgb(217, 223, 230);
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }
  :hover {
    em {
      background-color: rgb(61, 144, 255);
    }
    .valueName {
      color: #fff;
      background-color: rgb(61, 144, 255);
    }
  }
`

export const RelationMain = styled.div`
  flex: 1 1;
`

export const Extra = styled.div`
  margin-top: 8px;
`

export const AddBtn = styled(Button)`
  display: inline-block;
  margin-right: 16px;
  padding: 4px;
  color: rgb(61, 144, 255);
  font-size: 14px;
  line-height: 16px;
  height: 24px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    background-color: #e4f2ff !important;
    color: rgb(61, 144, 255) !important;
    .icon {
      color: #fff;
      background-color: #3d90ff;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    padding-top: 2px;
    color: rgb(61, 144, 255);
    font-size: 12px;
    text-align: center;
    background-color: rgb(228, 242, 255);
    transition: all 0.3s;
  }
`
