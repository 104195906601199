export interface SetStateProps {
  hoverItem: ExtendConditionDatumList | null
  searchWord: string
  activeTab: string
  chooseItemLabel: string
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  handleFieldArr: HandleFieldArr[]
}

export const handleFieldFunc = (
  data: SetStateProps,
  item: ExtendConditionDatumList
) => {
  const arrName = data.conditionDatumList.find(
    itm => itm.type === item.type
  )!.name
  const isField = !item.isCollection
  let newFieldArr = []
  let newHandleFieldArr = JSON.parse(
    JSON.stringify(data.handleFieldArr)
  ) as HandleFieldArr[]
  // 收藏
  if (isField) {
    const newFieldItem = {
      ...item,
      isCollection: true,
    }
    newFieldArr = [newFieldItem, ...data.fieldCollectionList.list]
    newHandleFieldArr.push({
      id: item.infoData.id,
      isCollection: 1,
    })
  } else {
    // 取消收藏
    const fieldIndex = data.fieldCollectionList.list.findIndex(
      itm => itm.label === item.label
    )
    newFieldArr = JSON.parse(JSON.stringify(data.fieldCollectionList.list))
    newFieldArr.splice(fieldIndex, 1)
    const index = newHandleFieldArr.findIndex(
      itm => itm.id === item.infoData.id
    )
    if (index > -1) {
      newHandleFieldArr.splice(index, 1)
    } else {
      newHandleFieldArr.push({
        id: item.infoData.id,
        isCollection: 0,
      })
    }
  }
  const newCon = data.conditionDatumList.find(
    itm => itm.name === arrName
  ) as ExtendConditionDatum
  const arrIndex = data.conditionDatumList.findIndex(
    itm => itm.name === arrName
  )
  const itemIndex = newCon.list.findIndex(itm => itm.label === item.label)
  const newList = JSON.parse(JSON.stringify(newCon.list))
  const collectItem = {
    ...item,
    isCollection: isField,
  }
  newList.splice(itemIndex, 1, collectItem)
  const newConArr = JSON.parse(JSON.stringify(data.conditionDatumList))
  newConArr.splice(arrIndex, 1, {
    ...data.conditionDatumList[arrIndex],
    list: newList,
  })
  return {
    fieldCollectionList: {
      ...data.fieldCollectionList,
      list: newFieldArr,
    },
    conditionDatumList: [...newConArr],
    handleFieldArr: newHandleFieldArr, // 需要抛出处理得组
  }
}
