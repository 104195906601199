import styled from 'styled-components'

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const DividerWrapper = styled.div`
  width: 0px;
  height: 15px;
  background: #ffffff;
  border-right: 1px solid #b5b5b5;
  margin: 0 16px;
`

export const RangeDiv = styled.div`
  /* padding: 0 8px; */
  display: flex;
  align-items: center;
  min-width: 86px;
  height: 28px;
  line-height: 28px;
  text-align: left;
  color: #43536d;
  cursor: pointer;
  .icon {
    margin-right: 5px;
    height: 19px;
    margin-top: -2px;
  }
`
