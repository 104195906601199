import styled from 'styled-components'

interface IWrapper {
  isShowDataModal: boolean
}

export const Wrapper = styled.div<IWrapper>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgb(240, 242, 245);
  display: ${({ isShowDataModal }) => (isShowDataModal ? 'block' : 'none')};
  transition: all 0.3s ease-in-out;
  min-width: 1440px;
  overflow-x: hidden;
`
