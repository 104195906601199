import React, { useState, useMemo } from 'react'
import { Select } from 'antd'
import moment from 'moment'
import PopoverTips from '@/components/PopoverTips'
import { CurrentDateComWrapper, SelectWrapper, NumberWrapper } from './styles'

const { Option } = Select

interface Props {
  handleValue: (value: any) => void
  filterValue: any
}
function CurrentDateCom(props: Props) {
  const { handleValue, filterValue } = props

  const [selectValue, setSelectValue] = useState<string>('区间')
  const [beforeFocused, setBeforeFocused] = useState<boolean>(false)
  const [afterFocused, setAfterFocused] = useState<boolean>(false)
  const handleChange = (value: string) => {
    setSelectValue(value)
    if (value === '过去') {
      handleValue(String(0))
    }
    if (value === '区间') {
      handleValue('-1, 1')
    }
  }

  function SelectCom() {
    return (
      <SelectWrapper
        size="small"
        // @ts-ignore
        onChange={handleChange}
        value={selectValue}
        suffixIcon={null}
        dropdownStyle={{
          color: '#67729d',
          fontWeight: 400,
        }}
      >
        <Option value="区间">区间</Option>
        <Option value="过去">过去</Option>
      </SelectWrapper>
    )
  }

  /*
    获取时间
      如果是负数则subtract 如果是等于0 今天 如果是大于 0 add  先 取绝对值 在四舍五入
  */
  const getTime = (value: number) => {
    if (value < 0) {
      return moment()
        .subtract(Math.round(Math.abs(value)), 'day')
        .format('YYYY-MM-DD')
    }
    if (value === 0) {
      return moment().format('YYYY-MM-DD')
    }
    return moment()
      .add(Math.round(Math.abs(value)), 'day')
      .format('YYYY-MM-DD')
  }

  /* 区间 */
  function BetweenCom() {
    const beforeValue = useMemo(() => filterValue?.split(',')[0], [filterValue])
    const afterValue = useMemo(() => filterValue?.split(',')[1], [filterValue])
    const beforeDate = `${getTime(beforeValue)}  00:00:00`
    const afterDate = `${getTime(afterValue)}  23:59:59`
    const info = `【事件时间】发生在 ${beforeDate} 到 ${afterDate}`
    return (
      <>
        <NumberWrapper
          size="small"
          autoFocus={beforeFocused}
          value={beforeValue}
          // @ts-ignore
          formatter={value => (Number(value) > 0 ? `+${value}` : value)}
          // @ts-ignore
          onChange={value => {
            /* 起始值 必须小于 结束值 */
            //  起始值 大于 结束值 则结束值 等于输入值
            if (!value > afterValue) {
              handleValue(`${value},${value}`)
            } else {
              handleValue(`${value},${afterValue}`)
            }
          }}
          onFocus={() => setBeforeFocused(true)}
          onBlur={() => setBeforeFocused(false)}
        />
        <span className="word">到</span>
        <NumberWrapper
          size="small"
          autoFocus={afterFocused}
          value={afterValue}
          // @ts-ignore
          formatter={value => (Number(value) > 0 ? `+${value}` : value)}
          // @ts-ignore
          onChange={value => {
            /* 结束值 不能 小于 起始值 */
            //  结束值 小于 起始值 则起始值 等于输入值
            if (!value < beforeValue) {
              handleValue(`${value},${value}`)
            } else {
              handleValue(`${beforeValue},${value}`)
            }
          }}
          onFocus={() => setAfterFocused(true)}
          onBlur={() => setAfterFocused(false)}
        />
        <span className="word">天</span>
        <PopoverTips content={info} placement="right" isBlackStyle />
      </>
    )
  }

  /* 过去 */
  function PastCom() {
    const beforeDate = `${getTime(filterValue)}  00:00:00`
    const info = `【事件时间】发生在 ${beforeDate} 之前`
    return (
      <>
        <NumberWrapper
          size="small"
          autoFocus={beforeFocused}
          value={filterValue}
          // @ts-ignore
          formatter={value => (Number(value) > 0 ? `+${value}` : value)}
          // @ts-ignore
          onChange={value => handleValue(String(value))}
          onFocus={() => setBeforeFocused(true)}
          onBlur={() => setBeforeFocused(false)}
          min={0}
        />
        <span className="word">天前</span>
        <PopoverTips content={info} placement="right" isBlackStyle />
      </>
    )
  }

  return (
    <CurrentDateComWrapper>
      <span className="word">在</span>
      <SelectCom />
      {selectValue === '区间' && <BetweenCom />}
      {selectValue === '过去' && <PastCom />}
    </CurrentDateComWrapper>
  )
}

export default CurrentDateCom
