import styled from 'styled-components'

export const EventWrapper = styled.div`
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 32px 0;
  /* height: 100%; */
  .contentWrapper {
    padding: 0 32px;
  }
`

export const HeaderWrapper = styled.div`
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 34px;
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #545454;
  }
  .searchWrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    .addWrapper {
      width: 82px;
      height: 30px;
      background: #1890ff;
      border-radius: 2px;
    }
  }
`

export const FormWrapper = styled.div`
  padding: 6px;
  .ant-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
      width: 50%;
      .ant-form-item-label
        > label.ant-form-item-required:not(
          .ant-form-item-required-mark-optional
        )::before {
        content: '';
      }
    }
    .needWrapper {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 50%;
      }
      .tableItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 294px;
      }
    }
    .dimensionTableWrapper {
      width: 100% !important;
      .dimensionTableContent {
        max-height: 180px;
        overflow-y: auto;
      }
      .dimensionTableItem {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        > div {
          margin: 0;
        }
        .ant-divider-horizontal {
          width: 14px;
          min-width: unset;
          margin: 0 15px;
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
    > div:nth-last-child(2),
    > div:last-child {
      width: 100%;
    }
  }
`
export const IconTextWrapper = styled.div`
  width: 50px;
  .iconText {
    color: #fff;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
`
export const IconSpan = styled.span`
  color: rgb(66, 84, 109);
  text-align: center;
  .icon {
    color: rgb(66, 84, 109);
    margin-right: 3px;
  }
`
