import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PopoverCondition from '../PopoverCondition'

const Wrapper = styled.div`
  width: 160px;
  min-height: 210px;
  maxi-height: 344px;
  background: #feffff;
  font-size: 14px;
  box-shadow: 0px 8px 12px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  color: black;
  padding: 14px 6px 0 4px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const TimeTabWrapper = styled.div``

const TabTitleWrapper = styled.div`
  width: 150px;
  height: 28px;
  background: #feffff;
  padding-left: 19px;
  font-size: 14px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #a4a4a4;
  line-height: 28px;
  cursor: pointer;
`

interface ITabListWrapper {
  isActive: boolean
}

interface List {
  name: string
  value: string
}

interface TimeList {
  name: string
  unit: string
  nameList: List[]
}

const TabListWrapper = styled(TabTitleWrapper)<ITabListWrapper>`
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  margin-bottom: 4px;
  background: ${({ isActive }) => (isActive ? '#ECF2FE !important' : 'white')};
  color: ${({ isActive }) =>
    isActive ? '#0052D9 !important' : 'rgba(0, 0, 0, 0.9)'};
  &:hover {
    background: #f0f2f5;
  }
`

interface IDurationTimeSelectorProps {
  handleDurationTimeSelect: (arg: string, dateType?: string) => void
  value: string
}
export default function DropdownTimeSelector(
  props: IDurationTimeSelectorProps
) {
  const timeList = [
    {
      name: '日',
      unit: 'day',
      nameList: [
        {
          name: '当日',
          value: 'currentDay',
        },
        {
          name: '次日',
          value: 'nextDay',
        },
        {
          name: '7日',
          value: '7Day',
        },
        {
          name: '14日',
          value: '14Day',
        },
        {
          name: '30日',
          value: '30Day',
        },
      ],
    },
    // {
    //   name: '周',
    //   unit: 'week',
    //   nameList: [
    //     {
    //       name: '当周',
    //       value: 'currentWeek',
    //     },
    //     {
    //       name: '次周',
    //       value: 'nextWeek',
    //     },
    //     {
    //       name: '4周',
    //       value: '4Week',
    //     },
    //     {
    //       name: '8周',
    //       value: '8Week',
    //     },
    //   ],
    // },
    // {
    //   name: '月',
    //   unit: 'month',
    //   nameList: [
    //     {
    //       name: '当月',
    //       value: 'currentMonth',
    //     },
    //     {
    //       name: '次月',
    //       value: 'nextMonth',
    //     },
    //     {
    //       name: '3月',
    //       value: '3Month',
    //     },
    //     {
    //       name: '6月',
    //       value: '6Month',
    //     },
    //     {
    //       name: '12月',
    //       value: '12Month',
    //     },
    //   ],
    // },
  ] as TimeList[]
  const { handleDurationTimeSelect, value } = props
  const [activeFlg, setActiveFlag] = useState<string>('7天')
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    const arr = [] as List[]
    timeList.map(item => {
      const { nameList } = item
      return arr.push(...nameList)
    })
    const listItem = arr.find(it => it.value === value) as List
    if (listItem) {
      setActiveFlag(listItem.name)
    }
  }, [value])

  const Content = () => (
    <Wrapper>
      {timeList.map((item: TimeList, index: number) => (
        <TimeTabWrapper key={index}>
          <TabTitleWrapper>{item.name}</TabTitleWrapper>
          {item.nameList.map((tab, tabInd) => (
            <TabListWrapper
              isActive={value === tab.name}
              onClick={() => {
                handleDurationTimeSelect(tab.value, item.unit)
                setVisible(false)
              }}
              key={tabInd}
            >
              {tab.name}
            </TabListWrapper>
          ))}
        </TimeTabWrapper>
      ))}
    </Wrapper>
  )
  return (
    <PopoverCondition
      visible={visible}
      changeVisible={(arg: boolean) => setVisible(arg)}
      Content={<Content />}
      ContentName={activeFlg}
    />
  )
}
