import styled from 'styled-components'
import { PlusSquareOutlined } from '@ant-design/icons'
import SvgIcon from '@/components/SvgIcon'

export const BodyWrapper = styled.div`
  width: 100%;
  height: calc(100% - 133px);
  padding: 0 16px 0 0;
  overflow: hidden;
`

interface CloseProps {
  close: boolean
}

export const Left = styled.div<CloseProps>`
  position: relative;
  display: inline-block;
  width: ${props => (props.close ? '15px' : '464px')};
  height: 100%;
  margin-right: 16px;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
`
export const TabBar = styled.div<CloseProps>`
  display: ${props => (props.close ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 24px;
  border-bottom: 1px solid rgb(240, 240, 240);
  .tabs {
    height: 54px;
    line-height: 53px;
  }
`
interface TabProps {
  active: boolean
}

export const Tab = styled.div<TabProps>`
  position: relative;
  display: inline-block;
  color: ${props => (props.active ? '#202d3f' : 'rgb(103, 114, 157)')};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
  height: 48px;
  margin-bottom: 16px;
  padding: 8px 24px;
  background: #fff;
  :after {
    position: absolute;
    top: auto;
    bottom: -5px;
    left: 50%;
    display: block;
    width: 38px;
    height: 3px;
    background-color: rgb(61, 144, 255);
    border-radius: 100px 100px 0 0;
    transform: translateX(-19px);
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.3s;
    content: '';
  }
`

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 54px);
`

export const Sizer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  opacity: 0;
`
export const Handler = styled.div`
  position: absolute;
  top: 50%;
  left: -12px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: -10px;
  color: rgb(103, 144, 157);
  font-size: 16px;
  background-color: #fff;
  border: 1px solid rgb(240, 242, 245);
  border-radius: 12px;
  /* cursor: pointer; */
  transition: all 0.3s;
`
interface SvgIconComProps {
  isClose: boolean
}
export const SvgIconCom = styled(SvgIcon)<SvgIconComProps>`
  transform: ${props => (props.isClose ? 'rotate(0)' : 'rotate(180deg)')};
  transition: all 0.3s;
`

export const RightWrapper = styled.div<CloseProps>`
  position: relative;
  display: inline-block;
  width: ${props => (props.close ? 'calc(100% - 31px)' : 'calc(100% - 480px)')};
  height: 100%;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
`
export const ToolBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  padding: 0 18px;
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 54px);
  padding: 0 18px;
  overflow: hidden;
`
export const PlusSquareOutWrapper = styled.div`
  /* padding-left: 20px; */
  width: 180px;
  text-align: center;
`
export const PlusSquareOutIcon = styled(PlusSquareOutlined)`
  margin-right: 5px;
  &:hover {
    color: rgb(61, 144, 255);
  }
`

export const RightIcon = styled.div`
  margin-left: 5px;
  display: none;
`

export const ColumnItem = styled.div`
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    ${RightIcon} {
      display: inline-block;
    }
  }
`
