import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 200px;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  padding: 20px 13px;
  .iconWrapper {
    display: flex;
    visibility: hidden;
  }
  &:hover {
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.16);
    .iconWrapper {
      visibility: unset;
      display: flex;
    }
  }
  transition: all 300ms ease-in-out;
`
export const TitleAndIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TitleWrapper = styled.div`
  /* width: 180px; */
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: #000000;
  line-height: 29px;
  display: flex;
`

export const TimeWrapper = styled.div`
  height: 17px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  margin-top: 3px;
`
export const CounterWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 21px;
  display: flex;
  .StatisticWrap {
    .ant-statistic-content-value {
      font-size: 36px;
    }
    .ant-statistic-content-suffix {
      font-size: 18px;
    }
  }
`
export const CounterNum = styled.div`
  max-width: 269px;
  /* display: inline-block; */
  height: 50px;
  font-size: 40px;
  font-family: HelveticaNeue-, HelveticaNeue;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
  line-height: 51px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
`
export const CounterUnit = styled.div`
  /* width: 27px; */
  // height: 36px;
  font-size: 18px;
  margin-left: 11px;
  font-family: HelveticaNeue-, HelveticaNeue;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
  line-height: 51px;
`
export const SquareWrapper = styled.div`
  display: inline-block;
  margin-right: 3px;
  width: 6px;
  height: 6px;
  background: #b2b2b2;
`
export const TodayWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC-Light, PingFang SC;
  font-weight: 300;
  color: #5a5a5a;
  line-height: 13px;
  position: relative;
  // top: -2px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
  margin-left: 8px;
  .icon {
    height: 16px;
    width: 16px;
    // visibility: hidden;
  }
  &:hover {
    cursor: pointer;
    background: #eeeeee;
    .icon {
      color: #5ba8ff;
    }
  }
  .iconWrapper {
    display: flex;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
  }
  .hover-toogle {
    height: 16px;
    width: 16px;
    &:hover {
      cursor: pointer;
    }
    transition: all 0.1s ease-in-out;
  }
`
export const EmptyData = styled.div`
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 14px;
`
