import React from 'react'
import { ColumnsType } from 'antd/lib/table/interface'
import DataTable from '@/components/DataTable'
import EchartsCom from './EchartsCom'
import LeftDesc from './DescCom/LeftDesc'
import TopDesc from './DescCom/TopDesc'
import RateDesc from './DescCom/RateDesc'
import TopRateDesc from './DescCom/TopRateDesc'
import LeftRateDesc from './DescCom/LeftRateDesc'
import { Wrapper } from './styles'

interface TableState {
  tableLoading: boolean
  tableColumns: ColumnsType<any>[]
  tableDataSource: object[]
}
const tableType = ['table']
interface Props {
  toggleType: string
  dataType: string
  indicators: string[]
  frozenIndicators: string[]
  byTime: string
  xData?: string[]
  trendData: IBasicTrendList[]
  groups: string[]
  frozenGroups: string[]
  distrGroupData: IBasicDistributionGroup
  cumulativeData: IBasicCumulative[]
  pieData: PieChart[][]
  descDataList?: IIndicatorsData | null
  descDate?: string
  showTop?: boolean
  showLeft?: boolean
  showRate?: boolean
  showTopRate?: boolean
  showLeftRate?: boolean
  tableState: TableState
}
function ContentWrap(props: Props) {
  const {
    toggleType,
    dataType,
    indicators = [],
    frozenIndicators = [],
    xData = [],
    trendData,
    byTime = 'byDay',
    cumulativeData,
    groups = [],
    frozenGroups = [],
    pieData,
    descDataList,
    descDate,
    showTop,
    showLeft,
    showRate,
    showTopRate,
    showLeftRate,
    distrGroupData,
    tableState,
  } = props
  const specialDataTypeArr = [
    'basic',
    'advanceData',
    'payMent',
    'accumulateDistribution',
    'dau',
    'weekLogin',
    'onlineDuration',
  ]

  const descDateText = toggleType === 'distribution' ? '合计' : descDate
  const lineChartsData = () => {
    switch (toggleType) {
      case 'trend':
        return trendData
      case 'cumulative':
        return cumulativeData
      case 'numerical':
        return trendData // 数值分布
      case 'percentage':
        return cumulativeData // 百分比
      default:
        return []
    }
  }

  return (
    <Wrapper
      style={{
        display: specialDataTypeArr.includes(dataType) ? 'flex' : 'unset',
      }}
    >
      {!!showLeft && !tableType.includes(toggleType) && (
        <LeftDesc
          date={descDateText}
          data={descDataList?.[toggleType]}
          indicators={indicators}
          isBasic={dataType === 'basic' || dataType === 'payMent'}
        />
      )}
      {!!showTop && toggleType !== 'table' && (
        <TopDesc
          date={descDateText}
          data={descDataList?.[toggleType]}
          indicators={indicators}
          isBasic={false}
        />
      )}
      {!!showRate && toggleType !== 'table' && (
        <RateDesc data={descDataList?.rate} />
      )}
      {!!showTopRate && toggleType !== 'table' && (
        <TopRateDesc data={descDataList?.rate} />
      )}
      {!!showLeftRate && toggleType !== 'table' && (
        <LeftRateDesc data={descDataList?.rate} />
      )}
      {tableType.includes(toggleType) && !!tableState.tableColumns.length && (
        <DataTable
          loading={tableState.tableLoading}
          dataSource={tableState.tableDataSource}
          columns={tableState.tableColumns}
        />
      )}
      {!tableType.includes(toggleType) && (
        <EchartsCom
          lineSeries={lineChartsData()}
          xData={xData}
          byTime={byTime}
          indicators={indicators}
          frozenIndicators={frozenIndicators}
          groups={groups}
          frozenGroups={frozenGroups}
          distrGroupData={distrGroupData}
          pieData={pieData}
          styles={{
            height: specialDataTypeArr.includes(dataType) ? '260px' : '160px',
          }}
          toggleType={toggleType}
        />
      )}
    </Wrapper>
  )
}

export default ContentWrap
