import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { showMessage } from './status'

message.config({
  duration: 1,
  maxCount: 1,
})

// 前端代理前缀数组
const proxyArray = [
  'api',
  'apc',
  'apg',
  'apb',
  'rapb',
  'rapp',
  'rapsql',
  'raptest',
]
export const baseUrl = process.env.REACT_APP_ENV_URL

export const axiosInstance: AxiosInstance = axios.create({
  // baseURL: '',
  baseURL: baseUrl,
  timeout: 60000,
})

// axios实例拦截响应
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code } = response.data
    if (response.status === 200) {
      if (code !== 0 && code !== 10044001) {
        message.error(response.data.message, 1)
        if (code === 10042008) {
          window.location.href = `${window.location.origin}/gh/login`
        }
      }
      return response.data
    }

    message.error(showMessage(response.status))
    return response
  },
  // 请求失败
  (error: any) => {
    const { response } = error
    if (response) {
      message.error(showMessage(response.status))
      return Promise.reject(response.data)
    }
    message.error('网络连接异常,请稍后再试!')
  }
)

// axios 请求拦截器
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // if (process.env.NODE_ENV !== 'development') {
    const { url } = config
    const [, targetPrefix, ...lasts] = url?.split('/') || []

    if (targetPrefix) {
      const isProxy = proxyArray.includes(targetPrefix)
      if (isProxy) {
        config.baseURL = process.env.REACT_APP_ENV_URL
        config.url = `/${lasts.join('/')}`
      }
    }
    // }

    const root = localStorage.getItem('persist:root')
    if (root) {
      const { token } = JSON.parse(JSON.parse(root).userInfo).userInfoData
      if (token) {
        config!.headers!.Authorization = `Bearer ${token}`
        config!.headers!['Content-Type'] = 'application/json'
        config!.headers!.charset = 'UTF-8'
      }
    } else {
      // 没有token 且不是登录
      // eslint-disable-next-line no-lonely-if
      if (window.location.pathname.indexOf('/login') === -1) {
        message.warning('token过期， 请重新登录')
        window.location.href = `${window.location.origin}/gh/login`
      }
    }
    return config
  },
  (error: any) => Promise.reject(error)
)
