/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Spin, message } from 'antd'
import { useAppSelector, useAppDispatch } from '@/hook/reduxHook'
import {
  getCollectionList,
  setterStoreState,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import { collectionModify } from '@/http/businessAnalysis/exploreBoard/condition'
import PopoverTips from '@/components/PopoverTips'
import EmptyBoxCom from '../EmptyCom'
import CollectModalCom from '../CollectModalCom'
import RenderContent from '../HistoryCom/Content'
import {
  CollectWrapper,
  Title,
  Main,
  ItemWrapper,
  ItemParams,
  ActionWrapper,
  ActionButton,
  EmptyList,
} from './styles'

const { confirm } = Modal

interface CollectComProps {
  goSearch: () => void
}

function CollectCom(props: CollectComProps) {
  const { goSearch } = props
  const dispatch = useAppDispatch()

  const modular = useAppSelector(state => state.exploreBoard.modular)
  const gameId = useAppSelector(state => state.exploreBoard.gameId)
  const userId = useAppSelector(state => state.exploreBoard.userId)
  const exploreCollectionList = useAppSelector(
    state => state.exploreBoard.exploreCollectionList
  )
  const exploreCollectionLoading = useAppSelector(
    state => state.exploreBoard.exploreCollectionLoading
  )

  const [messageApi, contextHolder] = message.useMessage()

  const [collectName, setCollectName] = useState('')
  const [collectItem, setCollectItem] = useState<ExploreCollectListData | null>(
    null
  )

  useEffect(() => {
    dispatch(
      getCollectionList({
        gameId,
        userId,
        identification: modular,
      })
    )
  }, [])

  const goAction = (item: ExploreHistoryListData) => {
    dispatch(
      setterStoreState({
        type: 'conditionData',
        value: item.condition ? JSON.parse(item.condition) : item.condition,
      })
    )
    dispatch(
      setterStoreState({
        type: 'groupDataList',
        value: item.grouping ? JSON.parse(item.grouping) : [],
      })
    )
    goSearch()
  }

  const handleCancel = () => {
    setCollectName('')
    setCollectItem(null)
  }

  /* 移出/重命名 移除则有removeItem 重命名则无 */
  const handleOk = (removeItem?: ExploreCollectListData) => {
    collectionModify({
      id: removeItem ? removeItem.id : collectItem!.id,
      name: !removeItem ? collectName : undefined,
    }).then(res => {
      dispatch(
        getCollectionList({
          gameId,
          userId,
          identification: modular,
        })
      )
      if (res.code === 0) {
        messageApi.success(`${removeItem ? '移除' : '更新'}成功`)
      }
      handleCancel()
    })
  }

  const collectProps = {
    collectItem: collectItem,
    openModal: !!collectItem,
    title: '重命名',
    collectName,
    inpCursor: 'end',
    okDisabled:
      !collectName || !!(collectItem && collectName === collectItem.name),
    handleOk,
    handleCancel,
    handleName: (val: string) => setCollectName(val),
  }

  return (
    <CollectWrapper>
      {contextHolder}
      <Title>收藏列表</Title>
      {!exploreCollectionLoading && (
        <Main>
          {!!exploreCollectionList.length && (
            <>
              {exploreCollectionList.map(item => (
                <PopoverTips
                  key={item.key}
                  placement="bottom"
                  content={<RenderContent item={item} />}
                  overlayInnerStyle={{
                    width: '240px',
                    fontSize: '14px',
                    borderRadius: '2px',
                    padding: '6px 10px',
                  }}
                >
                  <ItemWrapper>
                    <ItemParams>
                      <span className="index">{item.key}</span>
                      <div className="label">{item.name}</div>
                    </ItemParams>
                    <ActionWrapper className="action">
                      <ActionButton
                        onClick={() => {
                          setCollectName(item.name)
                          setCollectItem(item)
                        }}
                      >
                        重命名
                      </ActionButton>
                      <ActionButton
                        onClick={() => {
                          confirm({
                            title: `确定移除收藏: ${item.name}`,
                            icon: <ExclamationCircleOutlined />,
                            okText: '确定',
                            okType: 'primary',
                            cancelText: '取消',
                            centered: true,
                            bodyStyle: { padding: '32px 32px 24px' },
                            onOk() {
                              handleOk(item)
                            },
                          })
                        }}
                      >
                        移除
                      </ActionButton>
                      <ActionButton onClick={() => goAction(item)}>
                        执行
                      </ActionButton>
                    </ActionWrapper>
                  </ItemWrapper>
                </PopoverTips>
              ))}
            </>
          )}
          {!exploreCollectionList.length && <EmptyBoxCom />}
        </Main>
      )}
      {exploreCollectionLoading && (
        <EmptyList>
          <Spin size="large" />
          <div>数据加载</div>
        </EmptyList>
      )}
      <CollectModalCom {...collectProps} />
    </CollectWrapper>
  )
}

export default CollectCom
