import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function ActiveLife() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)
  const props = {
    modular: 'lifeCycle',
    exploreType: 'activeBoard',
    boardType: 'activeExploreBoard',
    dataType: 'life',
    boardTitle: '活跃用户生命周期构成',
    toggleType: 'trend',
    checkType: '日期',
    toolBarLeftProps: {
      needDropDownSelector: true,
      needDatePickerSelector: true,
    },
    showTop: true,
    tips: '生命周期天数指的是用户新增后的第几天，通过该维度计算活跃数据，可以了解活跃用户的构成，比如新增一周的用户与新增30天以上的用户占比各是多少',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
