import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}

// 用户行为序列-左侧数据
export const behaviorSequence = (params: {
  gameId: string
  uid: string
}): Promise<IResponse<BehaviorSequence>> =>
  axiosInstance.get('/api/econ/v1/userAnalyze/behaviorSequence', { params })

// 探索条件
export const extendCondition = (
  params: ExtendConditionProps
): Promise<IResponse<ExtendConditionDatum[]>> =>
  axiosInstance.get('/api/econ/v1/common/extendCondition', { params })
// 探索属性收藏列表
export const fieldCollectionList = (
  params: CollectionProps
): Promise<IResponse<ExtendConditionDatum>> =>
  axiosInstance.get('/api/econ/v1/common/getFieldCollection', { params })
// 探索属性收藏
export const setFieldCollection = (
  params: FieldCollectionProps
): Promise<IResponse<SetFieldCollectionProps>> =>
  axiosInstance.post('/api/econ/v1/common/fieldCollection', params)
// 事件列表
export const getEventList = (
  params: EventsProps
): Promise<IResponse<EventDatum>> =>
  axiosInstance.get('/api/econ/v1/common/event/list', { params })
// 用户行为序列-右侧数据
export const getBehaviorSequenceData = (
  params: BehaviorSequenceDataProps
): Promise<IResponse<BehaviorSequenceData>> =>
  axiosInstance.get('/api/econ/v1/userAnalyze/behaviorSequenceData', {
    params,
  })

// 探索字段类型过滤器
export const filterList = (): Promise<IResponse<FilterDatum[]>> =>
  axiosInstance.get('/api/econ/v1/common/filter')

// 用户细查
export const scrutinize = (
  params: ScrutinizeProps
): Promise<IResponse<ScrutinizeData>> =>
  axiosInstance.get('/api/econ/v1/userAnalyze/getScrutinize', { params })
