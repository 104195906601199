import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'

export interface IUserGroupManage {
  copyParams: {
    copyRoleId: number
    // copyAppId: number
    // pasteAppId: number
    // pasteRoleId: number
    // platformId: number[]
  }
}

const initialState: IUserGroupManage = {
  copyParams: {
    copyRoleId: 0,
    // copyAppId: 0,
    // pasteAppId: 0,
    // pasteRoleId: 0,
    // platformId: [],
  },
}

export const userGroupManageSlice = createSlice({
  name: 'userGroupManage',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IUserGroupManage; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
  },
})
export const storeState = (state: RootState) =>
  state.manageCenter.userGroupManage
