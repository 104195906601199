import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/store'
import { getIntervalData } from '@/http/businessAnalysis/customAnalysis/interval'
import {
  extendCondition,
  fieldCollectionList,
  filterList,
  collectionList,
} from '@/http/businessAnalysis/exploreBoard/condition'
import {
  getAttribute,
  getEventList,
} from '@/http/businessAnalysis/customAnalysis/events'

interface IAnalysisInterval {
  gameId: string
  userId: number
  iconPath: string
  conditionDatumList: ExtendConditionDatum[]
  startConditionDatumList: ExtendConditionDatum[] // 起点事件
  endConditionDatumList: ExtendConditionDatum[] // 终点事件
  fieldCollectionList: ExtendConditionDatum
  groupFieldCollectionList: ExtendConditionDatum
  filterList: FilterDatum[]
  conditionData: ConditionList | null
  groupDataList: GroupItem[]
  groups: string[]
  frozenGroups: string[]
  groupConditionDatumList: ExtendConditionDatum[]
  startIndicatorsData: any | null
  endIndicatorsData: any | null
  intervalAnalysisDatas: IntervalData | null
  eventList: EventDatum | null
  sessionDuration: {
    sessionInterval: number
    sessionType: string
  }
}
const initialState: IAnalysisInterval = {
  gameId: '',
  userId: -1,
  iconPath: '', // iconfont地址
  conditionDatumList: [], // 属性列表
  startConditionDatumList: [], // 起点事件
  endConditionDatumList: [], // 终点事件
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 属性收藏列表
  filterList: [], // 条件列表
  groups: [], // 分组
  frozenGroups: [], // 不会被改变的分组
  groupDataList: [], // 分组项
  groupConditionDatumList: [], // 分组项的属性列表
  groupFieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  }, // 分组项的属性列表
  conditionData: null, // 指标筛选
  startIndicatorsData: null,
  endIndicatorsData: null, // 分析指标数据
  intervalAnalysisDatas: {
    groupCols: [],
    timeArray: [],
    intervalData: null,
    distributionInterval: [],
  },
  eventList: null, // 事件列表
  sessionDuration: {
    sessionInterval: 30, // 会话间隔时长
    sessionType: 'minute', // 间隔类型
  },
}

/* 获取全局筛选属性列表 */
export const getExtendCondition = createAsyncThunk(
  'analysisInterval/getExtendCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取起点事件属性列表 */
export const getExtendStartCondition = createAsyncThunk(
  'analysisInterval/getExtendStartCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 获取终点事件属性列表 */
export const getExtendEndCondition = createAsyncThunk(
  'analysisInterval/getExtendEndCondition',
  async (params: ExtendConditionProps) => {
    const result = await extendCondition(params)
    return result.data
  }
)

/* 条件列表 */
export const getFilterList = createAsyncThunk(
  'analysisInterval/filterList',
  async () => {
    const result = await filterList()
    return result.data
  }
)
export const getAttributeCollection = createAsyncThunk(
  'analysisFlows/getAttributeCollection',
  async (params: any) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)

/* 获取收藏列表 */
export const getCollectionList = createAsyncThunk(
  'analysisInterval/getCollectionList',
  async (params: ExploreHistoryListProps) => {
    const result = await collectionList(params)
    return result.data
  }
)
/* 操作收藏列表 */
export const getFieldCollectionList = createAsyncThunk(
  'analysisInterval/getFieldCollectionList',
  async (params: CollectionProps) => {
    const result = await fieldCollectionList(params)
    return result.data
  }
)
/* 加载属性指标 */
export const getAttributeFunc = createAsyncThunk(
  'analysisInterval/getLoadPropQuotasFunc',
  async (gameId: string) => {
    const result = await getAttribute({ gameId })
    return result.data
  }
)
/* 事件列表 */
export const getEventListFunc = createAsyncThunk(
  'analysisInterval/getEventListFunc',
  async (params: EventsProps) => {
    const result = await getEventList(params)
    return result.data
  }
)

export const getIntervalDataQuery = createAsyncThunk(
  'analysisInterval/getIntervalDataQuery',
  async (params: any) => {
    const res = await getIntervalData({ ...params })
    return res.data
  }
)
export const analysisIntervalSlice = createSlice({
  name: 'analysisInterval',
  initialState,
  reducers: {
    setterStoreState: (
      state: any,
      action: PayloadAction<{ type: keyof IAnalysisInterval; value: any }>
    ) => {
      const { type, value } = action.payload
      state[type] = value
    },
    resetState: state => (state = initialState),
    initGameIdAndUser: (
      state: any,
      action: PayloadAction<{
        gameId: string
        userId: number
      }>
    ) => {
      const { gameId, userId } = action.payload
      state.gameId = gameId
      state.userId = userId
    },
  },

  extraReducers: builder => {
    builder.addCase(getIntervalDataQuery.fulfilled, (state, action) => {
      state.intervalAnalysisDatas = action.payload
    })
    // 全局筛选
    builder.addCase(getExtendCondition.fulfilled, (state, action) => {
      state.conditionDatumList = action.payload
    })
    // 起点事件
    builder.addCase(getExtendStartCondition.fulfilled, (state, action) => {
      state.startConditionDatumList = action.payload
    })
    // 终点事件
    builder.addCase(getExtendEndCondition.fulfilled, (state, action) => {
      state.endConditionDatumList = action.payload
    })
    /* 操作的收藏列表 */
    builder.addCase(getFieldCollectionList.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
    })
    /* 条件列表 */
    builder.addCase(getFilterList.fulfilled, (state, action) => {
      state.filterList = action.payload
    })
    builder.addCase(getEventListFunc.fulfilled, (state, action) => {
      state.eventList = action.payload
    })
    builder.addCase(getAttributeFunc.fulfilled, (state, action) => {
      console.log('getLoadPropQuotasFunc', action.payload)
    })
    builder.addCase(getAttributeCollection.fulfilled, (state, action) => {
      state.fieldCollectionList = action.payload
      state.iconPath = action.payload.iconPath
    })
  },
})

export const analysisIntervalState = (state: RootState) =>
  state.analysisInterval
export default analysisIntervalSlice.reducer
export const { setterStoreState, initGameIdAndUser, resetState } =
  analysisIntervalSlice.actions
