import styled from 'styled-components'
import { Select, Modal, Input } from 'antd'

const { TextArea } = Input

export const SelectTagsWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  .editIcon {
    position: absolute;
    top: 3px;
    right: 8px;
    z-index: 9;
    color: #67729d;
    font-size: 14px;
    opacity: 0;
    margin: 4px;
    :hover {
      color: #4fa1ff;
    }
  }
  :hover {
    .editIcon {
      opacity: 1;
    }
  }
`
export const SelectTags = styled(Select)`
  min-width: 120px;
  min-height: 28px;
  font-size: 14px !important;
  vertical-align: top;
  color: #42546d;
  .ant-select-selector {
    height: auto;
    max-height: 150px;
    overflow-y: auto;
    min-height: 28px !important;
    padding: 0 30px 0 6px !important;
    color: #67729d;
    border: #f0f2f5;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .ant-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
  }
  .ant-select-selector::after {
    line-height: 18px;
  }
  .ant-select-selection-overflow-item {
    margin-right: 2px;
    .ant-select-selection-item {
      height: 18px;
    }
    > span {
      position: relative;
      height: 18px;
      margin: 4px 0;
      padding: 0 2px;
      overflow: visible;
      line-height: 18px;
      background-color: #f0f2f5;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.3s;

      .ant-select-selection-item-content {
        max-width: 135px;
        margin-right: 0;
        padding-right: 6px;
        line-height: 18px;
        display: inline-block;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-select-selection-search {
    position: relative;
    right: 20px;
    width: 4px;
    left: 4px;
    max-width: 100px;
    height: 16px;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .ant-select-selection-search-input {
    height: 16px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
  }
  .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
`
export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`
export const EditWrapper = styled.div`
  min-height: 220px;
  overflow: hidden;
  .subtitle span {
    color: #c82829;
  }
  i {
    color: #ff0000;
  }
`
export const EditTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #202d3f;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  .subtitle {
    color: #8d9eb9;
    font-weight: 400;
    font-size: 12px;
  }
`
export const EditMain = styled.div`
  width: 100%;
  margin-top: 8px;
  .subtitle {
    color: #8d9eb9;
    font-weight: 400;
    font-size: 12px;
  }
`

export const TextAreaWrapper = styled(TextArea)`
  width: 100%;
  min-height: 32px;
  padding: 0 8px;
  color: #42546d;
  font-size: 14px;
  line-height: 24px;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  resize: none;
`

export const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: right;
  .btn {
    height: 24px;
    padding: 4px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 1px;
    min-width: 40px;
    margin-left: 8px;
    border-color: transparent !important;
  }
  .btn:first-child {
    float: left;
  }
  .copyBtn {
    color: #3d90ff;
    background-color: #e4f2ff;
  }
  .okBtn {
    color: #fff;
    background-color: #3d90ff;
  }
`
