import { axiosInstance } from '../../index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
}
// 自定义面板详情；看板数据参数获取
export const getPannelCondition = (
  id: number
): Promise<IResponse<IGetPannelCondition>> =>
  axiosInstance.get(`/rapb/econ/v1/userPanel/${id}`)
// 添加用户报表
// /rapb/econ/v1//userReport
export const addUserReportForm = (
  params: IAddUserReportFormParams
): Promise<IResponse<IAddUserReportFormData>> =>
  axiosInstance.post('/rapb/econ/v1/userReport', params)

// 更新用户报表
export const updateUserReportForm = (
  id: number,
  params: RetentionUpdateReport
): Promise<IResponse<Result>> =>
  axiosInstance.put(`/api/econ/v1/userReport/${id}`, params)
// 添加看板
export const addPannel = (
  params: IAddPannelParams
): Promise<IResponse<IAddPannelData>> =>
  axiosInstance.post('/rapb/econ/v1/userPanel', params)

// 查询报表列表
export const querryPannelList = (
  params: IQuerryPannelListParams
): Promise<IResponse<IQuerryPannelListData>> =>
  axiosInstance.post('/rapb/econ/v1/userReport/list', params)
// 查询报表列表
export const deleteuReportList = (data: {
  ids: number[]
}): Promise<IResponse<null>> =>
  axiosInstance.delete('/rapb/econ/v1/userReport', { data })
// 报表重命名
export const renamePannelList = (
  id: number,
  params: IRenamePannelListParams
): Promise<IResponse<null>> =>
  axiosInstance.put(`/rapb/econ/v1/userReport/rename/${id}`, params)
// 报表管理下载
export const downPannelList = (params: {
  gameId: string
  reportId: number[]
  taskId?: string
}): Promise<IResponse<any>> =>
  axiosInstance.post('/rapb/econ/v1/userReport/export', params)
