/* eslint-disable indent */
import styled from 'styled-components'
import { Dropdown, Menu } from 'antd'
import PopoverTips from '@/components/PopoverTips'

export const ModalWrapper = styled(PopoverTips)`
  .conditionPop {
    padding: 12px 16px;
    background: #000;
  }
`
interface ConditionLeftProps {
  isOpen?: boolean // 是否打开
}
export const ConditionRight = styled.div<ConditionLeftProps>`
  display: inline-block;
  min-width: 40px;
  max-height: 100px;
  margin-left: 4px;
  padding: 0 6px;
  overflow-y: auto;
  color: rgb(103, 114, 157);
  font-size: 14px;
  line-height: 26px;
  vertical-align: top;
  border: ${props =>
    props.isOpen
      ? '1px solid rgb(61, 144, 255 ) !important'
      : '1px solid rgb(240, 242, 245)'};
  background-color: ${props =>
    props.isOpen ? '#fff !important' : 'rgb(240, 242, 245)'};
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .operator {
    font-weight: 500;
  }
  :hover {
    border: 1px solid rgb(61, 144, 255) !important;
    background-color: #fff !important;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  min-width: 380px;
  max-height: 420px;
  background-color: rgb(246, 248, 250);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
`

export const Top = styled.div`
  flex-grow: 1;
  padding: 24px 20px;
  overflow-y: auto;
  background: #fff;
`

export const LabelWrapper = styled.span`
  margin-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  vertical-align: text-top;
`

export const OperatorSelect = styled(Dropdown)`
  display: inline-block;
  height: 28px;
  margin-right: 10px;
  margin-bottom: 4px;
  color: rgb(66, 84, 109);
  padding: 0 6px;
  font-size: 14px;
`

interface ActiveProps {
  active: boolean
}

export const MenuWrapper = styled(Menu)`
  > li {
    padding: 3px;
    :hover {
      background: unset;
    }
  }
`

export const MenuItemWrapper = styled.div<ActiveProps>`
  min-width: 80px;
  max-width: 280px;
  height: 32px;
  min-height: 32px;
  margin-bottom: 0;
  color: rgb(66, 84, 109);
  font-size: 14px;
  line-height: 28px;
  border-radius: 2px;
  background-color: ${props => (props.active ? '#e4f2ff !important' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .contentItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    text-align: left;
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props =>
      props.active ? 'rgb(61,144,255) !important' : 'rgb(66, 84, 109)'};
    .iconWrapper {
      background-color: ${props =>
        props.active ? 'rgb(189,221,255) !important' : 'rgb(246, 248, 250)'};
      color: ${props =>
        props.active ? 'rgb(61,144,255) !important' : 'rgb(66, 84, 109)'};
      display: inline-block;
      margin-right: 6px;
      padding: 0 3px;
      line-height: 17px;
      border-radius: 8px;
      .icon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
      }
    }
  }
  .tipIcon {
    color: rgb(141, 158, 185);
    font-size: 16px;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  :hover {
    background-color: rgb(246, 248, 250);
  }
`
export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  line-height: 20px;
  .descIcon {
    margin-right: 6px;
    color: rgb(232, 212, 126);
  }
  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgb(119, 137, 166);
  }
`
