import React from 'react'
import { Tooltip } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import {
  HeaderWrapper,
  TitleOperateWrapper,
  TitleWrapper,
  OperateWrapper,
  IconWrapper,
} from './styles'
import ConditionAndToogle from '../ConditionAndToogle'

interface IOperateItem {
  iconName: string
  title: string
  name: string
}

export interface ItoogleIconListItem {
  iconName: string
  name: string
  type: string
}
interface IHeaderOperateLayout {
  handleType: (type: string) => void
  operateClick: (arg: string) => void
  operateIconList: IOperateItem[]
  title: string
  children: React.ReactElement
  toogleIconList: ItoogleIconListItem[]
  toggleItem: ItoogleIconListItem
  tips?: string
}
export default function HeaderOperateLayout(props: IHeaderOperateLayout) {
  const {
    handleType,
    operateClick,
    operateIconList,
    title,
    children,
    toogleIconList,
    toggleItem,
    tips,
  } = props

  return (
    <HeaderWrapper>
      <TitleOperateWrapper>
        <TitleWrapper>
          {title}
          {tips && <PopoverTips content={tips} isBlackStyle placement="top" />}
        </TitleWrapper>
        <OperateWrapper>
          {operateIconList.map(tab => (
            <Tooltip title={tab.title} key={tab.title}>
              <IconWrapper>
                <SvgIcon
                  key={tab.title}
                  className="icon hover-toogle"
                  handleClick={() => operateClick(tab.name)}
                  icon={`#${tab.iconName}`}
                />
              </IconWrapper>
            </Tooltip>
          ))}
        </OperateWrapper>
      </TitleOperateWrapper>
      <ConditionAndToogle
        toogleIconList={toogleIconList}
        toogleChange={handleType}
        toggleItem={toggleItem}
      >
        {children}
      </ConditionAndToogle>
    </HeaderWrapper>
  )
}
