import styled from 'styled-components'
import { Button } from 'antd'

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const ModalBody = styled.div`
  padding: 16px 16px 0;
`

export const Title = styled.div`
  color: #42546d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const ModalFooter = styled.div`
  height: 48px;
  margin-top: 10px;
  padding: 8px 16px 0 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
`
export const ModalFooterBtn = styled(Button)`
  width: 52px;
  margin-left: 8px;
  padding: 0;
  border-radius: 2px;
  box-shadow: none;
  font-weight: 400;
  line-height: 20px;
  text-shadow: none;
`
