/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Suffix, Section } from './styles'

interface Props {
  filter: ItemFilter
}

function FilterLabel(props: Props) {
  const { filter } = props
  switch (filter?.label) {
    case '区间':
    case '位于区间':
      if (filter.value) {
        return (
          <Section>
            {filter.value.split(',')[0]} 至 {filter.value.split(',')[1]}
            之间
          </Section>
        )
      }
      return <Section />
    case '相对当前日期':
      /* 区间 */
      if (filter.value?.indexOf(',') > -1) {
        return (
          <Section>
            在{filter.value.split(',')[0]} 到 {filter.value.split(',')[1]}
            天之间
          </Section>
        )
      }
      return <Section>在 过去 {filter.value} 天前</Section>
    case '相对事件发生时刻':
      /* 区间 */
      if (filter.value?.indexOf(',') > -1) {
        const suffix = filter.value.split(',')[0].slice(-1)
        let suffixText
        switch (suffix) {
          case 'D':
            suffixText = '小时'
            break
          case 'H':
            suffixText = '小时'
            break
          case 'M':
            suffixText = '分'
            break
          default:
            suffixText = '小时'
            break
        }
        const beforeValue = filter?.value.split(',')[0].slice(0, -1)
        const afterValue = filter?.value.split(',')[1].slice(0, -1)
        return (
          <Section>{`在 ${beforeValue} 到 ${afterValue} ${suffixText} 之间`}</Section>
        )
      }
      return <Section>在 {filter?.value}</Section>
    case '元素位置':
      return (
        <Section>
          位于第{filter?.value.split(',')[0]}项的元素{' '}
          {filter?.value.split(',')[1]} {filter?.value.split(',')[2]}
        </Section>
      )
    default:
      return <Suffix>{filter?.value}</Suffix>
  }
}

export default FilterLabel
