import React from 'react'
import TemplateBoard from '@/pages/BusinessAnalysis/components/TemplateBoard'
import { useAppSelector } from '@/hook/reduxHook'
import { getDefaultRangeTime } from '@/utiles/common'

export default function FirstPaySituation() {
  const timeRangeBoard = useAppSelector(data => data.boardStore.timeRangeBoard)

  const grouping = [
    {
      label: '购买产品(礼包)名称',
      labelEn: 'payment_name',
      descIcon: '',
      dataType: '文本',
      dataTypeEn: 'text',
      type: 1,
      queryType: 1,
      descText: '预制属性',
      isCollection: false,
      isHistory: false,
      infoData: {
        id: 1,
        remarks: '',
        attributeType: '预制属性',
        attributeName: 'pay_currency',
        displayName: '支付币种',
        dataType: '文本',
        number: 0,
        subject: '',
        timeZone: '',
        updateTime: '',
        createUser: '',
      },
      disabled: false,
      id: 0,
      index: 0,
      valueType: 1,
      value: [],
      hasOption: false,
    },
  ]
  const props = {
    modular: 'firstPaySituation',
    exploreType: 'payBoard',
    boardType: 'payExploreBoard',
    dataType: 'firstPaySituation',
    boardTitle: '首充购买情况',
    toggleType: 'table',
    grouping,
    toolBarLeftProps: {
      needIndexSelector: true,
    },
    showTop: true,
    tips: '使用“是否首次付费”（虚拟属性）筛选"充值"事件，以充值礼包的类型进行分组，计算各组的"触发用户数"',
    timeRange: timeRangeBoard.length ? timeRangeBoard : getDefaultRangeTime,
  }

  return <TemplateBoard {...props} />
}
