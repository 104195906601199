import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/hook/reduxHook'
import {
  setterStoreState,
  handleEchartsData,
} from '@/pages/BusinessAnalysis/ExploreBoard/redux'
import LoadingAnimation from '@/components/LoadingAnimation'
import {
  handleBoardType,
  handleParams,
} from '@/pages/BusinessAnalysis/components/TemplateBoard/ContentWrap/common'
import CreateModal from '@/pages/BusinessAnalysis/components/CreateResultGroupModal'
import { createResultGroup } from '@/http/businessAnalysis/userAnalysis/attribute'
import { getToogleIconList } from '@/utiles/common'
import EmptyCom from './EmptyCom'
import SearchCom from './SearchCom'
import HistoryCom from './HistoryCom'
import CollectCom from './CollectCom'
import ToolBarLeftCom from './ToolBarCom/LeftCom'
import ToolBarRightCom from './ToolBarCom/RightCom'
import ContentCom from './ContentCom'
import { handleRetainedBoard, handleNoZ, handleZ } from './common'
import {
  BodyWrapper,
  Left,
  TabBar,
  Content,
  Handler,
  Sizer,
  Tab,
  SvgIconCom,
  RightWrapper,
  ToolBarWrapper,
  ContentWrapper,
} from './styles'

interface SendData {
  displayName: string // 分群名
  remarks: string // 分群简介
}

function Body() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const exploreType = useAppSelector(state => state.exploreBoard.exploreType)
  const closeLeft = useAppSelector(state => state.exploreBoard.closeLeft)
  const companyName = useAppSelector(state => state.exploreBoard.companyName)
  const exploreData = useAppSelector(state => state.exploreBoard.exploreData)
  const byTime = useAppSelector(state => state.exploreBoard.byTime)
  const boardTitle = useAppSelector(state => state.exploreBoard.boardTitle)
  const refreshProps = useAppSelector(state => state.exploreBoard.refreshProps)
  const exploreDataLoading = useAppSelector(
    state => state.exploreBoard.exploreDataLoading
  )
  const [activeTab, setActiveTab] = useState<string>('探索')
  const [showModal, setShowModal] = React.useState<object | null>(null)
  const [messageApi, contextHolder] = message.useMessage()
  const tabList = ['探索', '历史', '收藏']

  const renderTab = tabList.map(item => (
    <Tab
      active={activeTab === item}
      onClick={() => setActiveTab(item)}
      key={item}
    >
      {item}
    </Tab>
  ))

  useEffect(() => {
    const toogleTypeList = getToogleIconList(boardTitle)
    if (exploreData && exploreDataLoading) {
      if (exploreData && !exploreData?.y && !exploreData.y?.length) {
        dispatch(
          setterStoreState({
            type: 'exploreDataLoading',
            value: false,
          })
        )
        return
      }
      const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
      const clickShowTitle = (text: string) =>
        dispatch(
          setterStoreState({
            type: 'showModalTitle',
            value: text,
          })
        )
      const handleNavigate = (props: object) => {
        const boardType = handleBoardType(exploreType)
        const params = {
          ...props,
          ...refreshProps,
          boardType: boardType,
        }
        const resultParams = handleParams(params)
        navigate(`/${companyName}/customInfo`, {
          state: resultParams,
        })
      }
      const handleCreateModal = (props: object) => {
        const boardType = handleBoardType(exploreType)
        const params = {
          ...props,
          ...refreshProps,
          boardType: boardType,
        }
        const resultParams = handleParams(params)
        setShowModal(resultParams)
      }

      // 第一个是留存 第二个是流失
      if (needRetainedCountTitle.includes(boardTitle)) {
        const result = handleRetainedBoard(exploreData)
        dispatch(
          handleEchartsData({
            columns: [],
            dataSource: [],
            indicators: [],
            toogleTypeList,
            groups: [],
            xData: result.xData,
            modalTableHead: [],
          })
        )
      } else {
        // 无Z轴 处理所有的图形
        if (!exploreData!.z.length) {
          const result = handleNoZ(
            exploreData,
            byTime,
            handleNavigate,
            handleCreateModal
          )
          dispatch(
            handleEchartsData({
              columns: result.tableHead,
              dataSource: result.dataSource,
              xData: result.xData,
              pieData: result.pieData,
              trendData: result.trendData,
              distrGroupData: result.distrGroup,
              cumulativeData: result.cumulativeData,
              indicators: result.indicators,
              groups: result.groupsArr,
              toogleTypeList,
              downLoadDataSource: result.downLoadDataSource,
              downLoadHead: result.downLoadHead,
            })
          )
        }
        // 有分组项 有 Z
        if (exploreData!.z.length) {
          const result = handleZ(
            exploreData,
            byTime,
            boardTitle,
            clickShowTitle,
            handleNavigate,
            handleCreateModal
          )
          dispatch(
            handleEchartsData({
              columns: result.tableHead,
              dataSource: result.dataSource,
              xData: result.xData,
              pieData: result.pieData,
              trendData: result.trendData,
              distrGroupData: null,
              cumulativeData: result.cumulativeData,
              indicators: exploreData.indicators,
              groups: [],
              toogleTypeList,
              modalTableHead: result.modalTableHead,
              downLoadDataSource: result.downLoadDataSource,
              downLoadHead: result.downLoadHead,
            })
          )
        }
      }
    }
  }, [exploreData])

  const loadingAnimationProps = {
    handleCancellClick: () => console.log('calcell'),
    handleRetryClick: () => console.log('请重试'),
    status: exploreDataLoading,
    error: false,
    height: '260px',
  }

  const handleCallback = async (modalProps: SendData) => {
    const { displayName, remarks } = modalProps
    const finalJumpParams = {
      ...showModal,
      source: 'panel',
      remarks,
      displayName,
      gameId,
      userId: userInfoData!.account!.id,
      phone: userInfoData!.account!.phone,
    }
    const resData = await createResultGroup(finalJumpParams)
    if (resData.code === 0) {
      messageApi.success(resData.message)
      return true
    }
    messageApi.error(resData.message)
    return false
  }

  return (
    <BodyWrapper>
      {contextHolder}
      <Left close={closeLeft}>
        <TabBar close={closeLeft}>
          <div className="tabs">{renderTab}</div>
        </TabBar>
        <Content>
          {activeTab === '探索' && <SearchCom />}
          {activeTab === '历史' && (
            <HistoryCom goSearch={() => setActiveTab('探索')} />
          )}
          {activeTab === '收藏' && (
            <CollectCom goSearch={() => setActiveTab('探索')} />
          )}
        </Content>
        <Sizer>
          <Handler>
            <SvgIconCom
              isClose={closeLeft}
              icon="#icon-jiantou_yemian_xiangyou"
              className="icon"
              handleClick={() =>
                dispatch(setterStoreState({ type: 'closeLeft', value: false }))
              }
            />
          </Handler>
        </Sizer>
      </Left>
      <RightWrapper close={false}>
        <ToolBarWrapper>
          <ToolBarLeftCom />
          <ToolBarRightCom />
        </ToolBarWrapper>
        <ContentWrapper>
          {exploreDataLoading && (
            <LoadingAnimation {...loadingAnimationProps}>
              <div />
            </LoadingAnimation>
          )}
          {!exploreDataLoading && (
            <>
              {!!exploreData!.y?.length && <ContentCom />}
              {!exploreData!.y?.length && <EmptyCom />}
            </>
          )}
        </ContentWrapper>
      </RightWrapper>
      <CreateModal
        showCreate={!!showModal}
        handleCallback={handleCallback}
        cancelCallback={() => setShowModal(null)}
      />
    </BodyWrapper>
  )
}

export default Body
