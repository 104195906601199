import moment from 'moment'

/* 获取默认一周  从范围的最大日期取那一周 */
const getDefaultWeek = timeRange => {
  const date1 = timeRange[1]
  const weekDay1 = Number(moment(date1).format('E')) // 算出这周的周几
  // 拿到这个周的第一天
  const first = moment(date1)
    .subtract(weekDay1 - 1, 'days')
    .format('YYYY-MM-DD')
  const last = moment(first).add(6, 'days').format('YYYY-MM-DD')
  return [first, last]
}

/* 获取默认一个月/季度/年  从范围的最大日期取那一个月/季度/年 */
const getDefaultTypeDate = (timeRange, type) => {
  const date1 = timeRange[1]
  const monthStart =
    moment(date1).startOf(type) >= moment(timeRange[0])
      ? moment(timeRange[0]).format('YYYY-MM-DD')
      : moment(date1).startOf(type).format('YYYY-MM-DD')
  const monthEnd =
    moment(date1).endOf(type) >= moment(timeRange[1])
      ? moment(timeRange[1]).format('YYYY-MM-DD')
      : moment(date1).startOf(type).format('YYYY-MM-DD')
  return [monthStart, monthEnd]
}

/**
 * @description: 获取默认日期
 * 当选择 天数时  返回最大范围的默认一天
 */
export const getDefaultDate = (type, timeRange) => {
  switch (type) {
    case 'byDay':
      return {
        date: timeRange[1],
        dateRange: [timeRange[1], timeRange[1]],
      }
    case 'byWeek':
      return {
        date: getDefaultWeek(timeRange)[1],
        dateRange: getDefaultWeek(timeRange),
      }
    case 'byQuarter':
      return {
        date: getDefaultTypeDate(timeRange, 'quarter')[1],
        dateRange: getDefaultTypeDate(timeRange, 'quarter'),
      }
    case 'byMonth':
      return {
        date: getDefaultTypeDate(timeRange, 'month')[1],
        dateRange: getDefaultTypeDate(timeRange, 'month'),
      }
    case 'byYear':
      return {
        date: getDefaultTypeDate(timeRange, 'year')[1],
        dateRange: getDefaultTypeDate(timeRange, 'year'),
      }
    default:
      return null
  }
}

/* 获取类型 */
export const getPicker = byTime => {
  switch (byTime) {
    case 'byDay':
      return 'date'
    case 'byWeek':
      return null
    case 'byMonth':
      return 'month'
    case 'byQuarter':
      return ''
    case 'byYear':
      return 'year'
    default:
      return ''
  }
}

const getQuarter = timeRange => {
  const dateStart = moment(timeRange[0])
  const dateEnd = moment(timeRange[1])
  const timeValues = []

  while (
    dateEnd > dateStart ||
    dateStart.format('Q') === dateEnd.format('Q') ||
    dateStart.format('Y') === dateEnd.format('Y')
  ) {
    const Y = dateStart.format('Y')
    const Q = dateStart.format('Q')
    const startMoment = moment(`${Y}-${(Number(Q) - 1) * 3 + 1}-01`).startOf(
      'quarter'
    )
    const endMoment = moment(`${Y}-${(Number(Q) - 1) * 3 + 1}-01`).endOf(
      'quarter'
    )
    const startDate =
      startMoment <= moment(timeRange[0])
        ? moment(timeRange[0]).format('YYYY-MM-DD')
        : startMoment.format('YYYY-MM-DD')
    const endDate =
      endMoment >= moment(timeRange[1])
        ? moment(timeRange[1]).format('YYYY-MM-DD')
        : endMoment.format('YYYY-MM-DD')
    timeValues.push({
      label: `${Y}-第${Q}季度`,
      value: [startDate, endDate],
    })
    dateStart.add(1, 'quarter')
  }
  return timeValues.slice(0, -1)
}

const getYear = timeRange => {
  const dateStart = moment(timeRange[0])
  const dateEnd = moment(timeRange[1])
  const timeValues = []
  while (dateEnd > dateStart || dateStart.format('Y') === dateEnd.format('Y')) {
    const year = dateStart.format('YYYY')
    const startMoment = moment(`${year}-01`).startOf('year')
    const endMoment = moment(`${year}-01`).endOf('year')
    const startDate =
      startMoment <= moment(timeRange[0])
        ? moment(timeRange[0]).format('YYYY-MM-DD')
        : startMoment.format('YYYY-MM-DD')
    const endDate =
      endMoment >= moment(timeRange[1])
        ? moment(timeRange[1]).format('YYYY-MM-DD')
        : endMoment.format('YYYY-MM-DD')
    timeValues.push({
      label: dateStart.format('YYYY'),
      value: [startDate, endDate],
    })
    dateStart.add(1, 'year')
  }
  return timeValues
}

const getWeek = timeRange => {
  let dateStart = moment(timeRange[0])
  const dateEnd = moment(timeRange[1]).add(
    7 - Number(moment(timeRange[1]).format('E')),
    'days'
  )
  const timeValues = []
  while (dateEnd > dateStart) {
    const date = dateStart.format('E') // 周几;
    const startMoment = moment(dateStart).subtract(Number(date) - 1, 'days')
    const endMoment = moment(dateStart).add(7 - Number(date), 'days')
    const startDate =
      startMoment <= moment(timeRange[0])
        ? moment(timeRange[0]).format('YYYY-MM-DD')
        : startMoment.format('YYYY-MM-DD')
    const endDate =
      endMoment >= moment(timeRange[1])
        ? moment(timeRange[1]).format('YYYY-MM-DD')
        : endMoment.format('YYYY-MM-DD')
    timeValues.push({
      label: `${startMoment.format('YYYY-MM-DD')}当周`,
      value: [startDate, endDate],
    })
    dateStart = startMoment.add(1, 'week')
  }
  return timeValues
}

const getDay = timeRange => {
  const dateStart = moment(timeRange[0])
  const dateEnd = moment(timeRange[1])
  const timeValues = []
  while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
    const startMoment = moment(dateStart).startOf('day')
    const endMoment = moment(dateStart).endOf('day')
    const startDate =
      startMoment <= moment(timeRange[0])
        ? moment(timeRange[0]).format('YYYY-MM-DD')
        : startMoment.format('YYYY-MM-DD')
    const endDate =
      endMoment >= moment(timeRange[1])
        ? moment(timeRange[1]).format('YYYY-MM-DD')
        : endMoment.format('YYYY-MM-DD')
    timeValues.push({
      label: dateStart.format('YYYY-MM-DD'),
      value: [startDate, endDate],
    })
    dateStart.add(1, 'day')
  }
  return timeValues
}

const getMonth = timeRange => {
  const dateStart = moment(timeRange[0])
  const dateEnd = moment(timeRange[1])
  const timeValues = []
  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    const startMoment = moment(dateStart).startOf('month')
    const endMoment = moment(dateStart).endOf('month')
    const startDate =
      startMoment <= moment(timeRange[0])
        ? moment(timeRange[0]).format('YYYY-MM-DD')
        : startMoment.format('YYYY-MM-DD')
    const endDate =
      endMoment >= moment(timeRange[1])
        ? moment(timeRange[1]).format('YYYY-MM-DD')
        : endMoment.format('YYYY-MM-DD')
    timeValues.push({
      label: dateStart.format('YYYY-MM-DD'),
      value: [startDate, endDate],
    })
    dateStart.add(1, 'month')
  }
  console.log(1232, timeValues)
  return timeValues
}

export const getOptionList = (timeRange, type) => {
  let list = []
  if (type === 'byDay') {
    list = getDay(timeRange)
  }
  if (type === 'byWeek') {
    list = getWeek(timeRange)
  }
  if (type === 'byMonth') {
    list = getMonth(timeRange)
  }
  if (type === 'byQuarter') {
    list = getQuarter(timeRange)
  }
  if (type === 'byYear') {
    list = getYear(timeRange)
  }
  const newArr = []
  // eslint-disable-next-line array-callback-return
  list.map((item, index) => {
    newArr.push({
      ...item,
      index,
    })
  })
  return newArr
}
