import React from 'react'
import { Statistic } from 'antd'
import PopoverTips from '@/components/PopoverTips'
import RateCom from '@/pages/BusinessAnalysis/components/TemplateRate'
import { formatNumber } from '@/utiles/common'
import {
  Wrapper,
  TitleAndIconWrapper,
  TitleWrapper,
  TimeWrapper,
  CounterWrapper,
  CounterNum,
  IconWrapper,
} from './styles'
import SvgIcon from '@/components/SvgIcon'

interface ICounterTabs {
  data: IRateIndicatorsData
  boardTitle: string
  tips: string
  unit: string
  downLoadHead: string[]
  downLoadDataSource: string[]
  handleRefresh: () => void
  handleExplore: () => void
  handleDownLoad: () => void
}
export default function HeadCom(props: ICounterTabs) {
  const {
    handleExplore,
    handleRefresh,
    handleDownLoad,
    data,
    tips,
    boardTitle,
    unit = '',
  } = props

  const {
    date,
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
    totalNumber,
  } = data

  const rateProps = {
    yearOnYear,
    ringRatio,
    ringRatioText,
    yearOnYearText,
  }

  return (
    <Wrapper>
      <TitleAndIconWrapper>
        <TitleWrapper>
          {boardTitle}
          <PopoverTips content={tips} isBlackStyle placement="top" />
        </TitleWrapper>
        <div className="iconWrapper">
          <PopoverTips content="刷新" isBlackStyle placement="top">
            <IconWrapper>
              <SvgIcon
                icon="#icon-shuaxin1"
                handleClick={handleRefresh}
                className="icon hover-toogle toogle-shuaxin"
              />
            </IconWrapper>
          </PopoverTips>
          <PopoverTips content="探索" isBlackStyle placement="top">
            <IconWrapper>
              <SvgIcon
                icon="#icon-fangda"
                handleClick={handleExplore}
                className="icon hover-toogle toogle-shuaxin"
              />
            </IconWrapper>
          </PopoverTips>
          <PopoverTips
            content="按当前视图导出明细数据"
            isBlackStyle
            placement="top"
          >
            <IconWrapper>
              <SvgIcon
                icon="#icon-xiazai"
                handleClick={handleDownLoad}
                className="icon hover-toogle icon-xiazai"
              />
            </IconWrapper>
          </PopoverTips>
        </div>
      </TitleAndIconWrapper>
      <div>
        <TimeWrapper>{date}</TimeWrapper>
        <CounterWrapper>
          <CounterNum>
            <Statistic
              className="StatisticWrap"
              value={formatNumber(totalNumber).value}
              suffix={formatNumber(totalNumber).suffix + unit}
            />
          </CounterNum>
        </CounterWrapper>
        <RateCom {...rateProps} />
      </div>
    </Wrapper>
  )
}
