import React from 'react'
import { useAppSelector } from '@/hook/reduxHook'
import PopoverTips from '@/components/PopoverTips'
import SvgIcon from '@/components/SvgIcon'
import {
  CardWrapper,
  CardHeader,
  CardDesc,
  CardNoDesc,
  CardNoContainer,
  NoDescTitle,
  CardPanel,
  PanelItem,
  PanelTitle,
  PanelValue,
  PanelValueText,
  CardFooter,
  HeaderContainer,
  HeaderSub,
  HeaderContainerIcon,
  HeaderContainerTitle,
  HeaderContainerMain,
  HeaderContainerType,
  CardFooterLeft,
  CardFooterRight,
} from './styles'

interface Props {
  hoverItem: ExtendConditionDatumList | null
}
function Card(props: Props) {
  const { hoverItem } = props
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)
  const { companyName } = userInfoData!
  const goMetaManagement = () => {
    window.open(
      `/${companyName}/businisAnalysis/metaEventManagement/eventManagement`,
      '_blank'
    )
  }
  return (
    <CardWrapper>
      <CardHeader>
        <HeaderContainer>
          <HeaderContainerIcon>
            <SvgIcon icon={`#${hoverItem?.descIcon}`} className="icon" />
          </HeaderContainerIcon>
          <HeaderContainerTitle>
            <HeaderContainerMain>{hoverItem?.label}</HeaderContainerMain>
          </HeaderContainerTitle>
          <HeaderContainerType>{hoverItem?.descText}</HeaderContainerType>
        </HeaderContainer>
        <HeaderSub>{hoverItem?.labelEn}</HeaderSub>
      </CardHeader>
      {hoverItem?.dataType === '数值' && (
        <CardDesc>APP store、应用宝、豌豆荚等渠道</CardDesc>
      )}
      {hoverItem?.dataType === '文本' && (
        <CardNoDesc>
          <CardNoContainer>
            <SvgIcon icon="#icon-beizhu" className="icon" />
            <NoDescTitle>暂无备注</NoDescTitle>
          </CardNoContainer>
        </CardNoDesc>
      )}
      <CardPanel>
        <PanelItem>
          <PanelTitle>来自属性</PanelTitle>
          <PanelValue>
            <SvgIcon icon="#icon-guanlian" className="icon" />
            <PanelValueText>注册渠道</PanelValueText>
          </PanelValue>
        </PanelItem>
        <PanelItem>
          <PanelTitle>维度表大小</PanelTitle>
          <PanelValue>--</PanelValue>
        </PanelItem>
      </CardPanel>
      <CardFooter>
        <CardFooterLeft>
          <SvgIcon icon={`#${hoverItem?.descIcon}`} className="icon" />
          {hoverItem!.dataType}
        </CardFooterLeft>
        <PopoverTips placement="top" content="前往属性管理" isBlackStyle>
          <CardFooterRight>
            <SvgIcon
              icon="#icon-31zhuanfa"
              className="icon"
              handleClick={goMetaManagement}
            />
          </CardFooterRight>
        </PopoverTips>
      </CardFooter>
    </CardWrapper>
  )
}

export default React.memo(Card)
