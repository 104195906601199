/* eslint-disable indent */
import styled from 'styled-components'
import { InputNumber, Dropdown, Menu } from 'antd'

export const ElementPositionComWrapper = styled.div`
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  vertical-align: top;
`
export const Word = styled.span`
  margin-right: 4px;
  color: #67729d;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  vertical-align: top;
`

export const NumberWrapper = styled(InputNumber)`
  width: 50px;
  height: 28px !important;
  margin-right: 4px;
  color: #67729d;
  font-size: 14px !important;
  vertical-align: top;
  border: 1px solid #f0f2f5;
  border-radius: 2px;

  :hover {
    background-color: #fff;
    border-color: rgb(109, 173, 253);
  }
`
export const OperatorSelect = styled(Dropdown)`
  display: inline-block;
  height: 28px;
  margin-right: 10px;
  margin-bottom: 4px;
  color: rgb(66, 84, 109);
  padding: 0 6px;
  font-size: 14px;
`

export const MenuWrapper = styled(Menu)`
  > li {
    padding: 3px;
    :hover {
      background: unset;
    }
  }
`

interface ActiveProps {
  active: boolean
}

export const MenuItemWrapper = styled.div<ActiveProps>`
  min-width: 80px;
  max-width: 280px;
  height: 32px;
  min-height: 32px;
  margin-bottom: 0;
  color: rgb(66, 84, 109);
  font-size: 14px;
  line-height: 28px;
  border-radius: 2px;
  background-color: ${props => (props.active ? '#e4f2ff !important' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .contentItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    text-align: left;
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props =>
      props.active ? 'rgb(61,144,255) !important' : 'rgb(66, 84, 109)'};
    .iconWrapper {
      background-color: ${props =>
        props.active ? 'rgb(189,221,255) !important' : 'rgb(246, 248, 250)'};
      color: ${props =>
        props.active ? 'rgb(61,144,255) !important' : 'rgb(66, 84, 109)'};
      display: inline-block;
      margin-right: 6px;
      padding: 0 3px;
      line-height: 17px;
      border-radius: 8px;
      .icon {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
      }
    }
  }
  .tipIcon {
    color: rgb(141, 158, 185);
    font-size: 16px;
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  :hover {
    background-color: rgb(246, 248, 250);
  }
`
