import styled from 'styled-components'

export const PopoverText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-left: 6px;
  .tip-icon {
    height: 16px;
    margin-left: 5px;
  }
`

export const DefaultSvgBox = styled.span`
  /* display: inline-block; */
  min-width: 12px;
  min-height: 12px;
`

interface MaskDivProps {
  show: boolean
}

export const MaskDiv = styled.div<MaskDivProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`
/* 提示框的样式 */
export const PopoverTipsStyle = {
  color: '#fff',
  fontSize: '14px',
  backgroundColor: '#324157',
}
/* 提示框文本的样式 */
export const PopoverTextStyle = {
  display: 'inline-block',
  color: '#67729d',
  fontSize: '16px',
  verticalAlign: 'text-bottom',
  marginLeft: 0,
}

export const overlayInnerDefaultStyle = {
  width: '160px',
  padding: '10px 8px',
}
