import React, { useState } from 'react'
import TimePicker, { IhandleParams } from '@/components/TimePicker'
import { getDefaultRangeTime } from '@/utiles/common'
import PopoverCondition from '../PopoverCondition'

interface ITimePickerCom {
  handleTimePickerOkClick: (arg: IhandleParams) => void
  defaultTimeRange?: string[]
  defaultDateType?: number[]
  isBodyRender?: boolean
  defaultContentName: string | React.ReactElement
}

export default function TimePickerCom(props: ITimePickerCom) {
  const {
    handleTimePickerOkClick,
    defaultTimeRange = getDefaultRangeTime,
    defaultDateType = [2, 2],
    defaultContentName,
    isBodyRender = false,
  } = props
  const [visible, setVisible] = useState<boolean>(false)

  const Content = (
    <TimePicker
      handleVisible={arg => setVisible(arg)}
      handleOk={arg => handleTimePickerOkClick(arg)}
      defaultTimeRange={defaultTimeRange}
      defaultDateType={defaultDateType}
    />
  )

  return (
    <PopoverCondition
      visible={visible}
      isBodyRender={isBodyRender}
      changeVisible={(arg: boolean) => setVisible(arg)}
      Content={Content}
      ContentName={defaultContentName}
    />
  )
}
