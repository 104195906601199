/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useMemo } from 'react'
import { useSetState } from 'ahooks'
import { SearchOutlined } from '@ant-design/icons'
import MyIcon from '@/components/MyIcon'
import SvgIcon from '@/components/SvgIcon'
import ListItemCom from './ListItem'
import CardCom from './Card'
import { SetStateProps, handleFieldFunc } from './utils'
import {
  ConditionLeftWrapper,
  ConditionLeft,
  SearchWrapper,
  SearchHeader,
  SearchCom,
  SearchTabs,
  TabItem,
  ContainerWrapper,
  ContainerTop,
  ContainerList,
  OtherTabList,
  ListName,
  ListItem,
} from './styles'

interface ChooseItemProps {
  label: string
  descIcon: string
  descText: string
}

interface Props {
  chooseItem: ChooseItemProps
  conditionDatumList: ExtendConditionDatum[]
  fieldCollectionList: ExtendConditionDatum
  iconPath: string
  openPop: boolean
  groupName?: string[]
  needCollect?: boolean // 是否需要收藏
  setOpenPop: (val: boolean) => void
  handleCondition: (item: ExtendConditionDatumList) => void
  handleFieldCollection: (item: HandleFieldArr[]) => void
}
const defaultState = {
  hoverItem: null,
  searchWord: '',
  activeTab: '全部',
  chooseItemLabel: '',
  conditionDatumList: [],
  fieldCollectionList: {
    name: '收藏',
    icon: '',
    iconPath: '',
    list: [],
    type: 0,
  },
  handleFieldArr: [],
}

/**
 * @description: 全局属性列表 【不带checkBox的 】
 * @return {*}
 */
function ExtendConditionCom(props: Props) {
  const {
    handleCondition,
    handleFieldCollection,
    setOpenPop,
    groupName = [], // 分组项已选的名称组 （分组项不能重复选择）
    openPop,
    chooseItem,
    conditionDatumList,
    fieldCollectionList,
    iconPath,
    needCollect = true,
  } = props
  const otherTabs = conditionDatumList?.length
    ? conditionDatumList.map(item => item.name)
    : []
  const [data, setData] = useSetState<SetStateProps>({
    ...defaultState,
    conditionDatumList,
    fieldCollectionList,
  })
  const tabsArr = ['全部', ...otherTabs]
  useEffect(() => {
    setTimeout(() => {
      setData({
        ...defaultState,
        conditionDatumList,
        fieldCollectionList,
        chooseItemLabel: chooseItem.label,
      })
    }, 100)
    return () => {
      setData({
        ...defaultState,
        conditionDatumList,
        fieldCollectionList,
      })
    }
  }, [openPop])

  const tabsList = useMemo(() => {
    if (data.conditionDatumList?.length) {
      if (data.activeTab === '全部') {
        if (data.searchWord) {
          const arr = [] as ExtendConditionDatum[]
          data.conditionDatumList?.forEach(item => {
            const group = [] as ExtendConditionDatumList[]
            item.list.forEach(itm => {
              if (itm.label.indexOf(data.searchWord) !== -1) {
                group.push(itm)
              }
            })
            arr.push({
              ...item,
              list: group,
            })
          })
          const result = arr.filter(item => item.list.length)
          return result
        }
        return data.conditionDatumList
      }
      const arr = data.conditionDatumList?.filter(
        item => item.name === data.activeTab
      )
      if (data.searchWord) {
        const itemArr = [] as ExtendConditionDatum[]
        arr.forEach(item => {
          const group = [] as ExtendConditionDatumList[]
          item.list.forEach(itm => {
            if (itm.label.indexOf(data.searchWord) !== -1) {
              group.push(itm)
            }
          })
          itemArr.push({
            ...item,
            list: group,
          })
        })
        return itemArr
      }
      return arr
    }
    return []
  }, [data.conditionDatumList, data.activeTab, data.searchWord])

  const favoriteList = useMemo(() => {
    if (data.searchWord) {
      const group = [] as any
      data.fieldCollectionList!.list.forEach(
        (item: ExtendConditionDatumList) => {
          if (item.label.indexOf(data.searchWord) !== -1) {
            group.push(item)
          }
        }
      )
      return {
        ...fieldCollectionList,
        list: group,
      }
    }
    return data.fieldCollectionList
  }, [data.searchWord, data.fieldCollectionList.list])

  function RenderSearchTabs() {
    return (
      <SearchTabs>
        {tabsArr.map((item, index) => (
          <TabItem
            key={index}
            active={data.activeTab === item}
            onClick={() => {
              setData({
                hoverItem: null,
                chooseItemLabel: '',
                activeTab: item,
              })
            }}
          >
            {item}
          </TabItem>
        ))}
      </SearchTabs>
    )
  }

  const closeModal = () => {
    setData(defaultState)
    handleFieldCollection(data.handleFieldArr)
    setOpenPop(false)
  }

  /* 处理收藏 */
  const handleField = (item: ExtendConditionDatumList) => {
    const result = handleFieldFunc(data, item)
    setData({
      fieldCollectionList: result.fieldCollectionList,
      conditionDatumList: result.conditionDatumList,
      handleFieldArr: result.handleFieldArr, // 需要抛出处理得组
    })
  }

  const handleHoverItem = React.useCallback(
    (it: ExtendConditionDatumList | null, isCollect: boolean) => {
      const newIt = it
        ? {
            ...it,
            infoData: {
              ...it!.infoData,
              labelId: `${it!.infoData.id}${isCollect ? '-1' : ''}`,
            },
          }
        : null
      setData({
        hoverItem: newIt,
      })
    },
    []
  )

  const handleConditionFunc = (value: ExtendConditionDatumList) => {
    handleCondition(value)
    closeModal()
  }
  /* 收藏列表  有个取消收藏的功能 */
  const RenderFavoriteCom = (
    <div>
      {!!(
        !data.searchWord ||
        (data.searchWord && favoriteList!.list.length)
      ) && (
        <ListName>
          <MyIcon
            type={data.fieldCollectionList.icon}
            scriptUrl={iconPath}
            className="icon collectIcon"
          />
          <span>{data.fieldCollectionList.name}</span>
        </ListName>
      )}
      {!favoriteList!.list.length && !data.searchWord && (
        <ListItem isChoose={false} isText isDisabled={false}>
          <div className="descText">
            <span>点击选项右侧</span>
            <SvgIcon icon="#icon-shoucang" className="icon" />
            <span>添加收藏</span>
          </div>
        </ListItem>
      )}
      <div>
        {favoriteList!.list.map(
          (item: ExtendConditionDatumList, index: number) => (
            <ListItemCom
              key={index}
              item={{
                ...item,
                infoData: {
                  ...item.infoData,
                  labelId: `${item.infoData.id}-1`,
                },
              }}
              searchWord={data.searchWord}
              hoverItem={data.hoverItem}
              chooseItemLabel={data.chooseItemLabel}
              groupName={groupName}
              handleCondition={handleConditionFunc}
              handleFieldCollection={() => handleField(item)}
              handleHoverItem={it => handleHoverItem(it, true)}
            />
          )
        )}
      </div>
    </div>
  )

  const RenderContainer = (
    <ContainerWrapper>
      <ContainerList needTop={data.activeTab !== '全部'} id="ContainerList">
        {needCollect && data.activeTab === '全部' && RenderFavoriteCom}
        <div>
          {tabsList.map((item, index) => (
            <div key={index}>
              <ContainerTop>
                <div>
                  <MyIcon type={item.icon} scriptUrl={iconPath} />
                  <span>{item.name}</span>
                </div>
              </ContainerTop>
              <OtherTabList>
                {item.list.map((itm, idx) => (
                  <ListItemCom
                    key={idx}
                    item={{
                      ...itm,
                      infoData: {
                        ...itm.infoData,
                        labelId: `${itm.infoData.id}`,
                      },
                    }}
                    groupName={groupName}
                    hoverItem={data.hoverItem}
                    chooseItemLabel={data.chooseItemLabel}
                    searchWord={data.searchWord}
                    handleCondition={handleConditionFunc}
                    handleFieldCollection={handleField}
                    handleHoverItem={it => handleHoverItem(it, false)}
                  />
                ))}
              </OtherTabList>
            </div>
          ))}
        </div>
      </ContainerList>
    </ContainerWrapper>
  )

  const searchContentCom = (
    <SearchWrapper>
      <SearchHeader>
        <SearchCom
          prefix={<SearchOutlined className="icon" />}
          placeholder="请输入搜索"
          onChange={e =>
            setData({
              searchWord: e.target.value,
            })
          }
          style={{ width: 340 }}
          allowClear
          bordered={false}
        />
      </SearchHeader>
      <RenderSearchTabs />
      {RenderContainer}
      {data.hoverItem && <CardCom hoverItem={data.hoverItem} />}
    </SearchWrapper>
  )

  return (
    <ConditionLeftWrapper
      placement="bottomLeft"
      trigger="click"
      content={searchContentCom}
      open={openPop}
      needMask={openPop}
      setOpen={(open: boolean) => {
        if (!open) {
          return closeModal()
        }
        setOpenPop(open)
      }}
      overlayInnerStyle={{
        width: 'auto',
        padding: 0,
      }}
    >
      <ConditionLeft className="hoverBtn" isOpen={openPop}>
        <SvgIcon icon={`#${chooseItem.descIcon}`} className="icon" />
        <span className="label">{chooseItem.label}</span>
        {chooseItem.descText && <span className="sign">*</span>}
      </ConditionLeft>
    </ConditionLeftWrapper>
  )
}

export default React.memo(ExtendConditionCom)
