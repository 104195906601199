import styled from 'styled-components'

export const Suffix = styled.span`
  margin-left: 6px;
  word-break: break-all;
  min-width: 30px;
  display: inline-block;
`

export const Section = styled.span`
  margin-left: 6px;
  word-break: break-all;
`
