import { axiosInstance } from '..'

export interface IResponse<T> {
  code: number
  data: T
  message: string
  msg?: string
}

interface SortItem {
  id: number
  pid: number
  name: string
  children?: SortItem[]
}

interface SortDepartmentParams {
  deptList: SortItem[]
}

// ----管理中心

// 获取职位列表
export const getPositionList = (): Promise<IResponse<IHGetPositionList>> =>
  axiosInstance.get('/apc/permission/v1/account/postList')

// 新增
export const addUserManageRole = (
  params: IAddParams
): Promise<IResponse<null>> =>
  axiosInstance.post('/apc/permission/v1/accounts', params)
//  删除
export const deleteAccountRole = (
  id: number
): Promise<IResponse<IHGetMOdalObj>> =>
  axiosInstance.delete(`/apc/permission/v1/accounts/${id}`)
//  modal回显的数据
export const getAccountResetInfo = (
  id: number
): Promise<IResponse<IHGetMOdalObj>> =>
  axiosInstance.get(`/apc/permission/v1/accounts/${id}`)
//  修改
export const changeUserManageInfo = (
  id: number,
  params: IChangeParams
): Promise<IResponse<IHGetMOdalObj>> =>
  axiosInstance.put(`/apc/permission/v1/accounts/${id}`, params)
//  账户表格数据
export const getAccountTableList = (
  params: IGetAccountTableListParams
): Promise<IResponse<IHGetUserManageTableObj>> =>
  axiosInstance.get('/apc/permission/v1/accounts', { params })
// 获取树形列表
export const getTreeList = (): Promise<IResponse<IHGetTreeListObj>> =>
  axiosInstance.get('/apc/permission/v1/account/deptList')
// 表格里面账户绑定角色
export const accountBindRole = (
  params: IaccountBindRoleParams
): Promise<IResponse<null>> =>
  axiosInstance.post('/apc/permission/v1/account/SaveRole', params)

export const toogleStatus = (
  params: ItoogleStatusParams
): Promise<IResponse<null>> =>
  axiosInstance.post('/apc/permission/v1/account/modifyStatus', params)

// 新增部门
export const addDepartmentRequest = (params: any): Promise<IResponse<null>> =>
  axiosInstance.post('/permission/v1/depts', params)

// 编辑部门
export const editDepartmentRequest = (
  id: number,
  pid: number,
  name: string
): Promise<IResponse<null>> =>
  axiosInstance.put(`/permission/v1/depts/${id}`, {
    name,
    pid,
  })

// 删除部门
export const deleteDepartmentRequest = (id: number): Promise<IResponse<null>> =>
  axiosInstance.delete(`/permission/v1/depts/${id}`)

// 部门排序事件
export const sortDepartmentRequest = (
  params: SortDepartmentParams
): Promise<IResponse<null>> =>
  axiosInstance.post('/permission/v1/depts/sort', params)
