import { axiosInstance } from '@/http/index'

export interface IResponse<T> {
  code: number
  data: T
  msg: string
  message?: string
}

// 获取属性分析接口数据
export const getAttributeAnalysisData = (
  params: IAddAnounce
): Promise<IResponse<UserAnalysisAttributeRightData>> =>
  axiosInstance.post('/rapb/econ/v1/userAnalyze/attributeAnalysis', params)

// 创建条件分群
export const createConditionGroups = (params: any): Promise<IResponse<any>> =>
  axiosInstance.post('/rapb/econ/v1/userAnalyze/conditionalGrouping', params)

// 获取分群列表
export const getClusterList = (params: any): Promise<IResponse<any>> =>
  axiosInstance.get('/rapb/econ/v1/userAnalyze/getClusterList', { params })

// 删除分群
export const deleteGroupItem = (data: any): Promise<IResponse<any>> =>
  axiosInstance.delete('/rapb/econ/v1/userAnalyze/groupingDelete', { data })

// 编辑条件分群
export const updateGroupItem = (data: any): Promise<IResponse<any>> =>
  axiosInstance.put('/rapb/econ/v1/userAnalyze/conditionalGroupingUpdate', data)

// 查看条件详情
export const getGroupItemDetail = (params: any): Promise<IResponse<any>> =>
  axiosInstance.get('/rapb/econ/v1/userAnalyze/groupingInfo', { params })

// 刷新单条数据
export const refreshSingleData = (data: any): Promise<IResponse<any>> =>
  axiosInstance.put('/rapb/econ/v1/userAnalyze/groupingRefresh', data)

// 分群用户列表
export const cohortsUserList = (params: any): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/userAnalyze/grouping/userInfo', params)

export const attributeUserList = (data: any): Promise<IResponse<any>> =>
  axiosInstance.post('/api/econ/v1/userAnalyze/attributeAnalysisInfo', data)

// 获取结果分群默认展示名称
export const getDefaultResultName = (params: any): Promise<any> =>
  axiosInstance.get('/rapb/econ/v1/userAnalyze/getGroupingName', { params })

// 新建结果分群
export const createResultGroup = (data: any): Promise<IResponse<any>> =>
  axiosInstance.post('/rapb/econ/v1/userAnalyze/resultGrouping', data)

// 结果分群修改
export const updateResultGroup = (data: any): Promise<IResponse<any>> =>
  axiosInstance.put('/rapb/econ/v1/userAnalyze/resultGroupingUpdate', data)
