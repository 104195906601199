import React, { useEffect } from 'react'
import { useSetState } from 'ahooks'
import { Modal, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import DataTable from '@/components/DataTable'
import { useAppSelector } from '@/hook/reduxHook'
import { createResultGroup } from '@/http/businessAnalysis/userAnalysis/attribute'
import CreateModal from '@/pages/BusinessAnalysis/components/CreateResultGroupModal'
import {
  handleRetainedModalHead,
  handleRetainedModalSource,
  handleDefaultModalSource,
  handleDefaultModalHead,
} from '../handleBoardData'
import { handleParams } from '../common'

interface SendData {
  displayName: string // 分群名
  remarks: string // 分群简介
}

interface PageProps {
  page: number
  pageSize: number
  modalTableColumns: any[]
  modalDataSource: any[]
}

interface ModalTableProps {
  showModalTitle: string
  boardTitle: string
  exploreData: any
  checkType: string
  byTime: string
  modalTableHead: []
  frozenData: any
  companyName: string
  handleClose: () => void
}
function ModalTable(props: ModalTableProps) {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [showModal, setShowModal] = React.useState<object | null>(null)
  const gameId = useAppSelector(state => state.userInfo.gameId)
  const userInfoData = useAppSelector(state => state.userInfo.userInfoData)

  const {
    showModalTitle,
    boardTitle,
    exploreData,
    checkType,
    byTime,
    modalTableHead,
    frozenData,
    companyName,
    handleClose,
  } = props

  const handleNavigate = (navigateProps: any) => {
    let stateProps = {
      ...navigateProps,
      ...frozenData,
      grouping: frozenData?.grouping
        ? JSON.stringify(frozenData.grouping)
        : undefined,
    }
    stateProps = handleParams(stateProps)
    navigate(`/${companyName}/customInfo`, {
      state: stateProps,
    })
  }

  /* 创建结果分区 */
  const handleCreateModal = (navigateProps: any) => {
    let stateProps = {
      ...navigateProps,
      ...frozenData,
      grouping: frozenData?.grouping
        ? JSON.stringify(frozenData.grouping)
        : undefined,
    }
    stateProps = handleParams(stateProps)
    setShowModal(stateProps)
  }

  const [tableProps, setTableProps] = useSetState<PageProps>({
    page: 1,
    pageSize: 10,
    modalTableColumns: [],
    modalDataSource: [],
  })

  const getDataSource = () => {
    let modalDataSource: any[] = []
    const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
    if (needRetainedCountTitle.includes(boardTitle)) {
      modalDataSource = handleRetainedModalSource(
        exploreData,
        checkType,
        showModalTitle
      )
    } else {
      modalDataSource = handleDefaultModalSource(exploreData, showModalTitle)
    }
    return modalDataSource
  }

  const getColumns = () => {
    const needRetainedCountTitle = ['各渠道新增留存', '7日LTV(不支持设备Id)'] // 需要转换的表单
    if (needRetainedCountTitle.includes(boardTitle)) {
      const result = handleRetainedModalHead(
        exploreData,
        handleNavigate,
        handleCreateModal,
        checkType,
        showModalTitle,
        boardTitle
      )
      return result
    }
    if (exploreData.z.length) {
      return handleDefaultModalHead(
        exploreData,
        byTime,
        showModalTitle,
        boardTitle,
        handleNavigate,
        handleCreateModal
      )
    }
    return modalTableHead
  }

  useEffect(() => {
    if (showModalTitle && exploreData) {
      const resultColumns = getColumns()
      const resultSource = getDataSource()
      setTableProps({
        modalTableColumns: resultColumns,
        modalDataSource: resultSource,
      })
    }
  }, [showModalTitle, exploreData])

  const handleCallback = async (modalProps: SendData) => {
    const { displayName, remarks } = modalProps
    const finalJumpParams = {
      ...showModal,
      source: 'panel',
      remarks,
      displayName,
      gameId,
      userId: userInfoData!.account!.id,
      phone: userInfoData!.account!.phone,
    }
    const resData = await createResultGroup(finalJumpParams)
    if (resData.code === 0) {
      messageApi.success(resData.message)
      return true
    }
    messageApi.error(resData.message)
    return false
  }

  return (
    <div>
      {contextHolder}
      <Modal
        title={`${showModalTitle}分组详情`}
        open={!!showModalTitle}
        onCancel={handleClose}
        width="80%"
        footer={null}
        destroyOnClose
      >
        <DataTable
          pagination={
            tableProps.modalDataSource.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  total: tableProps.modalDataSource.length,
                  pageSize: tableProps.pageSize,
                  current: tableProps.page,
                  showSizeChanger: true,
                  onChange: (pages, pageSize) => {
                    setTableProps({
                      page: pages,
                      pageSize: pageSize,
                    })
                  },
                  showTotal: t => `共${t}条记录`,
                }
              : false
          }
          columns={tableProps.modalTableColumns}
          dataSource={tableProps.modalDataSource}
        />
      </Modal>
      <CreateModal
        showCreate={!!showModal}
        handleCallback={handleCallback}
        cancelCallback={() => setShowModal(null)}
      />
    </div>
  )
}

export default ModalTable
