import React, { useState } from 'react'
import { Radio, InputNumber } from 'antd'
import type { RadioChangeEvent } from 'antd'
import SvgIcon from '@/components/SvgIcon'
import {
  EditContentWrapper,
  EditContent,
  SelfSectionWrapper,
  SelfSectionTop,
  SelfSectionContent,
  RangeItemWrapper,
  SelfSectionAdd,
  AddBtn,
  ClearBtnIcon,
  ClearBtn,
  Symbol,
  Static,
  SetBatch,
  StepWrapper,
  StepIconWrapper,
  DeleteIconWrapper,
} from './styles'

interface ValueProps {
  min: string
  max: string
}
interface ItemProps {
  item: ValueProps
  index: number
}

const defaultResultArr = [
  {
    min: '-∞',
    max: '0',
  },
  {
    min: '0',
    max: '+∞',
  },
]

interface RadioGroupProps {
  handleCondition: (newList: GroupItem) => void
  itemEdit: GroupItem
}

function RadioGroup(props: RadioGroupProps) {
  const { handleCondition, itemEdit } = props
  const [showBatch, setShowBatch] = useState(false)

  const [focusIndex, setFocusIndex] = useState(-1)

  const resultArr = itemEdit.value as ValueProps[]

  React.useEffect(() => {
    if (itemEdit.valueType === 2) {
      const domDiv = document.getElementById('SelfSectionContent')
      if (domDiv) {
        setTimeout(() => {
          domDiv.scrollTop = domDiv.scrollHeight
        }, 100)
      }
    }
  }, [itemEdit.value])

  // console.log('resultArr区间', resultArr)
  /*
    添加区间
    如果是['-∞', '0'] 则 [0, 1]
    倒数第二个的数组的右边值 + 1 即[0,1] => [1,2]
    从倒数第二个开始变
  */
  const handleAdd = () => {
    const beforeArr = resultArr.slice(0, -2) //
    const SecondLastItem = resultArr[resultArr.length - 2] // 取倒数第二个
    let LastItem = resultArr[resultArr.length - 1] // 取最后一个
    const AddArr = {
      min: SecondLastItem.max,
      max: String(Number(SecondLastItem.max) + 1),
    } // 新增的区间
    LastItem = {
      min: AddArr.max,
      max: LastItem.max,
    } // 修改最后一项
    const newArr = [...beforeArr, SecondLastItem, AddArr, LastItem]
    handleCondition({
      ...itemEdit,
      value: newArr,
    })
  }

  /* 修改区间 */
  const handleChange = (val: string | null, index: number) => {
    const newArr: ValueProps[] = []
    let newItem: ValueProps
    resultArr.forEach((item, idx) => {
      if (idx < index) {
        return newArr.push(item)
      }
      // 修改当前
      if (idx === index) {
        newItem = {
          min: item.min,
          max: String(val),
        }
        return newArr.push(newItem)
      }
      // 检测后面的区间范围
      if (idx > index && idx !== resultArr.length - 1) {
        //  左边不能小于val  一旦小于 则左边为val
        if (Number(item.min) < Number(newItem.max)) {
          newItem = {
            min: newItem.max,
            max: item.max,
          }
        } else {
          newItem = item
        }
        // 右边的值 如果小于等于val 则val+1 否则不变
        if (Number(newItem.max) <= Number(newItem.min)) {
          newItem = {
            min: newItem.min,
            max: String(Number(newItem.min) + 1),
          }
        }
        return newArr.push(newItem)
      }
      // 检测最后一个区间
      if (idx === resultArr.length - 1) {
        if (Number(item.min) < Number(newItem.max)) {
          newItem = {
            min: newItem.max,
            max: item.max,
          }
        } else {
          newItem = item
        }
        return newArr.push(newItem)
      }
    })
    handleCondition({
      ...itemEdit,
      value: newArr,
    })
  }

  function RenderRangeItem(RangeItemProps: ItemProps) {
    const { item, index } = RangeItemProps
    return (
      <RangeItemWrapper>
        <Symbol>{index === 0 ? '(' : '['}</Symbol>
        <Static>{item.min}</Static>
        <Symbol>,</Symbol>
        {index === resultArr.length - 1 && <Static>{item.max}</Static>}
        {index !== resultArr.length - 1 && (
          <InputNumber
            value={item.max}
            onChange={val => {
              if (!val || val <= item.min) return // 禁止小于等于左边
              handleChange(val, index)
            }}
            size="small"
            style={{ width: 68, height: 26 }}
            autoFocus={focusIndex === index}
            onFocus={() => setFocusIndex(index)}
            onBlur={() => setFocusIndex(-1)}
          />
        )}
        <Symbol>{index === resultArr.length - 1 ? ']' : ')'}</Symbol>
        {index !== 0 && index !== resultArr.length - 1 && (
          <DeleteIconWrapper
            onClick={() => {
              const newArr = JSON.parse(JSON.stringify(resultArr))
              newArr.splice(index, 1)
              handleCondition({
                ...itemEdit,
                value: newArr,
              })
            }}
          >
            <SvgIcon icon="#icon-guanbi1" className="icon" />
          </DeleteIconWrapper>
        )}
      </RangeItemWrapper>
    )
  }

  const [focusType, setFocusType] = useState<'' | 'step' | 'stepLen'>('')
  const [stepLenValue, setStepLenValue] = useState('50') // 步长
  const [stepValue, setStepValue] = useState('1') // 步数

  const handleStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/
    if (focusType === 'stepLen') {
      if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
        setStepLenValue(inputValue)
      }
    } else if (inputValue.replace(/\D/g, '') !== '') {
      return setStepValue(String(parseInt(inputValue, 10)))
    }
  }

  const handleBlur = () => {
    let valueTemp = focusType === 'stepLen' ? stepLenValue : stepValue
    if (focusType === 'stepLen') {
      if (valueTemp.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
        valueTemp = valueTemp.slice(0, -1)
      }
      setStepLenValue(valueTemp.replace(/0*(\d+)/, '$1'))
    } else if (valueTemp.replace(/\D/g, '') !== '') {
      return setStepValue(String(parseInt(valueTemp, 10)))
    }
    setFocusType('')
  }

  /*
    新增 步数*步长
    根据步数 n步代表多出n行
    新增的第一个左边是0，右边是步长
    从最后一个往前推 是不会改变
  */
  const handleClick = () => {
    const stepArr: any[] = []
    const mapArr = Array(Number(stepValue)).fill(1)
    const SecondToLast = resultArr.slice(resultArr.length - 2, -1) // 取倒数第二个
    mapArr.map((item, index) => {
      if (index === 0) {
        return stepArr.push({
          min: SecondToLast[SecondToLast.length - 1].max,
          max: String(
            Number(SecondToLast[SecondToLast.length - 1].max) +
              Number(stepLenValue)
          ),
        })
      }
      return stepArr.push({
        min: stepArr[index - 1].max,
        max: String(Number(stepArr[index - 1].max) + Number(stepLenValue)),
      })
    })
    const beforeArr = resultArr.slice(0, -1)
    const lastItem = {
      min: stepArr[stepArr.length - 1].max,
      max: '+∞',
    }
    handleCondition({
      ...itemEdit,
      value: [...beforeArr, ...stepArr, lastItem],
    })
    setShowBatch(false)
  }

  function SelfSectionCom() {
    return (
      <SelfSectionWrapper>
        <SelfSectionTop>
          区间
          <ClearBtn
            onClick={() =>
              handleCondition({
                ...itemEdit,
                value: defaultResultArr,
              })
            }
          >
            <ClearBtnIcon icon="#icon-lajitong1" className="icon" />
            全部清空
          </ClearBtn>
        </SelfSectionTop>
        <SelfSectionContent id="SelfSectionContent">
          {resultArr.map((item, index) => (
            <RenderRangeItem item={item} index={index} key={index} />
          ))}
        </SelfSectionContent>
        <SelfSectionAdd>
          <AddBtn onClick={handleAdd}>
            <SvgIcon icon="#icon-tianjia" className="icon" />
            添加区间
          </AddBtn>
          <AddBtn onClick={() => setShowBatch(true)}>
            <SvgIcon icon="#icon-tianjia" className="icon" />
            批量添加
          </AddBtn>
        </SelfSectionAdd>
        {showBatch && (
          <SetBatch>
            步长
            <StepWrapper
              size="small"
              autoFocus={focusType === 'stepLen'}
              value={stepLenValue}
              onChange={handleStepChange}
              onBlur={handleBlur}
              onFocus={() => setFocusType('stepLen')}
            />
            步数
            <StepWrapper
              size="small"
              autoFocus={focusType === 'step'}
              value={stepValue}
              onChange={handleStepChange}
              onBlur={handleBlur}
              onFocus={() => setFocusType('step')}
            />
            <StepIconWrapper onClick={handleClick}>
              <SvgIcon icon="#icon-xuanze-" className="icon" />
            </StepIconWrapper>
          </SetBatch>
        )}
      </SelfSectionWrapper>
    )
  }

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value === 2) {
      handleCondition({
        ...itemEdit,
        value: defaultResultArr,
        valueType: 2,
      })
    }
    if (e.target.value === 1) {
      handleCondition({
        ...itemEdit,
        value: [],
        valueType: 1,
      })
    }
  }

  return (
    <EditContentWrapper>
      <div className="title">分组方式</div>
      <EditContent>
        <Radio.Group onChange={onChange} value={itemEdit.valueType}>
          <Radio value={1}>默认区间</Radio>
          <Radio value={2}>自定义区间</Radio>
        </Radio.Group>
        {itemEdit.valueType === 2 && <SelfSectionCom />}
      </EditContent>
    </EditContentWrapper>
  )
}

export default RadioGroup
