import React from 'react'
// import dayjs from 'dayjs'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import SvgIcon from '@/components/SvgIcon'
import { RangePickerComWrapper, RangePickerWrapper } from './styles'

const { RangePicker } = DatePicker

interface Props {
  handleValue: (value: any) => void
  filterValue: any
}

function RangePickerCom(props: Props) {
  const { handleValue, filterValue } = props

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
      handleValue(`${dateStrings[0]},${dateStrings[1]}`)
    }
  }

  const disabledDate: RangePickerProps['disabledDate'] = current =>
    current && current > dayjs().endOf('day')

  return (
    <RangePickerComWrapper>
      <RangePickerWrapper>
        <div>
          <RangePicker
            value={[
              filterValue
                ? dayjs(filterValue.split(',')[0], 'YYYY-MM-DD HH:mm:ss')
                : null,
              filterValue
                ? dayjs(filterValue.split(',')[1], 'YYYY-MM-DD HH:mm:ss')
                : null,
            ]}
            disabledDate={disabledDate}
            // size="small"
            onChange={onChange}
            suffixIcon={null}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                dayjs('00:00:00', 'HH:mm:ss'),
                dayjs('23:59:59', 'HH:mm:ss'),
              ],
            }}
            allowClear={false}
            getPopupContainer={triggerNode => triggerNode.parentElement!}
            separator={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <SvgIcon
                icon="#icon-zuojiantou-cu"
                className="icon separatorIcon"
              />
            }
            className="timePicker"
            dateRender={current => {
              const style: React.CSSProperties = {}
              const a = dayjs(current).isBefore(
                filterValue ? filterValue.split(',')[1] : ''
              )
              const b = dayjs(
                filterValue ? filterValue.split(',')[0] : ''
              ).isBefore(current)
              const c = dayjs(current).isSame(
                filterValue ? filterValue.split(',')[0] : '',
                'day'
              )
              const d = dayjs(current).isSame(
                filterValue ? filterValue.split(',')[1] : '',
                'day'
              )
              const e = dayjs(current).isSame(
                dayjs().format('YYYY-MM-DD'),
                'day'
              )

              let classNames = ''
              if (a && b) {
                classNames = 'chooseTime'
              } else {
                classNames = 'ant-picker-cell-inner'
              }
              if (d) {
                classNames = 'hideBoundaryRight'
              }
              if (c) {
                classNames = 'showBoundaryLeft'
              }
              if (c && d) {
                classNames = 'showOneDay'
              }
              let todayStyle
              if (e) {
                todayStyle = 'todayStyle'
              } else {
                todayStyle = ''
              }
              return (
                <div className={`${classNames} ${todayStyle}`} style={style}>
                  {current.date()}
                </div>
              )
            }}
          />
        </div>
      </RangePickerWrapper>
    </RangePickerComWrapper>
  )
}

export default RangePickerCom
