import styled from 'styled-components'

interface IColorWrapper {
  colorIndex: number
}

export const Wrapper = styled.div`
  margin-bottom: 30px;
`

export const TimeWrapper = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
`

export const CountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  .count {
    font-size: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.85);
  }
  .unit {
    margin-left: 6px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.85);
  }
`
export const DataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const TextWrapper = styled.div`
  height: 17px;
  font-size: 13px;
  font-family: PingFangSC-Regular-, PingFangSC-Regular;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  display: flex;
  align-items: center;
`

export const ColorWrapper = styled.div<IColorWrapper>`
  width: 13px;
  height: 13px;
  background: #f98349;
  border-radius: 50% 50%;
  margin-right: 6px;
  background: ${({ colorIndex }) =>
    // eslint-disable-next-line no-nested-ternary
    colorIndex === 0 ? '#34cdc5' : colorIndex === 1 ? '#219af2' : '#a9def4'};
`
