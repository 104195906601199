import dayjs from 'dayjs'
import React, { useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { DatePicker, Button, DatePickerProps, Input } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import { today, timeList, btnList } from './utils'

interface ITimeConditionTab {
  isActive: boolean
}
interface IContentTimeRanger {
  isShowStartDynamic: boolean
  isShowEndDynamic: boolean
}

interface IStartInputWrapper {
  isShow: boolean
}
export interface IhandleParams {
  startDateType: number // 开始时间类型。1.动态时间。2静态时间
  endDateType: number // 结束时间类型。 1.动态时间。2静态时间
  startDate: string // 开始时间
  endDate: string // 结束时间
  otherDates: string
}
interface ITimePickerCom {
  handleOk: (arg: IhandleParams) => void
  defaultTimeRange?: string[]
  defaultDateType?: number[]
  handleVisible: (arg: boolean) => void
}
const Wrapper = styled.div`
  width: 844px;
  min-height: 440px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  font-size: 14px;
`
const TitleWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding-left: 20px;
  border: 1px solid #ede7e7;
`
const CurrentTitleWrapper = styled.div`
  width: 60px;
  height: 20px;
  font-size: 12px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9f9f9f;
  line-height: 20px;
  margin-top: 8px;
`
const CurrentTimeWrapper = styled.div``

const RangeTimeWrapper = styled.span`
  font-size: 12px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #b5b5b5;
  line-height: 20px;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`

const ContentTimeCondition = styled.div`
  width: 200px;
  background: #f0f2f5;
`
const TabListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 15px 0 15px;
  width: 200px;
`

const TimeConditionTab = styled.div<ITimeConditionTab>`
  width: 80px;
  height: 34px;
  background: ${({ isActive }) => (isActive ? '#3D90FF' : '#ffffff')};
  border-radius: 11px 11px 11px 11px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: ${({ isActive }) => (isActive ? '#F7FAFF' : '#616161')};
  font-size: 14px;
  text-align: center;
  line-height: 34px;
  margin-bottom: 10px;
  cursor: pointer;
  &:nth-child(11),
  &:nth-child(12) {
    width: 100%;
  }
  &:hover {
    background: ${({ isActive }) => (isActive ? '#3D90FF' : '#b5d8ff')};
    color: ${({ isActive }) => (isActive ? '#F7FAFF' : '#616161')};
  }
  transition: all 0.3s ease-in-out;
`

const BtnWrapper = styled.div<{ isHasBgColor: boolean }>`
  margin-bottom: 10px;
  width: 80px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: ${({ isHasBgColor }) => (isHasBgColor ? '#3585FF' : '#f5f7f9')};
  color: ${({ isHasBgColor }) => (isHasBgColor ? 'white' : '#616161')};
  cursor: pointer;
`

const TextWrapper = styled.div`
  font-family: 'PingFang SC';
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #46556b;
  width: 80px;
`

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 330px;
  padding-top: 12px;
  padding-left: 20px;
  .ant-picker {
    width: 280px;
    transition: unset;
  }
  .choosedTime {
    background: #e9f6fe;
  }

  .hideBoundaryLeft {
    background: #89b5df;
    border-radius: 10px 0 0 10px;
    color: white;
  }
  .hideBoundaryRight {
    background: #89b5df;
    border-radius: 0 10px 10px 0;
    color: white;
  }
  .showBoundaryRight {
    border-radius: 0 10px 10px 0;
    background: #1890ff;
    color: white;
  }
  .showBoundaryLeft {
    border-radius: 10px 0 0 10px;
    background: #1890ff;
    color: white;
  }
  .showOneDay {
    border-radius: 10px 10px 10px 10px;
    background: #1890ff;
    color: white;
  }
  .todayStyle {
    background: #1890ff;
    border-radius: 50% 50% !important;
    color: white;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      z-index: 9;
      right: -4px;
      bottom: -4px;
      width: 0px;
      height: 0px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #3d90ff #3d90ff transparent;
    }
  }
  .ant-popover .ant-popover-inner {
    padding: unset !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50% 50% !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    /* border-width: 5px 5px;
    border-style: solid;
    border-color: #3d90ff #3d90ff transparent transparent !important; */
    border: 5px solid #3d90ff #3d90ff transparent transparent !important;
    transform: rotateX(180deg);
    top: unset;
    left: unset;
    right: -4px !important;
    bottom: -4px !important;
  }

  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 0 14px;
  }
  .ant-picker-dropdown .ant-picker-cell {
    padding: 2px 0;
  }
`

const ContentTimeRanger = styled.div<IContentTimeRanger>`
  /* width: 656px;
  padding: 20px; */
  background: white;
`
const RangeBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;
  padding: 6px 32px 10px 0;
  & > button {
    margin-left: 20px;
  }
`
const SmallBtnWrapper = styled(Button)`
  box-sizing: content-box;
  padding: 3px 18px !important;
  border-radius: 10px;
  font-size: 14px;
`

const ArrowWrapper = styled.div`
  & > div {
    font-size: 20px;
    margin: 34px 10px;
  }
`

const StartInputWrapper = styled.div<IStartInputWrapper>`
  position: absolute;
  top: 33px;
  left: 0;
  width: 280px;
  background: white;
  height: 34px;
  z-index: ${({ isShow }) => (isShow ? 1 : -1)};
  display: flex;
  & > span {
    margin-left: 20px;
    width: 50px;
  }
`

const EndInputWrapper = styled.div<IStartInputWrapper>`
  position: absolute;
  top: 33px;
  left: 0;
  width: 280px;
  z-index: ${({ isShow }) => (isShow ? 1 : -1)};
  display: flex;
  background: white;
  height: 34px;
  & > span {
    margin-left: 20px;
    width: 50px;
  }
`

export default function TimePickerCom(props: ITimePickerCom) {
  const {
    handleOk,
    defaultTimeRange,
    defaultDateType = [2, 2],
    handleVisible,
  } = props
  const [activeFlag, setActiveFlag] = useState<string>('')
  const [rangeDateValue, setRangeDateValue] = useState<any>([])
  // 1 是动态，2是动态
  const [startBtnType, setStartBtnType] = useState<number>(2)
  const [endBtnType, setEndBtnType] = useState<number>(2)
  const [startDynamicCount, setStartDynamicCount] = useState<number>(1)
  const [endDynamicCount, setEndDynamicCount] = useState<number>(1)
  const [otherDates, setOtherDates] = useState<string>('')

  const disabledDate: RangePickerProps['disabledDate'] = current =>
    current && current > dayjs().endOf('day')

  const disabledDate2: RangePickerProps['disabledDate'] = current =>
    current && current > dayjs().endOf('day')

  const cancellClick = () => {
    handleVisible(false)
  }

  const currentTime = useMemo(
    () =>
      `${dayjs(rangeDateValue[0]).format('YYYY/MM/DD')}  →  ${dayjs(
        rangeDateValue[1]
      ).format('YYYY/MM/DD')}`,
    [rangeDateValue]
  )

  const onCalendarChangeValue1: DatePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    setOtherDates('')
    const isBeforeFlag = dayjs(dates).isBefore(rangeDateValue[1])
    const isEqual = dayjs(rangeDateValue[1]).isSame(dates, 'day')
    if (!isBeforeFlag && !isEqual) {
      setRangeDateValue([dayjs(dates), dayjs(dates)])
    } else {
      rangeDateValue[0] = dates
      setRangeDateValue([...rangeDateValue])
    }
    setActiveFlag('')
  }

  const onCalendarChangeValue2: DatePickerProps['onChange'] = (
    dates,
    dateStrings
  ) => {
    setOtherDates('')
    const isBeforeFlag = dayjs(dates).isBefore(rangeDateValue[0])
    const isEqual = dayjs(rangeDateValue[1]).isSame(dates, 'day')
    if (isBeforeFlag && !isEqual) {
      setRangeDateValue([dayjs(dates), dayjs(dates)])
    } else {
      rangeDateValue[1] = dates
      setRangeDateValue([...rangeDateValue])
    }

    setActiveFlag('')
  }
  const okClick = () => {
    handleVisible(false)
    setActiveFlag
    const pickerParams = {
      startDateType: startBtnType, // 开始时间类型。1.动态时间。2静态时间
      endDateType: endBtnType, // 结束时间类型。 1.动态时间。2静态时间
      startDate: dayjs(rangeDateValue[0]).format('YYYY-MM-DD'), // 开始时间
      endDate: dayjs(rangeDateValue[1]).format('YYYY-MM-DD'), // 结束时间
      otherDates: otherDates, // 其他时间 thisMonth：本月 lastMonth：上月 thisWeek：本周 lastWeek：上周
    }
    handleOk(pickerParams)
  }

  useEffect(() => {
    setRangeDateValue([
      dayjs(defaultTimeRange![0]),
      dayjs(defaultTimeRange![1]),
    ])
  }, [defaultTimeRange])

  useEffect(() => {
    setStartBtnType(defaultDateType![0])
    setEndBtnType(defaultDateType![1])
  }, [defaultDateType])

  useEffect(() => {
    const isBeforeFlag = dayjs(rangeDateValue[0]).isBefore(rangeDateValue[1])
    const isEqual = dayjs(rangeDateValue[1]).isSame(rangeDateValue[0], 'day')

    if (!isBeforeFlag && !isEqual) {
      setRangeDateValue([dayjs(rangeDateValue[0]), dayjs(rangeDateValue[0])])
    }
    const dynamicStartCount = dayjs(dayjs().format('YYYY-MM-DD')).diff(
      rangeDateValue[0],
      'days'
    )
    const dynamicEndCount = dayjs(dayjs().format('YYYY-MM-DD')).diff(
      rangeDateValue[1],
      'days'
    )
    setStartDynamicCount(dynamicStartCount)
    setEndDynamicCount(dynamicEndCount)
  }, [rangeDateValue])

  return (
    <Wrapper className="timePick">
      <TitleWrapper>
        <CurrentTitleWrapper>当前日期</CurrentTitleWrapper>
        <CurrentTimeWrapper>
          {/* <DayWrapper>过去22天&nbsp;</DayWrapper> */}
          <RangeTimeWrapper>{`(${currentTime})`}</RangeTimeWrapper>
        </CurrentTimeWrapper>
      </TitleWrapper>

      <ContentWrapper>
        <ContentTimeCondition>
          <TabListWrapper>
            {timeList.map((item, index) => (
              <TimeConditionTab
                isActive={activeFlag === item.timeName}
                onClick={() => {
                  setActiveFlag(item.timeName)
                  setOtherDates(item.typeName)
                  setRangeDateValue([
                    dayjs(item.value[0]),
                    dayjs(item.value[1]),
                  ])
                }}
                key={index}
              >
                {item.timeName}
              </TimeConditionTab>
            ))}
          </TabListWrapper>
        </ContentTimeCondition>
        <ContentTimeRanger
          isShowStartDynamic={startBtnType === 1}
          isShowEndDynamic={endBtnType === 1}
        >
          <DatePickerWrapper>
            <div>
              <div style={{ display: 'flex', position: 'relative' }}>
                {btnList.map((item, index) => {
                  if (endBtnType === 2 && item.type === 1) {
                    return
                  }

                  return (
                    <BtnWrapper
                      key={index}
                      onClick={() => setStartBtnType(item.type)}
                      isHasBgColor={startBtnType === index + 1}
                    >
                      {item.name}
                    </BtnWrapper>
                  )
                })}
                <StartInputWrapper isShow={startBtnType === 1}>
                  <Input
                    placeholder="请选择日期"
                    value={startDynamicCount}
                    onChange={e => {
                      setOtherDates('')
                      console.log(e, 'inputNumner')
                      const dates = dayjs()
                        .subtract(Number(e.target.value), 'days')
                        .format('YYYY-MM-DD')

                      setRangeDateValue([
                        dayjs(dates),
                        dayjs(rangeDateValue[0]),
                      ])
                      setStartDynamicCount(Number(e.target.value))
                    }}
                  />
                  <TextWrapper>天前</TextWrapper>
                </StartInputWrapper>
              </div>

              <DatePicker
                getPopupContainer={triggerNode => triggerNode.parentElement!}
                value={rangeDateValue[0]}
                onChange={onCalendarChangeValue1}
                showToday={false}
                open
                suffixIcon={null}
                allowClear={false}
                disabledDate={disabledDate}
                dateRender={current => {
                  const style: React.CSSProperties = {}
                  // style.borderRadius = '50%'
                  const a = dayjs(current).isBefore(rangeDateValue[1])
                  const b = dayjs(rangeDateValue[0]).isBefore(current)
                  const c = dayjs(current).isSame(rangeDateValue[0], 'day')
                  const d = dayjs(current).isSame(rangeDateValue[1], 'day')
                  const e = dayjs(current).isSame(today, 'day')

                  let classNames = ''
                  if (a && b) {
                    classNames = 'choosedTime'
                  } else {
                    classNames = 'ant-picker-cell-inner'
                  }
                  if (d) {
                    classNames = 'hideBoundaryRight'
                  }
                  if (c) {
                    classNames = 'showBoundaryLeft'
                  }
                  if (c && d) {
                    classNames = 'showOneDay'
                  }
                  let todayStyle
                  if (e) {
                    todayStyle = 'todayStyle'
                  } else {
                    todayStyle = ''
                  }
                  return (
                    <div
                      className={`${classNames} ${todayStyle}`}
                      style={style}
                    >
                      {current.date()}
                    </div>
                  )
                }}
              />
            </div>
            <ArrowWrapper>
              <div>→</div>
            </ArrowWrapper>
            <div>
              <div style={{ display: 'flex', position: 'relative' }}>
                {btnList.map((item, index) => (
                  <BtnWrapper
                    key={index}
                    onClick={() => {
                      if (item.type === 2) {
                        setStartBtnType(2)
                      }
                      setEndBtnType(item.type)
                    }}
                    isHasBgColor={endBtnType === index + 1}
                  >
                    {item.name}
                  </BtnWrapper>
                ))}
                <EndInputWrapper isShow={endBtnType === 1}>
                  <Input
                    placeholder="请选择日期"
                    value={endDynamicCount}
                    onChange={e => {
                      setOtherDates('')

                      const dates = dayjs()
                        .subtract(Number(e.target.value), 'days')
                        .format('YYYY-MM-DD')

                      setRangeDateValue([
                        dayjs(rangeDateValue[0]),
                        dayjs(dates),
                      ])
                      setEndDynamicCount(Number(e.target.value))
                    }}
                  />
                  <TextWrapper>天前</TextWrapper>
                </EndInputWrapper>
              </div>

              <DatePicker
                getPopupContainer={triggerNode => triggerNode.parentElement!}
                value={rangeDateValue[1]}
                onChange={onCalendarChangeValue2}
                showToday={false}
                allowClear={false}
                disabledDate={disabledDate2}
                suffixIcon={null}
                dateRender={current => {
                  const style: React.CSSProperties = {}
                  // style.borderRadius = '50%'
                  const a = dayjs(current).isBefore(rangeDateValue[1])
                  const b = dayjs(rangeDateValue[0]).isBefore(current)
                  const c = dayjs(current).isSame(rangeDateValue[1], 'day')
                  const d = dayjs(current).isSame(rangeDateValue[0], 'day')
                  const e = dayjs(current).isSame(today, 'day')
                  let classNames = ''
                  if (a && b) {
                    classNames = 'choosedTime'
                  } else {
                    classNames = 'ant-picker-cell-inner'
                  }
                  if (d) {
                    classNames = 'hideBoundaryLeft'
                  }
                  if (c) {
                    classNames = 'showBoundaryRight'
                  }
                  if (c && d) {
                    classNames = 'showOneDay'
                  }
                  let todayStyle
                  if (e) {
                    todayStyle = 'todayStyle'
                  } else {
                    todayStyle = ''
                  }
                  return (
                    <div
                      className={`${classNames} ${todayStyle}`}
                      style={style}
                    >
                      {current.date()}
                    </div>
                  )
                }}
                open
              />
            </div>
          </DatePickerWrapper>
          <RangeBtnWrapper>
            <SmallBtnWrapper onClick={okClick} size="small" type="primary">
              确定
            </SmallBtnWrapper>
            <SmallBtnWrapper onClick={cancellClick} size="small">
              取消
            </SmallBtnWrapper>
          </RangeBtnWrapper>
        </ContentTimeRanger>
      </ContentWrapper>
    </Wrapper>
  )
}
