import styled from 'styled-components'

export const ConditionItem = styled.div`
  padding: 4px 0 0;
`
export const ActionRow = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 24px;
  line-height: 24px;
  :hover {
    background-color: rgb(246, 248, 250);
    .hoverBtn {
      background-color: #fff;
      border-color: #d9dfe6;
    }
    .actionRight {
      opacity: 1;
    }
  }
`

export const ActionLeft = styled.div`
  display: flex;
  align-items: flex-start;
  height: auto;
`

export const ActionCondition = styled.div`
  display: inline-block;
  min-height: 32px;
  padding-bottom: 2px;
  white-space: normal;
`
export const ConditionLeft = styled.div`
  display: inline-block;
  align-self: center;
  min-width: 40px;
  height: 28px;
  margin: 2px 4px 0 0;
  padding: 0 6px;
  font-size: 14px;
  line-height: 26px;
  white-space: nowrap;
  vertical-align: top;
  background-color: rgb(240, 242, 245);
  border: 1px solid rgb(240, 242, 245);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    margin-right: 2px;
    color: rgb(103, 114, 157);
    font-size: 14px;
  }
  .label {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    color: rgb(103, 114, 157);
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  :hover {
    border: 1px solid rgb(61, 144, 255);
    background-color: #fff;
  }
`
export const ActionRight = styled.div`
  padding-top: 4px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
`
export const ActionAdd = styled.div`
  margin-left: 4px;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  line-height: 22px;
  text-align: center;
  background-color: transparent;
  border-radius: 2px;

  cursor: pointer;
  transition: all 0.3s;
  .icon {
    width: 16px;
    height: 16px;
    color: rgb(103, 114, 157);
    font-size: 16px;
    vertical-align: sub;
    transition: color 0.3s;
  }
  :hover {
    background-color: rgb(240, 242, 245);
    .icon {
      color: rgb(61, 144, 255);
    }
  }
`
export const ActionDelete = styled.div`
  margin-left: 4px;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  padding: 0 4px;
  line-height: 22px;
  text-align: center;
  background-color: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  .icon {
    width: 16px;
    height: 16px;
    color: rgb(103, 114, 157);
    font-size: 16px;
    vertical-align: sub;
    transition: color 0.3s;
  }
  :hover {
    background-color: rgb(240, 242, 245);
    .icon {
      color: red;
    }
  }
`

export const HistoryWrapper = styled.div`
  margin: 0 4px;
  display: inline-block;
  height: 28px;
`
export const EditContentWrapper = styled.div`
  padding: 16px;
  .title {
    margin-bottom: 5px;
    color: #202d3f;
    font-weight: 500;
    font-size: 14px;
  }
`
export const EditContent = styled.div`
  display: flex;
`

export const Word = styled.span`
  margin: 0px 5px;
  height: 28px;
  line-height: 28px;
  color: rgb(103, 114, 157);
`

export const Title = styled.div`
  margin-bottom: 5px;
  color: #202d3f;
  font-weight: 500;
  font-size: 14px;
  .icon {
    margin-left: 3px;
  }
`
interface ConditionLeftProps {
  isOpen: boolean // 是否打开
}
export const VersionWrapper = styled.div<ConditionLeftProps>`
  display: inline-block;
  align-self: center;
  min-width: 40px;
  height: 28px;
  padding: 0 6px;
  font-size: 14px;
  line-height: 26px;
  white-space: nowrap;
  vertical-align: top;
  background-color: #fff;
  border: ${props =>
    props.isOpen
      ? '1px solid rgb(61, 144, 255 ) !important'
      : '1px solid rgb(240, 242, 245)'};
  background-color: ${props =>
    props.isOpen ? '#fff !important' : 'rgb(240, 242, 245)'};
  color: rgb(103, 114, 157);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    border: 1px solid rgb(61, 144, 255) !important;
    background-color: #fff !important;
  }
`
